'use strict';
export const validateDisconnectParticipant200 = validate11;
const schema12 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        '200': {
            type: 'object',
            additionalProperties: false,
            properties: {
                status: {
                    type: 'string',
                    description: 'Status of the response, eg. success.',
                    enum: ['success'],
                },
                result: {
                    oneOf: [
                        {type: 'null'},
                        {
                            type: 'boolean',
                            description:
                                'The result is true if successful, false otherwise.',
                        },
                    ],
                },
            },
            required: ['status', 'result'],
            title: 'Status200',
        },
    },
    oneOf: [
        {
            $ref: '#/definitions/disconnect_participant/properties/200/definitions/200',
        },
    ],
};
const schema13 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        status: {
            type: 'string',
            description: 'Status of the response, eg. success.',
            enum: ['success'],
        },
        result: {
            oneOf: [
                {type: 'null'},
                {
                    type: 'boolean',
                    description:
                        'The result is true if successful, false otherwise.',
                },
            ],
        },
    },
    required: ['status', 'result'],
    title: 'Status200',
};
function validate11(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/disconnect_participant/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/disconnect_participant/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/disconnect_participant/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema13.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs7 = errors;
                            const _errs8 = errors;
                            let valid3 = false;
                            let passing1 = null;
                            const _errs9 = errors;
                            if (data1 !== null) {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/disconnect_participant/properties/200/definitions/200/properties/result/oneOf/0/type',
                                    keyword: 'type',
                                    params: {type: 'null'},
                                    message: 'must be null',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var _valid1 = _errs9 === errors;
                            if (_valid1) {
                                valid3 = true;
                                passing1 = 0;
                            }
                            const _errs11 = errors;
                            if (typeof data1 !== 'boolean') {
                                const err4 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/disconnect_participant/properties/200/definitions/200/properties/result/oneOf/1/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err4];
                                } else {
                                    vErrors.push(err4);
                                }
                                errors++;
                            }
                            var _valid1 = _errs11 === errors;
                            if (_valid1 && valid3) {
                                valid3 = false;
                                passing1 = [passing1, 1];
                            } else {
                                if (_valid1) {
                                    valid3 = true;
                                    passing1 = 1;
                                }
                            }
                            if (!valid3) {
                                const err5 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/disconnect_participant/properties/200/definitions/200/properties/result/oneOf',
                                    keyword: 'oneOf',
                                    params: {passingSchemas: passing1},
                                    message:
                                        'must match exactly one schema in oneOf',
                                };
                                if (vErrors === null) {
                                    vErrors = [err5];
                                } else {
                                    vErrors.push(err5);
                                }
                                errors++;
                            } else {
                                errors = _errs8;
                                if (vErrors !== null) {
                                    if (_errs8) {
                                        vErrors.length = _errs8;
                                    } else {
                                        vErrors = null;
                                    }
                                }
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err6 = {
                instancePath,
                schemaPath:
                    '#/definitions/disconnect_participant/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err6];
            } else {
                vErrors.push(err6);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err7 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err7];
        } else {
            vErrors.push(err7);
        }
        errors++;
        validate11.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate11.errors = vErrors;
    return errors === 0;
}
export const validateDisconnectParticipant403 = validate12;
const schema14 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        '403': {
            type: 'object',
            additionalProperties: false,
            properties: {
                status: {
                    type: 'string',
                    description: 'Status of the response, eg. success.',
                    enum: ['success', 'failed'],
                },
                result: {
                    type: 'string',
                    enum: [
                        '404 Not Found',
                        '502 Bad Gateway',
                        '503 Service Unavailable',
                        'All conference hosts departed hosted conference',
                        'API initiated conference termination',
                        'API initiated participant disconnect',
                        'Call disconnected',
                        'Call rejected',
                        'Conference extension not found',
                        'Conference host ended the conference with a DTMF command',
                        'Conference terminated by an administrator',
                        'Conference terminated by another participant',
                        'Disabled',
                        'Disconnected by an administrator',
                        'Disconnected by another participant',
                        'Failed to forward request',
                        'Gateway dial out failed',
                        'Ice failure',
                        'Insufficient licenses',
                        'invalid gateway routing rule transform',
                        'Invalid license',
                        'Invalid PIN',
                        'Invalid protocol for service',
                        'Invalid role',
                        'Invalid token',
                        'Last remaining participant removed from conference after timeout',
                        'Media node disconnected',
                        'Media process disconnected',
                        'Neither conference nor gateway found',
                        'No direct route between Edge and Transcoding',
                        'No participants can keep conference alive',
                        'Out of proxying resource',
                        'Out of resource',
                        'Out of transcoding resource',
                        'Participant exceeded PIN entry retries',
                        'Participant limit reached',
                        'Proxied participant disconnected',
                        'Resource unavailable',
                        'Signaling node disconnected',
                        'SSO Authentication Failed. SSO is not available from this domain',
                        'SSO Authentication Failed. The system is in Maintenance mode',
                        'SSO Authentication Failed',
                        'SSO enabled but no Identity Providers configured',
                        'System in maintenance mode',
                        'Telehealth Call failure',
                        'Test call finished',
                        'Timeout waiting for conference host to join or permit access to locked conference',
                        'Timer expired awaiting token refresh',
                        'transfer failed',
                        'Unexpected Response: 503',
                        'Attempted to acquire license while license already acquired. This is usually due to an internal error.',
                        'Failed to transfer into a multi-party conference',
                        'Failed to transfer into a one-to-one conference',
                    ],
                    description: 'Error strings',
                },
            },
            required: ['status', 'result'],
            title: 'Status403',
        },
    },
    oneOf: [{$ref: '#/definitions/take_floor/properties/403/definitions/403'}],
};
const schema15 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        status: {
            type: 'string',
            description: 'Status of the response, eg. success.',
            enum: ['success', 'failed'],
        },
        result: {
            type: 'string',
            enum: [
                '404 Not Found',
                '502 Bad Gateway',
                '503 Service Unavailable',
                'All conference hosts departed hosted conference',
                'API initiated conference termination',
                'API initiated participant disconnect',
                'Call disconnected',
                'Call rejected',
                'Conference extension not found',
                'Conference host ended the conference with a DTMF command',
                'Conference terminated by an administrator',
                'Conference terminated by another participant',
                'Disabled',
                'Disconnected by an administrator',
                'Disconnected by another participant',
                'Failed to forward request',
                'Gateway dial out failed',
                'Ice failure',
                'Insufficient licenses',
                'invalid gateway routing rule transform',
                'Invalid license',
                'Invalid PIN',
                'Invalid protocol for service',
                'Invalid role',
                'Invalid token',
                'Last remaining participant removed from conference after timeout',
                'Media node disconnected',
                'Media process disconnected',
                'Neither conference nor gateway found',
                'No direct route between Edge and Transcoding',
                'No participants can keep conference alive',
                'Out of proxying resource',
                'Out of resource',
                'Out of transcoding resource',
                'Participant exceeded PIN entry retries',
                'Participant limit reached',
                'Proxied participant disconnected',
                'Resource unavailable',
                'Signaling node disconnected',
                'SSO Authentication Failed. SSO is not available from this domain',
                'SSO Authentication Failed. The system is in Maintenance mode',
                'SSO Authentication Failed',
                'SSO enabled but no Identity Providers configured',
                'System in maintenance mode',
                'Telehealth Call failure',
                'Test call finished',
                'Timeout waiting for conference host to join or permit access to locked conference',
                'Timer expired awaiting token refresh',
                'transfer failed',
                'Unexpected Response: 503',
                'Attempted to acquire license while license already acquired. This is usually due to an internal error.',
                'Failed to transfer into a multi-party conference',
                'Failed to transfer into a one-to-one conference',
            ],
            description: 'Error strings',
        },
    },
    required: ['status', 'result'],
    title: 'Status403',
};
function validate12(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/take_floor/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/take_floor/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/take_floor/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema15.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs7 = errors;
                            if (typeof data1 !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/take_floor/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            if (
                                !(
                                    data1 === '404 Not Found' ||
                                    data1 === '502 Bad Gateway' ||
                                    data1 === '503 Service Unavailable' ||
                                    data1 ===
                                        'All conference hosts departed hosted conference' ||
                                    data1 ===
                                        'API initiated conference termination' ||
                                    data1 ===
                                        'API initiated participant disconnect' ||
                                    data1 === 'Call disconnected' ||
                                    data1 === 'Call rejected' ||
                                    data1 ===
                                        'Conference extension not found' ||
                                    data1 ===
                                        'Conference host ended the conference with a DTMF command' ||
                                    data1 ===
                                        'Conference terminated by an administrator' ||
                                    data1 ===
                                        'Conference terminated by another participant' ||
                                    data1 === 'Disabled' ||
                                    data1 ===
                                        'Disconnected by an administrator' ||
                                    data1 ===
                                        'Disconnected by another participant' ||
                                    data1 === 'Failed to forward request' ||
                                    data1 === 'Gateway dial out failed' ||
                                    data1 === 'Ice failure' ||
                                    data1 === 'Insufficient licenses' ||
                                    data1 ===
                                        'invalid gateway routing rule transform' ||
                                    data1 === 'Invalid license' ||
                                    data1 === 'Invalid PIN' ||
                                    data1 === 'Invalid protocol for service' ||
                                    data1 === 'Invalid role' ||
                                    data1 === 'Invalid token' ||
                                    data1 ===
                                        'Last remaining participant removed from conference after timeout' ||
                                    data1 === 'Media node disconnected' ||
                                    data1 === 'Media process disconnected' ||
                                    data1 ===
                                        'Neither conference nor gateway found' ||
                                    data1 ===
                                        'No direct route between Edge and Transcoding' ||
                                    data1 ===
                                        'No participants can keep conference alive' ||
                                    data1 === 'Out of proxying resource' ||
                                    data1 === 'Out of resource' ||
                                    data1 === 'Out of transcoding resource' ||
                                    data1 ===
                                        'Participant exceeded PIN entry retries' ||
                                    data1 === 'Participant limit reached' ||
                                    data1 ===
                                        'Proxied participant disconnected' ||
                                    data1 === 'Resource unavailable' ||
                                    data1 === 'Signaling node disconnected' ||
                                    data1 ===
                                        'SSO Authentication Failed. SSO is not available from this domain' ||
                                    data1 ===
                                        'SSO Authentication Failed. The system is in Maintenance mode' ||
                                    data1 === 'SSO Authentication Failed' ||
                                    data1 ===
                                        'SSO enabled but no Identity Providers configured' ||
                                    data1 === 'System in maintenance mode' ||
                                    data1 === 'Telehealth Call failure' ||
                                    data1 === 'Test call finished' ||
                                    data1 ===
                                        'Timeout waiting for conference host to join or permit access to locked conference' ||
                                    data1 ===
                                        'Timer expired awaiting token refresh' ||
                                    data1 === 'transfer failed' ||
                                    data1 === 'Unexpected Response: 503' ||
                                    data1 ===
                                        'Attempted to acquire license while license already acquired. This is usually due to an internal error.' ||
                                    data1 ===
                                        'Failed to transfer into a multi-party conference' ||
                                    data1 ===
                                        'Failed to transfer into a one-to-one conference'
                                )
                            ) {
                                const err4 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/take_floor/properties/403/definitions/403/properties/result/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema15.properties.result.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                };
                                if (vErrors === null) {
                                    vErrors = [err4];
                                } else {
                                    vErrors.push(err4);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err5 = {
                instancePath,
                schemaPath:
                    '#/definitions/take_floor/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err5];
            } else {
                vErrors.push(err5);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err6 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err6];
        } else {
            vErrors.push(err6);
        }
        errors++;
        validate12.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate12.errors = vErrors;
    return errors === 0;
}
export const validateMuteParticipant200 = validate13;
const schema16 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        '200': {
            type: 'object',
            additionalProperties: false,
            properties: {
                status: {
                    type: 'string',
                    description: 'Status of the response, eg. success.',
                    enum: ['success'],
                },
                result: {
                    type: 'boolean',
                    description:
                        'The result is true if successful, false otherwise.',
                },
            },
            required: ['status', 'result'],
            title: 'Status200',
        },
    },
    oneOf: [
        {$ref: '#/definitions/mute_participant/properties/200/definitions/200'},
    ],
};
const schema17 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        status: {
            type: 'string',
            description: 'Status of the response, eg. success.',
            enum: ['success'],
        },
        result: {
            type: 'boolean',
            description: 'The result is true if successful, false otherwise.',
        },
    },
    required: ['status', 'result'],
    title: 'Status200',
};
function validate13(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/mute_participant/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/mute_participant/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/mute_participant/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema17.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/mute_participant/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/mute_participant/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate13.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate13.errors = vErrors;
    return errors === 0;
}
export const validateMuteParticipant403 = validate14;
function validate14(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/take_floor/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/take_floor/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/take_floor/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema15.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs7 = errors;
                            if (typeof data1 !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/take_floor/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            if (
                                !(
                                    data1 === '404 Not Found' ||
                                    data1 === '502 Bad Gateway' ||
                                    data1 === '503 Service Unavailable' ||
                                    data1 ===
                                        'All conference hosts departed hosted conference' ||
                                    data1 ===
                                        'API initiated conference termination' ||
                                    data1 ===
                                        'API initiated participant disconnect' ||
                                    data1 === 'Call disconnected' ||
                                    data1 === 'Call rejected' ||
                                    data1 ===
                                        'Conference extension not found' ||
                                    data1 ===
                                        'Conference host ended the conference with a DTMF command' ||
                                    data1 ===
                                        'Conference terminated by an administrator' ||
                                    data1 ===
                                        'Conference terminated by another participant' ||
                                    data1 === 'Disabled' ||
                                    data1 ===
                                        'Disconnected by an administrator' ||
                                    data1 ===
                                        'Disconnected by another participant' ||
                                    data1 === 'Failed to forward request' ||
                                    data1 === 'Gateway dial out failed' ||
                                    data1 === 'Ice failure' ||
                                    data1 === 'Insufficient licenses' ||
                                    data1 ===
                                        'invalid gateway routing rule transform' ||
                                    data1 === 'Invalid license' ||
                                    data1 === 'Invalid PIN' ||
                                    data1 === 'Invalid protocol for service' ||
                                    data1 === 'Invalid role' ||
                                    data1 === 'Invalid token' ||
                                    data1 ===
                                        'Last remaining participant removed from conference after timeout' ||
                                    data1 === 'Media node disconnected' ||
                                    data1 === 'Media process disconnected' ||
                                    data1 ===
                                        'Neither conference nor gateway found' ||
                                    data1 ===
                                        'No direct route between Edge and Transcoding' ||
                                    data1 ===
                                        'No participants can keep conference alive' ||
                                    data1 === 'Out of proxying resource' ||
                                    data1 === 'Out of resource' ||
                                    data1 === 'Out of transcoding resource' ||
                                    data1 ===
                                        'Participant exceeded PIN entry retries' ||
                                    data1 === 'Participant limit reached' ||
                                    data1 ===
                                        'Proxied participant disconnected' ||
                                    data1 === 'Resource unavailable' ||
                                    data1 === 'Signaling node disconnected' ||
                                    data1 ===
                                        'SSO Authentication Failed. SSO is not available from this domain' ||
                                    data1 ===
                                        'SSO Authentication Failed. The system is in Maintenance mode' ||
                                    data1 === 'SSO Authentication Failed' ||
                                    data1 ===
                                        'SSO enabled but no Identity Providers configured' ||
                                    data1 === 'System in maintenance mode' ||
                                    data1 === 'Telehealth Call failure' ||
                                    data1 === 'Test call finished' ||
                                    data1 ===
                                        'Timeout waiting for conference host to join or permit access to locked conference' ||
                                    data1 ===
                                        'Timer expired awaiting token refresh' ||
                                    data1 === 'transfer failed' ||
                                    data1 === 'Unexpected Response: 503' ||
                                    data1 ===
                                        'Attempted to acquire license while license already acquired. This is usually due to an internal error.' ||
                                    data1 ===
                                        'Failed to transfer into a multi-party conference' ||
                                    data1 ===
                                        'Failed to transfer into a one-to-one conference'
                                )
                            ) {
                                const err4 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/take_floor/properties/403/definitions/403/properties/result/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema15.properties.result.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                };
                                if (vErrors === null) {
                                    vErrors = [err4];
                                } else {
                                    vErrors.push(err4);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err5 = {
                instancePath,
                schemaPath:
                    '#/definitions/take_floor/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err5];
            } else {
                vErrors.push(err5);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err6 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err6];
        } else {
            vErrors.push(err6);
        }
        errors++;
        validate14.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate14.errors = vErrors;
    return errors === 0;
}
export const validateUnmuteParticipant200 = validate15;
function validate15(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/mute_participant/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/mute_participant/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/mute_participant/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema17.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/mute_participant/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/mute_participant/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate15.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate15.errors = vErrors;
    return errors === 0;
}
export const validateUnmuteParticipant403 = validate16;
function validate16(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/take_floor/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/take_floor/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/take_floor/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema15.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs7 = errors;
                            if (typeof data1 !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/take_floor/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            if (
                                !(
                                    data1 === '404 Not Found' ||
                                    data1 === '502 Bad Gateway' ||
                                    data1 === '503 Service Unavailable' ||
                                    data1 ===
                                        'All conference hosts departed hosted conference' ||
                                    data1 ===
                                        'API initiated conference termination' ||
                                    data1 ===
                                        'API initiated participant disconnect' ||
                                    data1 === 'Call disconnected' ||
                                    data1 === 'Call rejected' ||
                                    data1 ===
                                        'Conference extension not found' ||
                                    data1 ===
                                        'Conference host ended the conference with a DTMF command' ||
                                    data1 ===
                                        'Conference terminated by an administrator' ||
                                    data1 ===
                                        'Conference terminated by another participant' ||
                                    data1 === 'Disabled' ||
                                    data1 ===
                                        'Disconnected by an administrator' ||
                                    data1 ===
                                        'Disconnected by another participant' ||
                                    data1 === 'Failed to forward request' ||
                                    data1 === 'Gateway dial out failed' ||
                                    data1 === 'Ice failure' ||
                                    data1 === 'Insufficient licenses' ||
                                    data1 ===
                                        'invalid gateway routing rule transform' ||
                                    data1 === 'Invalid license' ||
                                    data1 === 'Invalid PIN' ||
                                    data1 === 'Invalid protocol for service' ||
                                    data1 === 'Invalid role' ||
                                    data1 === 'Invalid token' ||
                                    data1 ===
                                        'Last remaining participant removed from conference after timeout' ||
                                    data1 === 'Media node disconnected' ||
                                    data1 === 'Media process disconnected' ||
                                    data1 ===
                                        'Neither conference nor gateway found' ||
                                    data1 ===
                                        'No direct route between Edge and Transcoding' ||
                                    data1 ===
                                        'No participants can keep conference alive' ||
                                    data1 === 'Out of proxying resource' ||
                                    data1 === 'Out of resource' ||
                                    data1 === 'Out of transcoding resource' ||
                                    data1 ===
                                        'Participant exceeded PIN entry retries' ||
                                    data1 === 'Participant limit reached' ||
                                    data1 ===
                                        'Proxied participant disconnected' ||
                                    data1 === 'Resource unavailable' ||
                                    data1 === 'Signaling node disconnected' ||
                                    data1 ===
                                        'SSO Authentication Failed. SSO is not available from this domain' ||
                                    data1 ===
                                        'SSO Authentication Failed. The system is in Maintenance mode' ||
                                    data1 === 'SSO Authentication Failed' ||
                                    data1 ===
                                        'SSO enabled but no Identity Providers configured' ||
                                    data1 === 'System in maintenance mode' ||
                                    data1 === 'Telehealth Call failure' ||
                                    data1 === 'Test call finished' ||
                                    data1 ===
                                        'Timeout waiting for conference host to join or permit access to locked conference' ||
                                    data1 ===
                                        'Timer expired awaiting token refresh' ||
                                    data1 === 'transfer failed' ||
                                    data1 === 'Unexpected Response: 503' ||
                                    data1 ===
                                        'Attempted to acquire license while license already acquired. This is usually due to an internal error.' ||
                                    data1 ===
                                        'Failed to transfer into a multi-party conference' ||
                                    data1 ===
                                        'Failed to transfer into a one-to-one conference'
                                )
                            ) {
                                const err4 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/take_floor/properties/403/definitions/403/properties/result/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema15.properties.result.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                };
                                if (vErrors === null) {
                                    vErrors = [err4];
                                } else {
                                    vErrors.push(err4);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err5 = {
                instancePath,
                schemaPath:
                    '#/definitions/take_floor/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err5];
            } else {
                vErrors.push(err5);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err6 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err6];
        } else {
            vErrors.push(err6);
        }
        errors++;
        validate16.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate16.errors = vErrors;
    return errors === 0;
}
export const validateVideoMuteParticipant200 = validate17;
function validate17(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/mute_participant/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/mute_participant/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/mute_participant/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema17.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/mute_participant/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/mute_participant/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate17.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate17.errors = vErrors;
    return errors === 0;
}
export const validateVideoMuteParticipant403 = validate18;
function validate18(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/take_floor/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/take_floor/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/take_floor/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema15.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs7 = errors;
                            if (typeof data1 !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/take_floor/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            if (
                                !(
                                    data1 === '404 Not Found' ||
                                    data1 === '502 Bad Gateway' ||
                                    data1 === '503 Service Unavailable' ||
                                    data1 ===
                                        'All conference hosts departed hosted conference' ||
                                    data1 ===
                                        'API initiated conference termination' ||
                                    data1 ===
                                        'API initiated participant disconnect' ||
                                    data1 === 'Call disconnected' ||
                                    data1 === 'Call rejected' ||
                                    data1 ===
                                        'Conference extension not found' ||
                                    data1 ===
                                        'Conference host ended the conference with a DTMF command' ||
                                    data1 ===
                                        'Conference terminated by an administrator' ||
                                    data1 ===
                                        'Conference terminated by another participant' ||
                                    data1 === 'Disabled' ||
                                    data1 ===
                                        'Disconnected by an administrator' ||
                                    data1 ===
                                        'Disconnected by another participant' ||
                                    data1 === 'Failed to forward request' ||
                                    data1 === 'Gateway dial out failed' ||
                                    data1 === 'Ice failure' ||
                                    data1 === 'Insufficient licenses' ||
                                    data1 ===
                                        'invalid gateway routing rule transform' ||
                                    data1 === 'Invalid license' ||
                                    data1 === 'Invalid PIN' ||
                                    data1 === 'Invalid protocol for service' ||
                                    data1 === 'Invalid role' ||
                                    data1 === 'Invalid token' ||
                                    data1 ===
                                        'Last remaining participant removed from conference after timeout' ||
                                    data1 === 'Media node disconnected' ||
                                    data1 === 'Media process disconnected' ||
                                    data1 ===
                                        'Neither conference nor gateway found' ||
                                    data1 ===
                                        'No direct route between Edge and Transcoding' ||
                                    data1 ===
                                        'No participants can keep conference alive' ||
                                    data1 === 'Out of proxying resource' ||
                                    data1 === 'Out of resource' ||
                                    data1 === 'Out of transcoding resource' ||
                                    data1 ===
                                        'Participant exceeded PIN entry retries' ||
                                    data1 === 'Participant limit reached' ||
                                    data1 ===
                                        'Proxied participant disconnected' ||
                                    data1 === 'Resource unavailable' ||
                                    data1 === 'Signaling node disconnected' ||
                                    data1 ===
                                        'SSO Authentication Failed. SSO is not available from this domain' ||
                                    data1 ===
                                        'SSO Authentication Failed. The system is in Maintenance mode' ||
                                    data1 === 'SSO Authentication Failed' ||
                                    data1 ===
                                        'SSO enabled but no Identity Providers configured' ||
                                    data1 === 'System in maintenance mode' ||
                                    data1 === 'Telehealth Call failure' ||
                                    data1 === 'Test call finished' ||
                                    data1 ===
                                        'Timeout waiting for conference host to join or permit access to locked conference' ||
                                    data1 ===
                                        'Timer expired awaiting token refresh' ||
                                    data1 === 'transfer failed' ||
                                    data1 === 'Unexpected Response: 503' ||
                                    data1 ===
                                        'Attempted to acquire license while license already acquired. This is usually due to an internal error.' ||
                                    data1 ===
                                        'Failed to transfer into a multi-party conference' ||
                                    data1 ===
                                        'Failed to transfer into a one-to-one conference'
                                )
                            ) {
                                const err4 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/take_floor/properties/403/definitions/403/properties/result/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema15.properties.result.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                };
                                if (vErrors === null) {
                                    vErrors = [err4];
                                } else {
                                    vErrors.push(err4);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err5 = {
                instancePath,
                schemaPath:
                    '#/definitions/take_floor/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err5];
            } else {
                vErrors.push(err5);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err6 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err6];
        } else {
            vErrors.push(err6);
        }
        errors++;
        validate18.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate18.errors = vErrors;
    return errors === 0;
}
export const validateVideoUnmuteParticipant200 = validate19;
function validate19(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/mute_participant/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/mute_participant/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/mute_participant/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema17.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/mute_participant/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/mute_participant/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate19.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate19.errors = vErrors;
    return errors === 0;
}
export const validateVideoUnmuteParticipant403 = validate20;
function validate20(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/take_floor/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/take_floor/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/take_floor/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema15.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs7 = errors;
                            if (typeof data1 !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/take_floor/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            if (
                                !(
                                    data1 === '404 Not Found' ||
                                    data1 === '502 Bad Gateway' ||
                                    data1 === '503 Service Unavailable' ||
                                    data1 ===
                                        'All conference hosts departed hosted conference' ||
                                    data1 ===
                                        'API initiated conference termination' ||
                                    data1 ===
                                        'API initiated participant disconnect' ||
                                    data1 === 'Call disconnected' ||
                                    data1 === 'Call rejected' ||
                                    data1 ===
                                        'Conference extension not found' ||
                                    data1 ===
                                        'Conference host ended the conference with a DTMF command' ||
                                    data1 ===
                                        'Conference terminated by an administrator' ||
                                    data1 ===
                                        'Conference terminated by another participant' ||
                                    data1 === 'Disabled' ||
                                    data1 ===
                                        'Disconnected by an administrator' ||
                                    data1 ===
                                        'Disconnected by another participant' ||
                                    data1 === 'Failed to forward request' ||
                                    data1 === 'Gateway dial out failed' ||
                                    data1 === 'Ice failure' ||
                                    data1 === 'Insufficient licenses' ||
                                    data1 ===
                                        'invalid gateway routing rule transform' ||
                                    data1 === 'Invalid license' ||
                                    data1 === 'Invalid PIN' ||
                                    data1 === 'Invalid protocol for service' ||
                                    data1 === 'Invalid role' ||
                                    data1 === 'Invalid token' ||
                                    data1 ===
                                        'Last remaining participant removed from conference after timeout' ||
                                    data1 === 'Media node disconnected' ||
                                    data1 === 'Media process disconnected' ||
                                    data1 ===
                                        'Neither conference nor gateway found' ||
                                    data1 ===
                                        'No direct route between Edge and Transcoding' ||
                                    data1 ===
                                        'No participants can keep conference alive' ||
                                    data1 === 'Out of proxying resource' ||
                                    data1 === 'Out of resource' ||
                                    data1 === 'Out of transcoding resource' ||
                                    data1 ===
                                        'Participant exceeded PIN entry retries' ||
                                    data1 === 'Participant limit reached' ||
                                    data1 ===
                                        'Proxied participant disconnected' ||
                                    data1 === 'Resource unavailable' ||
                                    data1 === 'Signaling node disconnected' ||
                                    data1 ===
                                        'SSO Authentication Failed. SSO is not available from this domain' ||
                                    data1 ===
                                        'SSO Authentication Failed. The system is in Maintenance mode' ||
                                    data1 === 'SSO Authentication Failed' ||
                                    data1 ===
                                        'SSO enabled but no Identity Providers configured' ||
                                    data1 === 'System in maintenance mode' ||
                                    data1 === 'Telehealth Call failure' ||
                                    data1 === 'Test call finished' ||
                                    data1 ===
                                        'Timeout waiting for conference host to join or permit access to locked conference' ||
                                    data1 ===
                                        'Timer expired awaiting token refresh' ||
                                    data1 === 'transfer failed' ||
                                    data1 === 'Unexpected Response: 503' ||
                                    data1 ===
                                        'Attempted to acquire license while license already acquired. This is usually due to an internal error.' ||
                                    data1 ===
                                        'Failed to transfer into a multi-party conference' ||
                                    data1 ===
                                        'Failed to transfer into a one-to-one conference'
                                )
                            ) {
                                const err4 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/take_floor/properties/403/definitions/403/properties/result/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema15.properties.result.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                };
                                if (vErrors === null) {
                                    vErrors = [err4];
                                } else {
                                    vErrors.push(err4);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err5 = {
                instancePath,
                schemaPath:
                    '#/definitions/take_floor/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err5];
            } else {
                vErrors.push(err5);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err6 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err6];
        } else {
            vErrors.push(err6);
        }
        errors++;
        validate20.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate20.errors = vErrors;
    return errors === 0;
}
export const validateAllowrxpresentationParticipant200 = validate21;
function validate21(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/mute_participant/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/mute_participant/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/mute_participant/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema17.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/mute_participant/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/mute_participant/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate21.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate21.errors = vErrors;
    return errors === 0;
}
export const validateAllowrxpresentationParticipant403 = validate22;
function validate22(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/take_floor/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/take_floor/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/take_floor/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema15.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs7 = errors;
                            if (typeof data1 !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/take_floor/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            if (
                                !(
                                    data1 === '404 Not Found' ||
                                    data1 === '502 Bad Gateway' ||
                                    data1 === '503 Service Unavailable' ||
                                    data1 ===
                                        'All conference hosts departed hosted conference' ||
                                    data1 ===
                                        'API initiated conference termination' ||
                                    data1 ===
                                        'API initiated participant disconnect' ||
                                    data1 === 'Call disconnected' ||
                                    data1 === 'Call rejected' ||
                                    data1 ===
                                        'Conference extension not found' ||
                                    data1 ===
                                        'Conference host ended the conference with a DTMF command' ||
                                    data1 ===
                                        'Conference terminated by an administrator' ||
                                    data1 ===
                                        'Conference terminated by another participant' ||
                                    data1 === 'Disabled' ||
                                    data1 ===
                                        'Disconnected by an administrator' ||
                                    data1 ===
                                        'Disconnected by another participant' ||
                                    data1 === 'Failed to forward request' ||
                                    data1 === 'Gateway dial out failed' ||
                                    data1 === 'Ice failure' ||
                                    data1 === 'Insufficient licenses' ||
                                    data1 ===
                                        'invalid gateway routing rule transform' ||
                                    data1 === 'Invalid license' ||
                                    data1 === 'Invalid PIN' ||
                                    data1 === 'Invalid protocol for service' ||
                                    data1 === 'Invalid role' ||
                                    data1 === 'Invalid token' ||
                                    data1 ===
                                        'Last remaining participant removed from conference after timeout' ||
                                    data1 === 'Media node disconnected' ||
                                    data1 === 'Media process disconnected' ||
                                    data1 ===
                                        'Neither conference nor gateway found' ||
                                    data1 ===
                                        'No direct route between Edge and Transcoding' ||
                                    data1 ===
                                        'No participants can keep conference alive' ||
                                    data1 === 'Out of proxying resource' ||
                                    data1 === 'Out of resource' ||
                                    data1 === 'Out of transcoding resource' ||
                                    data1 ===
                                        'Participant exceeded PIN entry retries' ||
                                    data1 === 'Participant limit reached' ||
                                    data1 ===
                                        'Proxied participant disconnected' ||
                                    data1 === 'Resource unavailable' ||
                                    data1 === 'Signaling node disconnected' ||
                                    data1 ===
                                        'SSO Authentication Failed. SSO is not available from this domain' ||
                                    data1 ===
                                        'SSO Authentication Failed. The system is in Maintenance mode' ||
                                    data1 === 'SSO Authentication Failed' ||
                                    data1 ===
                                        'SSO enabled but no Identity Providers configured' ||
                                    data1 === 'System in maintenance mode' ||
                                    data1 === 'Telehealth Call failure' ||
                                    data1 === 'Test call finished' ||
                                    data1 ===
                                        'Timeout waiting for conference host to join or permit access to locked conference' ||
                                    data1 ===
                                        'Timer expired awaiting token refresh' ||
                                    data1 === 'transfer failed' ||
                                    data1 === 'Unexpected Response: 503' ||
                                    data1 ===
                                        'Attempted to acquire license while license already acquired. This is usually due to an internal error.' ||
                                    data1 ===
                                        'Failed to transfer into a multi-party conference' ||
                                    data1 ===
                                        'Failed to transfer into a one-to-one conference'
                                )
                            ) {
                                const err4 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/take_floor/properties/403/definitions/403/properties/result/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema15.properties.result.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                };
                                if (vErrors === null) {
                                    vErrors = [err4];
                                } else {
                                    vErrors.push(err4);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err5 = {
                instancePath,
                schemaPath:
                    '#/definitions/take_floor/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err5];
            } else {
                vErrors.push(err5);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err6 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err6];
        } else {
            vErrors.push(err6);
        }
        errors++;
        validate22.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate22.errors = vErrors;
    return errors === 0;
}
export const validateDenyrxpresentationParticipant200 = validate23;
function validate23(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/mute_participant/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/mute_participant/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/mute_participant/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema17.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/mute_participant/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/mute_participant/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate23.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate23.errors = vErrors;
    return errors === 0;
}
export const validateDenyrxpresentationParticipant403 = validate24;
function validate24(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/take_floor/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/take_floor/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/take_floor/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema15.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs7 = errors;
                            if (typeof data1 !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/take_floor/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            if (
                                !(
                                    data1 === '404 Not Found' ||
                                    data1 === '502 Bad Gateway' ||
                                    data1 === '503 Service Unavailable' ||
                                    data1 ===
                                        'All conference hosts departed hosted conference' ||
                                    data1 ===
                                        'API initiated conference termination' ||
                                    data1 ===
                                        'API initiated participant disconnect' ||
                                    data1 === 'Call disconnected' ||
                                    data1 === 'Call rejected' ||
                                    data1 ===
                                        'Conference extension not found' ||
                                    data1 ===
                                        'Conference host ended the conference with a DTMF command' ||
                                    data1 ===
                                        'Conference terminated by an administrator' ||
                                    data1 ===
                                        'Conference terminated by another participant' ||
                                    data1 === 'Disabled' ||
                                    data1 ===
                                        'Disconnected by an administrator' ||
                                    data1 ===
                                        'Disconnected by another participant' ||
                                    data1 === 'Failed to forward request' ||
                                    data1 === 'Gateway dial out failed' ||
                                    data1 === 'Ice failure' ||
                                    data1 === 'Insufficient licenses' ||
                                    data1 ===
                                        'invalid gateway routing rule transform' ||
                                    data1 === 'Invalid license' ||
                                    data1 === 'Invalid PIN' ||
                                    data1 === 'Invalid protocol for service' ||
                                    data1 === 'Invalid role' ||
                                    data1 === 'Invalid token' ||
                                    data1 ===
                                        'Last remaining participant removed from conference after timeout' ||
                                    data1 === 'Media node disconnected' ||
                                    data1 === 'Media process disconnected' ||
                                    data1 ===
                                        'Neither conference nor gateway found' ||
                                    data1 ===
                                        'No direct route between Edge and Transcoding' ||
                                    data1 ===
                                        'No participants can keep conference alive' ||
                                    data1 === 'Out of proxying resource' ||
                                    data1 === 'Out of resource' ||
                                    data1 === 'Out of transcoding resource' ||
                                    data1 ===
                                        'Participant exceeded PIN entry retries' ||
                                    data1 === 'Participant limit reached' ||
                                    data1 ===
                                        'Proxied participant disconnected' ||
                                    data1 === 'Resource unavailable' ||
                                    data1 === 'Signaling node disconnected' ||
                                    data1 ===
                                        'SSO Authentication Failed. SSO is not available from this domain' ||
                                    data1 ===
                                        'SSO Authentication Failed. The system is in Maintenance mode' ||
                                    data1 === 'SSO Authentication Failed' ||
                                    data1 ===
                                        'SSO enabled but no Identity Providers configured' ||
                                    data1 === 'System in maintenance mode' ||
                                    data1 === 'Telehealth Call failure' ||
                                    data1 === 'Test call finished' ||
                                    data1 ===
                                        'Timeout waiting for conference host to join or permit access to locked conference' ||
                                    data1 ===
                                        'Timer expired awaiting token refresh' ||
                                    data1 === 'transfer failed' ||
                                    data1 === 'Unexpected Response: 503' ||
                                    data1 ===
                                        'Attempted to acquire license while license already acquired. This is usually due to an internal error.' ||
                                    data1 ===
                                        'Failed to transfer into a multi-party conference' ||
                                    data1 ===
                                        'Failed to transfer into a one-to-one conference'
                                )
                            ) {
                                const err4 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/take_floor/properties/403/definitions/403/properties/result/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema15.properties.result.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                };
                                if (vErrors === null) {
                                    vErrors = [err4];
                                } else {
                                    vErrors.push(err4);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err5 = {
                instancePath,
                schemaPath:
                    '#/definitions/take_floor/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err5];
            } else {
                vErrors.push(err5);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err6 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err6];
        } else {
            vErrors.push(err6);
        }
        errors++;
        validate24.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate24.errors = vErrors;
    return errors === 0;
}
export const validateSpotlightonParticipant200 = validate25;
function validate25(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/mute_participant/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/mute_participant/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/mute_participant/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema17.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/mute_participant/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/mute_participant/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate25.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate25.errors = vErrors;
    return errors === 0;
}
export const validateSpotlightonParticipant403 = validate26;
function validate26(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/take_floor/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/take_floor/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/take_floor/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema15.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs7 = errors;
                            if (typeof data1 !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/take_floor/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            if (
                                !(
                                    data1 === '404 Not Found' ||
                                    data1 === '502 Bad Gateway' ||
                                    data1 === '503 Service Unavailable' ||
                                    data1 ===
                                        'All conference hosts departed hosted conference' ||
                                    data1 ===
                                        'API initiated conference termination' ||
                                    data1 ===
                                        'API initiated participant disconnect' ||
                                    data1 === 'Call disconnected' ||
                                    data1 === 'Call rejected' ||
                                    data1 ===
                                        'Conference extension not found' ||
                                    data1 ===
                                        'Conference host ended the conference with a DTMF command' ||
                                    data1 ===
                                        'Conference terminated by an administrator' ||
                                    data1 ===
                                        'Conference terminated by another participant' ||
                                    data1 === 'Disabled' ||
                                    data1 ===
                                        'Disconnected by an administrator' ||
                                    data1 ===
                                        'Disconnected by another participant' ||
                                    data1 === 'Failed to forward request' ||
                                    data1 === 'Gateway dial out failed' ||
                                    data1 === 'Ice failure' ||
                                    data1 === 'Insufficient licenses' ||
                                    data1 ===
                                        'invalid gateway routing rule transform' ||
                                    data1 === 'Invalid license' ||
                                    data1 === 'Invalid PIN' ||
                                    data1 === 'Invalid protocol for service' ||
                                    data1 === 'Invalid role' ||
                                    data1 === 'Invalid token' ||
                                    data1 ===
                                        'Last remaining participant removed from conference after timeout' ||
                                    data1 === 'Media node disconnected' ||
                                    data1 === 'Media process disconnected' ||
                                    data1 ===
                                        'Neither conference nor gateway found' ||
                                    data1 ===
                                        'No direct route between Edge and Transcoding' ||
                                    data1 ===
                                        'No participants can keep conference alive' ||
                                    data1 === 'Out of proxying resource' ||
                                    data1 === 'Out of resource' ||
                                    data1 === 'Out of transcoding resource' ||
                                    data1 ===
                                        'Participant exceeded PIN entry retries' ||
                                    data1 === 'Participant limit reached' ||
                                    data1 ===
                                        'Proxied participant disconnected' ||
                                    data1 === 'Resource unavailable' ||
                                    data1 === 'Signaling node disconnected' ||
                                    data1 ===
                                        'SSO Authentication Failed. SSO is not available from this domain' ||
                                    data1 ===
                                        'SSO Authentication Failed. The system is in Maintenance mode' ||
                                    data1 === 'SSO Authentication Failed' ||
                                    data1 ===
                                        'SSO enabled but no Identity Providers configured' ||
                                    data1 === 'System in maintenance mode' ||
                                    data1 === 'Telehealth Call failure' ||
                                    data1 === 'Test call finished' ||
                                    data1 ===
                                        'Timeout waiting for conference host to join or permit access to locked conference' ||
                                    data1 ===
                                        'Timer expired awaiting token refresh' ||
                                    data1 === 'transfer failed' ||
                                    data1 === 'Unexpected Response: 503' ||
                                    data1 ===
                                        'Attempted to acquire license while license already acquired. This is usually due to an internal error.' ||
                                    data1 ===
                                        'Failed to transfer into a multi-party conference' ||
                                    data1 ===
                                        'Failed to transfer into a one-to-one conference'
                                )
                            ) {
                                const err4 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/take_floor/properties/403/definitions/403/properties/result/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema15.properties.result.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                };
                                if (vErrors === null) {
                                    vErrors = [err4];
                                } else {
                                    vErrors.push(err4);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err5 = {
                instancePath,
                schemaPath:
                    '#/definitions/take_floor/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err5];
            } else {
                vErrors.push(err5);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err6 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err6];
        } else {
            vErrors.push(err6);
        }
        errors++;
        validate26.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate26.errors = vErrors;
    return errors === 0;
}
export const validateSpotlightoffParticipant200 = validate27;
function validate27(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/mute_participant/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/mute_participant/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/mute_participant/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema17.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/mute_participant/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/mute_participant/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate27.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate27.errors = vErrors;
    return errors === 0;
}
export const validateSpotlightoffParticipant403 = validate28;
function validate28(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/take_floor/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/take_floor/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/take_floor/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema15.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs7 = errors;
                            if (typeof data1 !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/take_floor/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            if (
                                !(
                                    data1 === '404 Not Found' ||
                                    data1 === '502 Bad Gateway' ||
                                    data1 === '503 Service Unavailable' ||
                                    data1 ===
                                        'All conference hosts departed hosted conference' ||
                                    data1 ===
                                        'API initiated conference termination' ||
                                    data1 ===
                                        'API initiated participant disconnect' ||
                                    data1 === 'Call disconnected' ||
                                    data1 === 'Call rejected' ||
                                    data1 ===
                                        'Conference extension not found' ||
                                    data1 ===
                                        'Conference host ended the conference with a DTMF command' ||
                                    data1 ===
                                        'Conference terminated by an administrator' ||
                                    data1 ===
                                        'Conference terminated by another participant' ||
                                    data1 === 'Disabled' ||
                                    data1 ===
                                        'Disconnected by an administrator' ||
                                    data1 ===
                                        'Disconnected by another participant' ||
                                    data1 === 'Failed to forward request' ||
                                    data1 === 'Gateway dial out failed' ||
                                    data1 === 'Ice failure' ||
                                    data1 === 'Insufficient licenses' ||
                                    data1 ===
                                        'invalid gateway routing rule transform' ||
                                    data1 === 'Invalid license' ||
                                    data1 === 'Invalid PIN' ||
                                    data1 === 'Invalid protocol for service' ||
                                    data1 === 'Invalid role' ||
                                    data1 === 'Invalid token' ||
                                    data1 ===
                                        'Last remaining participant removed from conference after timeout' ||
                                    data1 === 'Media node disconnected' ||
                                    data1 === 'Media process disconnected' ||
                                    data1 ===
                                        'Neither conference nor gateway found' ||
                                    data1 ===
                                        'No direct route between Edge and Transcoding' ||
                                    data1 ===
                                        'No participants can keep conference alive' ||
                                    data1 === 'Out of proxying resource' ||
                                    data1 === 'Out of resource' ||
                                    data1 === 'Out of transcoding resource' ||
                                    data1 ===
                                        'Participant exceeded PIN entry retries' ||
                                    data1 === 'Participant limit reached' ||
                                    data1 ===
                                        'Proxied participant disconnected' ||
                                    data1 === 'Resource unavailable' ||
                                    data1 === 'Signaling node disconnected' ||
                                    data1 ===
                                        'SSO Authentication Failed. SSO is not available from this domain' ||
                                    data1 ===
                                        'SSO Authentication Failed. The system is in Maintenance mode' ||
                                    data1 === 'SSO Authentication Failed' ||
                                    data1 ===
                                        'SSO enabled but no Identity Providers configured' ||
                                    data1 === 'System in maintenance mode' ||
                                    data1 === 'Telehealth Call failure' ||
                                    data1 === 'Test call finished' ||
                                    data1 ===
                                        'Timeout waiting for conference host to join or permit access to locked conference' ||
                                    data1 ===
                                        'Timer expired awaiting token refresh' ||
                                    data1 === 'transfer failed' ||
                                    data1 === 'Unexpected Response: 503' ||
                                    data1 ===
                                        'Attempted to acquire license while license already acquired. This is usually due to an internal error.' ||
                                    data1 ===
                                        'Failed to transfer into a multi-party conference' ||
                                    data1 ===
                                        'Failed to transfer into a one-to-one conference'
                                )
                            ) {
                                const err4 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/take_floor/properties/403/definitions/403/properties/result/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema15.properties.result.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                };
                                if (vErrors === null) {
                                    vErrors = [err4];
                                } else {
                                    vErrors.push(err4);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err5 = {
                instancePath,
                schemaPath:
                    '#/definitions/take_floor/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err5];
            } else {
                vErrors.push(err5);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err6 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err6];
        } else {
            vErrors.push(err6);
        }
        errors++;
        validate28.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate28.errors = vErrors;
    return errors === 0;
}
export const validateUnlockParticipant200 = validate29;
function validate29(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/mute_participant/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/mute_participant/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/mute_participant/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema17.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/mute_participant/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/mute_participant/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate29.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate29.errors = vErrors;
    return errors === 0;
}
export const validateUnlockParticipant403 = validate30;
function validate30(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/take_floor/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/take_floor/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/take_floor/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema15.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs7 = errors;
                            if (typeof data1 !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/take_floor/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            if (
                                !(
                                    data1 === '404 Not Found' ||
                                    data1 === '502 Bad Gateway' ||
                                    data1 === '503 Service Unavailable' ||
                                    data1 ===
                                        'All conference hosts departed hosted conference' ||
                                    data1 ===
                                        'API initiated conference termination' ||
                                    data1 ===
                                        'API initiated participant disconnect' ||
                                    data1 === 'Call disconnected' ||
                                    data1 === 'Call rejected' ||
                                    data1 ===
                                        'Conference extension not found' ||
                                    data1 ===
                                        'Conference host ended the conference with a DTMF command' ||
                                    data1 ===
                                        'Conference terminated by an administrator' ||
                                    data1 ===
                                        'Conference terminated by another participant' ||
                                    data1 === 'Disabled' ||
                                    data1 ===
                                        'Disconnected by an administrator' ||
                                    data1 ===
                                        'Disconnected by another participant' ||
                                    data1 === 'Failed to forward request' ||
                                    data1 === 'Gateway dial out failed' ||
                                    data1 === 'Ice failure' ||
                                    data1 === 'Insufficient licenses' ||
                                    data1 ===
                                        'invalid gateway routing rule transform' ||
                                    data1 === 'Invalid license' ||
                                    data1 === 'Invalid PIN' ||
                                    data1 === 'Invalid protocol for service' ||
                                    data1 === 'Invalid role' ||
                                    data1 === 'Invalid token' ||
                                    data1 ===
                                        'Last remaining participant removed from conference after timeout' ||
                                    data1 === 'Media node disconnected' ||
                                    data1 === 'Media process disconnected' ||
                                    data1 ===
                                        'Neither conference nor gateway found' ||
                                    data1 ===
                                        'No direct route between Edge and Transcoding' ||
                                    data1 ===
                                        'No participants can keep conference alive' ||
                                    data1 === 'Out of proxying resource' ||
                                    data1 === 'Out of resource' ||
                                    data1 === 'Out of transcoding resource' ||
                                    data1 ===
                                        'Participant exceeded PIN entry retries' ||
                                    data1 === 'Participant limit reached' ||
                                    data1 ===
                                        'Proxied participant disconnected' ||
                                    data1 === 'Resource unavailable' ||
                                    data1 === 'Signaling node disconnected' ||
                                    data1 ===
                                        'SSO Authentication Failed. SSO is not available from this domain' ||
                                    data1 ===
                                        'SSO Authentication Failed. The system is in Maintenance mode' ||
                                    data1 === 'SSO Authentication Failed' ||
                                    data1 ===
                                        'SSO enabled but no Identity Providers configured' ||
                                    data1 === 'System in maintenance mode' ||
                                    data1 === 'Telehealth Call failure' ||
                                    data1 === 'Test call finished' ||
                                    data1 ===
                                        'Timeout waiting for conference host to join or permit access to locked conference' ||
                                    data1 ===
                                        'Timer expired awaiting token refresh' ||
                                    data1 === 'transfer failed' ||
                                    data1 === 'Unexpected Response: 503' ||
                                    data1 ===
                                        'Attempted to acquire license while license already acquired. This is usually due to an internal error.' ||
                                    data1 ===
                                        'Failed to transfer into a multi-party conference' ||
                                    data1 ===
                                        'Failed to transfer into a one-to-one conference'
                                )
                            ) {
                                const err4 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/take_floor/properties/403/definitions/403/properties/result/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema15.properties.result.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                };
                                if (vErrors === null) {
                                    vErrors = [err4];
                                } else {
                                    vErrors.push(err4);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err5 = {
                instancePath,
                schemaPath:
                    '#/definitions/take_floor/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err5];
            } else {
                vErrors.push(err5);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err6 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err6];
        } else {
            vErrors.push(err6);
        }
        errors++;
        validate30.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate30.errors = vErrors;
    return errors === 0;
}
export const validateDtmfParticipantBody = validate31;
const schema52 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        TopLevel: {
            type: 'object',
            additionalProperties: false,
            properties: {
                digits: {
                    type: 'string',
                    description: 'The DTMF digits to send.',
                },
            },
            required: ['digits'],
            title: 'TopLevel',
        },
    },
    oneOf: [
        {
            $ref: '#/definitions/dtmf_participant/properties/Body/definitions/TopLevel',
        },
    ],
};
const schema53 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        digits: {type: 'string', description: 'The DTMF digits to send.'},
    },
    required: ['digits'],
    title: 'TopLevel',
};
function validate31(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (data.digits === undefined && (missing0 = 'digits')) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/dtmf_participant/properties/Body/definitions/TopLevel/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'digits')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.digits !== undefined) {
                        if (typeof data.digits !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/digits',
                                schemaPath:
                                    '#/definitions/dtmf_participant/properties/Body/definitions/TopLevel/properties/digits/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                    }
                }
            }
        } else {
            const err2 = {
                instancePath,
                schemaPath:
                    '#/definitions/dtmf_participant/properties/Body/definitions/TopLevel/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err2];
            } else {
                vErrors.push(err2);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err3 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err3];
        } else {
            vErrors.push(err3);
        }
        errors++;
        validate31.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate31.errors = vErrors;
    return errors === 0;
}
export const validateDtmfParticipant200 = validate32;
function validate32(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/mute_participant/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/mute_participant/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/mute_participant/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema17.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/mute_participant/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/mute_participant/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate32.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate32.errors = vErrors;
    return errors === 0;
}
export const validateDtmfParticipant403 = validate33;
function validate33(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/take_floor/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/take_floor/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/take_floor/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema15.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs7 = errors;
                            if (typeof data1 !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/take_floor/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            if (
                                !(
                                    data1 === '404 Not Found' ||
                                    data1 === '502 Bad Gateway' ||
                                    data1 === '503 Service Unavailable' ||
                                    data1 ===
                                        'All conference hosts departed hosted conference' ||
                                    data1 ===
                                        'API initiated conference termination' ||
                                    data1 ===
                                        'API initiated participant disconnect' ||
                                    data1 === 'Call disconnected' ||
                                    data1 === 'Call rejected' ||
                                    data1 ===
                                        'Conference extension not found' ||
                                    data1 ===
                                        'Conference host ended the conference with a DTMF command' ||
                                    data1 ===
                                        'Conference terminated by an administrator' ||
                                    data1 ===
                                        'Conference terminated by another participant' ||
                                    data1 === 'Disabled' ||
                                    data1 ===
                                        'Disconnected by an administrator' ||
                                    data1 ===
                                        'Disconnected by another participant' ||
                                    data1 === 'Failed to forward request' ||
                                    data1 === 'Gateway dial out failed' ||
                                    data1 === 'Ice failure' ||
                                    data1 === 'Insufficient licenses' ||
                                    data1 ===
                                        'invalid gateway routing rule transform' ||
                                    data1 === 'Invalid license' ||
                                    data1 === 'Invalid PIN' ||
                                    data1 === 'Invalid protocol for service' ||
                                    data1 === 'Invalid role' ||
                                    data1 === 'Invalid token' ||
                                    data1 ===
                                        'Last remaining participant removed from conference after timeout' ||
                                    data1 === 'Media node disconnected' ||
                                    data1 === 'Media process disconnected' ||
                                    data1 ===
                                        'Neither conference nor gateway found' ||
                                    data1 ===
                                        'No direct route between Edge and Transcoding' ||
                                    data1 ===
                                        'No participants can keep conference alive' ||
                                    data1 === 'Out of proxying resource' ||
                                    data1 === 'Out of resource' ||
                                    data1 === 'Out of transcoding resource' ||
                                    data1 ===
                                        'Participant exceeded PIN entry retries' ||
                                    data1 === 'Participant limit reached' ||
                                    data1 ===
                                        'Proxied participant disconnected' ||
                                    data1 === 'Resource unavailable' ||
                                    data1 === 'Signaling node disconnected' ||
                                    data1 ===
                                        'SSO Authentication Failed. SSO is not available from this domain' ||
                                    data1 ===
                                        'SSO Authentication Failed. The system is in Maintenance mode' ||
                                    data1 === 'SSO Authentication Failed' ||
                                    data1 ===
                                        'SSO enabled but no Identity Providers configured' ||
                                    data1 === 'System in maintenance mode' ||
                                    data1 === 'Telehealth Call failure' ||
                                    data1 === 'Test call finished' ||
                                    data1 ===
                                        'Timeout waiting for conference host to join or permit access to locked conference' ||
                                    data1 ===
                                        'Timer expired awaiting token refresh' ||
                                    data1 === 'transfer failed' ||
                                    data1 === 'Unexpected Response: 503' ||
                                    data1 ===
                                        'Attempted to acquire license while license already acquired. This is usually due to an internal error.' ||
                                    data1 ===
                                        'Failed to transfer into a multi-party conference' ||
                                    data1 ===
                                        'Failed to transfer into a one-to-one conference'
                                )
                            ) {
                                const err4 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/take_floor/properties/403/definitions/403/properties/result/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema15.properties.result.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                };
                                if (vErrors === null) {
                                    vErrors = [err4];
                                } else {
                                    vErrors.push(err4);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err5 = {
                instancePath,
                schemaPath:
                    '#/definitions/take_floor/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err5];
            } else {
                vErrors.push(err5);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err6 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err6];
        } else {
            vErrors.push(err6);
        }
        errors++;
        validate33.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate33.errors = vErrors;
    return errors === 0;
}
export const validateCallsWebrtcParticipantBody = validate34;
const schema58 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        TopLevel: {
            type: 'object',
            additionalProperties: false,
            properties: {
                call_type: {
                    type: 'string',
                    enum: ['WEBRTC', 'RTMP'],
                    description:
                        '`WEBRTC` for a WebRTC call and `RTMP` for an RTMP call.',
                },
                sdp: {
                    type: 'string',
                    description: 'Contains the SDP of the sender.',
                },
                present: {
                    type: 'string',
                    enum: ['send', 'receive'],
                    description:
                        'Optional field. Contains `send` or `receive` to act as a presentation stream rather than a main audio/video stream.',
                },
                fecc_supported: {
                    type: 'boolean',
                    default: false,
                    description:
                        'Set to true if this participant can be sent FECC messages; false if not.',
                },
                media_type: {
                    type: 'string',
                    enum: ['video', 'video-only', 'audio'],
                    description:
                        'The type of media that this client intends to send.',
                },
            },
            required: ['call_type', 'sdp'],
            title: 'TopLevel',
        },
    },
    oneOf: [
        {
            $ref: '#/definitions/calls_webrtc_participant/properties/Body/definitions/TopLevel',
        },
    ],
};
const schema59 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        call_type: {
            type: 'string',
            enum: ['WEBRTC', 'RTMP'],
            description:
                '`WEBRTC` for a WebRTC call and `RTMP` for an RTMP call.',
        },
        sdp: {type: 'string', description: 'Contains the SDP of the sender.'},
        present: {
            type: 'string',
            enum: ['send', 'receive'],
            description:
                'Optional field. Contains `send` or `receive` to act as a presentation stream rather than a main audio/video stream.',
        },
        fecc_supported: {
            type: 'boolean',
            default: false,
            description:
                'Set to true if this participant can be sent FECC messages; false if not.',
        },
        media_type: {
            type: 'string',
            enum: ['video', 'video-only', 'audio'],
            description: 'The type of media that this client intends to send.',
        },
    },
    required: ['call_type', 'sdp'],
    title: 'TopLevel',
};
function validate34(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.call_type === undefined && (missing0 = 'call_type')) ||
                (data.sdp === undefined && (missing0 = 'sdp'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/calls_webrtc_participant/properties/Body/definitions/TopLevel/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (
                        !(
                            key0 === 'call_type' ||
                            key0 === 'sdp' ||
                            key0 === 'present' ||
                            key0 === 'fecc_supported' ||
                            key0 === 'media_type'
                        )
                    ) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.call_type !== undefined) {
                        let data0 = data.call_type;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/call_type',
                                schemaPath:
                                    '#/definitions/calls_webrtc_participant/properties/Body/definitions/TopLevel/properties/call_type/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'WEBRTC' || data0 === 'RTMP')) {
                            const err2 = {
                                instancePath: instancePath + '/call_type',
                                schemaPath:
                                    '#/definitions/calls_webrtc_participant/properties/Body/definitions/TopLevel/properties/call_type/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema59.properties.call_type.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.sdp !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.sdp !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/sdp',
                                    schemaPath:
                                        '#/definitions/calls_webrtc_participant/properties/Body/definitions/TopLevel/properties/sdp/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                        if (valid2) {
                            if (data.present !== undefined) {
                                let data2 = data.present;
                                const _errs9 = errors;
                                if (typeof data2 !== 'string') {
                                    const err4 = {
                                        instancePath: instancePath + '/present',
                                        schemaPath:
                                            '#/definitions/calls_webrtc_participant/properties/Body/definitions/TopLevel/properties/present/type',
                                        keyword: 'type',
                                        params: {type: 'string'},
                                        message: 'must be string',
                                    };
                                    if (vErrors === null) {
                                        vErrors = [err4];
                                    } else {
                                        vErrors.push(err4);
                                    }
                                    errors++;
                                }
                                if (
                                    !(data2 === 'send' || data2 === 'receive')
                                ) {
                                    const err5 = {
                                        instancePath: instancePath + '/present',
                                        schemaPath:
                                            '#/definitions/calls_webrtc_participant/properties/Body/definitions/TopLevel/properties/present/enum',
                                        keyword: 'enum',
                                        params: {
                                            allowedValues:
                                                schema59.properties.present
                                                    .enum,
                                        },
                                        message:
                                            'must be equal to one of the allowed values',
                                    };
                                    if (vErrors === null) {
                                        vErrors = [err5];
                                    } else {
                                        vErrors.push(err5);
                                    }
                                    errors++;
                                }
                                var valid2 = _errs9 === errors;
                            } else {
                                var valid2 = true;
                            }
                            if (valid2) {
                                if (data.fecc_supported !== undefined) {
                                    const _errs11 = errors;
                                    if (
                                        typeof data.fecc_supported !== 'boolean'
                                    ) {
                                        const err6 = {
                                            instancePath:
                                                instancePath +
                                                '/fecc_supported',
                                            schemaPath:
                                                '#/definitions/calls_webrtc_participant/properties/Body/definitions/TopLevel/properties/fecc_supported/type',
                                            keyword: 'type',
                                            params: {type: 'boolean'},
                                            message: 'must be boolean',
                                        };
                                        if (vErrors === null) {
                                            vErrors = [err6];
                                        } else {
                                            vErrors.push(err6);
                                        }
                                        errors++;
                                    }
                                    var valid2 = _errs11 === errors;
                                } else {
                                    var valid2 = true;
                                }
                                if (valid2) {
                                    if (data.media_type !== undefined) {
                                        let data4 = data.media_type;
                                        const _errs13 = errors;
                                        if (typeof data4 !== 'string') {
                                            const err7 = {
                                                instancePath:
                                                    instancePath +
                                                    '/media_type',
                                                schemaPath:
                                                    '#/definitions/calls_webrtc_participant/properties/Body/definitions/TopLevel/properties/media_type/type',
                                                keyword: 'type',
                                                params: {type: 'string'},
                                                message: 'must be string',
                                            };
                                            if (vErrors === null) {
                                                vErrors = [err7];
                                            } else {
                                                vErrors.push(err7);
                                            }
                                            errors++;
                                        }
                                        if (
                                            !(
                                                data4 === 'video' ||
                                                data4 === 'video-only' ||
                                                data4 === 'audio'
                                            )
                                        ) {
                                            const err8 = {
                                                instancePath:
                                                    instancePath +
                                                    '/media_type',
                                                schemaPath:
                                                    '#/definitions/calls_webrtc_participant/properties/Body/definitions/TopLevel/properties/media_type/enum',
                                                keyword: 'enum',
                                                params: {
                                                    allowedValues:
                                                        schema59.properties
                                                            .media_type.enum,
                                                },
                                                message:
                                                    'must be equal to one of the allowed values',
                                            };
                                            if (vErrors === null) {
                                                vErrors = [err8];
                                            } else {
                                                vErrors.push(err8);
                                            }
                                            errors++;
                                        }
                                        var valid2 = _errs13 === errors;
                                    } else {
                                        var valid2 = true;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        } else {
            const err9 = {
                instancePath,
                schemaPath:
                    '#/definitions/calls_webrtc_participant/properties/Body/definitions/TopLevel/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err9];
            } else {
                vErrors.push(err9);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err10 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err10];
        } else {
            vErrors.push(err10);
        }
        errors++;
        validate34.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate34.errors = vErrors;
    return errors === 0;
}
export const validateCallsWebrtcParticipant200 = validate35;
const schema60 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        '200': {
            type: 'object',
            additionalProperties: false,
            properties: {
                status: {
                    type: 'string',
                    description: 'Status of the response, eg. success.',
                    enum: ['success'],
                },
                result: {
                    $ref: '#/definitions/calls_webrtc_participant/properties/200/definitions/Result',
                },
            },
            required: ['status', 'result'],
            title: 'Status200',
        },
        Result: {
            type: 'object',
            additionalProperties: false,
            properties: {
                call_uuid: {
                    type: 'string',
                    description: '`call_uuid` is used to control the call',
                },
                sdp: {type: 'string', description: 'SDP answer from the mcu'},
                offer_ignored: {
                    type: 'boolean',
                    description:
                        'When the call is direct-media and the offer is ignored',
                },
                turn: {
                    type: 'array',
                    items: {
                        $ref: '#/definitions/calls_webrtc_participant/properties/200/definitions/Turn',
                    },
                },
            },
            required: ['call_uuid', 'sdp'],
            title: 'Result',
        },
        Turn: {
            type: 'object',
            additionalProperties: false,
            properties: {
                urls: {type: 'array', items: {type: 'string'}},
                username: {type: 'string'},
                credential: {type: 'string'},
            },
            required: ['urls', 'username', 'credential'],
            title: 'Turn',
        },
    },
    oneOf: [
        {
            $ref: '#/definitions/calls_webrtc_participant/properties/200/definitions/200',
        },
    ],
};
const schema61 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        status: {
            type: 'string',
            description: 'Status of the response, eg. success.',
            enum: ['success'],
        },
        result: {
            $ref: '#/definitions/calls_webrtc_participant/properties/200/definitions/Result',
        },
    },
    required: ['status', 'result'],
    title: 'Status200',
};
const schema62 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        call_uuid: {
            type: 'string',
            description: '`call_uuid` is used to control the call',
        },
        sdp: {type: 'string', description: 'SDP answer from the mcu'},
        offer_ignored: {
            type: 'boolean',
            description:
                'When the call is direct-media and the offer is ignored',
        },
        turn: {
            type: 'array',
            items: {
                $ref: '#/definitions/calls_webrtc_participant/properties/200/definitions/Turn',
            },
        },
    },
    required: ['call_uuid', 'sdp'],
    title: 'Result',
};
const schema63 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        urls: {type: 'array', items: {type: 'string'}},
        username: {type: 'string'},
        credential: {type: 'string'},
    },
    required: ['urls', 'username', 'credential'],
    title: 'Turn',
};
function validate37(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (errors === 0) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.call_uuid === undefined && (missing0 = 'call_uuid')) ||
                (data.sdp === undefined && (missing0 = 'sdp'))
            ) {
                validate37.errors = [
                    {
                        instancePath,
                        schemaPath: '#/required',
                        keyword: 'required',
                        params: {missingProperty: missing0},
                        message:
                            "must have required property '" + missing0 + "'",
                    },
                ];
                return false;
            } else {
                const _errs1 = errors;
                for (const key0 in data) {
                    if (
                        !(
                            key0 === 'call_uuid' ||
                            key0 === 'sdp' ||
                            key0 === 'offer_ignored' ||
                            key0 === 'turn'
                        )
                    ) {
                        delete data[key0];
                    }
                }
                if (_errs1 === errors) {
                    if (data.call_uuid !== undefined) {
                        const _errs2 = errors;
                        if (typeof data.call_uuid !== 'string') {
                            validate37.errors = [
                                {
                                    instancePath: instancePath + '/call_uuid',
                                    schemaPath: '#/properties/call_uuid/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                },
                            ];
                            return false;
                        }
                        var valid0 = _errs2 === errors;
                    } else {
                        var valid0 = true;
                    }
                    if (valid0) {
                        if (data.sdp !== undefined) {
                            const _errs4 = errors;
                            if (typeof data.sdp !== 'string') {
                                validate37.errors = [
                                    {
                                        instancePath: instancePath + '/sdp',
                                        schemaPath: '#/properties/sdp/type',
                                        keyword: 'type',
                                        params: {type: 'string'},
                                        message: 'must be string',
                                    },
                                ];
                                return false;
                            }
                            var valid0 = _errs4 === errors;
                        } else {
                            var valid0 = true;
                        }
                        if (valid0) {
                            if (data.offer_ignored !== undefined) {
                                const _errs6 = errors;
                                if (typeof data.offer_ignored !== 'boolean') {
                                    validate37.errors = [
                                        {
                                            instancePath:
                                                instancePath + '/offer_ignored',
                                            schemaPath:
                                                '#/properties/offer_ignored/type',
                                            keyword: 'type',
                                            params: {type: 'boolean'},
                                            message: 'must be boolean',
                                        },
                                    ];
                                    return false;
                                }
                                var valid0 = _errs6 === errors;
                            } else {
                                var valid0 = true;
                            }
                            if (valid0) {
                                if (data.turn !== undefined) {
                                    let data3 = data.turn;
                                    const _errs8 = errors;
                                    if (errors === _errs8) {
                                        if (Array.isArray(data3)) {
                                            var valid1 = true;
                                            const len0 = data3.length;
                                            for (let i0 = 0; i0 < len0; i0++) {
                                                let data4 = data3[i0];
                                                const _errs10 = errors;
                                                const _errs11 = errors;
                                                if (errors === _errs11) {
                                                    if (
                                                        data4 &&
                                                        typeof data4 ==
                                                            'object' &&
                                                        !Array.isArray(data4)
                                                    ) {
                                                        let missing1;
                                                        if (
                                                            (data4.urls ===
                                                                undefined &&
                                                                (missing1 =
                                                                    'urls')) ||
                                                            (data4.username ===
                                                                undefined &&
                                                                (missing1 =
                                                                    'username')) ||
                                                            (data4.credential ===
                                                                undefined &&
                                                                (missing1 =
                                                                    'credential'))
                                                        ) {
                                                            validate37.errors =
                                                                [
                                                                    {
                                                                        instancePath:
                                                                            instancePath +
                                                                            '/turn/' +
                                                                            i0,
                                                                        schemaPath:
                                                                            '#/definitions/calls_webrtc_participant/properties/200/definitions/Turn/required',
                                                                        keyword:
                                                                            'required',
                                                                        params: {
                                                                            missingProperty:
                                                                                missing1,
                                                                        },
                                                                        message:
                                                                            "must have required property '" +
                                                                            missing1 +
                                                                            "'",
                                                                    },
                                                                ];
                                                            return false;
                                                        } else {
                                                            const _errs13 =
                                                                errors;
                                                            for (const key1 in data4) {
                                                                if (
                                                                    !(
                                                                        key1 ===
                                                                            'urls' ||
                                                                        key1 ===
                                                                            'username' ||
                                                                        key1 ===
                                                                            'credential'
                                                                    )
                                                                ) {
                                                                    delete data4[
                                                                        key1
                                                                    ];
                                                                }
                                                            }
                                                            if (
                                                                _errs13 ===
                                                                errors
                                                            ) {
                                                                if (
                                                                    data4.urls !==
                                                                    undefined
                                                                ) {
                                                                    let data5 =
                                                                        data4.urls;
                                                                    const _errs14 =
                                                                        errors;
                                                                    if (
                                                                        errors ===
                                                                        _errs14
                                                                    ) {
                                                                        if (
                                                                            Array.isArray(
                                                                                data5,
                                                                            )
                                                                        ) {
                                                                            var valid4 =
                                                                                true;
                                                                            const len1 =
                                                                                data5.length;
                                                                            for (
                                                                                let i1 = 0;
                                                                                i1 <
                                                                                len1;
                                                                                i1++
                                                                            ) {
                                                                                const _errs16 =
                                                                                    errors;
                                                                                if (
                                                                                    typeof data5[
                                                                                        i1
                                                                                    ] !==
                                                                                    'string'
                                                                                ) {
                                                                                    validate37.errors =
                                                                                        [
                                                                                            {
                                                                                                instancePath:
                                                                                                    instancePath +
                                                                                                    '/turn/' +
                                                                                                    i0 +
                                                                                                    '/urls/' +
                                                                                                    i1,
                                                                                                schemaPath:
                                                                                                    '#/definitions/calls_webrtc_participant/properties/200/definitions/Turn/properties/urls/items/type',
                                                                                                keyword:
                                                                                                    'type',
                                                                                                params: {
                                                                                                    type: 'string',
                                                                                                },
                                                                                                message:
                                                                                                    'must be string',
                                                                                            },
                                                                                        ];
                                                                                    return false;
                                                                                }
                                                                                var valid4 =
                                                                                    _errs16 ===
                                                                                    errors;
                                                                                if (
                                                                                    !valid4
                                                                                ) {
                                                                                    break;
                                                                                }
                                                                            }
                                                                        } else {
                                                                            validate37.errors =
                                                                                [
                                                                                    {
                                                                                        instancePath:
                                                                                            instancePath +
                                                                                            '/turn/' +
                                                                                            i0 +
                                                                                            '/urls',
                                                                                        schemaPath:
                                                                                            '#/definitions/calls_webrtc_participant/properties/200/definitions/Turn/properties/urls/type',
                                                                                        keyword:
                                                                                            'type',
                                                                                        params: {
                                                                                            type: 'array',
                                                                                        },
                                                                                        message:
                                                                                            'must be array',
                                                                                    },
                                                                                ];
                                                                            return false;
                                                                        }
                                                                    }
                                                                    var valid3 =
                                                                        _errs14 ===
                                                                        errors;
                                                                } else {
                                                                    var valid3 =
                                                                        true;
                                                                }
                                                                if (valid3) {
                                                                    if (
                                                                        data4.username !==
                                                                        undefined
                                                                    ) {
                                                                        const _errs18 =
                                                                            errors;
                                                                        if (
                                                                            typeof data4.username !==
                                                                            'string'
                                                                        ) {
                                                                            validate37.errors =
                                                                                [
                                                                                    {
                                                                                        instancePath:
                                                                                            instancePath +
                                                                                            '/turn/' +
                                                                                            i0 +
                                                                                            '/username',
                                                                                        schemaPath:
                                                                                            '#/definitions/calls_webrtc_participant/properties/200/definitions/Turn/properties/username/type',
                                                                                        keyword:
                                                                                            'type',
                                                                                        params: {
                                                                                            type: 'string',
                                                                                        },
                                                                                        message:
                                                                                            'must be string',
                                                                                    },
                                                                                ];
                                                                            return false;
                                                                        }
                                                                        var valid3 =
                                                                            _errs18 ===
                                                                            errors;
                                                                    } else {
                                                                        var valid3 =
                                                                            true;
                                                                    }
                                                                    if (
                                                                        valid3
                                                                    ) {
                                                                        if (
                                                                            data4.credential !==
                                                                            undefined
                                                                        ) {
                                                                            const _errs20 =
                                                                                errors;
                                                                            if (
                                                                                typeof data4.credential !==
                                                                                'string'
                                                                            ) {
                                                                                validate37.errors =
                                                                                    [
                                                                                        {
                                                                                            instancePath:
                                                                                                instancePath +
                                                                                                '/turn/' +
                                                                                                i0 +
                                                                                                '/credential',
                                                                                            schemaPath:
                                                                                                '#/definitions/calls_webrtc_participant/properties/200/definitions/Turn/properties/credential/type',
                                                                                            keyword:
                                                                                                'type',
                                                                                            params: {
                                                                                                type: 'string',
                                                                                            },
                                                                                            message:
                                                                                                'must be string',
                                                                                        },
                                                                                    ];
                                                                                return false;
                                                                            }
                                                                            var valid3 =
                                                                                _errs20 ===
                                                                                errors;
                                                                        } else {
                                                                            var valid3 =
                                                                                true;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    } else {
                                                        validate37.errors = [
                                                            {
                                                                instancePath:
                                                                    instancePath +
                                                                    '/turn/' +
                                                                    i0,
                                                                schemaPath:
                                                                    '#/definitions/calls_webrtc_participant/properties/200/definitions/Turn/type',
                                                                keyword: 'type',
                                                                params: {
                                                                    type: 'object',
                                                                },
                                                                message:
                                                                    'must be object',
                                                            },
                                                        ];
                                                        return false;
                                                    }
                                                }
                                                var valid1 = _errs10 === errors;
                                                if (!valid1) {
                                                    break;
                                                }
                                            }
                                        } else {
                                            validate37.errors = [
                                                {
                                                    instancePath:
                                                        instancePath + '/turn',
                                                    schemaPath:
                                                        '#/properties/turn/type',
                                                    keyword: 'type',
                                                    params: {type: 'array'},
                                                    message: 'must be array',
                                                },
                                            ];
                                            return false;
                                        }
                                    }
                                    var valid0 = _errs8 === errors;
                                } else {
                                    var valid0 = true;
                                }
                            }
                        }
                    }
                }
            }
        } else {
            validate37.errors = [
                {
                    instancePath,
                    schemaPath: '#/type',
                    keyword: 'type',
                    params: {type: 'object'},
                    message: 'must be object',
                },
            ];
            return false;
        }
    }
    validate37.errors = vErrors;
    return errors === 0;
}
function validate36(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (errors === 0) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                validate36.errors = [
                    {
                        instancePath,
                        schemaPath: '#/required',
                        keyword: 'required',
                        params: {missingProperty: missing0},
                        message:
                            "must have required property '" + missing0 + "'",
                    },
                ];
                return false;
            } else {
                const _errs1 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs1 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs2 = errors;
                        if (typeof data0 !== 'string') {
                            validate36.errors = [
                                {
                                    instancePath: instancePath + '/status',
                                    schemaPath: '#/properties/status/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                },
                            ];
                            return false;
                        }
                        if (!(data0 === 'success')) {
                            validate36.errors = [
                                {
                                    instancePath: instancePath + '/status',
                                    schemaPath: '#/properties/status/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema61.properties.status.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                },
                            ];
                            return false;
                        }
                        var valid0 = _errs2 === errors;
                    } else {
                        var valid0 = true;
                    }
                    if (valid0) {
                        if (data.result !== undefined) {
                            const _errs4 = errors;
                            if (
                                !validate37(data.result, {
                                    instancePath: instancePath + '/result',
                                    parentData: data,
                                    parentDataProperty: 'result',
                                    rootData,
                                })
                            ) {
                                vErrors =
                                    vErrors === null
                                        ? validate37.errors
                                        : vErrors.concat(validate37.errors);
                                errors = vErrors.length;
                            }
                            var valid0 = _errs4 === errors;
                        } else {
                            var valid0 = true;
                        }
                    }
                }
            }
        } else {
            validate36.errors = [
                {
                    instancePath,
                    schemaPath: '#/type',
                    keyword: 'type',
                    params: {type: 'object'},
                    message: 'must be object',
                },
            ];
            return false;
        }
    }
    validate36.errors = vErrors;
    return errors === 0;
}
function validate35(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    if (
        !validate36(data, {
            instancePath,
            parentData,
            parentDataProperty,
            rootData,
        })
    ) {
        vErrors =
            vErrors === null
                ? validate36.errors
                : vErrors.concat(validate36.errors);
        errors = vErrors.length;
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err0 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err0];
        } else {
            vErrors.push(err0);
        }
        errors++;
        validate35.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate35.errors = vErrors;
    return errors === 0;
}
export const validateCallsWebrtcParticipant400 = validate40;
const schema64 = {type: 'string'};
function validate40(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (typeof data !== 'string') {
        validate40.errors = [
            {
                instancePath,
                schemaPath: '#/type',
                keyword: 'type',
                params: {type: 'string'},
                message: 'must be string',
            },
        ];
        return false;
    }
    validate40.errors = vErrors;
    return errors === 0;
}
export const validateCallsWebrtcParticipant403 = validate41;
function validate41(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/take_floor/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/take_floor/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/take_floor/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema15.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs7 = errors;
                            if (typeof data1 !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/take_floor/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            if (
                                !(
                                    data1 === '404 Not Found' ||
                                    data1 === '502 Bad Gateway' ||
                                    data1 === '503 Service Unavailable' ||
                                    data1 ===
                                        'All conference hosts departed hosted conference' ||
                                    data1 ===
                                        'API initiated conference termination' ||
                                    data1 ===
                                        'API initiated participant disconnect' ||
                                    data1 === 'Call disconnected' ||
                                    data1 === 'Call rejected' ||
                                    data1 ===
                                        'Conference extension not found' ||
                                    data1 ===
                                        'Conference host ended the conference with a DTMF command' ||
                                    data1 ===
                                        'Conference terminated by an administrator' ||
                                    data1 ===
                                        'Conference terminated by another participant' ||
                                    data1 === 'Disabled' ||
                                    data1 ===
                                        'Disconnected by an administrator' ||
                                    data1 ===
                                        'Disconnected by another participant' ||
                                    data1 === 'Failed to forward request' ||
                                    data1 === 'Gateway dial out failed' ||
                                    data1 === 'Ice failure' ||
                                    data1 === 'Insufficient licenses' ||
                                    data1 ===
                                        'invalid gateway routing rule transform' ||
                                    data1 === 'Invalid license' ||
                                    data1 === 'Invalid PIN' ||
                                    data1 === 'Invalid protocol for service' ||
                                    data1 === 'Invalid role' ||
                                    data1 === 'Invalid token' ||
                                    data1 ===
                                        'Last remaining participant removed from conference after timeout' ||
                                    data1 === 'Media node disconnected' ||
                                    data1 === 'Media process disconnected' ||
                                    data1 ===
                                        'Neither conference nor gateway found' ||
                                    data1 ===
                                        'No direct route between Edge and Transcoding' ||
                                    data1 ===
                                        'No participants can keep conference alive' ||
                                    data1 === 'Out of proxying resource' ||
                                    data1 === 'Out of resource' ||
                                    data1 === 'Out of transcoding resource' ||
                                    data1 ===
                                        'Participant exceeded PIN entry retries' ||
                                    data1 === 'Participant limit reached' ||
                                    data1 ===
                                        'Proxied participant disconnected' ||
                                    data1 === 'Resource unavailable' ||
                                    data1 === 'Signaling node disconnected' ||
                                    data1 ===
                                        'SSO Authentication Failed. SSO is not available from this domain' ||
                                    data1 ===
                                        'SSO Authentication Failed. The system is in Maintenance mode' ||
                                    data1 === 'SSO Authentication Failed' ||
                                    data1 ===
                                        'SSO enabled but no Identity Providers configured' ||
                                    data1 === 'System in maintenance mode' ||
                                    data1 === 'Telehealth Call failure' ||
                                    data1 === 'Test call finished' ||
                                    data1 ===
                                        'Timeout waiting for conference host to join or permit access to locked conference' ||
                                    data1 ===
                                        'Timer expired awaiting token refresh' ||
                                    data1 === 'transfer failed' ||
                                    data1 === 'Unexpected Response: 503' ||
                                    data1 ===
                                        'Attempted to acquire license while license already acquired. This is usually due to an internal error.' ||
                                    data1 ===
                                        'Failed to transfer into a multi-party conference' ||
                                    data1 ===
                                        'Failed to transfer into a one-to-one conference'
                                )
                            ) {
                                const err4 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/take_floor/properties/403/definitions/403/properties/result/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema15.properties.result.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                };
                                if (vErrors === null) {
                                    vErrors = [err4];
                                } else {
                                    vErrors.push(err4);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err5 = {
                instancePath,
                schemaPath:
                    '#/definitions/take_floor/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err5];
            } else {
                vErrors.push(err5);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err6 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err6];
        } else {
            vErrors.push(err6);
        }
        errors++;
        validate41.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate41.errors = vErrors;
    return errors === 0;
}
export const validateCallsWebrtcParticipant500 = validate42;
const schema67 = {type: 'string'};
function validate42(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (typeof data !== 'string') {
        validate42.errors = [
            {
                instancePath,
                schemaPath: '#/type',
                keyword: 'type',
                params: {type: 'string'},
                message: 'must be string',
            },
        ];
        return false;
    }
    validate42.errors = vErrors;
    return errors === 0;
}
export const validateCallsWebrtcParticipant504 = validate43;
const schema68 = {type: 'string'};
function validate43(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (typeof data !== 'string') {
        validate43.errors = [
            {
                instancePath,
                schemaPath: '#/type',
                keyword: 'type',
                params: {type: 'string'},
                message: 'must be string',
            },
        ];
        return false;
    }
    validate43.errors = vErrors;
    return errors === 0;
}
export const validateCallsRtmpParticipantBody = validate44;
const schema69 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        TopLevel: {
            type: 'object',
            additionalProperties: false,
            properties: {
                call_type: {
                    type: 'string',
                    enum: ['RTMP'],
                    description: '`RTMP` for an RTMP call.',
                },
                present: {
                    type: 'string',
                    enum: ['send', 'receive'],
                    description:
                        'Optional field. Contains `send` or `receive` to act as a presentation stream rather than a main audio/video stream.',
                },
                streaming: {
                    type: 'boolean',
                    description:
                        'Optional field. Set to true if this is to be treated as a streaming participant for recording purposes.',
                },
                bandwidth: {
                    type: 'number',
                    description:
                        'Optional field. If supplied it provides a maximum incoming / outgoing bandwidth in kbps.',
                },
            },
            required: ['call_type'],
            title: 'TopLevel',
        },
    },
    oneOf: [
        {
            $ref: '#/definitions/calls_rtmp_participant/properties/Body/definitions/TopLevel',
        },
    ],
};
const schema70 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        call_type: {
            type: 'string',
            enum: ['RTMP'],
            description: '`RTMP` for an RTMP call.',
        },
        present: {
            type: 'string',
            enum: ['send', 'receive'],
            description:
                'Optional field. Contains `send` or `receive` to act as a presentation stream rather than a main audio/video stream.',
        },
        streaming: {
            type: 'boolean',
            description:
                'Optional field. Set to true if this is to be treated as a streaming participant for recording purposes.',
        },
        bandwidth: {
            type: 'number',
            description:
                'Optional field. If supplied it provides a maximum incoming / outgoing bandwidth in kbps.',
        },
    },
    required: ['call_type'],
    title: 'TopLevel',
};
function validate44(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (data.call_type === undefined && (missing0 = 'call_type')) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/calls_rtmp_participant/properties/Body/definitions/TopLevel/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (
                        !(
                            key0 === 'call_type' ||
                            key0 === 'present' ||
                            key0 === 'streaming' ||
                            key0 === 'bandwidth'
                        )
                    ) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.call_type !== undefined) {
                        let data0 = data.call_type;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/call_type',
                                schemaPath:
                                    '#/definitions/calls_rtmp_participant/properties/Body/definitions/TopLevel/properties/call_type/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'RTMP')) {
                            const err2 = {
                                instancePath: instancePath + '/call_type',
                                schemaPath:
                                    '#/definitions/calls_rtmp_participant/properties/Body/definitions/TopLevel/properties/call_type/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema70.properties.call_type.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.present !== undefined) {
                            let data1 = data.present;
                            const _errs7 = errors;
                            if (typeof data1 !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/present',
                                    schemaPath:
                                        '#/definitions/calls_rtmp_participant/properties/Body/definitions/TopLevel/properties/present/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            if (!(data1 === 'send' || data1 === 'receive')) {
                                const err4 = {
                                    instancePath: instancePath + '/present',
                                    schemaPath:
                                        '#/definitions/calls_rtmp_participant/properties/Body/definitions/TopLevel/properties/present/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema70.properties.present.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                };
                                if (vErrors === null) {
                                    vErrors = [err4];
                                } else {
                                    vErrors.push(err4);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                        if (valid2) {
                            if (data.streaming !== undefined) {
                                const _errs9 = errors;
                                if (typeof data.streaming !== 'boolean') {
                                    const err5 = {
                                        instancePath:
                                            instancePath + '/streaming',
                                        schemaPath:
                                            '#/definitions/calls_rtmp_participant/properties/Body/definitions/TopLevel/properties/streaming/type',
                                        keyword: 'type',
                                        params: {type: 'boolean'},
                                        message: 'must be boolean',
                                    };
                                    if (vErrors === null) {
                                        vErrors = [err5];
                                    } else {
                                        vErrors.push(err5);
                                    }
                                    errors++;
                                }
                                var valid2 = _errs9 === errors;
                            } else {
                                var valid2 = true;
                            }
                            if (valid2) {
                                if (data.bandwidth !== undefined) {
                                    const _errs11 = errors;
                                    if (!(typeof data.bandwidth == 'number')) {
                                        const err6 = {
                                            instancePath:
                                                instancePath + '/bandwidth',
                                            schemaPath:
                                                '#/definitions/calls_rtmp_participant/properties/Body/definitions/TopLevel/properties/bandwidth/type',
                                            keyword: 'type',
                                            params: {type: 'number'},
                                            message: 'must be number',
                                        };
                                        if (vErrors === null) {
                                            vErrors = [err6];
                                        } else {
                                            vErrors.push(err6);
                                        }
                                        errors++;
                                    }
                                    var valid2 = _errs11 === errors;
                                } else {
                                    var valid2 = true;
                                }
                            }
                        }
                    }
                }
            }
        } else {
            const err7 = {
                instancePath,
                schemaPath:
                    '#/definitions/calls_rtmp_participant/properties/Body/definitions/TopLevel/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err7];
            } else {
                vErrors.push(err7);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err8 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err8];
        } else {
            vErrors.push(err8);
        }
        errors++;
        validate44.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate44.errors = vErrors;
    return errors === 0;
}
export const validateCallsRtmpParticipant200 = validate45;
const schema71 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        '200': {
            type: 'object',
            additionalProperties: false,
            properties: {
                status: {
                    type: 'string',
                    description: 'Status of the response, eg. success.',
                    enum: ['success'],
                },
                result: {
                    $ref: '#/definitions/calls_rtmp_participant/properties/200/definitions/Result',
                },
            },
            required: ['status', 'result'],
            title: 'Status200',
        },
        Result: {
            type: 'object',
            additionalProperties: false,
            properties: {
                call_uuid: {
                    type: 'string',
                    description: '`call_uuid` is used to control the call',
                },
                url: {
                    type: 'string',
                    description:
                        'Insecure (rtmp://) RTMP URL that can be connected to by the client',
                },
                secure_url: {
                    type: 'string',
                    description:
                        'Secure (rtmps://) RTMP URL that can be connected to by the client',
                },
            },
            required: ['call_uuid', 'url', 'secure_url'],
            title: 'Result',
        },
    },
    oneOf: [
        {
            $ref: '#/definitions/calls_rtmp_participant/properties/200/definitions/200',
        },
    ],
};
const schema72 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        status: {
            type: 'string',
            description: 'Status of the response, eg. success.',
            enum: ['success'],
        },
        result: {
            $ref: '#/definitions/calls_rtmp_participant/properties/200/definitions/Result',
        },
    },
    required: ['status', 'result'],
    title: 'Status200',
};
const schema73 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        call_uuid: {
            type: 'string',
            description: '`call_uuid` is used to control the call',
        },
        url: {
            type: 'string',
            description:
                'Insecure (rtmp://) RTMP URL that can be connected to by the client',
        },
        secure_url: {
            type: 'string',
            description:
                'Secure (rtmps://) RTMP URL that can be connected to by the client',
        },
    },
    required: ['call_uuid', 'url', 'secure_url'],
    title: 'Result',
};
function validate46(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (errors === 0) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                validate46.errors = [
                    {
                        instancePath,
                        schemaPath: '#/required',
                        keyword: 'required',
                        params: {missingProperty: missing0},
                        message:
                            "must have required property '" + missing0 + "'",
                    },
                ];
                return false;
            } else {
                const _errs1 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs1 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs2 = errors;
                        if (typeof data0 !== 'string') {
                            validate46.errors = [
                                {
                                    instancePath: instancePath + '/status',
                                    schemaPath: '#/properties/status/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                },
                            ];
                            return false;
                        }
                        if (!(data0 === 'success')) {
                            validate46.errors = [
                                {
                                    instancePath: instancePath + '/status',
                                    schemaPath: '#/properties/status/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema72.properties.status.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                },
                            ];
                            return false;
                        }
                        var valid0 = _errs2 === errors;
                    } else {
                        var valid0 = true;
                    }
                    if (valid0) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs4 = errors;
                            const _errs5 = errors;
                            if (errors === _errs5) {
                                if (
                                    data1 &&
                                    typeof data1 == 'object' &&
                                    !Array.isArray(data1)
                                ) {
                                    let missing1;
                                    if (
                                        (data1.call_uuid === undefined &&
                                            (missing1 = 'call_uuid')) ||
                                        (data1.url === undefined &&
                                            (missing1 = 'url')) ||
                                        (data1.secure_url === undefined &&
                                            (missing1 = 'secure_url'))
                                    ) {
                                        validate46.errors = [
                                            {
                                                instancePath:
                                                    instancePath + '/result',
                                                schemaPath:
                                                    '#/definitions/calls_rtmp_participant/properties/200/definitions/Result/required',
                                                keyword: 'required',
                                                params: {
                                                    missingProperty: missing1,
                                                },
                                                message:
                                                    "must have required property '" +
                                                    missing1 +
                                                    "'",
                                            },
                                        ];
                                        return false;
                                    } else {
                                        const _errs7 = errors;
                                        for (const key1 in data1) {
                                            if (
                                                !(
                                                    key1 === 'call_uuid' ||
                                                    key1 === 'url' ||
                                                    key1 === 'secure_url'
                                                )
                                            ) {
                                                delete data1[key1];
                                            }
                                        }
                                        if (_errs7 === errors) {
                                            if (data1.call_uuid !== undefined) {
                                                const _errs8 = errors;
                                                if (
                                                    typeof data1.call_uuid !==
                                                    'string'
                                                ) {
                                                    validate46.errors = [
                                                        {
                                                            instancePath:
                                                                instancePath +
                                                                '/result/call_uuid',
                                                            schemaPath:
                                                                '#/definitions/calls_rtmp_participant/properties/200/definitions/Result/properties/call_uuid/type',
                                                            keyword: 'type',
                                                            params: {
                                                                type: 'string',
                                                            },
                                                            message:
                                                                'must be string',
                                                        },
                                                    ];
                                                    return false;
                                                }
                                                var valid2 = _errs8 === errors;
                                            } else {
                                                var valid2 = true;
                                            }
                                            if (valid2) {
                                                if (data1.url !== undefined) {
                                                    const _errs10 = errors;
                                                    if (
                                                        typeof data1.url !==
                                                        'string'
                                                    ) {
                                                        validate46.errors = [
                                                            {
                                                                instancePath:
                                                                    instancePath +
                                                                    '/result/url',
                                                                schemaPath:
                                                                    '#/definitions/calls_rtmp_participant/properties/200/definitions/Result/properties/url/type',
                                                                keyword: 'type',
                                                                params: {
                                                                    type: 'string',
                                                                },
                                                                message:
                                                                    'must be string',
                                                            },
                                                        ];
                                                        return false;
                                                    }
                                                    var valid2 =
                                                        _errs10 === errors;
                                                } else {
                                                    var valid2 = true;
                                                }
                                                if (valid2) {
                                                    if (
                                                        data1.secure_url !==
                                                        undefined
                                                    ) {
                                                        const _errs12 = errors;
                                                        if (
                                                            typeof data1.secure_url !==
                                                            'string'
                                                        ) {
                                                            validate46.errors =
                                                                [
                                                                    {
                                                                        instancePath:
                                                                            instancePath +
                                                                            '/result/secure_url',
                                                                        schemaPath:
                                                                            '#/definitions/calls_rtmp_participant/properties/200/definitions/Result/properties/secure_url/type',
                                                                        keyword:
                                                                            'type',
                                                                        params: {
                                                                            type: 'string',
                                                                        },
                                                                        message:
                                                                            'must be string',
                                                                    },
                                                                ];
                                                            return false;
                                                        }
                                                        var valid2 =
                                                            _errs12 === errors;
                                                    } else {
                                                        var valid2 = true;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                } else {
                                    validate46.errors = [
                                        {
                                            instancePath:
                                                instancePath + '/result',
                                            schemaPath:
                                                '#/definitions/calls_rtmp_participant/properties/200/definitions/Result/type',
                                            keyword: 'type',
                                            params: {type: 'object'},
                                            message: 'must be object',
                                        },
                                    ];
                                    return false;
                                }
                            }
                            var valid0 = _errs4 === errors;
                        } else {
                            var valid0 = true;
                        }
                    }
                }
            }
        } else {
            validate46.errors = [
                {
                    instancePath,
                    schemaPath: '#/type',
                    keyword: 'type',
                    params: {type: 'object'},
                    message: 'must be object',
                },
            ];
            return false;
        }
    }
    validate46.errors = vErrors;
    return errors === 0;
}
function validate45(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    if (
        !validate46(data, {
            instancePath,
            parentData,
            parentDataProperty,
            rootData,
        })
    ) {
        vErrors =
            vErrors === null
                ? validate46.errors
                : vErrors.concat(validate46.errors);
        errors = vErrors.length;
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err0 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err0];
        } else {
            vErrors.push(err0);
        }
        errors++;
        validate45.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate45.errors = vErrors;
    return errors === 0;
}
export const validateCallsRtmpParticipant400 = validate48;
const schema74 = {type: 'string'};
function validate48(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (typeof data !== 'string') {
        validate48.errors = [
            {
                instancePath,
                schemaPath: '#/type',
                keyword: 'type',
                params: {type: 'string'},
                message: 'must be string',
            },
        ];
        return false;
    }
    validate48.errors = vErrors;
    return errors === 0;
}
export const validateCallsRtmpParticipant403 = validate49;
function validate49(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/take_floor/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/take_floor/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/take_floor/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema15.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs7 = errors;
                            if (typeof data1 !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/take_floor/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            if (
                                !(
                                    data1 === '404 Not Found' ||
                                    data1 === '502 Bad Gateway' ||
                                    data1 === '503 Service Unavailable' ||
                                    data1 ===
                                        'All conference hosts departed hosted conference' ||
                                    data1 ===
                                        'API initiated conference termination' ||
                                    data1 ===
                                        'API initiated participant disconnect' ||
                                    data1 === 'Call disconnected' ||
                                    data1 === 'Call rejected' ||
                                    data1 ===
                                        'Conference extension not found' ||
                                    data1 ===
                                        'Conference host ended the conference with a DTMF command' ||
                                    data1 ===
                                        'Conference terminated by an administrator' ||
                                    data1 ===
                                        'Conference terminated by another participant' ||
                                    data1 === 'Disabled' ||
                                    data1 ===
                                        'Disconnected by an administrator' ||
                                    data1 ===
                                        'Disconnected by another participant' ||
                                    data1 === 'Failed to forward request' ||
                                    data1 === 'Gateway dial out failed' ||
                                    data1 === 'Ice failure' ||
                                    data1 === 'Insufficient licenses' ||
                                    data1 ===
                                        'invalid gateway routing rule transform' ||
                                    data1 === 'Invalid license' ||
                                    data1 === 'Invalid PIN' ||
                                    data1 === 'Invalid protocol for service' ||
                                    data1 === 'Invalid role' ||
                                    data1 === 'Invalid token' ||
                                    data1 ===
                                        'Last remaining participant removed from conference after timeout' ||
                                    data1 === 'Media node disconnected' ||
                                    data1 === 'Media process disconnected' ||
                                    data1 ===
                                        'Neither conference nor gateway found' ||
                                    data1 ===
                                        'No direct route between Edge and Transcoding' ||
                                    data1 ===
                                        'No participants can keep conference alive' ||
                                    data1 === 'Out of proxying resource' ||
                                    data1 === 'Out of resource' ||
                                    data1 === 'Out of transcoding resource' ||
                                    data1 ===
                                        'Participant exceeded PIN entry retries' ||
                                    data1 === 'Participant limit reached' ||
                                    data1 ===
                                        'Proxied participant disconnected' ||
                                    data1 === 'Resource unavailable' ||
                                    data1 === 'Signaling node disconnected' ||
                                    data1 ===
                                        'SSO Authentication Failed. SSO is not available from this domain' ||
                                    data1 ===
                                        'SSO Authentication Failed. The system is in Maintenance mode' ||
                                    data1 === 'SSO Authentication Failed' ||
                                    data1 ===
                                        'SSO enabled but no Identity Providers configured' ||
                                    data1 === 'System in maintenance mode' ||
                                    data1 === 'Telehealth Call failure' ||
                                    data1 === 'Test call finished' ||
                                    data1 ===
                                        'Timeout waiting for conference host to join or permit access to locked conference' ||
                                    data1 ===
                                        'Timer expired awaiting token refresh' ||
                                    data1 === 'transfer failed' ||
                                    data1 === 'Unexpected Response: 503' ||
                                    data1 ===
                                        'Attempted to acquire license while license already acquired. This is usually due to an internal error.' ||
                                    data1 ===
                                        'Failed to transfer into a multi-party conference' ||
                                    data1 ===
                                        'Failed to transfer into a one-to-one conference'
                                )
                            ) {
                                const err4 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/take_floor/properties/403/definitions/403/properties/result/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema15.properties.result.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                };
                                if (vErrors === null) {
                                    vErrors = [err4];
                                } else {
                                    vErrors.push(err4);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err5 = {
                instancePath,
                schemaPath:
                    '#/definitions/take_floor/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err5];
            } else {
                vErrors.push(err5);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err6 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err6];
        } else {
            vErrors.push(err6);
        }
        errors++;
        validate49.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate49.errors = vErrors;
    return errors === 0;
}
export const validateCallsRtmpParticipant500 = validate50;
const schema77 = {type: 'string'};
function validate50(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (typeof data !== 'string') {
        validate50.errors = [
            {
                instancePath,
                schemaPath: '#/type',
                keyword: 'type',
                params: {type: 'string'},
                message: 'must be string',
            },
        ];
        return false;
    }
    validate50.errors = vErrors;
    return errors === 0;
}
export const validateCallsRtmpParticipant504 = validate51;
const schema78 = {type: 'string'};
function validate51(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (typeof data !== 'string') {
        validate51.errors = [
            {
                instancePath,
                schemaPath: '#/type',
                keyword: 'type',
                params: {type: 'string'},
                message: 'must be string',
            },
        ];
        return false;
    }
    validate51.errors = vErrors;
    return errors === 0;
}
export const validateOverlaytextParticipantBody = validate52;
const schema79 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        TopLevel: {
            type: 'object',
            additionalProperties: false,
            properties: {
                text: {
                    type: 'string',
                    description:
                        'Text to use as the participant name overlay text.',
                },
            },
            required: ['text'],
            title: 'TopLevel',
        },
    },
    oneOf: [
        {
            $ref: '#/definitions/overlaytext_participant/properties/Body/definitions/TopLevel',
        },
    ],
};
const schema80 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        text: {
            type: 'string',
            description: 'Text to use as the participant name overlay text.',
        },
    },
    required: ['text'],
    title: 'TopLevel',
};
function validate52(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (data.text === undefined && (missing0 = 'text')) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/overlaytext_participant/properties/Body/definitions/TopLevel/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'text')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.text !== undefined) {
                        if (typeof data.text !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/text',
                                schemaPath:
                                    '#/definitions/overlaytext_participant/properties/Body/definitions/TopLevel/properties/text/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                    }
                }
            }
        } else {
            const err2 = {
                instancePath,
                schemaPath:
                    '#/definitions/overlaytext_participant/properties/Body/definitions/TopLevel/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err2];
            } else {
                vErrors.push(err2);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err3 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err3];
        } else {
            vErrors.push(err3);
        }
        errors++;
        validate52.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate52.errors = vErrors;
    return errors === 0;
}
export const validateOverlaytextParticipant200 = validate53;
function validate53(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/mute_participant/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/mute_participant/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/mute_participant/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema17.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/mute_participant/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/mute_participant/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate53.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate53.errors = vErrors;
    return errors === 0;
}
export const validateOverlaytextParticipant403 = validate54;
function validate54(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/take_floor/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/take_floor/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/take_floor/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema15.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs7 = errors;
                            if (typeof data1 !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/take_floor/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            if (
                                !(
                                    data1 === '404 Not Found' ||
                                    data1 === '502 Bad Gateway' ||
                                    data1 === '503 Service Unavailable' ||
                                    data1 ===
                                        'All conference hosts departed hosted conference' ||
                                    data1 ===
                                        'API initiated conference termination' ||
                                    data1 ===
                                        'API initiated participant disconnect' ||
                                    data1 === 'Call disconnected' ||
                                    data1 === 'Call rejected' ||
                                    data1 ===
                                        'Conference extension not found' ||
                                    data1 ===
                                        'Conference host ended the conference with a DTMF command' ||
                                    data1 ===
                                        'Conference terminated by an administrator' ||
                                    data1 ===
                                        'Conference terminated by another participant' ||
                                    data1 === 'Disabled' ||
                                    data1 ===
                                        'Disconnected by an administrator' ||
                                    data1 ===
                                        'Disconnected by another participant' ||
                                    data1 === 'Failed to forward request' ||
                                    data1 === 'Gateway dial out failed' ||
                                    data1 === 'Ice failure' ||
                                    data1 === 'Insufficient licenses' ||
                                    data1 ===
                                        'invalid gateway routing rule transform' ||
                                    data1 === 'Invalid license' ||
                                    data1 === 'Invalid PIN' ||
                                    data1 === 'Invalid protocol for service' ||
                                    data1 === 'Invalid role' ||
                                    data1 === 'Invalid token' ||
                                    data1 ===
                                        'Last remaining participant removed from conference after timeout' ||
                                    data1 === 'Media node disconnected' ||
                                    data1 === 'Media process disconnected' ||
                                    data1 ===
                                        'Neither conference nor gateway found' ||
                                    data1 ===
                                        'No direct route between Edge and Transcoding' ||
                                    data1 ===
                                        'No participants can keep conference alive' ||
                                    data1 === 'Out of proxying resource' ||
                                    data1 === 'Out of resource' ||
                                    data1 === 'Out of transcoding resource' ||
                                    data1 ===
                                        'Participant exceeded PIN entry retries' ||
                                    data1 === 'Participant limit reached' ||
                                    data1 ===
                                        'Proxied participant disconnected' ||
                                    data1 === 'Resource unavailable' ||
                                    data1 === 'Signaling node disconnected' ||
                                    data1 ===
                                        'SSO Authentication Failed. SSO is not available from this domain' ||
                                    data1 ===
                                        'SSO Authentication Failed. The system is in Maintenance mode' ||
                                    data1 === 'SSO Authentication Failed' ||
                                    data1 ===
                                        'SSO enabled but no Identity Providers configured' ||
                                    data1 === 'System in maintenance mode' ||
                                    data1 === 'Telehealth Call failure' ||
                                    data1 === 'Test call finished' ||
                                    data1 ===
                                        'Timeout waiting for conference host to join or permit access to locked conference' ||
                                    data1 ===
                                        'Timer expired awaiting token refresh' ||
                                    data1 === 'transfer failed' ||
                                    data1 === 'Unexpected Response: 503' ||
                                    data1 ===
                                        'Attempted to acquire license while license already acquired. This is usually due to an internal error.' ||
                                    data1 ===
                                        'Failed to transfer into a multi-party conference' ||
                                    data1 ===
                                        'Failed to transfer into a one-to-one conference'
                                )
                            ) {
                                const err4 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/take_floor/properties/403/definitions/403/properties/result/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema15.properties.result.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                };
                                if (vErrors === null) {
                                    vErrors = [err4];
                                } else {
                                    vErrors.push(err4);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err5 = {
                instancePath,
                schemaPath:
                    '#/definitions/take_floor/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err5];
            } else {
                vErrors.push(err5);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err6 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err6];
        } else {
            vErrors.push(err6);
        }
        errors++;
        validate54.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate54.errors = vErrors;
    return errors === 0;
}
export const validatePresInMixParticipantBody = validate55;
const schema85 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        TopLevel: {
            type: 'object',
            additionalProperties: false,
            properties: {
                state: {
                    type: 'string',
                    description:
                        'Controls whether or not the participant sees presentation in the layout mix.',
                },
            },
            required: ['state'],
            title: 'TopLevel',
        },
    },
    oneOf: [
        {
            $ref: '#/definitions/pres_in_mix_participant/properties/Body/definitions/TopLevel',
        },
    ],
};
const schema86 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        state: {
            type: 'string',
            description:
                'Controls whether or not the participant sees presentation in the layout mix.',
        },
    },
    required: ['state'],
    title: 'TopLevel',
};
function validate55(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (data.state === undefined && (missing0 = 'state')) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/pres_in_mix_participant/properties/Body/definitions/TopLevel/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'state')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.state !== undefined) {
                        if (typeof data.state !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/state',
                                schemaPath:
                                    '#/definitions/pres_in_mix_participant/properties/Body/definitions/TopLevel/properties/state/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                    }
                }
            }
        } else {
            const err2 = {
                instancePath,
                schemaPath:
                    '#/definitions/pres_in_mix_participant/properties/Body/definitions/TopLevel/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err2];
            } else {
                vErrors.push(err2);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err3 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err3];
        } else {
            vErrors.push(err3);
        }
        errors++;
        validate55.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate55.errors = vErrors;
    return errors === 0;
}
export const validatePresInMixParticipant200 = validate56;
function validate56(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/mute_participant/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/mute_participant/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/mute_participant/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema17.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/mute_participant/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/mute_participant/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate56.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate56.errors = vErrors;
    return errors === 0;
}
export const validatePresInMixParticipant403 = validate57;
function validate57(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/take_floor/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/take_floor/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/take_floor/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema15.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs7 = errors;
                            if (typeof data1 !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/take_floor/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            if (
                                !(
                                    data1 === '404 Not Found' ||
                                    data1 === '502 Bad Gateway' ||
                                    data1 === '503 Service Unavailable' ||
                                    data1 ===
                                        'All conference hosts departed hosted conference' ||
                                    data1 ===
                                        'API initiated conference termination' ||
                                    data1 ===
                                        'API initiated participant disconnect' ||
                                    data1 === 'Call disconnected' ||
                                    data1 === 'Call rejected' ||
                                    data1 ===
                                        'Conference extension not found' ||
                                    data1 ===
                                        'Conference host ended the conference with a DTMF command' ||
                                    data1 ===
                                        'Conference terminated by an administrator' ||
                                    data1 ===
                                        'Conference terminated by another participant' ||
                                    data1 === 'Disabled' ||
                                    data1 ===
                                        'Disconnected by an administrator' ||
                                    data1 ===
                                        'Disconnected by another participant' ||
                                    data1 === 'Failed to forward request' ||
                                    data1 === 'Gateway dial out failed' ||
                                    data1 === 'Ice failure' ||
                                    data1 === 'Insufficient licenses' ||
                                    data1 ===
                                        'invalid gateway routing rule transform' ||
                                    data1 === 'Invalid license' ||
                                    data1 === 'Invalid PIN' ||
                                    data1 === 'Invalid protocol for service' ||
                                    data1 === 'Invalid role' ||
                                    data1 === 'Invalid token' ||
                                    data1 ===
                                        'Last remaining participant removed from conference after timeout' ||
                                    data1 === 'Media node disconnected' ||
                                    data1 === 'Media process disconnected' ||
                                    data1 ===
                                        'Neither conference nor gateway found' ||
                                    data1 ===
                                        'No direct route between Edge and Transcoding' ||
                                    data1 ===
                                        'No participants can keep conference alive' ||
                                    data1 === 'Out of proxying resource' ||
                                    data1 === 'Out of resource' ||
                                    data1 === 'Out of transcoding resource' ||
                                    data1 ===
                                        'Participant exceeded PIN entry retries' ||
                                    data1 === 'Participant limit reached' ||
                                    data1 ===
                                        'Proxied participant disconnected' ||
                                    data1 === 'Resource unavailable' ||
                                    data1 === 'Signaling node disconnected' ||
                                    data1 ===
                                        'SSO Authentication Failed. SSO is not available from this domain' ||
                                    data1 ===
                                        'SSO Authentication Failed. The system is in Maintenance mode' ||
                                    data1 === 'SSO Authentication Failed' ||
                                    data1 ===
                                        'SSO enabled but no Identity Providers configured' ||
                                    data1 === 'System in maintenance mode' ||
                                    data1 === 'Telehealth Call failure' ||
                                    data1 === 'Test call finished' ||
                                    data1 ===
                                        'Timeout waiting for conference host to join or permit access to locked conference' ||
                                    data1 ===
                                        'Timer expired awaiting token refresh' ||
                                    data1 === 'transfer failed' ||
                                    data1 === 'Unexpected Response: 503' ||
                                    data1 ===
                                        'Attempted to acquire license while license already acquired. This is usually due to an internal error.' ||
                                    data1 ===
                                        'Failed to transfer into a multi-party conference' ||
                                    data1 ===
                                        'Failed to transfer into a one-to-one conference'
                                )
                            ) {
                                const err4 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/take_floor/properties/403/definitions/403/properties/result/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema15.properties.result.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                };
                                if (vErrors === null) {
                                    vErrors = [err4];
                                } else {
                                    vErrors.push(err4);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err5 = {
                instancePath,
                schemaPath:
                    '#/definitions/take_floor/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err5];
            } else {
                vErrors.push(err5);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err6 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err6];
        } else {
            vErrors.push(err6);
        }
        errors++;
        validate57.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate57.errors = vErrors;
    return errors === 0;
}
export const validateRoleParticipantBody = validate58;
const schema91 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        TopLevel: {
            type: 'object',
            additionalProperties: false,
            properties: {
                role: {
                    type: 'string',
                    enum: ['chair', 'guest'],
                    description:
                        '`chair` = Host participant; `guest` = Guest participant',
                },
            },
            required: ['role'],
            title: 'TopLevel',
        },
    },
    oneOf: [
        {
            $ref: '#/definitions/role_participant/properties/Body/definitions/TopLevel',
        },
    ],
};
const schema92 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        role: {
            type: 'string',
            enum: ['chair', 'guest'],
            description:
                '`chair` = Host participant; `guest` = Guest participant',
        },
    },
    required: ['role'],
    title: 'TopLevel',
};
function validate58(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (data.role === undefined && (missing0 = 'role')) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/role_participant/properties/Body/definitions/TopLevel/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'role')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.role !== undefined) {
                        let data0 = data.role;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/role',
                                schemaPath:
                                    '#/definitions/role_participant/properties/Body/definitions/TopLevel/properties/role/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'chair' || data0 === 'guest')) {
                            const err2 = {
                                instancePath: instancePath + '/role',
                                schemaPath:
                                    '#/definitions/role_participant/properties/Body/definitions/TopLevel/properties/role/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema92.properties.role.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                    }
                }
            }
        } else {
            const err3 = {
                instancePath,
                schemaPath:
                    '#/definitions/role_participant/properties/Body/definitions/TopLevel/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err3];
            } else {
                vErrors.push(err3);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err4 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err4];
        } else {
            vErrors.push(err4);
        }
        errors++;
        validate58.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate58.errors = vErrors;
    return errors === 0;
}
export const validateRoleParticipant200 = validate59;
function validate59(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/mute_participant/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/mute_participant/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/mute_participant/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema17.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/mute_participant/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/mute_participant/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate59.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate59.errors = vErrors;
    return errors === 0;
}
export const validateRoleParticipant403 = validate60;
function validate60(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/take_floor/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/take_floor/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/take_floor/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema15.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs7 = errors;
                            if (typeof data1 !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/take_floor/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            if (
                                !(
                                    data1 === '404 Not Found' ||
                                    data1 === '502 Bad Gateway' ||
                                    data1 === '503 Service Unavailable' ||
                                    data1 ===
                                        'All conference hosts departed hosted conference' ||
                                    data1 ===
                                        'API initiated conference termination' ||
                                    data1 ===
                                        'API initiated participant disconnect' ||
                                    data1 === 'Call disconnected' ||
                                    data1 === 'Call rejected' ||
                                    data1 ===
                                        'Conference extension not found' ||
                                    data1 ===
                                        'Conference host ended the conference with a DTMF command' ||
                                    data1 ===
                                        'Conference terminated by an administrator' ||
                                    data1 ===
                                        'Conference terminated by another participant' ||
                                    data1 === 'Disabled' ||
                                    data1 ===
                                        'Disconnected by an administrator' ||
                                    data1 ===
                                        'Disconnected by another participant' ||
                                    data1 === 'Failed to forward request' ||
                                    data1 === 'Gateway dial out failed' ||
                                    data1 === 'Ice failure' ||
                                    data1 === 'Insufficient licenses' ||
                                    data1 ===
                                        'invalid gateway routing rule transform' ||
                                    data1 === 'Invalid license' ||
                                    data1 === 'Invalid PIN' ||
                                    data1 === 'Invalid protocol for service' ||
                                    data1 === 'Invalid role' ||
                                    data1 === 'Invalid token' ||
                                    data1 ===
                                        'Last remaining participant removed from conference after timeout' ||
                                    data1 === 'Media node disconnected' ||
                                    data1 === 'Media process disconnected' ||
                                    data1 ===
                                        'Neither conference nor gateway found' ||
                                    data1 ===
                                        'No direct route between Edge and Transcoding' ||
                                    data1 ===
                                        'No participants can keep conference alive' ||
                                    data1 === 'Out of proxying resource' ||
                                    data1 === 'Out of resource' ||
                                    data1 === 'Out of transcoding resource' ||
                                    data1 ===
                                        'Participant exceeded PIN entry retries' ||
                                    data1 === 'Participant limit reached' ||
                                    data1 ===
                                        'Proxied participant disconnected' ||
                                    data1 === 'Resource unavailable' ||
                                    data1 === 'Signaling node disconnected' ||
                                    data1 ===
                                        'SSO Authentication Failed. SSO is not available from this domain' ||
                                    data1 ===
                                        'SSO Authentication Failed. The system is in Maintenance mode' ||
                                    data1 === 'SSO Authentication Failed' ||
                                    data1 ===
                                        'SSO enabled but no Identity Providers configured' ||
                                    data1 === 'System in maintenance mode' ||
                                    data1 === 'Telehealth Call failure' ||
                                    data1 === 'Test call finished' ||
                                    data1 ===
                                        'Timeout waiting for conference host to join or permit access to locked conference' ||
                                    data1 ===
                                        'Timer expired awaiting token refresh' ||
                                    data1 === 'transfer failed' ||
                                    data1 === 'Unexpected Response: 503' ||
                                    data1 ===
                                        'Attempted to acquire license while license already acquired. This is usually due to an internal error.' ||
                                    data1 ===
                                        'Failed to transfer into a multi-party conference' ||
                                    data1 ===
                                        'Failed to transfer into a one-to-one conference'
                                )
                            ) {
                                const err4 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/take_floor/properties/403/definitions/403/properties/result/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema15.properties.result.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                };
                                if (vErrors === null) {
                                    vErrors = [err4];
                                } else {
                                    vErrors.push(err4);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err5 = {
                instancePath,
                schemaPath:
                    '#/definitions/take_floor/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err5];
            } else {
                vErrors.push(err5);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err6 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err6];
        } else {
            vErrors.push(err6);
        }
        errors++;
        validate60.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate60.errors = vErrors;
    return errors === 0;
}
export const validateFeccParticipantBody = validate61;
const schema97 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        TopLevel: {
            type: 'object',
            additionalProperties: false,
            properties: {
                action: {
                    type: 'string',
                    enum: ['start', 'stop', 'continue'],
                    description: 'Either `start`, `stop`, or `continue`.',
                },
                target: {
                    type: 'string',
                    description:
                        'UUID of the target participant (from the participant list). Leave undefined for a gateway call.',
                },
                movement: {
                    type: 'array',
                    items: {
                        $ref: '#/definitions/fecc_participant/properties/Body/definitions/Movement',
                    },
                },
            },
            required: ['action', 'target', 'movement'],
            title: 'TopLevel',
        },
        Movement: {
            type: 'object',
            additionalProperties: false,
            properties: {
                axis: {
                    type: 'string',
                    enum: ['pan', 'tilt', 'zoom'],
                    description: 'Either `pan`, `tilt`, or `zoom`.',
                },
                direction: {
                    type: 'string',
                    enum: ['left', 'right', 'up', 'down', 'in', 'out'],
                    description:
                        'Use `left`, `right` for pan; `up`, `down` for tilt; or `in`, `out` for zoom.',
                },
                timeout: {
                    type: 'number',
                    description:
                        'The duration for which to send the signal. Recommended values are 1000 (1 second) for initial `start` message; 200 for `continue` messages.',
                },
            },
            required: ['axis', 'direction'],
            title: 'Endpoint',
        },
    },
    oneOf: [
        {
            $ref: '#/definitions/fecc_participant/properties/Body/definitions/TopLevel',
        },
    ],
};
const schema98 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        action: {
            type: 'string',
            enum: ['start', 'stop', 'continue'],
            description: 'Either `start`, `stop`, or `continue`.',
        },
        target: {
            type: 'string',
            description:
                'UUID of the target participant (from the participant list). Leave undefined for a gateway call.',
        },
        movement: {
            type: 'array',
            items: {
                $ref: '#/definitions/fecc_participant/properties/Body/definitions/Movement',
            },
        },
    },
    required: ['action', 'target', 'movement'],
    title: 'TopLevel',
};
const schema99 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        axis: {
            type: 'string',
            enum: ['pan', 'tilt', 'zoom'],
            description: 'Either `pan`, `tilt`, or `zoom`.',
        },
        direction: {
            type: 'string',
            enum: ['left', 'right', 'up', 'down', 'in', 'out'],
            description:
                'Use `left`, `right` for pan; `up`, `down` for tilt; or `in`, `out` for zoom.',
        },
        timeout: {
            type: 'number',
            description:
                'The duration for which to send the signal. Recommended values are 1000 (1 second) for initial `start` message; 200 for `continue` messages.',
        },
    },
    required: ['axis', 'direction'],
    title: 'Endpoint',
};
function validate62(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (errors === 0) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.action === undefined && (missing0 = 'action')) ||
                (data.target === undefined && (missing0 = 'target')) ||
                (data.movement === undefined && (missing0 = 'movement'))
            ) {
                validate62.errors = [
                    {
                        instancePath,
                        schemaPath: '#/required',
                        keyword: 'required',
                        params: {missingProperty: missing0},
                        message:
                            "must have required property '" + missing0 + "'",
                    },
                ];
                return false;
            } else {
                const _errs1 = errors;
                for (const key0 in data) {
                    if (
                        !(
                            key0 === 'action' ||
                            key0 === 'target' ||
                            key0 === 'movement'
                        )
                    ) {
                        delete data[key0];
                    }
                }
                if (_errs1 === errors) {
                    if (data.action !== undefined) {
                        let data0 = data.action;
                        const _errs2 = errors;
                        if (typeof data0 !== 'string') {
                            validate62.errors = [
                                {
                                    instancePath: instancePath + '/action',
                                    schemaPath: '#/properties/action/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                },
                            ];
                            return false;
                        }
                        if (
                            !(
                                data0 === 'start' ||
                                data0 === 'stop' ||
                                data0 === 'continue'
                            )
                        ) {
                            validate62.errors = [
                                {
                                    instancePath: instancePath + '/action',
                                    schemaPath: '#/properties/action/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema98.properties.action.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                },
                            ];
                            return false;
                        }
                        var valid0 = _errs2 === errors;
                    } else {
                        var valid0 = true;
                    }
                    if (valid0) {
                        if (data.target !== undefined) {
                            const _errs4 = errors;
                            if (typeof data.target !== 'string') {
                                validate62.errors = [
                                    {
                                        instancePath: instancePath + '/target',
                                        schemaPath: '#/properties/target/type',
                                        keyword: 'type',
                                        params: {type: 'string'},
                                        message: 'must be string',
                                    },
                                ];
                                return false;
                            }
                            var valid0 = _errs4 === errors;
                        } else {
                            var valid0 = true;
                        }
                        if (valid0) {
                            if (data.movement !== undefined) {
                                let data2 = data.movement;
                                const _errs6 = errors;
                                if (errors === _errs6) {
                                    if (Array.isArray(data2)) {
                                        var valid1 = true;
                                        const len0 = data2.length;
                                        for (let i0 = 0; i0 < len0; i0++) {
                                            let data3 = data2[i0];
                                            const _errs8 = errors;
                                            const _errs9 = errors;
                                            if (errors === _errs9) {
                                                if (
                                                    data3 &&
                                                    typeof data3 == 'object' &&
                                                    !Array.isArray(data3)
                                                ) {
                                                    let missing1;
                                                    if (
                                                        (data3.axis ===
                                                            undefined &&
                                                            (missing1 =
                                                                'axis')) ||
                                                        (data3.direction ===
                                                            undefined &&
                                                            (missing1 =
                                                                'direction'))
                                                    ) {
                                                        validate62.errors = [
                                                            {
                                                                instancePath:
                                                                    instancePath +
                                                                    '/movement/' +
                                                                    i0,
                                                                schemaPath:
                                                                    '#/definitions/fecc_participant/properties/Body/definitions/Movement/required',
                                                                keyword:
                                                                    'required',
                                                                params: {
                                                                    missingProperty:
                                                                        missing1,
                                                                },
                                                                message:
                                                                    "must have required property '" +
                                                                    missing1 +
                                                                    "'",
                                                            },
                                                        ];
                                                        return false;
                                                    } else {
                                                        const _errs11 = errors;
                                                        for (const key1 in data3) {
                                                            if (
                                                                !(
                                                                    key1 ===
                                                                        'axis' ||
                                                                    key1 ===
                                                                        'direction' ||
                                                                    key1 ===
                                                                        'timeout'
                                                                )
                                                            ) {
                                                                delete data3[
                                                                    key1
                                                                ];
                                                            }
                                                        }
                                                        if (
                                                            _errs11 === errors
                                                        ) {
                                                            if (
                                                                data3.axis !==
                                                                undefined
                                                            ) {
                                                                let data4 =
                                                                    data3.axis;
                                                                const _errs12 =
                                                                    errors;
                                                                if (
                                                                    typeof data4 !==
                                                                    'string'
                                                                ) {
                                                                    validate62.errors =
                                                                        [
                                                                            {
                                                                                instancePath:
                                                                                    instancePath +
                                                                                    '/movement/' +
                                                                                    i0 +
                                                                                    '/axis',
                                                                                schemaPath:
                                                                                    '#/definitions/fecc_participant/properties/Body/definitions/Movement/properties/axis/type',
                                                                                keyword:
                                                                                    'type',
                                                                                params: {
                                                                                    type: 'string',
                                                                                },
                                                                                message:
                                                                                    'must be string',
                                                                            },
                                                                        ];
                                                                    return false;
                                                                }
                                                                if (
                                                                    !(
                                                                        data4 ===
                                                                            'pan' ||
                                                                        data4 ===
                                                                            'tilt' ||
                                                                        data4 ===
                                                                            'zoom'
                                                                    )
                                                                ) {
                                                                    validate62.errors =
                                                                        [
                                                                            {
                                                                                instancePath:
                                                                                    instancePath +
                                                                                    '/movement/' +
                                                                                    i0 +
                                                                                    '/axis',
                                                                                schemaPath:
                                                                                    '#/definitions/fecc_participant/properties/Body/definitions/Movement/properties/axis/enum',
                                                                                keyword:
                                                                                    'enum',
                                                                                params: {
                                                                                    allowedValues:
                                                                                        schema99
                                                                                            .properties
                                                                                            .axis
                                                                                            .enum,
                                                                                },
                                                                                message:
                                                                                    'must be equal to one of the allowed values',
                                                                            },
                                                                        ];
                                                                    return false;
                                                                }
                                                                var valid3 =
                                                                    _errs12 ===
                                                                    errors;
                                                            } else {
                                                                var valid3 =
                                                                    true;
                                                            }
                                                            if (valid3) {
                                                                if (
                                                                    data3.direction !==
                                                                    undefined
                                                                ) {
                                                                    let data5 =
                                                                        data3.direction;
                                                                    const _errs14 =
                                                                        errors;
                                                                    if (
                                                                        typeof data5 !==
                                                                        'string'
                                                                    ) {
                                                                        validate62.errors =
                                                                            [
                                                                                {
                                                                                    instancePath:
                                                                                        instancePath +
                                                                                        '/movement/' +
                                                                                        i0 +
                                                                                        '/direction',
                                                                                    schemaPath:
                                                                                        '#/definitions/fecc_participant/properties/Body/definitions/Movement/properties/direction/type',
                                                                                    keyword:
                                                                                        'type',
                                                                                    params: {
                                                                                        type: 'string',
                                                                                    },
                                                                                    message:
                                                                                        'must be string',
                                                                                },
                                                                            ];
                                                                        return false;
                                                                    }
                                                                    if (
                                                                        !(
                                                                            data5 ===
                                                                                'left' ||
                                                                            data5 ===
                                                                                'right' ||
                                                                            data5 ===
                                                                                'up' ||
                                                                            data5 ===
                                                                                'down' ||
                                                                            data5 ===
                                                                                'in' ||
                                                                            data5 ===
                                                                                'out'
                                                                        )
                                                                    ) {
                                                                        validate62.errors =
                                                                            [
                                                                                {
                                                                                    instancePath:
                                                                                        instancePath +
                                                                                        '/movement/' +
                                                                                        i0 +
                                                                                        '/direction',
                                                                                    schemaPath:
                                                                                        '#/definitions/fecc_participant/properties/Body/definitions/Movement/properties/direction/enum',
                                                                                    keyword:
                                                                                        'enum',
                                                                                    params: {
                                                                                        allowedValues:
                                                                                            schema99
                                                                                                .properties
                                                                                                .direction
                                                                                                .enum,
                                                                                    },
                                                                                    message:
                                                                                        'must be equal to one of the allowed values',
                                                                                },
                                                                            ];
                                                                        return false;
                                                                    }
                                                                    var valid3 =
                                                                        _errs14 ===
                                                                        errors;
                                                                } else {
                                                                    var valid3 =
                                                                        true;
                                                                }
                                                                if (valid3) {
                                                                    if (
                                                                        data3.timeout !==
                                                                        undefined
                                                                    ) {
                                                                        const _errs16 =
                                                                            errors;
                                                                        if (
                                                                            !(
                                                                                typeof data3.timeout ==
                                                                                'number'
                                                                            )
                                                                        ) {
                                                                            validate62.errors =
                                                                                [
                                                                                    {
                                                                                        instancePath:
                                                                                            instancePath +
                                                                                            '/movement/' +
                                                                                            i0 +
                                                                                            '/timeout',
                                                                                        schemaPath:
                                                                                            '#/definitions/fecc_participant/properties/Body/definitions/Movement/properties/timeout/type',
                                                                                        keyword:
                                                                                            'type',
                                                                                        params: {
                                                                                            type: 'number',
                                                                                        },
                                                                                        message:
                                                                                            'must be number',
                                                                                    },
                                                                                ];
                                                                            return false;
                                                                        }
                                                                        var valid3 =
                                                                            _errs16 ===
                                                                            errors;
                                                                    } else {
                                                                        var valid3 =
                                                                            true;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                } else {
                                                    validate62.errors = [
                                                        {
                                                            instancePath:
                                                                instancePath +
                                                                '/movement/' +
                                                                i0,
                                                            schemaPath:
                                                                '#/definitions/fecc_participant/properties/Body/definitions/Movement/type',
                                                            keyword: 'type',
                                                            params: {
                                                                type: 'object',
                                                            },
                                                            message:
                                                                'must be object',
                                                        },
                                                    ];
                                                    return false;
                                                }
                                            }
                                            var valid1 = _errs8 === errors;
                                            if (!valid1) {
                                                break;
                                            }
                                        }
                                    } else {
                                        validate62.errors = [
                                            {
                                                instancePath:
                                                    instancePath + '/movement',
                                                schemaPath:
                                                    '#/properties/movement/type',
                                                keyword: 'type',
                                                params: {type: 'array'},
                                                message: 'must be array',
                                            },
                                        ];
                                        return false;
                                    }
                                }
                                var valid0 = _errs6 === errors;
                            } else {
                                var valid0 = true;
                            }
                        }
                    }
                }
            }
        } else {
            validate62.errors = [
                {
                    instancePath,
                    schemaPath: '#/type',
                    keyword: 'type',
                    params: {type: 'object'},
                    message: 'must be object',
                },
            ];
            return false;
        }
    }
    validate62.errors = vErrors;
    return errors === 0;
}
function validate61(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    if (
        !validate62(data, {
            instancePath,
            parentData,
            parentDataProperty,
            rootData,
        })
    ) {
        vErrors =
            vErrors === null
                ? validate62.errors
                : vErrors.concat(validate62.errors);
        errors = vErrors.length;
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err0 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err0];
        } else {
            vErrors.push(err0);
        }
        errors++;
        validate61.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate61.errors = vErrors;
    return errors === 0;
}
export const validateFeccParticipant200 = validate64;
function validate64(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/mute_participant/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/mute_participant/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/mute_participant/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema17.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/mute_participant/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/mute_participant/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate64.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate64.errors = vErrors;
    return errors === 0;
}
export const validateFeccParticipant403 = validate65;
function validate65(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/take_floor/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/take_floor/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/take_floor/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema15.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs7 = errors;
                            if (typeof data1 !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/take_floor/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            if (
                                !(
                                    data1 === '404 Not Found' ||
                                    data1 === '502 Bad Gateway' ||
                                    data1 === '503 Service Unavailable' ||
                                    data1 ===
                                        'All conference hosts departed hosted conference' ||
                                    data1 ===
                                        'API initiated conference termination' ||
                                    data1 ===
                                        'API initiated participant disconnect' ||
                                    data1 === 'Call disconnected' ||
                                    data1 === 'Call rejected' ||
                                    data1 ===
                                        'Conference extension not found' ||
                                    data1 ===
                                        'Conference host ended the conference with a DTMF command' ||
                                    data1 ===
                                        'Conference terminated by an administrator' ||
                                    data1 ===
                                        'Conference terminated by another participant' ||
                                    data1 === 'Disabled' ||
                                    data1 ===
                                        'Disconnected by an administrator' ||
                                    data1 ===
                                        'Disconnected by another participant' ||
                                    data1 === 'Failed to forward request' ||
                                    data1 === 'Gateway dial out failed' ||
                                    data1 === 'Ice failure' ||
                                    data1 === 'Insufficient licenses' ||
                                    data1 ===
                                        'invalid gateway routing rule transform' ||
                                    data1 === 'Invalid license' ||
                                    data1 === 'Invalid PIN' ||
                                    data1 === 'Invalid protocol for service' ||
                                    data1 === 'Invalid role' ||
                                    data1 === 'Invalid token' ||
                                    data1 ===
                                        'Last remaining participant removed from conference after timeout' ||
                                    data1 === 'Media node disconnected' ||
                                    data1 === 'Media process disconnected' ||
                                    data1 ===
                                        'Neither conference nor gateway found' ||
                                    data1 ===
                                        'No direct route between Edge and Transcoding' ||
                                    data1 ===
                                        'No participants can keep conference alive' ||
                                    data1 === 'Out of proxying resource' ||
                                    data1 === 'Out of resource' ||
                                    data1 === 'Out of transcoding resource' ||
                                    data1 ===
                                        'Participant exceeded PIN entry retries' ||
                                    data1 === 'Participant limit reached' ||
                                    data1 ===
                                        'Proxied participant disconnected' ||
                                    data1 === 'Resource unavailable' ||
                                    data1 === 'Signaling node disconnected' ||
                                    data1 ===
                                        'SSO Authentication Failed. SSO is not available from this domain' ||
                                    data1 ===
                                        'SSO Authentication Failed. The system is in Maintenance mode' ||
                                    data1 === 'SSO Authentication Failed' ||
                                    data1 ===
                                        'SSO enabled but no Identity Providers configured' ||
                                    data1 === 'System in maintenance mode' ||
                                    data1 === 'Telehealth Call failure' ||
                                    data1 === 'Test call finished' ||
                                    data1 ===
                                        'Timeout waiting for conference host to join or permit access to locked conference' ||
                                    data1 ===
                                        'Timer expired awaiting token refresh' ||
                                    data1 === 'transfer failed' ||
                                    data1 === 'Unexpected Response: 503' ||
                                    data1 ===
                                        'Attempted to acquire license while license already acquired. This is usually due to an internal error.' ||
                                    data1 ===
                                        'Failed to transfer into a multi-party conference' ||
                                    data1 ===
                                        'Failed to transfer into a one-to-one conference'
                                )
                            ) {
                                const err4 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/take_floor/properties/403/definitions/403/properties/result/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema15.properties.result.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                };
                                if (vErrors === null) {
                                    vErrors = [err4];
                                } else {
                                    vErrors.push(err4);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err5 = {
                instancePath,
                schemaPath:
                    '#/definitions/take_floor/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err5];
            } else {
                vErrors.push(err5);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err6 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err6];
        } else {
            vErrors.push(err6);
        }
        errors++;
        validate65.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate65.errors = vErrors;
    return errors === 0;
}
export const validateBuzzParticipant200 = validate66;
function validate66(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/mute_participant/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/mute_participant/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/mute_participant/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema17.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/mute_participant/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/mute_participant/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate66.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate66.errors = vErrors;
    return errors === 0;
}
export const validateBuzzParticipant403 = validate67;
function validate67(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/take_floor/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/take_floor/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/take_floor/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema15.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs7 = errors;
                            if (typeof data1 !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/take_floor/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            if (
                                !(
                                    data1 === '404 Not Found' ||
                                    data1 === '502 Bad Gateway' ||
                                    data1 === '503 Service Unavailable' ||
                                    data1 ===
                                        'All conference hosts departed hosted conference' ||
                                    data1 ===
                                        'API initiated conference termination' ||
                                    data1 ===
                                        'API initiated participant disconnect' ||
                                    data1 === 'Call disconnected' ||
                                    data1 === 'Call rejected' ||
                                    data1 ===
                                        'Conference extension not found' ||
                                    data1 ===
                                        'Conference host ended the conference with a DTMF command' ||
                                    data1 ===
                                        'Conference terminated by an administrator' ||
                                    data1 ===
                                        'Conference terminated by another participant' ||
                                    data1 === 'Disabled' ||
                                    data1 ===
                                        'Disconnected by an administrator' ||
                                    data1 ===
                                        'Disconnected by another participant' ||
                                    data1 === 'Failed to forward request' ||
                                    data1 === 'Gateway dial out failed' ||
                                    data1 === 'Ice failure' ||
                                    data1 === 'Insufficient licenses' ||
                                    data1 ===
                                        'invalid gateway routing rule transform' ||
                                    data1 === 'Invalid license' ||
                                    data1 === 'Invalid PIN' ||
                                    data1 === 'Invalid protocol for service' ||
                                    data1 === 'Invalid role' ||
                                    data1 === 'Invalid token' ||
                                    data1 ===
                                        'Last remaining participant removed from conference after timeout' ||
                                    data1 === 'Media node disconnected' ||
                                    data1 === 'Media process disconnected' ||
                                    data1 ===
                                        'Neither conference nor gateway found' ||
                                    data1 ===
                                        'No direct route between Edge and Transcoding' ||
                                    data1 ===
                                        'No participants can keep conference alive' ||
                                    data1 === 'Out of proxying resource' ||
                                    data1 === 'Out of resource' ||
                                    data1 === 'Out of transcoding resource' ||
                                    data1 ===
                                        'Participant exceeded PIN entry retries' ||
                                    data1 === 'Participant limit reached' ||
                                    data1 ===
                                        'Proxied participant disconnected' ||
                                    data1 === 'Resource unavailable' ||
                                    data1 === 'Signaling node disconnected' ||
                                    data1 ===
                                        'SSO Authentication Failed. SSO is not available from this domain' ||
                                    data1 ===
                                        'SSO Authentication Failed. The system is in Maintenance mode' ||
                                    data1 === 'SSO Authentication Failed' ||
                                    data1 ===
                                        'SSO enabled but no Identity Providers configured' ||
                                    data1 === 'System in maintenance mode' ||
                                    data1 === 'Telehealth Call failure' ||
                                    data1 === 'Test call finished' ||
                                    data1 ===
                                        'Timeout waiting for conference host to join or permit access to locked conference' ||
                                    data1 ===
                                        'Timer expired awaiting token refresh' ||
                                    data1 === 'transfer failed' ||
                                    data1 === 'Unexpected Response: 503' ||
                                    data1 ===
                                        'Attempted to acquire license while license already acquired. This is usually due to an internal error.' ||
                                    data1 ===
                                        'Failed to transfer into a multi-party conference' ||
                                    data1 ===
                                        'Failed to transfer into a one-to-one conference'
                                )
                            ) {
                                const err4 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/take_floor/properties/403/definitions/403/properties/result/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema15.properties.result.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                };
                                if (vErrors === null) {
                                    vErrors = [err4];
                                } else {
                                    vErrors.push(err4);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err5 = {
                instancePath,
                schemaPath:
                    '#/definitions/take_floor/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err5];
            } else {
                vErrors.push(err5);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err6 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err6];
        } else {
            vErrors.push(err6);
        }
        errors++;
        validate67.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate67.errors = vErrors;
    return errors === 0;
}
export const validateClearbuzzParticipant200 = validate68;
function validate68(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/mute_participant/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/mute_participant/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/mute_participant/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema17.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/mute_participant/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/mute_participant/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate68.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate68.errors = vErrors;
    return errors === 0;
}
export const validateClearbuzzParticipant403 = validate69;
function validate69(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/take_floor/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/take_floor/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/take_floor/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema15.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs7 = errors;
                            if (typeof data1 !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/take_floor/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            if (
                                !(
                                    data1 === '404 Not Found' ||
                                    data1 === '502 Bad Gateway' ||
                                    data1 === '503 Service Unavailable' ||
                                    data1 ===
                                        'All conference hosts departed hosted conference' ||
                                    data1 ===
                                        'API initiated conference termination' ||
                                    data1 ===
                                        'API initiated participant disconnect' ||
                                    data1 === 'Call disconnected' ||
                                    data1 === 'Call rejected' ||
                                    data1 ===
                                        'Conference extension not found' ||
                                    data1 ===
                                        'Conference host ended the conference with a DTMF command' ||
                                    data1 ===
                                        'Conference terminated by an administrator' ||
                                    data1 ===
                                        'Conference terminated by another participant' ||
                                    data1 === 'Disabled' ||
                                    data1 ===
                                        'Disconnected by an administrator' ||
                                    data1 ===
                                        'Disconnected by another participant' ||
                                    data1 === 'Failed to forward request' ||
                                    data1 === 'Gateway dial out failed' ||
                                    data1 === 'Ice failure' ||
                                    data1 === 'Insufficient licenses' ||
                                    data1 ===
                                        'invalid gateway routing rule transform' ||
                                    data1 === 'Invalid license' ||
                                    data1 === 'Invalid PIN' ||
                                    data1 === 'Invalid protocol for service' ||
                                    data1 === 'Invalid role' ||
                                    data1 === 'Invalid token' ||
                                    data1 ===
                                        'Last remaining participant removed from conference after timeout' ||
                                    data1 === 'Media node disconnected' ||
                                    data1 === 'Media process disconnected' ||
                                    data1 ===
                                        'Neither conference nor gateway found' ||
                                    data1 ===
                                        'No direct route between Edge and Transcoding' ||
                                    data1 ===
                                        'No participants can keep conference alive' ||
                                    data1 === 'Out of proxying resource' ||
                                    data1 === 'Out of resource' ||
                                    data1 === 'Out of transcoding resource' ||
                                    data1 ===
                                        'Participant exceeded PIN entry retries' ||
                                    data1 === 'Participant limit reached' ||
                                    data1 ===
                                        'Proxied participant disconnected' ||
                                    data1 === 'Resource unavailable' ||
                                    data1 === 'Signaling node disconnected' ||
                                    data1 ===
                                        'SSO Authentication Failed. SSO is not available from this domain' ||
                                    data1 ===
                                        'SSO Authentication Failed. The system is in Maintenance mode' ||
                                    data1 === 'SSO Authentication Failed' ||
                                    data1 ===
                                        'SSO enabled but no Identity Providers configured' ||
                                    data1 === 'System in maintenance mode' ||
                                    data1 === 'Telehealth Call failure' ||
                                    data1 === 'Test call finished' ||
                                    data1 ===
                                        'Timeout waiting for conference host to join or permit access to locked conference' ||
                                    data1 ===
                                        'Timer expired awaiting token refresh' ||
                                    data1 === 'transfer failed' ||
                                    data1 === 'Unexpected Response: 503' ||
                                    data1 ===
                                        'Attempted to acquire license while license already acquired. This is usually due to an internal error.' ||
                                    data1 ===
                                        'Failed to transfer into a multi-party conference' ||
                                    data1 ===
                                        'Failed to transfer into a one-to-one conference'
                                )
                            ) {
                                const err4 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/take_floor/properties/403/definitions/403/properties/result/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema15.properties.result.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                };
                                if (vErrors === null) {
                                    vErrors = [err4];
                                } else {
                                    vErrors.push(err4);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err5 = {
                instancePath,
                schemaPath:
                    '#/definitions/take_floor/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err5];
            } else {
                vErrors.push(err5);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err6 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err6];
        } else {
            vErrors.push(err6);
        }
        errors++;
        validate69.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate69.errors = vErrors;
    return errors === 0;
}
export const validateTransferParticipantBody = validate70;
const schema112 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        TopLevel: {
            type: 'object',
            additionalProperties: false,
            properties: {
                role: {
                    type: 'string',
                    enum: ['host', 'guest'],
                    description:
                        '`host` = Host participant; `guest` = Guest participant',
                },
                conference_alias: {
                    type: 'string',
                    description: 'Target conference alias.',
                },
                pin: {
                    type: 'string',
                    description:
                        'PIN code for the specified role at the specified conference, if required.',
                },
            },
            required: ['role', 'conference_alias'],
            title: 'TopLevel',
        },
    },
    oneOf: [
        {
            $ref: '#/definitions/transfer_participant/properties/Body/definitions/TopLevel',
        },
    ],
};
const schema113 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        role: {
            type: 'string',
            enum: ['host', 'guest'],
            description:
                '`host` = Host participant; `guest` = Guest participant',
        },
        conference_alias: {
            type: 'string',
            description: 'Target conference alias.',
        },
        pin: {
            type: 'string',
            description:
                'PIN code for the specified role at the specified conference, if required.',
        },
    },
    required: ['role', 'conference_alias'],
    title: 'TopLevel',
};
function validate70(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.role === undefined && (missing0 = 'role')) ||
                (data.conference_alias === undefined &&
                    (missing0 = 'conference_alias'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/transfer_participant/properties/Body/definitions/TopLevel/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (
                        !(
                            key0 === 'role' ||
                            key0 === 'conference_alias' ||
                            key0 === 'pin'
                        )
                    ) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.role !== undefined) {
                        let data0 = data.role;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/role',
                                schemaPath:
                                    '#/definitions/transfer_participant/properties/Body/definitions/TopLevel/properties/role/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'host' || data0 === 'guest')) {
                            const err2 = {
                                instancePath: instancePath + '/role',
                                schemaPath:
                                    '#/definitions/transfer_participant/properties/Body/definitions/TopLevel/properties/role/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema113.properties.role.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.conference_alias !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.conference_alias !== 'string') {
                                const err3 = {
                                    instancePath:
                                        instancePath + '/conference_alias',
                                    schemaPath:
                                        '#/definitions/transfer_participant/properties/Body/definitions/TopLevel/properties/conference_alias/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                        if (valid2) {
                            if (data.pin !== undefined) {
                                const _errs9 = errors;
                                if (typeof data.pin !== 'string') {
                                    const err4 = {
                                        instancePath: instancePath + '/pin',
                                        schemaPath:
                                            '#/definitions/transfer_participant/properties/Body/definitions/TopLevel/properties/pin/type',
                                        keyword: 'type',
                                        params: {type: 'string'},
                                        message: 'must be string',
                                    };
                                    if (vErrors === null) {
                                        vErrors = [err4];
                                    } else {
                                        vErrors.push(err4);
                                    }
                                    errors++;
                                }
                                var valid2 = _errs9 === errors;
                            } else {
                                var valid2 = true;
                            }
                        }
                    }
                }
            }
        } else {
            const err5 = {
                instancePath,
                schemaPath:
                    '#/definitions/transfer_participant/properties/Body/definitions/TopLevel/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err5];
            } else {
                vErrors.push(err5);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err6 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err6];
        } else {
            vErrors.push(err6);
        }
        errors++;
        validate70.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate70.errors = vErrors;
    return errors === 0;
}
export const validateTransferParticipant200 = validate71;
function validate71(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/mute_participant/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/mute_participant/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/mute_participant/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema17.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/mute_participant/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/mute_participant/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate71.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate71.errors = vErrors;
    return errors === 0;
}
export const validateTransferParticipant403 = validate72;
function validate72(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/take_floor/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/take_floor/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/take_floor/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema15.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs7 = errors;
                            if (typeof data1 !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/take_floor/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            if (
                                !(
                                    data1 === '404 Not Found' ||
                                    data1 === '502 Bad Gateway' ||
                                    data1 === '503 Service Unavailable' ||
                                    data1 ===
                                        'All conference hosts departed hosted conference' ||
                                    data1 ===
                                        'API initiated conference termination' ||
                                    data1 ===
                                        'API initiated participant disconnect' ||
                                    data1 === 'Call disconnected' ||
                                    data1 === 'Call rejected' ||
                                    data1 ===
                                        'Conference extension not found' ||
                                    data1 ===
                                        'Conference host ended the conference with a DTMF command' ||
                                    data1 ===
                                        'Conference terminated by an administrator' ||
                                    data1 ===
                                        'Conference terminated by another participant' ||
                                    data1 === 'Disabled' ||
                                    data1 ===
                                        'Disconnected by an administrator' ||
                                    data1 ===
                                        'Disconnected by another participant' ||
                                    data1 === 'Failed to forward request' ||
                                    data1 === 'Gateway dial out failed' ||
                                    data1 === 'Ice failure' ||
                                    data1 === 'Insufficient licenses' ||
                                    data1 ===
                                        'invalid gateway routing rule transform' ||
                                    data1 === 'Invalid license' ||
                                    data1 === 'Invalid PIN' ||
                                    data1 === 'Invalid protocol for service' ||
                                    data1 === 'Invalid role' ||
                                    data1 === 'Invalid token' ||
                                    data1 ===
                                        'Last remaining participant removed from conference after timeout' ||
                                    data1 === 'Media node disconnected' ||
                                    data1 === 'Media process disconnected' ||
                                    data1 ===
                                        'Neither conference nor gateway found' ||
                                    data1 ===
                                        'No direct route between Edge and Transcoding' ||
                                    data1 ===
                                        'No participants can keep conference alive' ||
                                    data1 === 'Out of proxying resource' ||
                                    data1 === 'Out of resource' ||
                                    data1 === 'Out of transcoding resource' ||
                                    data1 ===
                                        'Participant exceeded PIN entry retries' ||
                                    data1 === 'Participant limit reached' ||
                                    data1 ===
                                        'Proxied participant disconnected' ||
                                    data1 === 'Resource unavailable' ||
                                    data1 === 'Signaling node disconnected' ||
                                    data1 ===
                                        'SSO Authentication Failed. SSO is not available from this domain' ||
                                    data1 ===
                                        'SSO Authentication Failed. The system is in Maintenance mode' ||
                                    data1 === 'SSO Authentication Failed' ||
                                    data1 ===
                                        'SSO enabled but no Identity Providers configured' ||
                                    data1 === 'System in maintenance mode' ||
                                    data1 === 'Telehealth Call failure' ||
                                    data1 === 'Test call finished' ||
                                    data1 ===
                                        'Timeout waiting for conference host to join or permit access to locked conference' ||
                                    data1 ===
                                        'Timer expired awaiting token refresh' ||
                                    data1 === 'transfer failed' ||
                                    data1 === 'Unexpected Response: 503' ||
                                    data1 ===
                                        'Attempted to acquire license while license already acquired. This is usually due to an internal error.' ||
                                    data1 ===
                                        'Failed to transfer into a multi-party conference' ||
                                    data1 ===
                                        'Failed to transfer into a one-to-one conference'
                                )
                            ) {
                                const err4 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/take_floor/properties/403/definitions/403/properties/result/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema15.properties.result.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                };
                                if (vErrors === null) {
                                    vErrors = [err4];
                                } else {
                                    vErrors.push(err4);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err5 = {
                instancePath,
                schemaPath:
                    '#/definitions/take_floor/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err5];
            } else {
                vErrors.push(err5);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err6 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err6];
        } else {
            vErrors.push(err6);
        }
        errors++;
        validate72.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate72.errors = vErrors;
    return errors === 0;
}
export const validateMessageParticipantBody = validate73;
const schema118 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        TopLevel: {
            type: 'object',
            additionalProperties: false,
            properties: {
                type: {
                    type: 'string',
                    enum: ['text/plain', 'application/json'],
                    description: 'The MIME Content-Type.',
                },
                payload: {type: 'string', description: 'Message to send.'},
            },
            required: ['type', 'payload'],
            title: 'TopLevel',
        },
    },
    oneOf: [
        {
            $ref: '#/definitions/message_participant/properties/Body/definitions/TopLevel',
        },
    ],
};
const schema119 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        type: {
            type: 'string',
            enum: ['text/plain', 'application/json'],
            description: 'The MIME Content-Type.',
        },
        payload: {type: 'string', description: 'Message to send.'},
    },
    required: ['type', 'payload'],
    title: 'TopLevel',
};
function validate73(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.type === undefined && (missing0 = 'type')) ||
                (data.payload === undefined && (missing0 = 'payload'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/message_participant/properties/Body/definitions/TopLevel/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'type' || key0 === 'payload')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.type !== undefined) {
                        let data0 = data.type;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/type',
                                schemaPath:
                                    '#/definitions/message_participant/properties/Body/definitions/TopLevel/properties/type/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (
                            !(
                                data0 === 'text/plain' ||
                                data0 === 'application/json'
                            )
                        ) {
                            const err2 = {
                                instancePath: instancePath + '/type',
                                schemaPath:
                                    '#/definitions/message_participant/properties/Body/definitions/TopLevel/properties/type/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema119.properties.type.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.payload !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.payload !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/payload',
                                    schemaPath:
                                        '#/definitions/message_participant/properties/Body/definitions/TopLevel/properties/payload/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/message_participant/properties/Body/definitions/TopLevel/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate73.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate73.errors = vErrors;
    return errors === 0;
}
export const validateMessageParticipant200 = validate74;
function validate74(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/mute_participant/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/mute_participant/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/mute_participant/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema17.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/mute_participant/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/mute_participant/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate74.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate74.errors = vErrors;
    return errors === 0;
}
export const validateMessageParticipant403 = validate75;
function validate75(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/take_floor/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/take_floor/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/take_floor/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema15.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs7 = errors;
                            if (typeof data1 !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/take_floor/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            if (
                                !(
                                    data1 === '404 Not Found' ||
                                    data1 === '502 Bad Gateway' ||
                                    data1 === '503 Service Unavailable' ||
                                    data1 ===
                                        'All conference hosts departed hosted conference' ||
                                    data1 ===
                                        'API initiated conference termination' ||
                                    data1 ===
                                        'API initiated participant disconnect' ||
                                    data1 === 'Call disconnected' ||
                                    data1 === 'Call rejected' ||
                                    data1 ===
                                        'Conference extension not found' ||
                                    data1 ===
                                        'Conference host ended the conference with a DTMF command' ||
                                    data1 ===
                                        'Conference terminated by an administrator' ||
                                    data1 ===
                                        'Conference terminated by another participant' ||
                                    data1 === 'Disabled' ||
                                    data1 ===
                                        'Disconnected by an administrator' ||
                                    data1 ===
                                        'Disconnected by another participant' ||
                                    data1 === 'Failed to forward request' ||
                                    data1 === 'Gateway dial out failed' ||
                                    data1 === 'Ice failure' ||
                                    data1 === 'Insufficient licenses' ||
                                    data1 ===
                                        'invalid gateway routing rule transform' ||
                                    data1 === 'Invalid license' ||
                                    data1 === 'Invalid PIN' ||
                                    data1 === 'Invalid protocol for service' ||
                                    data1 === 'Invalid role' ||
                                    data1 === 'Invalid token' ||
                                    data1 ===
                                        'Last remaining participant removed from conference after timeout' ||
                                    data1 === 'Media node disconnected' ||
                                    data1 === 'Media process disconnected' ||
                                    data1 ===
                                        'Neither conference nor gateway found' ||
                                    data1 ===
                                        'No direct route between Edge and Transcoding' ||
                                    data1 ===
                                        'No participants can keep conference alive' ||
                                    data1 === 'Out of proxying resource' ||
                                    data1 === 'Out of resource' ||
                                    data1 === 'Out of transcoding resource' ||
                                    data1 ===
                                        'Participant exceeded PIN entry retries' ||
                                    data1 === 'Participant limit reached' ||
                                    data1 ===
                                        'Proxied participant disconnected' ||
                                    data1 === 'Resource unavailable' ||
                                    data1 === 'Signaling node disconnected' ||
                                    data1 ===
                                        'SSO Authentication Failed. SSO is not available from this domain' ||
                                    data1 ===
                                        'SSO Authentication Failed. The system is in Maintenance mode' ||
                                    data1 === 'SSO Authentication Failed' ||
                                    data1 ===
                                        'SSO enabled but no Identity Providers configured' ||
                                    data1 === 'System in maintenance mode' ||
                                    data1 === 'Telehealth Call failure' ||
                                    data1 === 'Test call finished' ||
                                    data1 ===
                                        'Timeout waiting for conference host to join or permit access to locked conference' ||
                                    data1 ===
                                        'Timer expired awaiting token refresh' ||
                                    data1 === 'transfer failed' ||
                                    data1 === 'Unexpected Response: 503' ||
                                    data1 ===
                                        'Attempted to acquire license while license already acquired. This is usually due to an internal error.' ||
                                    data1 ===
                                        'Failed to transfer into a multi-party conference' ||
                                    data1 ===
                                        'Failed to transfer into a one-to-one conference'
                                )
                            ) {
                                const err4 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/take_floor/properties/403/definitions/403/properties/result/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema15.properties.result.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                };
                                if (vErrors === null) {
                                    vErrors = [err4];
                                } else {
                                    vErrors.push(err4);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err5 = {
                instancePath,
                schemaPath:
                    '#/definitions/take_floor/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err5];
            } else {
                vErrors.push(err5);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err6 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err6];
        } else {
            vErrors.push(err6);
        }
        errors++;
        validate75.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate75.errors = vErrors;
    return errors === 0;
}
export const validateAvatarJpgParticipant403 = validate76;
function validate76(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/take_floor/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/take_floor/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/take_floor/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema15.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs7 = errors;
                            if (typeof data1 !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/take_floor/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            if (
                                !(
                                    data1 === '404 Not Found' ||
                                    data1 === '502 Bad Gateway' ||
                                    data1 === '503 Service Unavailable' ||
                                    data1 ===
                                        'All conference hosts departed hosted conference' ||
                                    data1 ===
                                        'API initiated conference termination' ||
                                    data1 ===
                                        'API initiated participant disconnect' ||
                                    data1 === 'Call disconnected' ||
                                    data1 === 'Call rejected' ||
                                    data1 ===
                                        'Conference extension not found' ||
                                    data1 ===
                                        'Conference host ended the conference with a DTMF command' ||
                                    data1 ===
                                        'Conference terminated by an administrator' ||
                                    data1 ===
                                        'Conference terminated by another participant' ||
                                    data1 === 'Disabled' ||
                                    data1 ===
                                        'Disconnected by an administrator' ||
                                    data1 ===
                                        'Disconnected by another participant' ||
                                    data1 === 'Failed to forward request' ||
                                    data1 === 'Gateway dial out failed' ||
                                    data1 === 'Ice failure' ||
                                    data1 === 'Insufficient licenses' ||
                                    data1 ===
                                        'invalid gateway routing rule transform' ||
                                    data1 === 'Invalid license' ||
                                    data1 === 'Invalid PIN' ||
                                    data1 === 'Invalid protocol for service' ||
                                    data1 === 'Invalid role' ||
                                    data1 === 'Invalid token' ||
                                    data1 ===
                                        'Last remaining participant removed from conference after timeout' ||
                                    data1 === 'Media node disconnected' ||
                                    data1 === 'Media process disconnected' ||
                                    data1 ===
                                        'Neither conference nor gateway found' ||
                                    data1 ===
                                        'No direct route between Edge and Transcoding' ||
                                    data1 ===
                                        'No participants can keep conference alive' ||
                                    data1 === 'Out of proxying resource' ||
                                    data1 === 'Out of resource' ||
                                    data1 === 'Out of transcoding resource' ||
                                    data1 ===
                                        'Participant exceeded PIN entry retries' ||
                                    data1 === 'Participant limit reached' ||
                                    data1 ===
                                        'Proxied participant disconnected' ||
                                    data1 === 'Resource unavailable' ||
                                    data1 === 'Signaling node disconnected' ||
                                    data1 ===
                                        'SSO Authentication Failed. SSO is not available from this domain' ||
                                    data1 ===
                                        'SSO Authentication Failed. The system is in Maintenance mode' ||
                                    data1 === 'SSO Authentication Failed' ||
                                    data1 ===
                                        'SSO enabled but no Identity Providers configured' ||
                                    data1 === 'System in maintenance mode' ||
                                    data1 === 'Telehealth Call failure' ||
                                    data1 === 'Test call finished' ||
                                    data1 ===
                                        'Timeout waiting for conference host to join or permit access to locked conference' ||
                                    data1 ===
                                        'Timer expired awaiting token refresh' ||
                                    data1 === 'transfer failed' ||
                                    data1 === 'Unexpected Response: 503' ||
                                    data1 ===
                                        'Attempted to acquire license while license already acquired. This is usually due to an internal error.' ||
                                    data1 ===
                                        'Failed to transfer into a multi-party conference' ||
                                    data1 ===
                                        'Failed to transfer into a one-to-one conference'
                                )
                            ) {
                                const err4 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/take_floor/properties/403/definitions/403/properties/result/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema15.properties.result.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                };
                                if (vErrors === null) {
                                    vErrors = [err4];
                                } else {
                                    vErrors.push(err4);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err5 = {
                instancePath,
                schemaPath:
                    '#/definitions/take_floor/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err5];
            } else {
                vErrors.push(err5);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err6 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err6];
        } else {
            vErrors.push(err6);
        }
        errors++;
        validate76.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate76.errors = vErrors;
    return errors === 0;
}
export const validateAvatarJpgParticipant404 = validate77;
const schema126 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        '404': {
            type: 'object',
            additionalProperties: false,
            properties: {
                status: {
                    type: 'string',
                    description: 'Status of the response, eg. success.',
                    enum: ['failed'],
                },
                result: {
                    type: 'string',
                    enum: ['No image available'],
                    description: 'We cant find the url of the user profile',
                },
            },
            required: ['status', 'result'],
            title: 'Status404',
        },
    },
    oneOf: [
        {
            $ref: '#/definitions/avatar.jpg_participant/properties/404/definitions/404',
        },
    ],
};
const schema127 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        status: {
            type: 'string',
            description: 'Status of the response, eg. success.',
            enum: ['failed'],
        },
        result: {
            type: 'string',
            enum: ['No image available'],
            description: 'We cant find the url of the user profile',
        },
    },
    required: ['status', 'result'],
    title: 'Status404',
};
function validate77(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/avatar.jpg_participant/properties/404/definitions/404/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/avatar.jpg_participant/properties/404/definitions/404/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/avatar.jpg_participant/properties/404/definitions/404/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema127.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs7 = errors;
                            if (typeof data1 !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/avatar.jpg_participant/properties/404/definitions/404/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            if (!(data1 === 'No image available')) {
                                const err4 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/avatar.jpg_participant/properties/404/definitions/404/properties/result/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema127.properties.result.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                };
                                if (vErrors === null) {
                                    vErrors = [err4];
                                } else {
                                    vErrors.push(err4);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err5 = {
                instancePath,
                schemaPath:
                    '#/definitions/avatar.jpg_participant/properties/404/definitions/404/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err5];
            } else {
                vErrors.push(err5);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err6 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err6];
        } else {
            vErrors.push(err6);
        }
        errors++;
        validate77.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate77.errors = vErrors;
    return errors === 0;
}
export const validateTakeFloor200 = validate78;
const schema128 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        '200': {
            type: 'object',
            additionalProperties: false,
            properties: {
                status: {
                    type: 'string',
                    description: 'Status of the response, eg. success.',
                    enum: ['success'],
                },
                result: {type: 'null'},
            },
            required: ['status', 'result'],
            title: 'Status200',
        },
    },
    oneOf: [{$ref: '#/definitions/take_floor/properties/200/definitions/200'}],
};
const schema129 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        status: {
            type: 'string',
            description: 'Status of the response, eg. success.',
            enum: ['success'],
        },
        result: {type: 'null'},
    },
    required: ['status', 'result'],
    title: 'Status200',
};
function validate78(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/take_floor/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/take_floor/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/take_floor/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema129.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (data.result !== null) {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/take_floor/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'null'},
                                    message: 'must be null',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/take_floor/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate78.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate78.errors = vErrors;
    return errors === 0;
}
export const validateTakeFloor403 = validate79;
function validate79(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/take_floor/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/take_floor/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/take_floor/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema15.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs7 = errors;
                            if (typeof data1 !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/take_floor/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            if (
                                !(
                                    data1 === '404 Not Found' ||
                                    data1 === '502 Bad Gateway' ||
                                    data1 === '503 Service Unavailable' ||
                                    data1 ===
                                        'All conference hosts departed hosted conference' ||
                                    data1 ===
                                        'API initiated conference termination' ||
                                    data1 ===
                                        'API initiated participant disconnect' ||
                                    data1 === 'Call disconnected' ||
                                    data1 === 'Call rejected' ||
                                    data1 ===
                                        'Conference extension not found' ||
                                    data1 ===
                                        'Conference host ended the conference with a DTMF command' ||
                                    data1 ===
                                        'Conference terminated by an administrator' ||
                                    data1 ===
                                        'Conference terminated by another participant' ||
                                    data1 === 'Disabled' ||
                                    data1 ===
                                        'Disconnected by an administrator' ||
                                    data1 ===
                                        'Disconnected by another participant' ||
                                    data1 === 'Failed to forward request' ||
                                    data1 === 'Gateway dial out failed' ||
                                    data1 === 'Ice failure' ||
                                    data1 === 'Insufficient licenses' ||
                                    data1 ===
                                        'invalid gateway routing rule transform' ||
                                    data1 === 'Invalid license' ||
                                    data1 === 'Invalid PIN' ||
                                    data1 === 'Invalid protocol for service' ||
                                    data1 === 'Invalid role' ||
                                    data1 === 'Invalid token' ||
                                    data1 ===
                                        'Last remaining participant removed from conference after timeout' ||
                                    data1 === 'Media node disconnected' ||
                                    data1 === 'Media process disconnected' ||
                                    data1 ===
                                        'Neither conference nor gateway found' ||
                                    data1 ===
                                        'No direct route between Edge and Transcoding' ||
                                    data1 ===
                                        'No participants can keep conference alive' ||
                                    data1 === 'Out of proxying resource' ||
                                    data1 === 'Out of resource' ||
                                    data1 === 'Out of transcoding resource' ||
                                    data1 ===
                                        'Participant exceeded PIN entry retries' ||
                                    data1 === 'Participant limit reached' ||
                                    data1 ===
                                        'Proxied participant disconnected' ||
                                    data1 === 'Resource unavailable' ||
                                    data1 === 'Signaling node disconnected' ||
                                    data1 ===
                                        'SSO Authentication Failed. SSO is not available from this domain' ||
                                    data1 ===
                                        'SSO Authentication Failed. The system is in Maintenance mode' ||
                                    data1 === 'SSO Authentication Failed' ||
                                    data1 ===
                                        'SSO enabled but no Identity Providers configured' ||
                                    data1 === 'System in maintenance mode' ||
                                    data1 === 'Telehealth Call failure' ||
                                    data1 === 'Test call finished' ||
                                    data1 ===
                                        'Timeout waiting for conference host to join or permit access to locked conference' ||
                                    data1 ===
                                        'Timer expired awaiting token refresh' ||
                                    data1 === 'transfer failed' ||
                                    data1 === 'Unexpected Response: 503' ||
                                    data1 ===
                                        'Attempted to acquire license while license already acquired. This is usually due to an internal error.' ||
                                    data1 ===
                                        'Failed to transfer into a multi-party conference' ||
                                    data1 ===
                                        'Failed to transfer into a one-to-one conference'
                                )
                            ) {
                                const err4 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/take_floor/properties/403/definitions/403/properties/result/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema15.properties.result.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                };
                                if (vErrors === null) {
                                    vErrors = [err4];
                                } else {
                                    vErrors.push(err4);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err5 = {
                instancePath,
                schemaPath:
                    '#/definitions/take_floor/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err5];
            } else {
                vErrors.push(err5);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err6 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err6];
        } else {
            vErrors.push(err6);
        }
        errors++;
        validate79.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate79.errors = vErrors;
    return errors === 0;
}
export const validateReleaseFloor200 = validate80;
const schema132 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        '200': {
            type: 'object',
            additionalProperties: false,
            properties: {
                status: {
                    type: 'string',
                    description: 'Status of the response, eg. success.',
                    enum: ['success'],
                },
                result: {type: 'null'},
            },
            required: ['status', 'result'],
            title: 'Status200',
        },
    },
    oneOf: [
        {$ref: '#/definitions/release_floor/properties/200/definitions/200'},
    ],
};
const schema133 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        status: {
            type: 'string',
            description: 'Status of the response, eg. success.',
            enum: ['success'],
        },
        result: {type: 'null'},
    },
    required: ['status', 'result'],
    title: 'Status200',
};
function validate80(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/release_floor/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/release_floor/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/release_floor/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema133.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (data.result !== null) {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/release_floor/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'null'},
                                    message: 'must be null',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/release_floor/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate80.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate80.errors = vErrors;
    return errors === 0;
}
export const validateReleaseFloor403 = validate81;
function validate81(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/take_floor/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/take_floor/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/take_floor/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema15.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs7 = errors;
                            if (typeof data1 !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/take_floor/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            if (
                                !(
                                    data1 === '404 Not Found' ||
                                    data1 === '502 Bad Gateway' ||
                                    data1 === '503 Service Unavailable' ||
                                    data1 ===
                                        'All conference hosts departed hosted conference' ||
                                    data1 ===
                                        'API initiated conference termination' ||
                                    data1 ===
                                        'API initiated participant disconnect' ||
                                    data1 === 'Call disconnected' ||
                                    data1 === 'Call rejected' ||
                                    data1 ===
                                        'Conference extension not found' ||
                                    data1 ===
                                        'Conference host ended the conference with a DTMF command' ||
                                    data1 ===
                                        'Conference terminated by an administrator' ||
                                    data1 ===
                                        'Conference terminated by another participant' ||
                                    data1 === 'Disabled' ||
                                    data1 ===
                                        'Disconnected by an administrator' ||
                                    data1 ===
                                        'Disconnected by another participant' ||
                                    data1 === 'Failed to forward request' ||
                                    data1 === 'Gateway dial out failed' ||
                                    data1 === 'Ice failure' ||
                                    data1 === 'Insufficient licenses' ||
                                    data1 ===
                                        'invalid gateway routing rule transform' ||
                                    data1 === 'Invalid license' ||
                                    data1 === 'Invalid PIN' ||
                                    data1 === 'Invalid protocol for service' ||
                                    data1 === 'Invalid role' ||
                                    data1 === 'Invalid token' ||
                                    data1 ===
                                        'Last remaining participant removed from conference after timeout' ||
                                    data1 === 'Media node disconnected' ||
                                    data1 === 'Media process disconnected' ||
                                    data1 ===
                                        'Neither conference nor gateway found' ||
                                    data1 ===
                                        'No direct route between Edge and Transcoding' ||
                                    data1 ===
                                        'No participants can keep conference alive' ||
                                    data1 === 'Out of proxying resource' ||
                                    data1 === 'Out of resource' ||
                                    data1 === 'Out of transcoding resource' ||
                                    data1 ===
                                        'Participant exceeded PIN entry retries' ||
                                    data1 === 'Participant limit reached' ||
                                    data1 ===
                                        'Proxied participant disconnected' ||
                                    data1 === 'Resource unavailable' ||
                                    data1 === 'Signaling node disconnected' ||
                                    data1 ===
                                        'SSO Authentication Failed. SSO is not available from this domain' ||
                                    data1 ===
                                        'SSO Authentication Failed. The system is in Maintenance mode' ||
                                    data1 === 'SSO Authentication Failed' ||
                                    data1 ===
                                        'SSO enabled but no Identity Providers configured' ||
                                    data1 === 'System in maintenance mode' ||
                                    data1 === 'Telehealth Call failure' ||
                                    data1 === 'Test call finished' ||
                                    data1 ===
                                        'Timeout waiting for conference host to join or permit access to locked conference' ||
                                    data1 ===
                                        'Timer expired awaiting token refresh' ||
                                    data1 === 'transfer failed' ||
                                    data1 === 'Unexpected Response: 503' ||
                                    data1 ===
                                        'Attempted to acquire license while license already acquired. This is usually due to an internal error.' ||
                                    data1 ===
                                        'Failed to transfer into a multi-party conference' ||
                                    data1 ===
                                        'Failed to transfer into a one-to-one conference'
                                )
                            ) {
                                const err4 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/take_floor/properties/403/definitions/403/properties/result/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema15.properties.result.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                };
                                if (vErrors === null) {
                                    vErrors = [err4];
                                } else {
                                    vErrors.push(err4);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err5 = {
                instancePath,
                schemaPath:
                    '#/definitions/take_floor/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err5];
            } else {
                vErrors.push(err5);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err6 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err6];
        } else {
            vErrors.push(err6);
        }
        errors++;
        validate81.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate81.errors = vErrors;
    return errors === 0;
}
export const validateShowLiveCaptions200 = validate82;
const schema136 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        '200': {
            type: 'object',
            additionalProperties: false,
            properties: {
                status: {
                    type: 'string',
                    description: 'Status of the response, eg. success.',
                    enum: ['success'],
                },
                result: {
                    type: 'null',
                    description: 'The results flag is not set.',
                },
            },
            required: ['status', 'result'],
            title: 'Status200',
        },
    },
    oneOf: [
        {
            $ref: '#/definitions/show_live_captions/properties/200/definitions/200',
        },
    ],
};
const schema137 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        status: {
            type: 'string',
            description: 'Status of the response, eg. success.',
            enum: ['success'],
        },
        result: {type: 'null', description: 'The results flag is not set.'},
    },
    required: ['status', 'result'],
    title: 'Status200',
};
function validate82(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/show_live_captions/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/show_live_captions/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/show_live_captions/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema137.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (data.result !== null) {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/show_live_captions/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'null'},
                                    message: 'must be null',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/show_live_captions/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate82.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate82.errors = vErrors;
    return errors === 0;
}
export const validateShowLiveCaptions403 = validate83;
function validate83(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/take_floor/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/take_floor/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/take_floor/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema15.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs7 = errors;
                            if (typeof data1 !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/take_floor/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            if (
                                !(
                                    data1 === '404 Not Found' ||
                                    data1 === '502 Bad Gateway' ||
                                    data1 === '503 Service Unavailable' ||
                                    data1 ===
                                        'All conference hosts departed hosted conference' ||
                                    data1 ===
                                        'API initiated conference termination' ||
                                    data1 ===
                                        'API initiated participant disconnect' ||
                                    data1 === 'Call disconnected' ||
                                    data1 === 'Call rejected' ||
                                    data1 ===
                                        'Conference extension not found' ||
                                    data1 ===
                                        'Conference host ended the conference with a DTMF command' ||
                                    data1 ===
                                        'Conference terminated by an administrator' ||
                                    data1 ===
                                        'Conference terminated by another participant' ||
                                    data1 === 'Disabled' ||
                                    data1 ===
                                        'Disconnected by an administrator' ||
                                    data1 ===
                                        'Disconnected by another participant' ||
                                    data1 === 'Failed to forward request' ||
                                    data1 === 'Gateway dial out failed' ||
                                    data1 === 'Ice failure' ||
                                    data1 === 'Insufficient licenses' ||
                                    data1 ===
                                        'invalid gateway routing rule transform' ||
                                    data1 === 'Invalid license' ||
                                    data1 === 'Invalid PIN' ||
                                    data1 === 'Invalid protocol for service' ||
                                    data1 === 'Invalid role' ||
                                    data1 === 'Invalid token' ||
                                    data1 ===
                                        'Last remaining participant removed from conference after timeout' ||
                                    data1 === 'Media node disconnected' ||
                                    data1 === 'Media process disconnected' ||
                                    data1 ===
                                        'Neither conference nor gateway found' ||
                                    data1 ===
                                        'No direct route between Edge and Transcoding' ||
                                    data1 ===
                                        'No participants can keep conference alive' ||
                                    data1 === 'Out of proxying resource' ||
                                    data1 === 'Out of resource' ||
                                    data1 === 'Out of transcoding resource' ||
                                    data1 ===
                                        'Participant exceeded PIN entry retries' ||
                                    data1 === 'Participant limit reached' ||
                                    data1 ===
                                        'Proxied participant disconnected' ||
                                    data1 === 'Resource unavailable' ||
                                    data1 === 'Signaling node disconnected' ||
                                    data1 ===
                                        'SSO Authentication Failed. SSO is not available from this domain' ||
                                    data1 ===
                                        'SSO Authentication Failed. The system is in Maintenance mode' ||
                                    data1 === 'SSO Authentication Failed' ||
                                    data1 ===
                                        'SSO enabled but no Identity Providers configured' ||
                                    data1 === 'System in maintenance mode' ||
                                    data1 === 'Telehealth Call failure' ||
                                    data1 === 'Test call finished' ||
                                    data1 ===
                                        'Timeout waiting for conference host to join or permit access to locked conference' ||
                                    data1 ===
                                        'Timer expired awaiting token refresh' ||
                                    data1 === 'transfer failed' ||
                                    data1 === 'Unexpected Response: 503' ||
                                    data1 ===
                                        'Attempted to acquire license while license already acquired. This is usually due to an internal error.' ||
                                    data1 ===
                                        'Failed to transfer into a multi-party conference' ||
                                    data1 ===
                                        'Failed to transfer into a one-to-one conference'
                                )
                            ) {
                                const err4 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/take_floor/properties/403/definitions/403/properties/result/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema15.properties.result.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                };
                                if (vErrors === null) {
                                    vErrors = [err4];
                                } else {
                                    vErrors.push(err4);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err5 = {
                instancePath,
                schemaPath:
                    '#/definitions/take_floor/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err5];
            } else {
                vErrors.push(err5);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err6 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err6];
        } else {
            vErrors.push(err6);
        }
        errors++;
        validate83.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate83.errors = vErrors;
    return errors === 0;
}
export const validateHideLiveCaptions200 = validate84;
function validate84(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/show_live_captions/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/show_live_captions/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/show_live_captions/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema137.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (data.result !== null) {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/show_live_captions/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'null'},
                                    message: 'must be null',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/show_live_captions/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate84.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate84.errors = vErrors;
    return errors === 0;
}
export const validateHideLiveCaptions403 = validate85;
function validate85(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/take_floor/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/take_floor/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/take_floor/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema15.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs7 = errors;
                            if (typeof data1 !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/take_floor/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            if (
                                !(
                                    data1 === '404 Not Found' ||
                                    data1 === '502 Bad Gateway' ||
                                    data1 === '503 Service Unavailable' ||
                                    data1 ===
                                        'All conference hosts departed hosted conference' ||
                                    data1 ===
                                        'API initiated conference termination' ||
                                    data1 ===
                                        'API initiated participant disconnect' ||
                                    data1 === 'Call disconnected' ||
                                    data1 === 'Call rejected' ||
                                    data1 ===
                                        'Conference extension not found' ||
                                    data1 ===
                                        'Conference host ended the conference with a DTMF command' ||
                                    data1 ===
                                        'Conference terminated by an administrator' ||
                                    data1 ===
                                        'Conference terminated by another participant' ||
                                    data1 === 'Disabled' ||
                                    data1 ===
                                        'Disconnected by an administrator' ||
                                    data1 ===
                                        'Disconnected by another participant' ||
                                    data1 === 'Failed to forward request' ||
                                    data1 === 'Gateway dial out failed' ||
                                    data1 === 'Ice failure' ||
                                    data1 === 'Insufficient licenses' ||
                                    data1 ===
                                        'invalid gateway routing rule transform' ||
                                    data1 === 'Invalid license' ||
                                    data1 === 'Invalid PIN' ||
                                    data1 === 'Invalid protocol for service' ||
                                    data1 === 'Invalid role' ||
                                    data1 === 'Invalid token' ||
                                    data1 ===
                                        'Last remaining participant removed from conference after timeout' ||
                                    data1 === 'Media node disconnected' ||
                                    data1 === 'Media process disconnected' ||
                                    data1 ===
                                        'Neither conference nor gateway found' ||
                                    data1 ===
                                        'No direct route between Edge and Transcoding' ||
                                    data1 ===
                                        'No participants can keep conference alive' ||
                                    data1 === 'Out of proxying resource' ||
                                    data1 === 'Out of resource' ||
                                    data1 === 'Out of transcoding resource' ||
                                    data1 ===
                                        'Participant exceeded PIN entry retries' ||
                                    data1 === 'Participant limit reached' ||
                                    data1 ===
                                        'Proxied participant disconnected' ||
                                    data1 === 'Resource unavailable' ||
                                    data1 === 'Signaling node disconnected' ||
                                    data1 ===
                                        'SSO Authentication Failed. SSO is not available from this domain' ||
                                    data1 ===
                                        'SSO Authentication Failed. The system is in Maintenance mode' ||
                                    data1 === 'SSO Authentication Failed' ||
                                    data1 ===
                                        'SSO enabled but no Identity Providers configured' ||
                                    data1 === 'System in maintenance mode' ||
                                    data1 === 'Telehealth Call failure' ||
                                    data1 === 'Test call finished' ||
                                    data1 ===
                                        'Timeout waiting for conference host to join or permit access to locked conference' ||
                                    data1 ===
                                        'Timer expired awaiting token refresh' ||
                                    data1 === 'transfer failed' ||
                                    data1 === 'Unexpected Response: 503' ||
                                    data1 ===
                                        'Attempted to acquire license while license already acquired. This is usually due to an internal error.' ||
                                    data1 ===
                                        'Failed to transfer into a multi-party conference' ||
                                    data1 ===
                                        'Failed to transfer into a one-to-one conference'
                                )
                            ) {
                                const err4 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/take_floor/properties/403/definitions/403/properties/result/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema15.properties.result.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                };
                                if (vErrors === null) {
                                    vErrors = [err4];
                                } else {
                                    vErrors.push(err4);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err5 = {
                instancePath,
                schemaPath:
                    '#/definitions/take_floor/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err5];
            } else {
                vErrors.push(err5);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err6 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err6];
        } else {
            vErrors.push(err6);
        }
        errors++;
        validate85.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate85.errors = vErrors;
    return errors === 0;
}
export const validatePreferredAspectRatioBody = validate86;
const schema144 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        TopLevel: {
            type: 'object',
            additionalProperties: false,
            properties: {
                aspect_ratio: {
                    type: 'number',
                    description: '`Layout either 16/9 or 9/16',
                },
            },
            required: [],
            title: 'TopLevel',
        },
    },
    oneOf: [
        {
            $ref: '#/definitions/preferred_aspect_ratio/properties/Body/definitions/TopLevel',
        },
    ],
};
const schema145 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        aspect_ratio: {
            type: 'number',
            description: '`Layout either 16/9 or 9/16',
        },
    },
    required: [],
    title: 'TopLevel',
};
function validate86(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            const _errs4 = errors;
            for (const key0 in data) {
                if (!(key0 === 'aspect_ratio')) {
                    delete data[key0];
                }
            }
            if (_errs4 === errors) {
                if (data.aspect_ratio !== undefined) {
                    if (!(typeof data.aspect_ratio == 'number')) {
                        const err0 = {
                            instancePath: instancePath + '/aspect_ratio',
                            schemaPath:
                                '#/definitions/preferred_aspect_ratio/properties/Body/definitions/TopLevel/properties/aspect_ratio/type',
                            keyword: 'type',
                            params: {type: 'number'},
                            message: 'must be number',
                        };
                        if (vErrors === null) {
                            vErrors = [err0];
                        } else {
                            vErrors.push(err0);
                        }
                        errors++;
                    }
                }
            }
        } else {
            const err1 = {
                instancePath,
                schemaPath:
                    '#/definitions/preferred_aspect_ratio/properties/Body/definitions/TopLevel/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err1];
            } else {
                vErrors.push(err1);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err2 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err2];
        } else {
            vErrors.push(err2);
        }
        errors++;
        validate86.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate86.errors = vErrors;
    return errors === 0;
}
export const validatePreferredAspectRatio200 = validate87;
function validate87(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/mute_participant/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/mute_participant/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/mute_participant/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema17.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/mute_participant/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/mute_participant/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate87.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate87.errors = vErrors;
    return errors === 0;
}
export const validatePreferredAspectRatio403 = validate88;
function validate88(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/take_floor/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/take_floor/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/take_floor/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema15.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs7 = errors;
                            if (typeof data1 !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/take_floor/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            if (
                                !(
                                    data1 === '404 Not Found' ||
                                    data1 === '502 Bad Gateway' ||
                                    data1 === '503 Service Unavailable' ||
                                    data1 ===
                                        'All conference hosts departed hosted conference' ||
                                    data1 ===
                                        'API initiated conference termination' ||
                                    data1 ===
                                        'API initiated participant disconnect' ||
                                    data1 === 'Call disconnected' ||
                                    data1 === 'Call rejected' ||
                                    data1 ===
                                        'Conference extension not found' ||
                                    data1 ===
                                        'Conference host ended the conference with a DTMF command' ||
                                    data1 ===
                                        'Conference terminated by an administrator' ||
                                    data1 ===
                                        'Conference terminated by another participant' ||
                                    data1 === 'Disabled' ||
                                    data1 ===
                                        'Disconnected by an administrator' ||
                                    data1 ===
                                        'Disconnected by another participant' ||
                                    data1 === 'Failed to forward request' ||
                                    data1 === 'Gateway dial out failed' ||
                                    data1 === 'Ice failure' ||
                                    data1 === 'Insufficient licenses' ||
                                    data1 ===
                                        'invalid gateway routing rule transform' ||
                                    data1 === 'Invalid license' ||
                                    data1 === 'Invalid PIN' ||
                                    data1 === 'Invalid protocol for service' ||
                                    data1 === 'Invalid role' ||
                                    data1 === 'Invalid token' ||
                                    data1 ===
                                        'Last remaining participant removed from conference after timeout' ||
                                    data1 === 'Media node disconnected' ||
                                    data1 === 'Media process disconnected' ||
                                    data1 ===
                                        'Neither conference nor gateway found' ||
                                    data1 ===
                                        'No direct route between Edge and Transcoding' ||
                                    data1 ===
                                        'No participants can keep conference alive' ||
                                    data1 === 'Out of proxying resource' ||
                                    data1 === 'Out of resource' ||
                                    data1 === 'Out of transcoding resource' ||
                                    data1 ===
                                        'Participant exceeded PIN entry retries' ||
                                    data1 === 'Participant limit reached' ||
                                    data1 ===
                                        'Proxied participant disconnected' ||
                                    data1 === 'Resource unavailable' ||
                                    data1 === 'Signaling node disconnected' ||
                                    data1 ===
                                        'SSO Authentication Failed. SSO is not available from this domain' ||
                                    data1 ===
                                        'SSO Authentication Failed. The system is in Maintenance mode' ||
                                    data1 === 'SSO Authentication Failed' ||
                                    data1 ===
                                        'SSO enabled but no Identity Providers configured' ||
                                    data1 === 'System in maintenance mode' ||
                                    data1 === 'Telehealth Call failure' ||
                                    data1 === 'Test call finished' ||
                                    data1 ===
                                        'Timeout waiting for conference host to join or permit access to locked conference' ||
                                    data1 ===
                                        'Timer expired awaiting token refresh' ||
                                    data1 === 'transfer failed' ||
                                    data1 === 'Unexpected Response: 503' ||
                                    data1 ===
                                        'Attempted to acquire license while license already acquired. This is usually due to an internal error.' ||
                                    data1 ===
                                        'Failed to transfer into a multi-party conference' ||
                                    data1 ===
                                        'Failed to transfer into a one-to-one conference'
                                )
                            ) {
                                const err4 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/take_floor/properties/403/definitions/403/properties/result/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema15.properties.result.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                };
                                if (vErrors === null) {
                                    vErrors = [err4];
                                } else {
                                    vErrors.push(err4);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err5 = {
                instancePath,
                schemaPath:
                    '#/definitions/take_floor/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err5];
            } else {
                vErrors.push(err5);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err6 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err6];
        } else {
            vErrors.push(err6);
        }
        errors++;
        validate88.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate88.errors = vErrors;
    return errors === 0;
}
