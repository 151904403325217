import type {SSOdMessage} from './types';

export const isUndefined = <T>(t: T | undefined): t is undefined =>
    typeof t === 'undefined';

export const isNull = <T>(t: T | undefined | null): t is null =>
    typeof t === 'object' && !t;

export const isNotUndefined = <T>(t: T | undefined | null): t is T =>
    !isUndefined(t) && !isNull(t);

export const isSSOdMessage = (e: unknown): e is SSOdMessage => {
    if (typeof e !== 'object') {
        return false;
    }
    if (e === null) {
        return false;
    }
    if ('result' in e && ('token' in e || 'code' in e)) {
        return true;
    }
    return false;
};
