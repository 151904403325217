import {ValidationError, NonJSONResponseError} from '../errors';

import type {Endpoints} from './types';
import {
    validateBreakoutsBody,
    validateBreakouts200,
    validateBreakoutsMessageBody,
    validateBreakoutsMessage200,
    validateBreakoutsDisconnect200,
    validateBreakoutsEmpty200,
    validateBreakoutDisconnect200,
    validateBreakoutTransformLayoutBody,
    validateBreakoutTransformLayout200,
    validateBreakoutMuteguests200,
    validateBreakoutUnmuteguests200,
    validateBreakoutMoveParticipantsFromMainBody,
    validateBreakoutMoveParticipantsFromMain200,
    validateBreakoutMoveParticipantsFromMain403,
    validateBreakoutMoveParticipantsBody,
    validateBreakoutMoveParticipants200,
    validateBreakoutMoveParticipants403,
    validateBreakoutCallsWebrtcParticipantBody,
    validateBreakoutCallsWebrtcParticipant200,
    validateBreakoutCallsWebrtcParticipant400,
    validateBreakoutCallsWebrtcParticipant403,
    validateBreakoutCallsWebrtcParticipant500,
    validateBreakoutCallsWebrtcParticipant504,
    validateBreakoutMuteParticipant200,
    validateBreakoutMuteParticipant403,
    validateBreakoutUnmuteParticipant200,
    validateBreakoutUnmuteParticipant403,
    validateBreakoutOverlaytextParticipantBody,
    validateBreakoutOverlaytextParticipant200,
    validateBreakoutOverlaytextParticipant403,
    validateBreakoutTakeFloor200,
    validateBreakoutTakeFloor403,
    validateBreakoutReleaseFloor200,
    validateBreakoutReleaseFloor403,
    validateBreakoutAckBody,
    validateBreakoutAck200,
    validateBreakoutAck403,
    validateBreakoutDisconnectCall200,
    validateBreakoutDisconnectCall403,
    validateBreakoutDisconnectCall502,
    validateBreakoutDisconnectCall503,
    validateBreakoutNewCandidateBody,
    validateBreakoutNewCandidate200,
    validateBreakoutNewCandidate403,
    validateBreakoutNewCandidate502,
    validateBreakoutUpdateBody,
    validateBreakoutUpdate200,
    validateBreakoutUpdate403,
    validateBreakoutUpdate502,
    validateBreakoutUpdate503,
    validateBreakoutUpdate520,
    validateBreakoutShowLiveCaptions200,
    validateBreakoutShowLiveCaptions403,
    validateBreakoutHideLiveCaptions200,
    validateBreakoutHideLiveCaptions403,
    validateBreakoutMessageConferenceBody,
    validateBreakoutMessageConference200,
} from './validation';

type BreakoutsResponse = {status: 200; data: Endpoints['breakouts']['200']};
export async function breakouts({
    fetcher,
    init,
    params,
    body,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['breakouts']['Params'];
    body: Endpoints['breakouts']['Body'];
    host: string;
}): Promise<BreakoutsResponse> {
    if (!validateBreakoutsBody(body)) {
        throw new ValidationError(validateBreakoutsBody.errors);
    }
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(
            params.conferenceAlias,
        )}/breakouts`,
        {
            ...init,
            method: 'post',

            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            },
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateBreakouts200(data)) {
                throw new ValidationError(validateBreakouts200.errors);
            }
            return {
                status: res.status,
                data: data as Endpoints['breakouts']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type BreakoutsMap = Endpoints['breakouts'];

type BreakoutsMessageResponse = {
    status: 200;
    data: Endpoints['breakouts_message']['200'];
};
export async function breakoutsMessage({
    fetcher,
    init,
    params,
    body,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['breakouts_message']['Params'];
    body: Endpoints['breakouts_message']['Body'];
    host: string;
}): Promise<BreakoutsMessageResponse> {
    if (!validateBreakoutsMessageBody(body)) {
        throw new ValidationError(validateBreakoutsMessageBody.errors);
    }
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(
            params.conferenceAlias,
        )}/breakouts/message`,
        {
            ...init,
            method: 'post',

            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            },
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateBreakoutsMessage200(data)) {
                throw new ValidationError(validateBreakoutsMessage200.errors);
            }
            return {
                status: res.status,
                data: data as Endpoints['breakouts_message']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type BreakoutsMessageMap = Endpoints['breakouts_message'];

type BreakoutsDisconnectResponse = {
    status: 200;
    data: Endpoints['breakouts_disconnect']['200'];
};
export async function breakoutsDisconnect({
    fetcher,
    init,
    params,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['breakouts_disconnect']['Params'];
    host: string;
}): Promise<BreakoutsDisconnectResponse> {
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(
            params.conferenceAlias,
        )}/breakouts/disconnect`,
        {
            ...init,
            method: 'post',
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateBreakoutsDisconnect200(data)) {
                throw new ValidationError(
                    validateBreakoutsDisconnect200.errors,
                );
            }
            return {
                status: res.status,
                data: data as Endpoints['breakouts_disconnect']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type BreakoutsDisconnectMap = Endpoints['breakouts_disconnect'];

type BreakoutsEmptyResponse = {
    status: 200;
    data: Endpoints['breakouts_empty']['200'];
};
export async function breakoutsEmpty({
    fetcher,
    init,
    params,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['breakouts_empty']['Params'];
    host: string;
}): Promise<BreakoutsEmptyResponse> {
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(
            params.conferenceAlias,
        )}/breakouts/empty`,
        {
            ...init,
            method: 'post',
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateBreakoutsEmpty200(data)) {
                throw new ValidationError(validateBreakoutsEmpty200.errors);
            }
            return {
                status: res.status,
                data: data as Endpoints['breakouts_empty']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type BreakoutsEmptyMap = Endpoints['breakouts_empty'];

type BreakoutDisconnectResponse = {
    status: 200;
    data: Endpoints['breakout_disconnect']['200'];
};
export async function breakoutDisconnect({
    fetcher,
    init,
    params,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['breakout_disconnect']['Params'];
    host: string;
}): Promise<BreakoutDisconnectResponse> {
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(
            params.conferenceAlias,
        )}/breakouts/${encodeURIComponent(params.breakoutUuid)}/disconnect`,
        {
            ...init,
            method: 'post',
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateBreakoutDisconnect200(data)) {
                throw new ValidationError(validateBreakoutDisconnect200.errors);
            }
            return {
                status: res.status,
                data: data as Endpoints['breakout_disconnect']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type BreakoutDisconnectMap = Endpoints['breakout_disconnect'];

type BreakoutTransformLayoutResponse = {
    status: 200;
    data: Endpoints['breakout_transform_layout']['200'];
};
export async function breakoutTransformLayout({
    fetcher,
    init,
    params,
    body,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['breakout_transform_layout']['Params'];
    body: Endpoints['breakout_transform_layout']['Body'];
    host: string;
}): Promise<BreakoutTransformLayoutResponse> {
    if (!validateBreakoutTransformLayoutBody(body)) {
        throw new ValidationError(validateBreakoutTransformLayoutBody.errors);
    }
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(
            params.conferenceAlias,
        )}/breakouts/${encodeURIComponent(
            params.breakoutUuid,
        )}/transform_layout`,
        {
            ...init,
            method: 'post',

            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            },
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateBreakoutTransformLayout200(data)) {
                throw new ValidationError(
                    validateBreakoutTransformLayout200.errors,
                );
            }
            return {
                status: res.status,
                data: data as Endpoints['breakout_transform_layout']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type BreakoutTransformLayoutMap = Endpoints['breakout_transform_layout'];

type BreakoutMuteguestsResponse = {
    status: 200;
    data: Endpoints['breakout_muteguests']['200'];
};
export async function breakoutMuteguests({
    fetcher,
    init,
    params,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['breakout_muteguests']['Params'];
    host: string;
}): Promise<BreakoutMuteguestsResponse> {
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(
            params.conferenceAlias,
        )}/breakouts/${encodeURIComponent(params.breakoutUuid)}/muteguests`,
        {
            ...init,
            method: 'post',
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateBreakoutMuteguests200(data)) {
                throw new ValidationError(validateBreakoutMuteguests200.errors);
            }
            return {
                status: res.status,
                data: data as Endpoints['breakout_muteguests']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type BreakoutMuteguestsMap = Endpoints['breakout_muteguests'];

type BreakoutUnmuteguestsResponse = {
    status: 200;
    data: Endpoints['breakout_unmuteguests']['200'];
};
export async function breakoutUnmuteguests({
    fetcher,
    init,
    params,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['breakout_unmuteguests']['Params'];
    host: string;
}): Promise<BreakoutUnmuteguestsResponse> {
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(
            params.conferenceAlias,
        )}/breakouts/${encodeURIComponent(params.breakoutUuid)}/unmuteguests`,
        {
            ...init,
            method: 'post',
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateBreakoutUnmuteguests200(data)) {
                throw new ValidationError(
                    validateBreakoutUnmuteguests200.errors,
                );
            }
            return {
                status: res.status,
                data: data as Endpoints['breakout_unmuteguests']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type BreakoutUnmuteguestsMap = Endpoints['breakout_unmuteguests'];

type BreakoutMoveParticipantsFromMainResponse =
    | {
          status: 200;
          data: Endpoints['breakout_move_participants_from_main']['200'];
      }
    | {
          status: 403;
          data: Endpoints['breakout_move_participants_from_main']['403'];
      };
export async function breakoutMoveParticipantsFromMain({
    fetcher,
    init,
    params,
    body,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['breakout_move_participants_from_main']['Params'];
    body: Endpoints['breakout_move_participants_from_main']['Body'];
    host: string;
}): Promise<BreakoutMoveParticipantsFromMainResponse> {
    if (!validateBreakoutMoveParticipantsFromMainBody(body)) {
        throw new ValidationError(
            validateBreakoutMoveParticipantsFromMainBody.errors,
        );
    }
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(
            params.conferenceAlias,
        )}/participants/breakout`,
        {
            ...init,
            method: 'post',

            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            },
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateBreakoutMoveParticipantsFromMain200(data)) {
                throw new ValidationError(
                    validateBreakoutMoveParticipantsFromMain200.errors,
                );
            }
            return {
                status: res.status,
                data: data as Endpoints['breakout_move_participants_from_main']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }

    if (res.status === 403) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateBreakoutMoveParticipantsFromMain403(data)) {
                throw new ValidationError(
                    validateBreakoutMoveParticipantsFromMain403.errors,
                );
            }
            return {
                status: res.status,
                data: data as Endpoints['breakout_move_participants_from_main']['403'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type BreakoutMoveParticipantsFromMainMap =
    Endpoints['breakout_move_participants_from_main'];

type BreakoutMoveParticipantsResponse =
    | {status: 200; data: Endpoints['breakout_move_participants']['200']}
    | {status: 403; data: Endpoints['breakout_move_participants']['403']};
export async function breakoutMoveParticipants({
    fetcher,
    init,
    params,
    body,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['breakout_move_participants']['Params'];
    body: Endpoints['breakout_move_participants']['Body'];
    host: string;
}): Promise<BreakoutMoveParticipantsResponse> {
    if (!validateBreakoutMoveParticipantsBody(body)) {
        throw new ValidationError(validateBreakoutMoveParticipantsBody.errors);
    }
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(
            params.conferenceAlias,
        )}/breakouts/${encodeURIComponent(
            params.breakoutUuid,
        )}/participants/breakout`,
        {
            ...init,
            method: 'post',

            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            },
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateBreakoutMoveParticipants200(data)) {
                throw new ValidationError(
                    validateBreakoutMoveParticipants200.errors,
                );
            }
            return {
                status: res.status,
                data: data as Endpoints['breakout_move_participants']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }

    if (res.status === 403) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateBreakoutMoveParticipants403(data)) {
                throw new ValidationError(
                    validateBreakoutMoveParticipants403.errors,
                );
            }
            return {
                status: res.status,
                data: data as Endpoints['breakout_move_participants']['403'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type BreakoutMoveParticipantsMap =
    Endpoints['breakout_move_participants'];

type BreakoutCallsWebrtcParticipantResponse =
    | {status: 200; data: Endpoints['breakout_calls_webrtc_participant']['200']}
    | {status: 400; data: Endpoints['breakout_calls_webrtc_participant']['400']}
    | {status: 403; data: Endpoints['breakout_calls_webrtc_participant']['403']}
    | {status: 500; data: Endpoints['breakout_calls_webrtc_participant']['500']}
    | {
          status: 504;
          data: Endpoints['breakout_calls_webrtc_participant']['504'];
      };
export async function breakoutCallsWebrtcParticipant({
    fetcher,
    init,
    params,
    body,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['breakout_calls_webrtc_participant']['Params'];
    body: Endpoints['breakout_calls_webrtc_participant']['Body'];
    host: string;
}): Promise<BreakoutCallsWebrtcParticipantResponse> {
    if (!validateBreakoutCallsWebrtcParticipantBody(body)) {
        throw new ValidationError(
            validateBreakoutCallsWebrtcParticipantBody.errors,
        );
    }
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(
            params.conferenceAlias,
        )}/breakouts/${encodeURIComponent(
            params.breakoutUuid,
        )}/participants/${encodeURIComponent(params.participantUuid)}/calls`,
        {
            ...init,
            method: 'post',

            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            },
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateBreakoutCallsWebrtcParticipant200(data)) {
                throw new ValidationError(
                    validateBreakoutCallsWebrtcParticipant200.errors,
                );
            }
            return {
                status: res.status,
                data: data as Endpoints['breakout_calls_webrtc_participant']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }

    if (res.status === 400) {
        const data = await res.text();
        if (!validateBreakoutCallsWebrtcParticipant400(data)) {
            throw new ValidationError(
                validateBreakoutCallsWebrtcParticipant400.errors,
            );
        }
        return {status: res.status, data};
    }

    if (res.status === 403) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateBreakoutCallsWebrtcParticipant403(data)) {
                throw new ValidationError(
                    validateBreakoutCallsWebrtcParticipant403.errors,
                );
            }
            return {
                status: res.status,
                data: data as Endpoints['breakout_calls_webrtc_participant']['403'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }

    if (res.status === 500) {
        const data = await res.text();
        if (!validateBreakoutCallsWebrtcParticipant500(data)) {
            throw new ValidationError(
                validateBreakoutCallsWebrtcParticipant500.errors,
            );
        }
        return {status: res.status, data};
    }

    if (res.status === 504) {
        const data = await res.text();
        if (!validateBreakoutCallsWebrtcParticipant504(data)) {
            throw new ValidationError(
                validateBreakoutCallsWebrtcParticipant504.errors,
            );
        }
        return {status: res.status, data};
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type BreakoutCallsWebrtcParticipantMap =
    Endpoints['breakout_calls_webrtc_participant'];

type BreakoutMuteParticipantResponse =
    | {status: 200; data: Endpoints['breakout_mute_participant']['200']}
    | {status: 403; data: Endpoints['breakout_mute_participant']['403']};
export async function breakoutMuteParticipant({
    fetcher,
    init,
    params,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['breakout_mute_participant']['Params'];
    host: string;
}): Promise<BreakoutMuteParticipantResponse> {
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(
            params.conferenceAlias,
        )}/breakouts/${encodeURIComponent(
            params.breakoutUuid,
        )}/participants/${encodeURIComponent(params.participantUuid)}/mute`,
        {
            ...init,
            method: 'post',
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateBreakoutMuteParticipant200(data)) {
                throw new ValidationError(
                    validateBreakoutMuteParticipant200.errors,
                );
            }
            return {
                status: res.status,
                data: data as Endpoints['breakout_mute_participant']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }

    if (res.status === 403) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateBreakoutMuteParticipant403(data)) {
                throw new ValidationError(
                    validateBreakoutMuteParticipant403.errors,
                );
            }
            return {
                status: res.status,
                data: data as Endpoints['breakout_mute_participant']['403'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type BreakoutMuteParticipantMap = Endpoints['breakout_mute_participant'];

type BreakoutUnmuteParticipantResponse =
    | {status: 200; data: Endpoints['breakout_unmute_participant']['200']}
    | {status: 403; data: Endpoints['breakout_unmute_participant']['403']};
export async function breakoutUnmuteParticipant({
    fetcher,
    init,
    params,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['breakout_unmute_participant']['Params'];
    host: string;
}): Promise<BreakoutUnmuteParticipantResponse> {
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(
            params.conferenceAlias,
        )}/breakouts/${encodeURIComponent(
            params.breakoutUuid,
        )}/participants/${encodeURIComponent(params.participantUuid)}/unmute`,
        {
            ...init,
            method: 'post',
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateBreakoutUnmuteParticipant200(data)) {
                throw new ValidationError(
                    validateBreakoutUnmuteParticipant200.errors,
                );
            }
            return {
                status: res.status,
                data: data as Endpoints['breakout_unmute_participant']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }

    if (res.status === 403) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateBreakoutUnmuteParticipant403(data)) {
                throw new ValidationError(
                    validateBreakoutUnmuteParticipant403.errors,
                );
            }
            return {
                status: res.status,
                data: data as Endpoints['breakout_unmute_participant']['403'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type BreakoutUnmuteParticipantMap =
    Endpoints['breakout_unmute_participant'];

type BreakoutOverlaytextParticipantResponse =
    | {status: 200; data: Endpoints['breakout_overlaytext_participant']['200']}
    | {status: 403; data: Endpoints['breakout_overlaytext_participant']['403']};
export async function breakoutOverlaytextParticipant({
    fetcher,
    init,
    params,
    body,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['breakout_overlaytext_participant']['Params'];
    body: Endpoints['breakout_overlaytext_participant']['Body'];
    host: string;
}): Promise<BreakoutOverlaytextParticipantResponse> {
    if (!validateBreakoutOverlaytextParticipantBody(body)) {
        throw new ValidationError(
            validateBreakoutOverlaytextParticipantBody.errors,
        );
    }
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(
            params.conferenceAlias,
        )}/breakouts/${encodeURIComponent(
            params.breakoutUuid,
        )}/participants/${encodeURIComponent(
            params.participantUuid,
        )}/overlaytext`,
        {
            ...init,
            method: 'post',

            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            },
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateBreakoutOverlaytextParticipant200(data)) {
                throw new ValidationError(
                    validateBreakoutOverlaytextParticipant200.errors,
                );
            }
            return {
                status: res.status,
                data: data as Endpoints['breakout_overlaytext_participant']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }

    if (res.status === 403) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateBreakoutOverlaytextParticipant403(data)) {
                throw new ValidationError(
                    validateBreakoutOverlaytextParticipant403.errors,
                );
            }
            return {
                status: res.status,
                data: data as Endpoints['breakout_overlaytext_participant']['403'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type BreakoutOverlaytextParticipantMap =
    Endpoints['breakout_overlaytext_participant'];

type BreakoutTakeFloorResponse =
    | {status: 200; data: Endpoints['breakout_take_floor']['200']}
    | {status: 403; data: Endpoints['breakout_take_floor']['403']};
export async function breakoutTakeFloor({
    fetcher,
    init,
    params,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['breakout_take_floor']['Params'];
    host: string;
}): Promise<BreakoutTakeFloorResponse> {
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(
            params.conferenceAlias,
        )}/breakouts/${encodeURIComponent(
            params.breakoutUuid,
        )}/participants/${encodeURIComponent(
            params.participantUuid,
        )}/take_floor`,
        {
            ...init,
            method: 'post',
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateBreakoutTakeFloor200(data)) {
                throw new ValidationError(validateBreakoutTakeFloor200.errors);
            }
            return {
                status: res.status,
                data: data as Endpoints['breakout_take_floor']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }

    if (res.status === 403) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateBreakoutTakeFloor403(data)) {
                throw new ValidationError(validateBreakoutTakeFloor403.errors);
            }
            return {
                status: res.status,
                data: data as Endpoints['breakout_take_floor']['403'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type BreakoutTakeFloorMap = Endpoints['breakout_take_floor'];

type BreakoutReleaseFloorResponse =
    | {status: 200; data: Endpoints['breakout_release_floor']['200']}
    | {status: 403; data: Endpoints['breakout_release_floor']['403']};
export async function breakoutReleaseFloor({
    fetcher,
    init,
    params,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['breakout_release_floor']['Params'];
    host: string;
}): Promise<BreakoutReleaseFloorResponse> {
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(
            params.conferenceAlias,
        )}/breakouts/${encodeURIComponent(
            params.breakoutUuid,
        )}/participants/${encodeURIComponent(
            params.participantUuid,
        )}/release_floor`,
        {
            ...init,
            method: 'post',
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateBreakoutReleaseFloor200(data)) {
                throw new ValidationError(
                    validateBreakoutReleaseFloor200.errors,
                );
            }
            return {
                status: res.status,
                data: data as Endpoints['breakout_release_floor']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }

    if (res.status === 403) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateBreakoutReleaseFloor403(data)) {
                throw new ValidationError(
                    validateBreakoutReleaseFloor403.errors,
                );
            }
            return {
                status: res.status,
                data: data as Endpoints['breakout_release_floor']['403'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type BreakoutReleaseFloorMap = Endpoints['breakout_release_floor'];

type BreakoutAckResponse =
    | {status: 200; data: Endpoints['breakout_ack']['200']}
    | {status: 403; data: Endpoints['breakout_ack']['403']};
export async function breakoutAck({
    fetcher,
    init,
    params,
    body,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['breakout_ack']['Params'];
    body: Endpoints['breakout_ack']['Body'];
    host: string;
}): Promise<BreakoutAckResponse> {
    if (!validateBreakoutAckBody(body)) {
        throw new ValidationError(validateBreakoutAckBody.errors);
    }
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(
            params.conferenceAlias,
        )}/breakouts/${encodeURIComponent(
            params.breakoutUuid,
        )}/participants/${encodeURIComponent(
            params.participantUuid,
        )}/calls/${encodeURIComponent(params.callUuid)}/ack`,
        {
            ...init,
            method: 'post',

            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            },
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateBreakoutAck200(data)) {
                throw new ValidationError(validateBreakoutAck200.errors);
            }
            return {
                status: res.status,
                data: data as Endpoints['breakout_ack']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }

    if (res.status === 403) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateBreakoutAck403(data)) {
                throw new ValidationError(validateBreakoutAck403.errors);
            }
            return {
                status: res.status,
                data: data as Endpoints['breakout_ack']['403'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type BreakoutAckMap = Endpoints['breakout_ack'];

type BreakoutDisconnectCallResponse =
    | {status: 200; data: Endpoints['breakout_disconnect_call']['200']}
    | {status: 403; data: Endpoints['breakout_disconnect_call']['403']}
    | {status: 502; data: Endpoints['breakout_disconnect_call']['502']}
    | {status: 503; data: Endpoints['breakout_disconnect_call']['503']};
export async function breakoutDisconnectCall({
    fetcher,
    init,
    params,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['breakout_disconnect_call']['Params'];
    host: string;
}): Promise<BreakoutDisconnectCallResponse> {
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(
            params.conferenceAlias,
        )}/breakouts/${encodeURIComponent(
            params.breakoutUuid,
        )}/participants/${encodeURIComponent(
            params.participantUuid,
        )}/calls/${encodeURIComponent(params.callUuid)}/disconnect`,
        {
            ...init,
            method: 'post',
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateBreakoutDisconnectCall200(data)) {
                throw new ValidationError(
                    validateBreakoutDisconnectCall200.errors,
                );
            }
            return {
                status: res.status,
                data: data as Endpoints['breakout_disconnect_call']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }

    if (res.status === 403) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateBreakoutDisconnectCall403(data)) {
                throw new ValidationError(
                    validateBreakoutDisconnectCall403.errors,
                );
            }
            return {
                status: res.status,
                data: data as Endpoints['breakout_disconnect_call']['403'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }

    if (res.status === 502) {
        const data = await res.text();
        if (!validateBreakoutDisconnectCall502(data)) {
            throw new ValidationError(validateBreakoutDisconnectCall502.errors);
        }
        return {status: res.status, data};
    }

    if (res.status === 503) {
        const data = await res.text();
        if (!validateBreakoutDisconnectCall503(data)) {
            throw new ValidationError(validateBreakoutDisconnectCall503.errors);
        }
        return {status: res.status, data};
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type BreakoutDisconnectCallMap = Endpoints['breakout_disconnect_call'];

type BreakoutNewCandidateResponse =
    | {status: 200; data: Endpoints['breakout_new_candidate']['200']}
    | {status: 403; data: Endpoints['breakout_new_candidate']['403']}
    | {status: 502; data: Endpoints['breakout_new_candidate']['502']};
export async function breakoutNewCandidate({
    fetcher,
    init,
    params,
    body,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['breakout_new_candidate']['Params'];
    body: Endpoints['breakout_new_candidate']['Body'];
    host: string;
}): Promise<BreakoutNewCandidateResponse> {
    if (!validateBreakoutNewCandidateBody(body)) {
        throw new ValidationError(validateBreakoutNewCandidateBody.errors);
    }
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(
            params.conferenceAlias,
        )}/breakouts/${encodeURIComponent(
            params.breakoutUuid,
        )}/participants/${encodeURIComponent(
            params.participantUuid,
        )}/calls/${encodeURIComponent(params.callUuid)}/new_candidate`,
        {
            ...init,
            method: 'post',

            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            },
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateBreakoutNewCandidate200(data)) {
                throw new ValidationError(
                    validateBreakoutNewCandidate200.errors,
                );
            }
            return {
                status: res.status,
                data: data as Endpoints['breakout_new_candidate']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }

    if (res.status === 403) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateBreakoutNewCandidate403(data)) {
                throw new ValidationError(
                    validateBreakoutNewCandidate403.errors,
                );
            }
            return {
                status: res.status,
                data: data as Endpoints['breakout_new_candidate']['403'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }

    if (res.status === 502) {
        const data = await res.text();
        if (!validateBreakoutNewCandidate502(data)) {
            throw new ValidationError(validateBreakoutNewCandidate502.errors);
        }
        return {status: res.status, data};
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type BreakoutNewCandidateMap = Endpoints['breakout_new_candidate'];

type BreakoutUpdateResponse =
    | {status: 200; data: Endpoints['breakout_update']['200']}
    | {status: 403; data: Endpoints['breakout_update']['403']}
    | {status: 502; data: Endpoints['breakout_update']['502']}
    | {status: 503; data: Endpoints['breakout_update']['503']}
    | {status: 520; data: Endpoints['breakout_update']['520']};
export async function breakoutUpdate({
    fetcher,
    init,
    params,
    body,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['breakout_update']['Params'];
    body: Endpoints['breakout_update']['Body'];
    host: string;
}): Promise<BreakoutUpdateResponse> {
    if (!validateBreakoutUpdateBody(body)) {
        throw new ValidationError(validateBreakoutUpdateBody.errors);
    }
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(
            params.conferenceAlias,
        )}/breakouts/${encodeURIComponent(
            params.breakoutUuid,
        )}/participants/${encodeURIComponent(
            params.participantUuid,
        )}/calls/${encodeURIComponent(params.callUuid)}/update`,
        {
            ...init,
            method: 'post',

            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            },
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateBreakoutUpdate200(data)) {
                throw new ValidationError(validateBreakoutUpdate200.errors);
            }
            return {
                status: res.status,
                data: data as Endpoints['breakout_update']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }

    if (res.status === 403) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateBreakoutUpdate403(data)) {
                throw new ValidationError(validateBreakoutUpdate403.errors);
            }
            return {
                status: res.status,
                data: data as Endpoints['breakout_update']['403'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }

    if (res.status === 502) {
        const data = await res.text();
        if (!validateBreakoutUpdate502(data)) {
            throw new ValidationError(validateBreakoutUpdate502.errors);
        }
        return {status: res.status, data};
    }

    if (res.status === 503) {
        const data = await res.text();
        if (!validateBreakoutUpdate503(data)) {
            throw new ValidationError(validateBreakoutUpdate503.errors);
        }
        return {status: res.status, data};
    }

    if (res.status === 520) {
        const data = await res.text();
        if (!validateBreakoutUpdate520(data)) {
            throw new ValidationError(validateBreakoutUpdate520.errors);
        }
        return {status: res.status, data};
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type BreakoutUpdateMap = Endpoints['breakout_update'];

type BreakoutShowLiveCaptionsResponse =
    | {status: 200; data: Endpoints['breakout_show_live_captions']['200']}
    | {status: 403; data: Endpoints['breakout_show_live_captions']['403']};
export async function breakoutShowLiveCaptions({
    fetcher,
    init,
    params,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['breakout_show_live_captions']['Params'];
    host: string;
}): Promise<BreakoutShowLiveCaptionsResponse> {
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(
            params.conferenceAlias,
        )}/breakouts/${encodeURIComponent(
            params.breakoutUuid,
        )}/participants/${encodeURIComponent(
            params.participantUuid,
        )}/show_live_captions`,
        {
            ...init,
            method: 'post',
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateBreakoutShowLiveCaptions200(data)) {
                throw new ValidationError(
                    validateBreakoutShowLiveCaptions200.errors,
                );
            }
            return {
                status: res.status,
                data: data as Endpoints['breakout_show_live_captions']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }

    if (res.status === 403) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateBreakoutShowLiveCaptions403(data)) {
                throw new ValidationError(
                    validateBreakoutShowLiveCaptions403.errors,
                );
            }
            return {
                status: res.status,
                data: data as Endpoints['breakout_show_live_captions']['403'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type BreakoutShowLiveCaptionsMap =
    Endpoints['breakout_show_live_captions'];

type BreakoutHideLiveCaptionsResponse =
    | {status: 200; data: Endpoints['breakout_hide_live_captions']['200']}
    | {status: 403; data: Endpoints['breakout_hide_live_captions']['403']};
export async function breakoutHideLiveCaptions({
    fetcher,
    init,
    params,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['breakout_hide_live_captions']['Params'];
    host: string;
}): Promise<BreakoutHideLiveCaptionsResponse> {
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(
            params.conferenceAlias,
        )}/breakouts/${encodeURIComponent(
            params.breakoutUuid,
        )}/participants/${encodeURIComponent(
            params.participantUuid,
        )}/hide_live_captions`,
        {
            ...init,
            method: 'post',
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateBreakoutHideLiveCaptions200(data)) {
                throw new ValidationError(
                    validateBreakoutHideLiveCaptions200.errors,
                );
            }
            return {
                status: res.status,
                data: data as Endpoints['breakout_hide_live_captions']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }

    if (res.status === 403) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateBreakoutHideLiveCaptions403(data)) {
                throw new ValidationError(
                    validateBreakoutHideLiveCaptions403.errors,
                );
            }
            return {
                status: res.status,
                data: data as Endpoints['breakout_hide_live_captions']['403'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type BreakoutHideLiveCaptionsMap =
    Endpoints['breakout_hide_live_captions'];

type BreakoutMessageConferenceResponse = {
    status: 200;
    data: Endpoints['breakout_message_conference']['200'];
};
export async function breakoutMessageConference({
    fetcher,
    init,
    params,
    body,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['breakout_message_conference']['Params'];
    body: Endpoints['breakout_message_conference']['Body'];
    host: string;
}): Promise<BreakoutMessageConferenceResponse> {
    if (!validateBreakoutMessageConferenceBody(body)) {
        throw new ValidationError(validateBreakoutMessageConferenceBody.errors);
    }
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(
            params.conferenceAlias,
        )}/breakouts/${encodeURIComponent(params.breakoutUuid)}/message`,
        {
            ...init,
            method: 'post',

            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            },
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateBreakoutMessageConference200(data)) {
                throw new ValidationError(
                    validateBreakoutMessageConference200.errors,
                );
            }
            return {
                status: res.status,
                data: data as Endpoints['breakout_message_conference']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type BreakoutMessageConferenceMap =
    Endpoints['breakout_message_conference'];
