import React from 'react';
import cx from 'classnames';

import {StepNumber} from '../../elements/StepNumber/StepNumber';
import {Text} from '../../elements/Text/Text';
import {FontVariant} from '../../../../design-tokens/constants';

import {getTextVariant} from './MultistepIndicator.utils';

import styles from './MultistepIndicator.module.scss';

export const OrdinalText: React.FC<{
    className?: string;
    isActive?: boolean;
    isCompleted?: boolean;
    isCurrent: boolean;
    isDisabled?: boolean;
    isStepNameTruncated?: boolean;
    isStepNameHidden: boolean;
    label: string;
    stepNumber: string;
}> = ({
    isActive,
    isCompleted,
    isCurrent,
    isDisabled,
    isStepNameTruncated,
    isStepNameHidden,
    label,
    stepNumber,
}) => (
    <>
        <StepNumber
            className={cx(styles.number, {'mr-2': !isStepNameHidden})}
            isActive={isActive || isCurrent}
            isCompleted={isCompleted}
            isDisabled={isDisabled}
            stepNumber={stepNumber}
        />
        {!isStepNameHidden && (
            <Text
                isTruncated={isStepNameTruncated}
                className={styles.text}
                fontVariant={FontVariant.BodyBold}
                variant={getTextVariant({
                    isDisabled,
                    isActive: isActive || isCurrent,
                })}
            >
                {label}
            </Text>
        )}
    </>
);
