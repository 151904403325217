import React from 'react';

import {
    FontVariant,
    TextHeading,
    Button,
    Icon,
    IconTypes,
} from '@pexip/components';

import {TestId} from '../../../test/testIds';

import styles from './BreakoutRoomsPanelHeader.module.scss';

export const BreakoutRoomsPanelHeader: React.FC<{
    title?: string;
    onBackClick?: () => void;
    onCloseClick?: () => void;
}> = ({title, onCloseClick, onBackClick}) => {
    return (
        <div className={styles.container}>
            <div className={styles.startContent}>
                {onBackClick && (
                    <Button
                        onClick={onBackClick}
                        className="mr-1"
                        size="none"
                        variant="translucent"
                        data-testid={TestId.BreakoutSetupPanelBackBtn}
                    >
                        <Icon
                            size="small"
                            source={IconTypes.IconArrowLeftRegular}
                        ></Icon>
                    </Button>
                )}
                {title && (
                    <TextHeading htmlTag="h5" fontVariant={FontVariant.H5}>
                        {title}
                    </TextHeading>
                )}
            </div>
            {onCloseClick && (
                <Button
                    onClick={onCloseClick}
                    size="compact"
                    variant="translucent"
                >
                    <Icon size="compact" source={IconTypes.IconClose}></Icon>
                </Button>
            )}
        </div>
    );
};

export type BreakoutRoomsPanelHeaderProps = React.ComponentProps<
    typeof BreakoutRoomsPanelHeader
>;
