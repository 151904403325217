import type {Dispatch} from 'react';
import {useEffect, useState} from 'react';

import {lastOr} from '@pexip/utils';

import type {ChatMessage} from '../types';

export const useBadgeCounter = (
    messages: ChatMessage[],
): [number, Dispatch<React.SetStateAction<number>>] => {
    const [newMessagesCount, setNewMessagesCount] = useState(0);

    useEffect(() => {
        if (
            lastOr(messages, {
                type: 'activity',
            } as ChatMessage).type !== 'activity'
        ) {
            setNewMessagesCount(previousCount => previousCount + 1);
        }
    }, [messages]);

    return [newMessagesCount, setNewMessagesCount];
};
