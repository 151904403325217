import React from 'react';
import cx from 'classnames';

import styles from './InteractiveElement.module.scss';

export type InteractiveElementHTMLTag = 'button' | 'a' | 'div';

const isAnchor = (
    t: React.ComponentProps<InteractiveElementHTMLTag>,
): t is React.ComponentProps<'a'> =>
    'href' in t && Boolean(t.href) && t.href !== '#';

export const InteractiveElement: React.FC<
    React.ComponentProps<InteractiveElementHTMLTag> & {
        className?: string;
        disabled?: boolean;
        htmlTag?: InteractiveElementHTMLTag;
        href?: string;
    }
> = ({children, className, disabled, htmlTag = 'button', ...props}) =>
    React.createElement(
        !disabled ? (isAnchor(props) ? 'a' : htmlTag) : 'button',
        {
            className: cx(styles.el, className),
            disabled,
            ...props,
        },
        children,
    );

export type InteractiveElementProps = React.ComponentProps<
    typeof InteractiveElement
>;
