import React from 'react';

import {IconTypes} from '@pexip/components';

import {TestId} from '../../../test/testIds';
import {ResponsiveButton} from '../ResponsiveButton/ResponsiveButton.view';

export const BreakoutRoomsPanelToggleView: React.FC<
    React.ComponentProps<'button'> & {
        buttonAriaLabel: string;
        buttonText: string;
        hideButtonText: boolean;
        isPanelOpen: boolean;
    }
> = ({isPanelOpen, hideButtonText, buttonAriaLabel, buttonText, ...props}) => {
    return (
        <ResponsiveButton
            aria-label={buttonAriaLabel}
            data-testid={TestId.ButtonBreakoutRooms}
            iconSource={IconTypes.IconBreakoutRooms}
            isActive={isPanelOpen}
            hideChildren={hideButtonText}
            {...props}
        >
            {buttonText}
        </ResponsiveButton>
    );
};

export type BreakoutRoomsPanelToggleViewProps = React.ComponentProps<
    typeof BreakoutRoomsPanelToggleView
>;
