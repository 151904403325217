import React from 'react';
import cx from 'classnames';

import {FontVariant, Group} from '@pexip/components';

import {JoinCallDetailsWrapper} from '../JoinCallDetailsWrapper/JoinCallDetailsWrapper.view';
import {PreflightJoinDetailsToRender} from '../../types';
import {TestId} from '../../../test/testIds';
import {JoinDetailsText} from '../JoinDetailsText/JoinDetailsText.view';

import styles from './JoinCallDetails.module.scss';

const shouldRenderDeviceSelection = (
    joinDetailsToRender: PreflightJoinDetailsToRender,
) =>
    [
        PreflightJoinDetailsToRender.DeviceSelection,
        PreflightJoinDetailsToRender.BlockedDeviceSelection,
    ].includes(joinDetailsToRender);

export const JoinCallDetails: React.FC<
    React.ComponentProps<typeof JoinDetailsText> & {
        inMeetingCount?: number;
        joinDetailsToRender: PreflightJoinDetailsToRender;
        onMouseOut?: () => void;
        onMouseOver?: () => void;
        otherWaysToJoin?: React.ReactNode;
        preflightDeviceSelection: React.ReactNode;
    }
> = ({
    children,
    displayInviteesModal,
    inMeetingCount,
    isGuest,
    joinDetailsToRender,
    onMouseOut,
    onMouseOver,
    otherWaysToJoin,
    preflightDeviceSelection,
}) => (
    <JoinCallDetailsWrapper
        className={cx(
            styles.joinCallDetails,
            joinDetailsToRender ===
                PreflightJoinDetailsToRender.BlockedDeviceSelection &&
                styles.blockingOverlay,
        )}
        onMouseOut={onMouseOut}
        onMouseOver={onMouseOver}
        otherWaysToJoin={otherWaysToJoin}
    >
        <JoinDetailsText
            isGuest={isGuest}
            inMeetingCount={inMeetingCount}
            displayInviteesModal={displayInviteesModal}
            descriptionFontVariant={FontVariant.Body}
            descriptionVariant="standard"
            headingFontVariant={FontVariant.H4}
            headingClassName="mb-4"
        />
        {shouldRenderDeviceSelection(joinDetailsToRender) && (
            <div
                data-testid={
                    joinDetailsToRender ===
                    PreflightJoinDetailsToRender.BlockedDeviceSelection
                        ? TestId.DevicesSelectionBlocked
                        : TestId.DevicesSelection
                }
            >
                {preflightDeviceSelection}
            </div>
        )}
        <Group spacing="compact" className="mt-3">
            {children}
        </Group>
    </JoinCallDetailsWrapper>
);
