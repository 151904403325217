import React, {useEffect, useState} from 'react';
import cx from 'classnames';

import {
    getCalculateAudioMeterStateFn,
    getLinearGradient,
} from '../../utils/audioMeter';

import {InputControl} from './InputControl.view';

import styles from './AudioMeterButton.module.scss';

const calculateAudioMeterState = getCalculateAudioMeterStateFn();

export const AudioMeterButton: React.FC<
    React.ComponentProps<typeof InputControl> & {
        level?: number;
        isMobileDevice?: boolean;
        miniButtons?: boolean;
    }
> = ({level, isActive, isMobileDevice, miniButtons, ...props}) => {
    const [linearGradient, setLinearGradient] = useState('');

    useEffect(() => {
        let ignore = false;

        void calculateAudioMeterState(level)
            .then(audioState => {
                if (ignore) {
                    return;
                }

                setLinearGradient(
                    getLinearGradient({
                        ...audioState,
                        enabled: isActive === true,
                    }),
                );
            })
            .catch(() => {
                // prevents console promise error on cancel (reject)
            });

        return () => {
            ignore = true;
            calculateAudioMeterState?.cancel();
        };
    }, [isActive, level]);

    return (
        <InputControl
            style={{
                backgroundImage: linearGradient,
            }}
            className={cx(
                styles[isActive ? 'enabled' : 'disabled'],
                miniButtons && styles.miniButton,
            )}
            variant={isActive ? 'translucent' : 'danger'}
            size={isMobileDevice ? 'compact' : 'medium'}
            {...props}
        />
    );
};
