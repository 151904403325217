import type {
    ImageSrc,
    BackgroundImageSrc,
    OverlayOpacity,
    ColorScheme,
} from '@pexip/components';

import type {DefaultUserConfig} from '../config';
import type {ALLOWED_LANGUAGES} from '../i18n';
import type {ApplicationConfig} from '../applicationConfig';

/*
    VERSIONS:
    0 - initial draft
 */

const path = new URL(`./branding/manifest.json`, document.baseURI);
const BRANDING_MANIFEST_PATH = path.toString();
export const getBrandingPath = (path: string) =>
    new URL(path, BRANDING_MANIFEST_PATH).toString();

/**
 * Branding manifest
 */
export interface Manifest {
    /**
     * Manifest schema version. Should be 0.
     */
    version: 0;
    /**
     * Manifest metadata, not used by the app.
     */
    meta: {name: string; brandVersion: string; baseColor?: string};
    /**
     *  Title to be used in the application's HTML document i.e. tab name.
     */
    appTitle?: string;
    /**
     * Official brand name to be used in translations and labels.
     */
    brandName: string;
    /**
     * Custom background color.
     */
    backgroundColor?: string;
    /**
     *  Overlay used on top of the background image
     */
    overlay?: ColorScheme;
    /**
     *  Opacity of the background image's overlay
     */
    overlayOpacity?: OverlayOpacity;
    /**
     * Palette of colors (hex) generated from the primary brand color.
     */
    colorPalette?: string[];
    /**
     * Brand images.
     */
    images: Images;
    /**
     * Custom translations.
     */
    translations: Record<string, string>;
    /**
     * Available languages.
     */
    availableLanguages?: typeof ALLOWED_LANGUAGES;
    /**
     * Application config.
     */
    applicationConfig: Partial<ApplicationConfig>;
    /**
     * Default user config.
     */
    defaultUserConfig: Partial<DefaultUserConfig>;
    /**
     * Plugins.
     */
    plugins?: Plugin[];
}
export interface Images {
    logo?: ImageSrc;
    jumbotron?: string;
    background?: BackgroundImageSrc;
}
export interface Plugin {
    src: string;
}

export const DEFAULT: Manifest = {
    version: 0,

    meta: {name: 'DEFAULT', brandVersion: 'n/a'},
    brandName: '',
    images: {},
    translations: {},
    applicationConfig: {},
    defaultUserConfig: {},
};

export async function loadBranding() {
    // TODO: Use cache: 'only-if-cache' to try to get something asap, then load it again later?
    const res = await fetch(BRANDING_MANIFEST_PATH, {
        // These are necessary to re-use the preloaded manifest.
        credentials: 'include',
        mode: 'no-cors',
    });
    return res.json() as Promise<Manifest>;
}
