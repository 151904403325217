import {stopStreamTracks} from '../utils';

import type {
    ProcessInputType,
    Segmenter,
    ProcessStatus,
    RenderParams,
    SegmentationTransform,
} from './types';
import {
    FOREGROUND_THRESHOLD,
    BACKGROUND_BLUR_AMOUNT,
    EDGE_BLUR_AMOUNT,
    FLIP_HORIZONTAL,
    PROCESSING_WIDTH,
    PROCESSING_HEIGHT,
} from './constants';
import {createCanvas} from './utils';
import {createCanvasRenderUtils} from './canvasRenderUtils';

const createAssertInRange = (from: number, to: number) => (value: number) => {
    if (value < from || value > to) {
        throw new Error(`Invalid value (${value}) to range [${from}, ${to}]`);
    }
};

const assertInRangeFrom0To1 = createAssertInRange(0, 1);
const assertInRangeFrom0To20 = createAssertInRange(0, 20);
type Params = Omit<RenderParams, 'frameRate'>;
interface Options extends Omit<Params, 'backgroundImage'> {
    selfManageSegmenter?: boolean;
    bgImageUrl?: string;
}

interface Props extends Params {
    outputCanvas?: HTMLCanvasElement;
    outputStream?: MediaStream;
    segmenter: Segmenter;
    status: ProcessStatus;
    backgroundImageUrl?: string;

    utils: ReturnType<typeof createCanvasRenderUtils>;
}

const NOT_INITED_ERROR_MSG = 'Please call init() method first!';

export const createTransform = (
    segmenter: Segmenter,
    {
        width = PROCESSING_WIDTH,
        height = PROCESSING_HEIGHT,
        foregroundThreshold = FOREGROUND_THRESHOLD,
        backgroundBlurAmount = BACKGROUND_BLUR_AMOUNT,
        edgeBlurAmount = EDGE_BLUR_AMOUNT,
        flipHorizontal = FLIP_HORIZONTAL,
        effects = 'none',
        selfManageSegmenter,
        bgImageUrl,
    }: Partial<Options> = {},
): SegmentationTransform => {
    const props: Props = {
        segmenter,
        width,
        height,
        foregroundThreshold,
        backgroundBlurAmount,
        edgeBlurAmount,
        flipHorizontal,
        utils: createCanvasRenderUtils(width, height),
        effects,
        backgroundImage: undefined,
        status: 'created',
        backgroundImageUrl: bgImageUrl,
    };
    const processInput = async (input: ProcessInputType) => {
        if (
            props.segmenter.status === 'created' ||
            props.segmenter.status === 'closed'
        ) {
            await props.segmenter.open();
        }
        if (props.segmenter.status === 'opening') {
            return [];
        }
        return await props.segmenter.process(input);
    };

    const loadBackgroundImage = async (url: string) => {
        if (props.backgroundImageUrl === url && props.backgroundImage) {
            return;
        }
        props.backgroundImageUrl = url;
        props.backgroundImage = await props.utils.loadBackgroundImage(url);
    };

    return {
        get status() {
            return props.status;
        },
        get width() {
            return props.width;
        },
        get height() {
            return props.height;
        },
        get foregroundThreshold() {
            return props.foregroundThreshold;
        },
        get backgroundBlurAmount() {
            return props.backgroundBlurAmount;
        },
        get edgeBlurAmount() {
            return props.edgeBlurAmount;
        },
        get flipHorizontal() {
            return props.flipHorizontal;
        },
        get effects() {
            return props.effects;
        },
        get backgroundImage() {
            return props.backgroundImage;
        },
        set foregroundThreshold(value) {
            assertInRangeFrom0To1(value);
            props.foregroundThreshold = value;
        },
        set backgroundBlurAmount(value) {
            assertInRangeFrom0To20(value);
            props.backgroundBlurAmount = value;
        },
        set edgeBlurAmount(value) {
            assertInRangeFrom0To20(value);
            props.edgeBlurAmount = value;
        },
        set flipHorizontal(value) {
            props.flipHorizontal = value;
        },
        set effects(value) {
            props.effects = value;
        },
        get backgroundImageUrl() {
            return props.backgroundImageUrl;
        },
        set backgroundImage(canvas) {
            props.backgroundImage = canvas;
        },
        loadBackgroundImage,
        get segmenter() {
            return props.segmenter;
        },
        set segmenter(value) {
            if (value !== props.segmenter) {
                props.segmenter = value;
            }
        },
        init: async () => {
            props.outputCanvas = createCanvas(width, height);
            if (props.backgroundImageUrl) {
                await loadBackgroundImage(props.backgroundImageUrl);
            }
            props.status = 'opened';
        },
        transform: async (videoFrame, controller) => {
            if (!props.outputCanvas) {
                throw new Error(NOT_INITED_ERROR_MSG);
            }
            switch (props.effects) {
                case 'blur': {
                    const image =
                        await props.utils.renderImageToOffScreenCanvas(
                            videoFrame,
                            'inputCanvas',
                        );
                    const segmentations = await processInput(image);
                    if (props.status === 'closed') {
                        break;
                    }
                    if (props.outputCanvas.height !== height) {
                        props.outputCanvas.width = width;
                        props.outputCanvas.height = height;
                    }
                    await props.utils.drawBlurEffect(
                        props.outputCanvas,
                        image,
                        segmentations,
                        props.foregroundThreshold,
                        props.backgroundBlurAmount,
                        props.edgeBlurAmount,
                        props.flipHorizontal,
                    );
                    controller.enqueue(props.outputCanvas);
                    break;
                }
                case 'overlay': {
                    if (!props.backgroundImage) {
                        throw new Error(
                            'Please call setBackgroundImage() method first',
                        );
                    }
                    const image =
                        await props.utils.renderImageToOffScreenCanvas(
                            videoFrame,
                            'inputCanvas',
                        );
                    const segmentations = await processInput(image);
                    if (props.status === 'closed') {
                        break;
                    }
                    if (props.outputCanvas.height !== height) {
                        props.outputCanvas.width = width;
                        props.outputCanvas.height = height;
                    }
                    await props.utils.drawOverlayEffect(
                        props.outputCanvas,
                        image,
                        props.backgroundImage,
                        segmentations,
                        props.foregroundThreshold,
                        0, // No blur for overlay
                        props.edgeBlurAmount,
                        props.flipHorizontal,
                    );
                    controller.enqueue(props.outputCanvas);
                    break;
                }
                case 'none': {
                    controller.enqueue(videoFrame);
                    break;
                }
            }
            props.status = 'processing';
        },
        close: () => {
            if (!selfManageSegmenter) {
                segmenter.close();
            }
            props.outputCanvas = undefined;
            stopStreamTracks(props.outputStream);
            props.outputStream = undefined;
            props.status = 'closed';
        },
        destroy: async () => {
            if (!selfManageSegmenter) {
                await segmenter.destroy();
            }
            props.status = 'destroyed';
        },
    };
};
