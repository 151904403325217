import type {PluginContext, PluginElements} from './types';

export const updatePluginsElements = (
    pluginsElements: PluginContext,
    [chanId, pluginElements]: [string, PluginElements],
) => {
    const pluginIndex = Object.keys(pluginsElements).findIndex(
        id => id === chanId,
    );
    const pluginsElementsEntries = Object.entries(pluginsElements);
    if (pluginIndex !== -1) {
        pluginsElementsEntries.splice(pluginIndex, 1, [chanId, pluginElements]);
    }
    return Object.fromEntries(pluginsElementsEntries);
};
