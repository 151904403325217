import React from 'react';
import cx from 'classnames';

import {Bar, Group} from '@pexip/components';

import styles from './Footer.module.scss';

export const Footer: React.FC<
    React.ComponentProps<typeof Bar> & {
        start?: React.ReactNode;
        middle?: React.ReactNode;
        end?: React.ReactNode;
        isPhone?: boolean;
    }
> = ({className, start, middle, end, isPhone = false, ...props}) => (
    <Bar
        position="absoluteBottom"
        padding="small"
        className={cx(styles.footer, isPhone && styles.phone, className)}
        {...props}
    >
        {start && (
            <div className={styles.start}>
                <Group spacing="compact">{start}</Group>
            </div>
        )}
        {middle && (
            <div
                className={cx(styles.middle, {
                    [styles.singleEnhancer]: !start && !end,
                })}
            >
                <Group spacing={isPhone ? 'small' : 'compact'}>{middle}</Group>
            </div>
        )}
        {end && (
            <div className={styles.end}>
                <Group spacing="compact">{end}</Group>
            </div>
        )}
    </Bar>
);
