import type {AudioMeterState} from '../types';

export const getLinearGradient = (state: {
    level: number;
    peak: number;
    enabled: boolean;
}) => {
    const {level = 0, peak: peak = 0, enabled = true} = state;
    const key = enabled ? 'enabled' : 'disabled';

    return `linear-gradient(
        to top,
        var(--mic-button-${key}-background-color-dark) 0% ${level}%,
        var(--mic-button-${key}-background-color-light) ${level}% ${peak}%,
        var(--mic-button-${key}-background-color) ${peak}% 100%)`;
};

type Reject = () => void;

export const getCalculateAudioMeterStateFn = (
    lastLevel = 0,
    timeout = 3000,
    timeoutId = 0,
) => {
    let lastReject: Reject | undefined = undefined;

    const cancel = () => {
        lastReject?.();
    };

    const fn = (level = 0) =>
        new Promise<AudioMeterState>((resolve, reject) => {
            lastReject = reject;

            if (lastLevel > level) {
                if (timeoutId > 0) {
                    resolve({level, peak: lastLevel});
                } else {
                    timeoutId = window.setTimeout(() => {
                        timeoutId = 0;
                        clearTimeout(timeoutId);
                        lastLevel = 0;

                        resolve({
                            level,
                            peak: 0,
                        });
                        cancel();
                    }, timeout);
                }
            } else {
                lastLevel = level;

                resolve({
                    level,
                    peak: 0,
                });
            }
        });

    fn.cancel = cancel;

    return fn;
};
