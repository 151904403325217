import React, {useState} from 'react';
import {useTranslation, Trans} from 'react-i18next';

import {
    Button,
    FontVariant,
    Form,
    Input,
    TextHeading,
    TextLink,
    Text,
} from '@pexip/components';

import {TERMS_URL} from '../../constants';
import {TestId} from '../../../test/testIds';

const FORM_ID = 'userNameStepInputForm';
const NAME_INPUT_ID = 'userNameStepTextInput';

export const UserNameStep: React.FC<{
    defaultName?: string;
    onSubmit: (name: string) => void;
    termsUrl?: string;
}> = ({defaultName = '', onSubmit, termsUrl = TERMS_URL}) => {
    const [nameValue, setNameValue] = useState(defaultName);
    const {t} = useTranslation();

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) =>
        setNameValue(e.target.value);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onSubmit(nameValue.trim());
    };

    return (
        <div data-testid={TestId.UserNameStep}>
            <TextHeading
                htmlTag={FontVariant.H2}
                fontVariant={FontVariant.H2}
                className="mb-3"
            >
                <Trans i18nKey="username.hey-whats-your-name">
                    Hello. <br /> What&apos;s your name?
                </Trans>
            </TextHeading>
            <Text
                fontVariant={FontVariant.Body}
                className="mb-3 d-inline-block"
            >
                {t(
                    'username.usage-purpose',
                    "Please enter a display name so other people know who's in the meeting",
                )}
            </Text>
            <Form
                onSubmit={handleSubmit}
                id={FORM_ID}
                data-testid={TestId.UserNameStepInputForm}
            >
                <Input
                    autoComplete="name"
                    value={nameValue}
                    id={NAME_INPUT_ID}
                    className="mb-3"
                    label={t('username.your-name', 'Your name')}
                    name="name"
                    onChange={handleNameChange}
                    placeholder={t(
                        'username.name-input-placeholder',
                        "e.g. 'David'",
                    )}
                    sizeModifier="medium"
                    data-testid={TestId.UserNameStepTextInput}
                />
                <Button
                    modifier="fullWidth"
                    className="mb-3"
                    isDisabled={!nameValue.trim()}
                    size="large"
                    data-testid={TestId.ButtonSetName}
                >
                    <Trans t={t} i18nKey="common.next">
                        Next
                    </Trans>
                </Button>
                <Text fontVariant={FontVariant.Small} variant="tinted">
                    <Trans t={t} i18nKey="common.next-terms-and-conditions">
                        By continuing you confirm that you agree to our{' '}
                        <TextLink
                            href={termsUrl}
                            target="_blank"
                            isUnderlined
                            fontVariant={FontVariant.Small}
                            data-testid={TestId.TermsAndServicesLink}
                        >
                            terms and services
                        </TextLink>
                    </Trans>
                </Text>
            </Form>
        </div>
    );
};

export type UserNameStepProps = React.ComponentProps<typeof UserNameStep>;
