import React from 'react';
import {Trans, useTranslation} from 'react-i18next';

import {
    Box,
    ModalCloseButton as CloseButton,
    CustomTooltip,
    FontVariant,
    List,
    Row,
    Text,
    TextLink,
} from '@pexip/components';
import type {DeniedDevices} from '@pexip/media';

import {TestId} from '../../../test/testIds';

import styles from './DeviceDeniedTooltip.module.scss';

/*
t('media.permission-info.[microphone].title', 'microphone')
t('media.permission-info.[camera].title', 'camera')
t('media.permission-info.[microphone-and-camera].title', 'camera and microphone')
*/

export const DeviceDeniedTooltip: React.FC<{
    children: React.ReactNode;
    contentWrapperClassName?: string;
    deniedDevice: DeniedDevices;
    setShowHelpVideo: React.Dispatch<React.SetStateAction<boolean>>;
    learnHowToGrantAccessURL?: string;
    mainWrapperClassName?: string;
    onClose: () => void;
    visible: boolean;
}> = ({
    children,
    contentWrapperClassName,
    deniedDevice,
    setShowHelpVideo,
    learnHowToGrantAccessURL,
    mainWrapperClassName,
    onClose,
    visible,
    ...props
}) => {
    const {t} = useTranslation();
    return (
        <CustomTooltip
            controlledIsVisible={visible}
            isHoverEnabled={false}
            mainWrapperClassName={mainWrapperClassName}
            wrapperClassName={contentWrapperClassName}
            {...props}
            content={
                <Box className={styles.tooltipWrapper}>
                    <List>
                        <Row className="pt-3 pb-1">
                            <Text fontVariant={FontVariant.BodyBold}>
                                {t(
                                    'media.you-blocked-access',
                                    `You blocked access to your ${deniedDevice.replaceAll(
                                        '-',
                                        ' ',
                                    )}`,
                                    {
                                        seeOrHearYou: t(
                                            `media.permission-info.[${deniedDevice}].title`,
                                        ),
                                    },
                                )}
                            </Text>
                            <CloseButton
                                className={styles.close}
                                onClose={onClose}
                                aria-label={t(
                                    'common.close-tooltip',
                                    'Close tooltip',
                                )}
                            />
                        </Row>
                        <Row className="pt-0">
                            <Text>
                                <Trans
                                    t={t}
                                    i18nKey="media.permissions-help-video-link"
                                >
                                    To grant access please follow the steps in{' '}
                                    <TextLink
                                        onClick={() => setShowHelpVideo(true)}
                                        target="_blank"
                                        data-testid={
                                            TestId.TextLinkLearnRequestPermissions
                                        }
                                    >
                                        this short video
                                    </TextLink>
                                </Trans>
                                {learnHowToGrantAccessURL && (
                                    <Trans
                                        t={t}
                                        i18nKey="media.learn-how-to-grant-access"
                                    >
                                        {' '}
                                        or go to the{' '}
                                        <TextLink
                                            href={learnHowToGrantAccessURL}
                                            target="_blank"
                                            data-testid={
                                                TestId.TextLinkLearnRequestPermissions
                                            }
                                        >
                                            privacy settings
                                        </TextLink>{' '}
                                        of your browser
                                    </Trans>
                                )}
                            </Text>
                        </Row>
                    </List>
                </Box>
            }
            position="topCenter"
        >
            {children}
        </CustomTooltip>
    );
};
