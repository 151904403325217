import type {ReactNode} from 'react';

import {createSignal} from '@pexip/signal';

import type {ToastPosition} from '../Toast/Toast';
import type {ColorScheme} from '../../../types/variants';

export type NotificationToastMessage = {
    colorScheme?: ColorScheme;
    enhanceStart?: React.ReactNode;
    isClickable?: boolean;
    isDanger?: boolean;
    /**
     * A toast that can interrupt will jump the queue and remove the
     * currently rendering toast and be rendered instead of it. Currently
     * rendering toasts that have a timeout of 0 cannot be interrupted, the
     * toast that tries to interrupt it is discarded and not queued.
     *
     * An example when this can be useful: if many
     * toasts are sent rapidly in response to UI state changes;
     * User mutes, unmute & mutes again and toasts are displayed to reflect these states,
     * the toasts will display without a long timeout between each.
     */
    isInterrupt?: boolean;
    message: ReactNode;
    position?: ToastPosition;
    testid?: string;
    timeout?: number;
};

export type NotificationToastMessageSignal = NotificationToastMessage[];

/*
 * A toast message that has a timeout of 0 stays open
 * until you emit a close signal.
 */
type NotificationToastCloseSignal = {close: true};

export type NotificationToastSignal =
    | NotificationToastMessageSignal
    | NotificationToastCloseSignal;

export const notificationToastSignal = createSignal<NotificationToastSignal>({
    name: 'notification:toast',
});
