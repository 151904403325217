import {useCallback, useEffect, useRef, useState} from 'react';

export const usePromise = <T>(
    getPromise: () => Promise<T>,
    cb?: (data: T) => void,
    failCb?: (error: string) => void,
) => {
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    // keep track of the current mounted state of the component
    const active = useRef(true);

    const handleAction = useCallback(() => {
        // reset error if re-triggered
        setError('');
        setLoading(true);

        // Returning to give tests something to await, I don't expect it to be actually used outside this context
        return getPromise()
            .then(data => {
                if (active.current) {
                    cb?.(data);
                }
            })
            .catch((error: Error) => {
                if (active.current) {
                    setError(error.message);
                    failCb?.(error.message);
                }
            })
            .finally(() => {
                if (active.current) {
                    setLoading(false);
                }
            });
    }, [cb, failCb, getPromise]);

    useEffect(
        () => () => {
            active.current = false;
        },
        [],
    );

    return {error, loading, handleAction};
};
