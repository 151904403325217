import type {RenderEffects, SegmentationModel} from './types';
import {RENDER_EFFECTS, SEG_MODELS} from './types';

export const isRenderEffects = (t: unknown): t is RenderEffects => {
    if (typeof t !== 'string') {
        return false;
    }
    return RENDER_EFFECTS.some(effect => effect === t);
};

export const isSegmentationModel = (t: unknown): t is SegmentationModel => {
    if (typeof t !== 'string') {
        return false;
    }
    return SEG_MODELS.some(model => model === t);
};
