'use strict';
export const validateBreakoutsBody = validate11;
const schema12 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        TopLevel: {
            type: 'object',
            additionalProperties: false,
            properties: {
                name: {
                    type: 'string',
                    description:
                        'Unused for now, but could be used as overlay text etc.',
                },
                description: {
                    type: 'string',
                    description:
                        'Unused for now, but could be used as overlay text or passed to client to display somehow',
                },
                duration: {
                    type: 'number',
                    description:
                        'Optional timer for how long the breakout is open (not present or 0 = indefinitely)',
                },
                end_action: {
                    type: 'string',
                    enum: ['disconnect', 'transfer'],
                    default: 'transfer',
                    description:
                        'When timer expires, or the breakout is closed, what do we do with participants: Disconnect them all, or transfer them back to the main room (default transfer)',
                },
                participants: {
                    type: 'object',
                    description:
                        'Optional list of participants to transfer to the breakout on creation. Record<FromBreakoutRoomUuid, ParticipantUuid[]>',
                    additionalProperties: {
                        type: 'array',
                        items: {type: 'string'},
                    },
                },
            },
            required: ['name'],
            title: 'TopLevel',
        },
    },
    oneOf: [
        {$ref: '#/definitions/breakouts/properties/Body/definitions/TopLevel'},
    ],
};
const schema13 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        name: {
            type: 'string',
            description:
                'Unused for now, but could be used as overlay text etc.',
        },
        description: {
            type: 'string',
            description:
                'Unused for now, but could be used as overlay text or passed to client to display somehow',
        },
        duration: {
            type: 'number',
            description:
                'Optional timer for how long the breakout is open (not present or 0 = indefinitely)',
        },
        end_action: {
            type: 'string',
            enum: ['disconnect', 'transfer'],
            default: 'transfer',
            description:
                'When timer expires, or the breakout is closed, what do we do with participants: Disconnect them all, or transfer them back to the main room (default transfer)',
        },
        participants: {
            type: 'object',
            description:
                'Optional list of participants to transfer to the breakout on creation. Record<FromBreakoutRoomUuid, ParticipantUuid[]>',
            additionalProperties: {type: 'array', items: {type: 'string'}},
        },
    },
    required: ['name'],
    title: 'TopLevel',
};
function validate11(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (data.name === undefined && (missing0 = 'name')) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakouts/properties/Body/definitions/TopLevel/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (
                        !(
                            key0 === 'name' ||
                            key0 === 'description' ||
                            key0 === 'duration' ||
                            key0 === 'end_action' ||
                            key0 === 'participants'
                        )
                    ) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.name !== undefined) {
                        const _errs5 = errors;
                        if (typeof data.name !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/name',
                                schemaPath:
                                    '#/definitions/breakouts/properties/Body/definitions/TopLevel/properties/name/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.description !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.description !== 'string') {
                                const err2 = {
                                    instancePath: instancePath + '/description',
                                    schemaPath:
                                        '#/definitions/breakouts/properties/Body/definitions/TopLevel/properties/description/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err2];
                                } else {
                                    vErrors.push(err2);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                        if (valid2) {
                            if (data.duration !== undefined) {
                                const _errs9 = errors;
                                if (!(typeof data.duration == 'number')) {
                                    const err3 = {
                                        instancePath:
                                            instancePath + '/duration',
                                        schemaPath:
                                            '#/definitions/breakouts/properties/Body/definitions/TopLevel/properties/duration/type',
                                        keyword: 'type',
                                        params: {type: 'number'},
                                        message: 'must be number',
                                    };
                                    if (vErrors === null) {
                                        vErrors = [err3];
                                    } else {
                                        vErrors.push(err3);
                                    }
                                    errors++;
                                }
                                var valid2 = _errs9 === errors;
                            } else {
                                var valid2 = true;
                            }
                            if (valid2) {
                                if (data.end_action !== undefined) {
                                    let data3 = data.end_action;
                                    const _errs11 = errors;
                                    if (typeof data3 !== 'string') {
                                        const err4 = {
                                            instancePath:
                                                instancePath + '/end_action',
                                            schemaPath:
                                                '#/definitions/breakouts/properties/Body/definitions/TopLevel/properties/end_action/type',
                                            keyword: 'type',
                                            params: {type: 'string'},
                                            message: 'must be string',
                                        };
                                        if (vErrors === null) {
                                            vErrors = [err4];
                                        } else {
                                            vErrors.push(err4);
                                        }
                                        errors++;
                                    }
                                    if (
                                        !(
                                            data3 === 'disconnect' ||
                                            data3 === 'transfer'
                                        )
                                    ) {
                                        const err5 = {
                                            instancePath:
                                                instancePath + '/end_action',
                                            schemaPath:
                                                '#/definitions/breakouts/properties/Body/definitions/TopLevel/properties/end_action/enum',
                                            keyword: 'enum',
                                            params: {
                                                allowedValues:
                                                    schema13.properties
                                                        .end_action.enum,
                                            },
                                            message:
                                                'must be equal to one of the allowed values',
                                        };
                                        if (vErrors === null) {
                                            vErrors = [err5];
                                        } else {
                                            vErrors.push(err5);
                                        }
                                        errors++;
                                    }
                                    var valid2 = _errs11 === errors;
                                } else {
                                    var valid2 = true;
                                }
                                if (valid2) {
                                    if (data.participants !== undefined) {
                                        let data4 = data.participants;
                                        const _errs13 = errors;
                                        if (errors === _errs13) {
                                            if (
                                                data4 &&
                                                typeof data4 == 'object' &&
                                                !Array.isArray(data4)
                                            ) {
                                                const _errs15 = errors;
                                                for (const key1 in data4) {
                                                    let data5 = data4[key1];
                                                    const _errs16 = errors;
                                                    if (errors === _errs16) {
                                                        if (
                                                            Array.isArray(data5)
                                                        ) {
                                                            var valid4 = true;
                                                            const len0 =
                                                                data5.length;
                                                            for (
                                                                let i0 = 0;
                                                                i0 < len0;
                                                                i0++
                                                            ) {
                                                                const _errs18 =
                                                                    errors;
                                                                if (
                                                                    typeof data5[
                                                                        i0
                                                                    ] !==
                                                                    'string'
                                                                ) {
                                                                    const err6 =
                                                                        {};
                                                                    if (
                                                                        vErrors ===
                                                                        null
                                                                    ) {
                                                                        vErrors =
                                                                            [
                                                                                err6,
                                                                            ];
                                                                    } else {
                                                                        vErrors.push(
                                                                            err6,
                                                                        );
                                                                    }
                                                                    errors++;
                                                                }
                                                                var valid4 =
                                                                    _errs18 ===
                                                                    errors;
                                                                if (!valid4) {
                                                                    break;
                                                                }
                                                            }
                                                        } else {
                                                            const err7 = {};
                                                            if (
                                                                vErrors === null
                                                            ) {
                                                                vErrors = [
                                                                    err7,
                                                                ];
                                                            } else {
                                                                vErrors.push(
                                                                    err7,
                                                                );
                                                            }
                                                            errors++;
                                                        }
                                                    }
                                                    var valid3 =
                                                        _errs16 === errors;
                                                    if (!valid3) {
                                                        errors = _errs15;
                                                        if (vErrors !== null) {
                                                            if (_errs15) {
                                                                vErrors.length =
                                                                    _errs15;
                                                            } else {
                                                                vErrors = null;
                                                            }
                                                        }
                                                        delete data4[key1];
                                                    }
                                                }
                                            } else {
                                                const err8 = {
                                                    instancePath:
                                                        instancePath +
                                                        '/participants',
                                                    schemaPath:
                                                        '#/definitions/breakouts/properties/Body/definitions/TopLevel/properties/participants/type',
                                                    keyword: 'type',
                                                    params: {type: 'object'},
                                                    message: 'must be object',
                                                };
                                                if (vErrors === null) {
                                                    vErrors = [err8];
                                                } else {
                                                    vErrors.push(err8);
                                                }
                                                errors++;
                                            }
                                        }
                                        var valid2 = _errs13 === errors;
                                    } else {
                                        var valid2 = true;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        } else {
            const err9 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakouts/properties/Body/definitions/TopLevel/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err9];
            } else {
                vErrors.push(err9);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err10 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err10];
        } else {
            vErrors.push(err10);
        }
        errors++;
        validate11.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate11.errors = vErrors;
    return errors === 0;
}
export const validateBreakouts200 = validate12;
const schema14 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        '200': {
            type: 'object',
            additionalProperties: false,
            properties: {
                status: {
                    type: 'string',
                    description: 'Status of the response, eg. success.',
                    enum: ['success'],
                },
                result: {
                    $ref: '#/definitions/breakouts/properties/200/definitions/Result',
                },
            },
            required: ['status', 'result'],
            title: 'Status200',
        },
        Result: {
            type: 'object',
            additionalProperties: false,
            properties: {breakout_uuid: {type: 'string'}},
            required: ['breakout_uuid'],
            title: 'Result',
        },
    },
    oneOf: [{$ref: '#/definitions/breakouts/properties/200/definitions/200'}],
};
const schema15 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        status: {
            type: 'string',
            description: 'Status of the response, eg. success.',
            enum: ['success'],
        },
        result: {
            $ref: '#/definitions/breakouts/properties/200/definitions/Result',
        },
    },
    required: ['status', 'result'],
    title: 'Status200',
};
const schema16 = {
    type: 'object',
    additionalProperties: false,
    properties: {breakout_uuid: {type: 'string'}},
    required: ['breakout_uuid'],
    title: 'Result',
};
function validate13(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (errors === 0) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                validate13.errors = [
                    {
                        instancePath,
                        schemaPath: '#/required',
                        keyword: 'required',
                        params: {missingProperty: missing0},
                        message:
                            "must have required property '" + missing0 + "'",
                    },
                ];
                return false;
            } else {
                const _errs1 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs1 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs2 = errors;
                        if (typeof data0 !== 'string') {
                            validate13.errors = [
                                {
                                    instancePath: instancePath + '/status',
                                    schemaPath: '#/properties/status/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                },
                            ];
                            return false;
                        }
                        if (!(data0 === 'success')) {
                            validate13.errors = [
                                {
                                    instancePath: instancePath + '/status',
                                    schemaPath: '#/properties/status/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema15.properties.status.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                },
                            ];
                            return false;
                        }
                        var valid0 = _errs2 === errors;
                    } else {
                        var valid0 = true;
                    }
                    if (valid0) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs4 = errors;
                            const _errs5 = errors;
                            if (errors === _errs5) {
                                if (
                                    data1 &&
                                    typeof data1 == 'object' &&
                                    !Array.isArray(data1)
                                ) {
                                    let missing1;
                                    if (
                                        data1.breakout_uuid === undefined &&
                                        (missing1 = 'breakout_uuid')
                                    ) {
                                        validate13.errors = [
                                            {
                                                instancePath:
                                                    instancePath + '/result',
                                                schemaPath:
                                                    '#/definitions/breakouts/properties/200/definitions/Result/required',
                                                keyword: 'required',
                                                params: {
                                                    missingProperty: missing1,
                                                },
                                                message:
                                                    "must have required property '" +
                                                    missing1 +
                                                    "'",
                                            },
                                        ];
                                        return false;
                                    } else {
                                        const _errs7 = errors;
                                        for (const key1 in data1) {
                                            if (!(key1 === 'breakout_uuid')) {
                                                delete data1[key1];
                                            }
                                        }
                                        if (_errs7 === errors) {
                                            if (
                                                data1.breakout_uuid !==
                                                undefined
                                            ) {
                                                if (
                                                    typeof data1.breakout_uuid !==
                                                    'string'
                                                ) {
                                                    validate13.errors = [
                                                        {
                                                            instancePath:
                                                                instancePath +
                                                                '/result/breakout_uuid',
                                                            schemaPath:
                                                                '#/definitions/breakouts/properties/200/definitions/Result/properties/breakout_uuid/type',
                                                            keyword: 'type',
                                                            params: {
                                                                type: 'string',
                                                            },
                                                            message:
                                                                'must be string',
                                                        },
                                                    ];
                                                    return false;
                                                }
                                            }
                                        }
                                    }
                                } else {
                                    validate13.errors = [
                                        {
                                            instancePath:
                                                instancePath + '/result',
                                            schemaPath:
                                                '#/definitions/breakouts/properties/200/definitions/Result/type',
                                            keyword: 'type',
                                            params: {type: 'object'},
                                            message: 'must be object',
                                        },
                                    ];
                                    return false;
                                }
                            }
                            var valid0 = _errs4 === errors;
                        } else {
                            var valid0 = true;
                        }
                    }
                }
            }
        } else {
            validate13.errors = [
                {
                    instancePath,
                    schemaPath: '#/type',
                    keyword: 'type',
                    params: {type: 'object'},
                    message: 'must be object',
                },
            ];
            return false;
        }
    }
    validate13.errors = vErrors;
    return errors === 0;
}
function validate12(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    if (
        !validate13(data, {
            instancePath,
            parentData,
            parentDataProperty,
            rootData,
        })
    ) {
        vErrors =
            vErrors === null
                ? validate13.errors
                : vErrors.concat(validate13.errors);
        errors = vErrors.length;
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err0 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err0];
        } else {
            vErrors.push(err0);
        }
        errors++;
        validate12.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate12.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutsMessageBody = validate15;
const schema17 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        TopLevel: {
            type: 'object',
            additionalProperties: false,
            properties: {
                type: {
                    type: 'string',
                    enum: ['text/plain', 'application/json'],
                    description: 'The MIME Content-Type.',
                },
                payload: {type: 'string', description: 'Message to send.'},
            },
            required: ['type', 'payload'],
            title: 'TopLevel',
        },
    },
    oneOf: [
        {
            $ref: '#/definitions/breakouts_message/properties/Body/definitions/TopLevel',
        },
    ],
};
const schema18 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        type: {
            type: 'string',
            enum: ['text/plain', 'application/json'],
            description: 'The MIME Content-Type.',
        },
        payload: {type: 'string', description: 'Message to send.'},
    },
    required: ['type', 'payload'],
    title: 'TopLevel',
};
function validate15(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.type === undefined && (missing0 = 'type')) ||
                (data.payload === undefined && (missing0 = 'payload'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakouts_message/properties/Body/definitions/TopLevel/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'type' || key0 === 'payload')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.type !== undefined) {
                        let data0 = data.type;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/type',
                                schemaPath:
                                    '#/definitions/breakouts_message/properties/Body/definitions/TopLevel/properties/type/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (
                            !(
                                data0 === 'text/plain' ||
                                data0 === 'application/json'
                            )
                        ) {
                            const err2 = {
                                instancePath: instancePath + '/type',
                                schemaPath:
                                    '#/definitions/breakouts_message/properties/Body/definitions/TopLevel/properties/type/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema18.properties.type.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.payload !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.payload !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/payload',
                                    schemaPath:
                                        '#/definitions/breakouts_message/properties/Body/definitions/TopLevel/properties/payload/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakouts_message/properties/Body/definitions/TopLevel/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate15.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate15.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutsMessage200 = validate16;
const schema19 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        '200': {
            type: 'object',
            additionalProperties: false,
            properties: {
                status: {
                    type: 'string',
                    description: 'Status of the response, eg. success.',
                    enum: ['success'],
                },
                result: {
                    type: 'boolean',
                    description:
                        'The result is true if successful, false otherwise.',
                },
            },
            required: ['status', 'result'],
            title: 'Status200',
        },
    },
    oneOf: [
        {$ref: '#/definitions/breakout_ack/properties/200/definitions/200'},
    ],
};
const schema20 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        status: {
            type: 'string',
            description: 'Status of the response, eg. success.',
            enum: ['success'],
        },
        result: {
            type: 'boolean',
            description: 'The result is true if successful, false otherwise.',
        },
    },
    required: ['status', 'result'],
    title: 'Status200',
};
function validate16(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema20.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate16.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate16.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutsDisconnect200 = validate17;
const schema21 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        '200': {
            type: 'object',
            additionalProperties: false,
            properties: {
                status: {
                    type: 'string',
                    description: 'Status of the response, eg. success.',
                    enum: ['success'],
                },
                result: {type: 'null'},
            },
            required: ['status', 'result'],
            title: 'Status200',
        },
    },
    oneOf: [
        {
            $ref: '#/definitions/breakouts_disconnect/properties/200/definitions/200',
        },
    ],
};
const schema22 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        status: {
            type: 'string',
            description: 'Status of the response, eg. success.',
            enum: ['success'],
        },
        result: {type: 'null'},
    },
    required: ['status', 'result'],
    title: 'Status200',
};
function validate17(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakouts_disconnect/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakouts_disconnect/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakouts_disconnect/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema22.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (data.result !== null) {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakouts_disconnect/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'null'},
                                    message: 'must be null',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakouts_disconnect/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate17.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate17.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutsEmpty200 = validate18;
function validate18(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema20.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate18.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate18.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutDisconnect200 = validate19;
function validate19(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema20.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate19.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate19.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutTransformLayoutBody = validate20;
const schema27 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        TopLevel: {
            type: 'object',
            additionalProperties: false,
            properties: {
                transforms: {
                    $ref: '#/definitions/breakout_transform_layout/properties/Body/definitions/Transforms',
                },
            },
            required: ['transforms'],
            title: 'TopLevel',
        },
        Transforms: {
            type: 'object',
            additionalProperties: false,
            properties: {
                layout: {
                    type: 'string',
                    enum: [
                        '1:0',
                        '1:7',
                        '1:21',
                        '2:21',
                        '1:33',
                        '4:0',
                        '9:0',
                        '16:0',
                        '25:0',
                        '5:7',
                        'ac_presentation_in_mix',
                        'ac_presentation_in_mix_group',
                    ],
                    description:
                        'In VMRs the layout for Hosts and Guests is controlled by the layout parameter.',
                },
                host_layout: {
                    type: 'string',
                    enum: [
                        '1:0',
                        '1:7',
                        '1:21',
                        '2:21',
                        '1:33',
                        '4:0',
                        '9:0',
                        '16:0',
                        '25:0',
                        '5:7',
                        'ac_presentation_in_mix',
                        'ac_presentation_in_mix_group',
                    ],
                    description:
                        'In Virtual Auditoriums the Host layout is controlled by the host_layout parameter',
                },
                guest_layout: {
                    type: 'string',
                    enum: [
                        '1:0',
                        '1:7',
                        '1:21',
                        '2:21',
                        '1:33',
                        '4:0',
                        '9:0',
                        '16:0',
                        '25:0',
                        '5:7',
                        'ac_presentation_in_mix',
                        'ac_presentation_in_mix_group',
                    ],
                    description:
                        'In Virtual Auditoriums the Guest layout is controlled by the guest_layout parameter',
                },
                enable_extended_ac: {
                    type: 'boolean',
                    description:
                        'This enables an extended Adaptive Composition (AC) layout that can contain more video participants than the standard AC layout.',
                },
                streaming_indicator: {
                    type: 'boolean',
                    description:
                        'Determines whether the streaming indicator icon is disabled (false) or enabled (true).',
                },
                recording_indicator: {
                    type: 'boolean',
                    description:
                        'Determines whether the recording indicator icon is disabled (false) or enabled (true).',
                },
                enable_active_speaker_indication: {
                    type: 'boolean',
                    description:
                        'Determines whether active speaker indication is disabled (false) or enabled (true).',
                },
                enable_overlay_text: {
                    type: 'boolean',
                    description:
                        'Determines whether active speaker indication is disabled (false) or enabled (true).',
                },
                streaming: {
                    $ref: '#/definitions/breakout_transform_layout/properties/Body/definitions/Streaming',
                },
                free_form_overlay_text: {
                    type: 'array',
                    description:
                        'Allows you to customize (override) the overlay text. It contains a list of strings to apply to each participant in the order in which the participants are displayed.',
                    items: {type: 'string'},
                },
            },
            required: [],
            title: 'Transforms',
        },
        Streaming: {
            type: 'object',
            additionalProperties: false,
            properties: {
                layout: {
                    type: 'string',
                    enum: [
                        '1:0',
                        '1:7',
                        '1:21',
                        '2:21',
                        '1:33',
                        '4:0',
                        '9:0',
                        '16:0',
                        '25:0',
                    ],
                    description:
                        'Sets the layout seen by the streaming participant (regardless of Host or Guest role).',
                },
                waiting_screen_enabled: {
                    type: 'boolean',
                    default: false,
                    description:
                        'Determines whether the stream_waiting splash screen is displayed (true) or not (false).',
                },
                plus_n_pip_enabled: {
                    type: 'boolean',
                    default: true,
                    description:
                        'Controls whether the `plus n indicator` i.e. the number of participants present in the conference, is displayed (true) or not (false).',
                },
                indicators_enabled: {
                    type: 'boolean',
                    default: true,
                    description:
                        'Controls whether the in-conference indicators (audio participant indicators, conference locked indicators etc.) are displayed (true) or not (false).',
                },
            },
            required: [],
            title: 'Streaming',
        },
    },
    oneOf: [
        {
            $ref: '#/definitions/breakout_transform_layout/properties/Body/definitions/TopLevel',
        },
    ],
};
const schema28 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        transforms: {
            $ref: '#/definitions/breakout_transform_layout/properties/Body/definitions/Transforms',
        },
    },
    required: ['transforms'],
    title: 'TopLevel',
};
const schema29 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        layout: {
            type: 'string',
            enum: [
                '1:0',
                '1:7',
                '1:21',
                '2:21',
                '1:33',
                '4:0',
                '9:0',
                '16:0',
                '25:0',
                '5:7',
                'ac_presentation_in_mix',
                'ac_presentation_in_mix_group',
            ],
            description:
                'In VMRs the layout for Hosts and Guests is controlled by the layout parameter.',
        },
        host_layout: {
            type: 'string',
            enum: [
                '1:0',
                '1:7',
                '1:21',
                '2:21',
                '1:33',
                '4:0',
                '9:0',
                '16:0',
                '25:0',
                '5:7',
                'ac_presentation_in_mix',
                'ac_presentation_in_mix_group',
            ],
            description:
                'In Virtual Auditoriums the Host layout is controlled by the host_layout parameter',
        },
        guest_layout: {
            type: 'string',
            enum: [
                '1:0',
                '1:7',
                '1:21',
                '2:21',
                '1:33',
                '4:0',
                '9:0',
                '16:0',
                '25:0',
                '5:7',
                'ac_presentation_in_mix',
                'ac_presentation_in_mix_group',
            ],
            description:
                'In Virtual Auditoriums the Guest layout is controlled by the guest_layout parameter',
        },
        enable_extended_ac: {
            type: 'boolean',
            description:
                'This enables an extended Adaptive Composition (AC) layout that can contain more video participants than the standard AC layout.',
        },
        streaming_indicator: {
            type: 'boolean',
            description:
                'Determines whether the streaming indicator icon is disabled (false) or enabled (true).',
        },
        recording_indicator: {
            type: 'boolean',
            description:
                'Determines whether the recording indicator icon is disabled (false) or enabled (true).',
        },
        enable_active_speaker_indication: {
            type: 'boolean',
            description:
                'Determines whether active speaker indication is disabled (false) or enabled (true).',
        },
        enable_overlay_text: {
            type: 'boolean',
            description:
                'Determines whether active speaker indication is disabled (false) or enabled (true).',
        },
        streaming: {
            $ref: '#/definitions/breakout_transform_layout/properties/Body/definitions/Streaming',
        },
        free_form_overlay_text: {
            type: 'array',
            description:
                'Allows you to customize (override) the overlay text. It contains a list of strings to apply to each participant in the order in which the participants are displayed.',
            items: {type: 'string'},
        },
    },
    required: [],
    title: 'Transforms',
};
const schema30 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        layout: {
            type: 'string',
            enum: [
                '1:0',
                '1:7',
                '1:21',
                '2:21',
                '1:33',
                '4:0',
                '9:0',
                '16:0',
                '25:0',
            ],
            description:
                'Sets the layout seen by the streaming participant (regardless of Host or Guest role).',
        },
        waiting_screen_enabled: {
            type: 'boolean',
            default: false,
            description:
                'Determines whether the stream_waiting splash screen is displayed (true) or not (false).',
        },
        plus_n_pip_enabled: {
            type: 'boolean',
            default: true,
            description:
                'Controls whether the `plus n indicator` i.e. the number of participants present in the conference, is displayed (true) or not (false).',
        },
        indicators_enabled: {
            type: 'boolean',
            default: true,
            description:
                'Controls whether the in-conference indicators (audio participant indicators, conference locked indicators etc.) are displayed (true) or not (false).',
        },
    },
    required: [],
    title: 'Streaming',
};
const func2 = Object.prototype.hasOwnProperty;
function validate22(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (errors === 0) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            const _errs1 = errors;
            for (const key0 in data) {
                if (!func2.call(schema29.properties, key0)) {
                    delete data[key0];
                }
            }
            if (_errs1 === errors) {
                if (data.layout !== undefined) {
                    let data0 = data.layout;
                    const _errs2 = errors;
                    if (typeof data0 !== 'string') {
                        validate22.errors = [
                            {
                                instancePath: instancePath + '/layout',
                                schemaPath: '#/properties/layout/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            },
                        ];
                        return false;
                    }
                    if (
                        !(
                            data0 === '1:0' ||
                            data0 === '1:7' ||
                            data0 === '1:21' ||
                            data0 === '2:21' ||
                            data0 === '1:33' ||
                            data0 === '4:0' ||
                            data0 === '9:0' ||
                            data0 === '16:0' ||
                            data0 === '25:0' ||
                            data0 === '5:7' ||
                            data0 === 'ac_presentation_in_mix' ||
                            data0 === 'ac_presentation_in_mix_group'
                        )
                    ) {
                        validate22.errors = [
                            {
                                instancePath: instancePath + '/layout',
                                schemaPath: '#/properties/layout/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema29.properties.layout.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            },
                        ];
                        return false;
                    }
                    var valid0 = _errs2 === errors;
                } else {
                    var valid0 = true;
                }
                if (valid0) {
                    if (data.host_layout !== undefined) {
                        let data1 = data.host_layout;
                        const _errs4 = errors;
                        if (typeof data1 !== 'string') {
                            validate22.errors = [
                                {
                                    instancePath: instancePath + '/host_layout',
                                    schemaPath: '#/properties/host_layout/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                },
                            ];
                            return false;
                        }
                        if (
                            !(
                                data1 === '1:0' ||
                                data1 === '1:7' ||
                                data1 === '1:21' ||
                                data1 === '2:21' ||
                                data1 === '1:33' ||
                                data1 === '4:0' ||
                                data1 === '9:0' ||
                                data1 === '16:0' ||
                                data1 === '25:0' ||
                                data1 === '5:7' ||
                                data1 === 'ac_presentation_in_mix' ||
                                data1 === 'ac_presentation_in_mix_group'
                            )
                        ) {
                            validate22.errors = [
                                {
                                    instancePath: instancePath + '/host_layout',
                                    schemaPath: '#/properties/host_layout/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema29.properties.host_layout
                                                .enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                },
                            ];
                            return false;
                        }
                        var valid0 = _errs4 === errors;
                    } else {
                        var valid0 = true;
                    }
                    if (valid0) {
                        if (data.guest_layout !== undefined) {
                            let data2 = data.guest_layout;
                            const _errs6 = errors;
                            if (typeof data2 !== 'string') {
                                validate22.errors = [
                                    {
                                        instancePath:
                                            instancePath + '/guest_layout',
                                        schemaPath:
                                            '#/properties/guest_layout/type',
                                        keyword: 'type',
                                        params: {type: 'string'},
                                        message: 'must be string',
                                    },
                                ];
                                return false;
                            }
                            if (
                                !(
                                    data2 === '1:0' ||
                                    data2 === '1:7' ||
                                    data2 === '1:21' ||
                                    data2 === '2:21' ||
                                    data2 === '1:33' ||
                                    data2 === '4:0' ||
                                    data2 === '9:0' ||
                                    data2 === '16:0' ||
                                    data2 === '25:0' ||
                                    data2 === '5:7' ||
                                    data2 === 'ac_presentation_in_mix' ||
                                    data2 === 'ac_presentation_in_mix_group'
                                )
                            ) {
                                validate22.errors = [
                                    {
                                        instancePath:
                                            instancePath + '/guest_layout',
                                        schemaPath:
                                            '#/properties/guest_layout/enum',
                                        keyword: 'enum',
                                        params: {
                                            allowedValues:
                                                schema29.properties.guest_layout
                                                    .enum,
                                        },
                                        message:
                                            'must be equal to one of the allowed values',
                                    },
                                ];
                                return false;
                            }
                            var valid0 = _errs6 === errors;
                        } else {
                            var valid0 = true;
                        }
                        if (valid0) {
                            if (data.enable_extended_ac !== undefined) {
                                const _errs8 = errors;
                                if (
                                    typeof data.enable_extended_ac !== 'boolean'
                                ) {
                                    validate22.errors = [
                                        {
                                            instancePath:
                                                instancePath +
                                                '/enable_extended_ac',
                                            schemaPath:
                                                '#/properties/enable_extended_ac/type',
                                            keyword: 'type',
                                            params: {type: 'boolean'},
                                            message: 'must be boolean',
                                        },
                                    ];
                                    return false;
                                }
                                var valid0 = _errs8 === errors;
                            } else {
                                var valid0 = true;
                            }
                            if (valid0) {
                                if (data.streaming_indicator !== undefined) {
                                    const _errs10 = errors;
                                    if (
                                        typeof data.streaming_indicator !==
                                        'boolean'
                                    ) {
                                        validate22.errors = [
                                            {
                                                instancePath:
                                                    instancePath +
                                                    '/streaming_indicator',
                                                schemaPath:
                                                    '#/properties/streaming_indicator/type',
                                                keyword: 'type',
                                                params: {type: 'boolean'},
                                                message: 'must be boolean',
                                            },
                                        ];
                                        return false;
                                    }
                                    var valid0 = _errs10 === errors;
                                } else {
                                    var valid0 = true;
                                }
                                if (valid0) {
                                    if (
                                        data.recording_indicator !== undefined
                                    ) {
                                        const _errs12 = errors;
                                        if (
                                            typeof data.recording_indicator !==
                                            'boolean'
                                        ) {
                                            validate22.errors = [
                                                {
                                                    instancePath:
                                                        instancePath +
                                                        '/recording_indicator',
                                                    schemaPath:
                                                        '#/properties/recording_indicator/type',
                                                    keyword: 'type',
                                                    params: {type: 'boolean'},
                                                    message: 'must be boolean',
                                                },
                                            ];
                                            return false;
                                        }
                                        var valid0 = _errs12 === errors;
                                    } else {
                                        var valid0 = true;
                                    }
                                    if (valid0) {
                                        if (
                                            data.enable_active_speaker_indication !==
                                            undefined
                                        ) {
                                            const _errs14 = errors;
                                            if (
                                                typeof data.enable_active_speaker_indication !==
                                                'boolean'
                                            ) {
                                                validate22.errors = [
                                                    {
                                                        instancePath:
                                                            instancePath +
                                                            '/enable_active_speaker_indication',
                                                        schemaPath:
                                                            '#/properties/enable_active_speaker_indication/type',
                                                        keyword: 'type',
                                                        params: {
                                                            type: 'boolean',
                                                        },
                                                        message:
                                                            'must be boolean',
                                                    },
                                                ];
                                                return false;
                                            }
                                            var valid0 = _errs14 === errors;
                                        } else {
                                            var valid0 = true;
                                        }
                                        if (valid0) {
                                            if (
                                                data.enable_overlay_text !==
                                                undefined
                                            ) {
                                                const _errs16 = errors;
                                                if (
                                                    typeof data.enable_overlay_text !==
                                                    'boolean'
                                                ) {
                                                    validate22.errors = [
                                                        {
                                                            instancePath:
                                                                instancePath +
                                                                '/enable_overlay_text',
                                                            schemaPath:
                                                                '#/properties/enable_overlay_text/type',
                                                            keyword: 'type',
                                                            params: {
                                                                type: 'boolean',
                                                            },
                                                            message:
                                                                'must be boolean',
                                                        },
                                                    ];
                                                    return false;
                                                }
                                                var valid0 = _errs16 === errors;
                                            } else {
                                                var valid0 = true;
                                            }
                                            if (valid0) {
                                                if (
                                                    data.streaming !== undefined
                                                ) {
                                                    let data8 = data.streaming;
                                                    const _errs18 = errors;
                                                    const _errs19 = errors;
                                                    if (errors === _errs19) {
                                                        if (
                                                            data8 &&
                                                            typeof data8 ==
                                                                'object' &&
                                                            !Array.isArray(
                                                                data8,
                                                            )
                                                        ) {
                                                            const _errs21 =
                                                                errors;
                                                            for (const key1 in data8) {
                                                                if (
                                                                    !(
                                                                        key1 ===
                                                                            'layout' ||
                                                                        key1 ===
                                                                            'waiting_screen_enabled' ||
                                                                        key1 ===
                                                                            'plus_n_pip_enabled' ||
                                                                        key1 ===
                                                                            'indicators_enabled'
                                                                    )
                                                                ) {
                                                                    delete data8[
                                                                        key1
                                                                    ];
                                                                }
                                                            }
                                                            if (
                                                                _errs21 ===
                                                                errors
                                                            ) {
                                                                if (
                                                                    data8.layout !==
                                                                    undefined
                                                                ) {
                                                                    let data9 =
                                                                        data8.layout;
                                                                    const _errs22 =
                                                                        errors;
                                                                    if (
                                                                        typeof data9 !==
                                                                        'string'
                                                                    ) {
                                                                        validate22.errors =
                                                                            [
                                                                                {
                                                                                    instancePath:
                                                                                        instancePath +
                                                                                        '/streaming/layout',
                                                                                    schemaPath:
                                                                                        '#/definitions/breakout_transform_layout/properties/Body/definitions/Streaming/properties/layout/type',
                                                                                    keyword:
                                                                                        'type',
                                                                                    params: {
                                                                                        type: 'string',
                                                                                    },
                                                                                    message:
                                                                                        'must be string',
                                                                                },
                                                                            ];
                                                                        return false;
                                                                    }
                                                                    if (
                                                                        !(
                                                                            data9 ===
                                                                                '1:0' ||
                                                                            data9 ===
                                                                                '1:7' ||
                                                                            data9 ===
                                                                                '1:21' ||
                                                                            data9 ===
                                                                                '2:21' ||
                                                                            data9 ===
                                                                                '1:33' ||
                                                                            data9 ===
                                                                                '4:0' ||
                                                                            data9 ===
                                                                                '9:0' ||
                                                                            data9 ===
                                                                                '16:0' ||
                                                                            data9 ===
                                                                                '25:0'
                                                                        )
                                                                    ) {
                                                                        validate22.errors =
                                                                            [
                                                                                {
                                                                                    instancePath:
                                                                                        instancePath +
                                                                                        '/streaming/layout',
                                                                                    schemaPath:
                                                                                        '#/definitions/breakout_transform_layout/properties/Body/definitions/Streaming/properties/layout/enum',
                                                                                    keyword:
                                                                                        'enum',
                                                                                    params: {
                                                                                        allowedValues:
                                                                                            schema30
                                                                                                .properties
                                                                                                .layout
                                                                                                .enum,
                                                                                    },
                                                                                    message:
                                                                                        'must be equal to one of the allowed values',
                                                                                },
                                                                            ];
                                                                        return false;
                                                                    }
                                                                    var valid2 =
                                                                        _errs22 ===
                                                                        errors;
                                                                } else {
                                                                    var valid2 =
                                                                        true;
                                                                }
                                                                if (valid2) {
                                                                    if (
                                                                        data8.waiting_screen_enabled !==
                                                                        undefined
                                                                    ) {
                                                                        const _errs24 =
                                                                            errors;
                                                                        if (
                                                                            typeof data8.waiting_screen_enabled !==
                                                                            'boolean'
                                                                        ) {
                                                                            validate22.errors =
                                                                                [
                                                                                    {
                                                                                        instancePath:
                                                                                            instancePath +
                                                                                            '/streaming/waiting_screen_enabled',
                                                                                        schemaPath:
                                                                                            '#/definitions/breakout_transform_layout/properties/Body/definitions/Streaming/properties/waiting_screen_enabled/type',
                                                                                        keyword:
                                                                                            'type',
                                                                                        params: {
                                                                                            type: 'boolean',
                                                                                        },
                                                                                        message:
                                                                                            'must be boolean',
                                                                                    },
                                                                                ];
                                                                            return false;
                                                                        }
                                                                        var valid2 =
                                                                            _errs24 ===
                                                                            errors;
                                                                    } else {
                                                                        var valid2 =
                                                                            true;
                                                                    }
                                                                    if (
                                                                        valid2
                                                                    ) {
                                                                        if (
                                                                            data8.plus_n_pip_enabled !==
                                                                            undefined
                                                                        ) {
                                                                            const _errs26 =
                                                                                errors;
                                                                            if (
                                                                                typeof data8.plus_n_pip_enabled !==
                                                                                'boolean'
                                                                            ) {
                                                                                validate22.errors =
                                                                                    [
                                                                                        {
                                                                                            instancePath:
                                                                                                instancePath +
                                                                                                '/streaming/plus_n_pip_enabled',
                                                                                            schemaPath:
                                                                                                '#/definitions/breakout_transform_layout/properties/Body/definitions/Streaming/properties/plus_n_pip_enabled/type',
                                                                                            keyword:
                                                                                                'type',
                                                                                            params: {
                                                                                                type: 'boolean',
                                                                                            },
                                                                                            message:
                                                                                                'must be boolean',
                                                                                        },
                                                                                    ];
                                                                                return false;
                                                                            }
                                                                            var valid2 =
                                                                                _errs26 ===
                                                                                errors;
                                                                        } else {
                                                                            var valid2 =
                                                                                true;
                                                                        }
                                                                        if (
                                                                            valid2
                                                                        ) {
                                                                            if (
                                                                                data8.indicators_enabled !==
                                                                                undefined
                                                                            ) {
                                                                                const _errs28 =
                                                                                    errors;
                                                                                if (
                                                                                    typeof data8.indicators_enabled !==
                                                                                    'boolean'
                                                                                ) {
                                                                                    validate22.errors =
                                                                                        [
                                                                                            {
                                                                                                instancePath:
                                                                                                    instancePath +
                                                                                                    '/streaming/indicators_enabled',
                                                                                                schemaPath:
                                                                                                    '#/definitions/breakout_transform_layout/properties/Body/definitions/Streaming/properties/indicators_enabled/type',
                                                                                                keyword:
                                                                                                    'type',
                                                                                                params: {
                                                                                                    type: 'boolean',
                                                                                                },
                                                                                                message:
                                                                                                    'must be boolean',
                                                                                            },
                                                                                        ];
                                                                                    return false;
                                                                                }
                                                                                var valid2 =
                                                                                    _errs28 ===
                                                                                    errors;
                                                                            } else {
                                                                                var valid2 =
                                                                                    true;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        } else {
                                                            validate22.errors =
                                                                [
                                                                    {
                                                                        instancePath:
                                                                            instancePath +
                                                                            '/streaming',
                                                                        schemaPath:
                                                                            '#/definitions/breakout_transform_layout/properties/Body/definitions/Streaming/type',
                                                                        keyword:
                                                                            'type',
                                                                        params: {
                                                                            type: 'object',
                                                                        },
                                                                        message:
                                                                            'must be object',
                                                                    },
                                                                ];
                                                            return false;
                                                        }
                                                    }
                                                    var valid0 =
                                                        _errs18 === errors;
                                                } else {
                                                    var valid0 = true;
                                                }
                                                if (valid0) {
                                                    if (
                                                        data.free_form_overlay_text !==
                                                        undefined
                                                    ) {
                                                        let data13 =
                                                            data.free_form_overlay_text;
                                                        const _errs30 = errors;
                                                        if (
                                                            errors === _errs30
                                                        ) {
                                                            if (
                                                                Array.isArray(
                                                                    data13,
                                                                )
                                                            ) {
                                                                var valid3 =
                                                                    true;
                                                                const len0 =
                                                                    data13.length;
                                                                for (
                                                                    let i0 = 0;
                                                                    i0 < len0;
                                                                    i0++
                                                                ) {
                                                                    const _errs32 =
                                                                        errors;
                                                                    if (
                                                                        typeof data13[
                                                                            i0
                                                                        ] !==
                                                                        'string'
                                                                    ) {
                                                                        validate22.errors =
                                                                            [
                                                                                {
                                                                                    instancePath:
                                                                                        instancePath +
                                                                                        '/free_form_overlay_text/' +
                                                                                        i0,
                                                                                    schemaPath:
                                                                                        '#/properties/free_form_overlay_text/items/type',
                                                                                    keyword:
                                                                                        'type',
                                                                                    params: {
                                                                                        type: 'string',
                                                                                    },
                                                                                    message:
                                                                                        'must be string',
                                                                                },
                                                                            ];
                                                                        return false;
                                                                    }
                                                                    var valid3 =
                                                                        _errs32 ===
                                                                        errors;
                                                                    if (
                                                                        !valid3
                                                                    ) {
                                                                        break;
                                                                    }
                                                                }
                                                            } else {
                                                                validate22.errors =
                                                                    [
                                                                        {
                                                                            instancePath:
                                                                                instancePath +
                                                                                '/free_form_overlay_text',
                                                                            schemaPath:
                                                                                '#/properties/free_form_overlay_text/type',
                                                                            keyword:
                                                                                'type',
                                                                            params: {
                                                                                type: 'array',
                                                                            },
                                                                            message:
                                                                                'must be array',
                                                                        },
                                                                    ];
                                                                return false;
                                                            }
                                                        }
                                                        var valid0 =
                                                            _errs30 === errors;
                                                    } else {
                                                        var valid0 = true;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        } else {
            validate22.errors = [
                {
                    instancePath,
                    schemaPath: '#/type',
                    keyword: 'type',
                    params: {type: 'object'},
                    message: 'must be object',
                },
            ];
            return false;
        }
    }
    validate22.errors = vErrors;
    return errors === 0;
}
function validate21(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (errors === 0) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (data.transforms === undefined && (missing0 = 'transforms')) {
                validate21.errors = [
                    {
                        instancePath,
                        schemaPath: '#/required',
                        keyword: 'required',
                        params: {missingProperty: missing0},
                        message:
                            "must have required property '" + missing0 + "'",
                    },
                ];
                return false;
            } else {
                const _errs1 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'transforms')) {
                        delete data[key0];
                    }
                }
                if (_errs1 === errors) {
                    if (data.transforms !== undefined) {
                        if (
                            !validate22(data.transforms, {
                                instancePath: instancePath + '/transforms',
                                parentData: data,
                                parentDataProperty: 'transforms',
                                rootData,
                            })
                        ) {
                            vErrors =
                                vErrors === null
                                    ? validate22.errors
                                    : vErrors.concat(validate22.errors);
                            errors = vErrors.length;
                        }
                    }
                }
            }
        } else {
            validate21.errors = [
                {
                    instancePath,
                    schemaPath: '#/type',
                    keyword: 'type',
                    params: {type: 'object'},
                    message: 'must be object',
                },
            ];
            return false;
        }
    }
    validate21.errors = vErrors;
    return errors === 0;
}
function validate20(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    if (
        !validate21(data, {
            instancePath,
            parentData,
            parentDataProperty,
            rootData,
        })
    ) {
        vErrors =
            vErrors === null
                ? validate21.errors
                : vErrors.concat(validate21.errors);
        errors = vErrors.length;
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err0 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err0];
        } else {
            vErrors.push(err0);
        }
        errors++;
        validate20.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate20.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutTransformLayout200 = validate25;
function validate25(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema20.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate25.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate25.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutMuteguests200 = validate26;
function validate26(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema20.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate26.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate26.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutUnmuteguests200 = validate27;
function validate27(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema20.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate27.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate27.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutMoveParticipantsFromMainBody = validate28;
const schema37 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        TopLevel: {
            type: 'object',
            additionalProperties: false,
            properties: {
                breakout_uuid: {
                    type: 'string',
                    description:
                        'The breakout room you send the participants to. If the string is empty, the participants will be sent to the main room.',
                },
                participants: {
                    type: 'array',
                    description:
                        'The participants to move from the main room or breakout room.',
                    items: {type: 'string'},
                },
            },
            required: ['breakout_uuid', 'participants'],
            title: 'TopLevel',
        },
    },
    oneOf: [
        {
            $ref: '#/definitions/breakout_move_participants/properties/Body/definitions/TopLevel',
        },
    ],
};
const schema38 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        breakout_uuid: {
            type: 'string',
            description:
                'The breakout room you send the participants to. If the string is empty, the participants will be sent to the main room.',
        },
        participants: {
            type: 'array',
            description:
                'The participants to move from the main room or breakout room.',
            items: {type: 'string'},
        },
    },
    required: ['breakout_uuid', 'participants'],
    title: 'TopLevel',
};
function validate28(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.breakout_uuid === undefined &&
                    (missing0 = 'breakout_uuid')) ||
                (data.participants === undefined && (missing0 = 'participants'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_move_participants/properties/Body/definitions/TopLevel/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (
                        !(key0 === 'breakout_uuid' || key0 === 'participants')
                    ) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.breakout_uuid !== undefined) {
                        const _errs5 = errors;
                        if (typeof data.breakout_uuid !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/breakout_uuid',
                                schemaPath:
                                    '#/definitions/breakout_move_participants/properties/Body/definitions/TopLevel/properties/breakout_uuid/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.participants !== undefined) {
                            let data1 = data.participants;
                            const _errs7 = errors;
                            if (errors === _errs7) {
                                if (Array.isArray(data1)) {
                                    var valid3 = true;
                                    const len0 = data1.length;
                                    for (let i0 = 0; i0 < len0; i0++) {
                                        const _errs9 = errors;
                                        if (typeof data1[i0] !== 'string') {
                                            const err2 = {
                                                instancePath:
                                                    instancePath +
                                                    '/participants/' +
                                                    i0,
                                                schemaPath:
                                                    '#/definitions/breakout_move_participants/properties/Body/definitions/TopLevel/properties/participants/items/type',
                                                keyword: 'type',
                                                params: {type: 'string'},
                                                message: 'must be string',
                                            };
                                            if (vErrors === null) {
                                                vErrors = [err2];
                                            } else {
                                                vErrors.push(err2);
                                            }
                                            errors++;
                                        }
                                        var valid3 = _errs9 === errors;
                                        if (!valid3) {
                                            break;
                                        }
                                    }
                                } else {
                                    const err3 = {
                                        instancePath:
                                            instancePath + '/participants',
                                        schemaPath:
                                            '#/definitions/breakout_move_participants/properties/Body/definitions/TopLevel/properties/participants/type',
                                        keyword: 'type',
                                        params: {type: 'array'},
                                        message: 'must be array',
                                    };
                                    if (vErrors === null) {
                                        vErrors = [err3];
                                    } else {
                                        vErrors.push(err3);
                                    }
                                    errors++;
                                }
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_move_participants/properties/Body/definitions/TopLevel/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate28.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate28.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutMoveParticipantsFromMain200 = validate29;
function validate29(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema20.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate29.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate29.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutMoveParticipantsFromMain403 = validate30;
const schema41 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        '403': {
            type: 'object',
            additionalProperties: false,
            properties: {
                status: {
                    type: 'string',
                    description: 'Status of the response, eg. success.',
                    enum: ['success', 'failed'],
                },
                result: {
                    type: 'string',
                    enum: [
                        '404 Not Found',
                        '502 Bad Gateway',
                        '503 Service Unavailable',
                        'All conference hosts departed hosted conference',
                        'API initiated conference termination',
                        'API initiated participant disconnect',
                        'Call disconnected',
                        'Call rejected',
                        'Conference extension not found',
                        'Conference host ended the conference with a DTMF command',
                        'Conference terminated by an administrator',
                        'Conference terminated by another participant',
                        'Disabled',
                        'Disconnected by an administrator',
                        'Disconnected by another participant',
                        'Failed to forward request',
                        'Gateway dial out failed',
                        'Ice failure',
                        'Insufficient licenses',
                        'invalid gateway routing rule transform',
                        'Invalid license',
                        'Invalid PIN',
                        'Invalid protocol for service',
                        'Invalid role',
                        'Invalid token',
                        'Last remaining participant removed from conference after timeout',
                        'Media node disconnected',
                        'Media process disconnected',
                        'Neither conference nor gateway found',
                        'No direct route between Edge and Transcoding',
                        'No participants can keep conference alive',
                        'Out of proxying resource',
                        'Out of resource',
                        'Out of transcoding resource',
                        'Participant exceeded PIN entry retries',
                        'Participant limit reached',
                        'Proxied participant disconnected',
                        'Resource unavailable',
                        'Signaling node disconnected',
                        'SSO Authentication Failed. SSO is not available from this domain',
                        'SSO Authentication Failed. The system is in Maintenance mode',
                        'SSO Authentication Failed',
                        'SSO enabled but no Identity Providers configured',
                        'System in maintenance mode',
                        'Telehealth Call failure',
                        'Test call finished',
                        'Timeout waiting for conference host to join or permit access to locked conference',
                        'Timer expired awaiting token refresh',
                        'transfer failed',
                        'Unexpected Response: 503',
                        'Attempted to acquire license while license already acquired. This is usually due to an internal error.',
                        'Failed to transfer into a multi-party conference',
                        'Failed to transfer into a one-to-one conference',
                    ],
                    description: 'Error strings',
                },
            },
            required: ['status', 'result'],
            title: 'Status403',
        },
    },
    oneOf: [
        {$ref: '#/definitions/breakout_ack/properties/403/definitions/403'},
    ],
};
const schema42 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        status: {
            type: 'string',
            description: 'Status of the response, eg. success.',
            enum: ['success', 'failed'],
        },
        result: {
            type: 'string',
            enum: [
                '404 Not Found',
                '502 Bad Gateway',
                '503 Service Unavailable',
                'All conference hosts departed hosted conference',
                'API initiated conference termination',
                'API initiated participant disconnect',
                'Call disconnected',
                'Call rejected',
                'Conference extension not found',
                'Conference host ended the conference with a DTMF command',
                'Conference terminated by an administrator',
                'Conference terminated by another participant',
                'Disabled',
                'Disconnected by an administrator',
                'Disconnected by another participant',
                'Failed to forward request',
                'Gateway dial out failed',
                'Ice failure',
                'Insufficient licenses',
                'invalid gateway routing rule transform',
                'Invalid license',
                'Invalid PIN',
                'Invalid protocol for service',
                'Invalid role',
                'Invalid token',
                'Last remaining participant removed from conference after timeout',
                'Media node disconnected',
                'Media process disconnected',
                'Neither conference nor gateway found',
                'No direct route between Edge and Transcoding',
                'No participants can keep conference alive',
                'Out of proxying resource',
                'Out of resource',
                'Out of transcoding resource',
                'Participant exceeded PIN entry retries',
                'Participant limit reached',
                'Proxied participant disconnected',
                'Resource unavailable',
                'Signaling node disconnected',
                'SSO Authentication Failed. SSO is not available from this domain',
                'SSO Authentication Failed. The system is in Maintenance mode',
                'SSO Authentication Failed',
                'SSO enabled but no Identity Providers configured',
                'System in maintenance mode',
                'Telehealth Call failure',
                'Test call finished',
                'Timeout waiting for conference host to join or permit access to locked conference',
                'Timer expired awaiting token refresh',
                'transfer failed',
                'Unexpected Response: 503',
                'Attempted to acquire license while license already acquired. This is usually due to an internal error.',
                'Failed to transfer into a multi-party conference',
                'Failed to transfer into a one-to-one conference',
            ],
            description: 'Error strings',
        },
    },
    required: ['status', 'result'],
    title: 'Status403',
};
function validate30(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema42.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs7 = errors;
                            if (typeof data1 !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            if (
                                !(
                                    data1 === '404 Not Found' ||
                                    data1 === '502 Bad Gateway' ||
                                    data1 === '503 Service Unavailable' ||
                                    data1 ===
                                        'All conference hosts departed hosted conference' ||
                                    data1 ===
                                        'API initiated conference termination' ||
                                    data1 ===
                                        'API initiated participant disconnect' ||
                                    data1 === 'Call disconnected' ||
                                    data1 === 'Call rejected' ||
                                    data1 ===
                                        'Conference extension not found' ||
                                    data1 ===
                                        'Conference host ended the conference with a DTMF command' ||
                                    data1 ===
                                        'Conference terminated by an administrator' ||
                                    data1 ===
                                        'Conference terminated by another participant' ||
                                    data1 === 'Disabled' ||
                                    data1 ===
                                        'Disconnected by an administrator' ||
                                    data1 ===
                                        'Disconnected by another participant' ||
                                    data1 === 'Failed to forward request' ||
                                    data1 === 'Gateway dial out failed' ||
                                    data1 === 'Ice failure' ||
                                    data1 === 'Insufficient licenses' ||
                                    data1 ===
                                        'invalid gateway routing rule transform' ||
                                    data1 === 'Invalid license' ||
                                    data1 === 'Invalid PIN' ||
                                    data1 === 'Invalid protocol for service' ||
                                    data1 === 'Invalid role' ||
                                    data1 === 'Invalid token' ||
                                    data1 ===
                                        'Last remaining participant removed from conference after timeout' ||
                                    data1 === 'Media node disconnected' ||
                                    data1 === 'Media process disconnected' ||
                                    data1 ===
                                        'Neither conference nor gateway found' ||
                                    data1 ===
                                        'No direct route between Edge and Transcoding' ||
                                    data1 ===
                                        'No participants can keep conference alive' ||
                                    data1 === 'Out of proxying resource' ||
                                    data1 === 'Out of resource' ||
                                    data1 === 'Out of transcoding resource' ||
                                    data1 ===
                                        'Participant exceeded PIN entry retries' ||
                                    data1 === 'Participant limit reached' ||
                                    data1 ===
                                        'Proxied participant disconnected' ||
                                    data1 === 'Resource unavailable' ||
                                    data1 === 'Signaling node disconnected' ||
                                    data1 ===
                                        'SSO Authentication Failed. SSO is not available from this domain' ||
                                    data1 ===
                                        'SSO Authentication Failed. The system is in Maintenance mode' ||
                                    data1 === 'SSO Authentication Failed' ||
                                    data1 ===
                                        'SSO enabled but no Identity Providers configured' ||
                                    data1 === 'System in maintenance mode' ||
                                    data1 === 'Telehealth Call failure' ||
                                    data1 === 'Test call finished' ||
                                    data1 ===
                                        'Timeout waiting for conference host to join or permit access to locked conference' ||
                                    data1 ===
                                        'Timer expired awaiting token refresh' ||
                                    data1 === 'transfer failed' ||
                                    data1 === 'Unexpected Response: 503' ||
                                    data1 ===
                                        'Attempted to acquire license while license already acquired. This is usually due to an internal error.' ||
                                    data1 ===
                                        'Failed to transfer into a multi-party conference' ||
                                    data1 ===
                                        'Failed to transfer into a one-to-one conference'
                                )
                            ) {
                                const err4 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/403/definitions/403/properties/result/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema42.properties.result.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                };
                                if (vErrors === null) {
                                    vErrors = [err4];
                                } else {
                                    vErrors.push(err4);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err5 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err5];
            } else {
                vErrors.push(err5);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err6 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err6];
        } else {
            vErrors.push(err6);
        }
        errors++;
        validate30.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate30.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutMoveParticipantsBody = validate31;
function validate31(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.breakout_uuid === undefined &&
                    (missing0 = 'breakout_uuid')) ||
                (data.participants === undefined && (missing0 = 'participants'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_move_participants/properties/Body/definitions/TopLevel/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (
                        !(key0 === 'breakout_uuid' || key0 === 'participants')
                    ) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.breakout_uuid !== undefined) {
                        const _errs5 = errors;
                        if (typeof data.breakout_uuid !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/breakout_uuid',
                                schemaPath:
                                    '#/definitions/breakout_move_participants/properties/Body/definitions/TopLevel/properties/breakout_uuid/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.participants !== undefined) {
                            let data1 = data.participants;
                            const _errs7 = errors;
                            if (errors === _errs7) {
                                if (Array.isArray(data1)) {
                                    var valid3 = true;
                                    const len0 = data1.length;
                                    for (let i0 = 0; i0 < len0; i0++) {
                                        const _errs9 = errors;
                                        if (typeof data1[i0] !== 'string') {
                                            const err2 = {
                                                instancePath:
                                                    instancePath +
                                                    '/participants/' +
                                                    i0,
                                                schemaPath:
                                                    '#/definitions/breakout_move_participants/properties/Body/definitions/TopLevel/properties/participants/items/type',
                                                keyword: 'type',
                                                params: {type: 'string'},
                                                message: 'must be string',
                                            };
                                            if (vErrors === null) {
                                                vErrors = [err2];
                                            } else {
                                                vErrors.push(err2);
                                            }
                                            errors++;
                                        }
                                        var valid3 = _errs9 === errors;
                                        if (!valid3) {
                                            break;
                                        }
                                    }
                                } else {
                                    const err3 = {
                                        instancePath:
                                            instancePath + '/participants',
                                        schemaPath:
                                            '#/definitions/breakout_move_participants/properties/Body/definitions/TopLevel/properties/participants/type',
                                        keyword: 'type',
                                        params: {type: 'array'},
                                        message: 'must be array',
                                    };
                                    if (vErrors === null) {
                                        vErrors = [err3];
                                    } else {
                                        vErrors.push(err3);
                                    }
                                    errors++;
                                }
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_move_participants/properties/Body/definitions/TopLevel/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate31.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate31.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutMoveParticipants200 = validate32;
function validate32(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema20.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate32.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate32.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutMoveParticipants403 = validate33;
function validate33(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema42.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs7 = errors;
                            if (typeof data1 !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            if (
                                !(
                                    data1 === '404 Not Found' ||
                                    data1 === '502 Bad Gateway' ||
                                    data1 === '503 Service Unavailable' ||
                                    data1 ===
                                        'All conference hosts departed hosted conference' ||
                                    data1 ===
                                        'API initiated conference termination' ||
                                    data1 ===
                                        'API initiated participant disconnect' ||
                                    data1 === 'Call disconnected' ||
                                    data1 === 'Call rejected' ||
                                    data1 ===
                                        'Conference extension not found' ||
                                    data1 ===
                                        'Conference host ended the conference with a DTMF command' ||
                                    data1 ===
                                        'Conference terminated by an administrator' ||
                                    data1 ===
                                        'Conference terminated by another participant' ||
                                    data1 === 'Disabled' ||
                                    data1 ===
                                        'Disconnected by an administrator' ||
                                    data1 ===
                                        'Disconnected by another participant' ||
                                    data1 === 'Failed to forward request' ||
                                    data1 === 'Gateway dial out failed' ||
                                    data1 === 'Ice failure' ||
                                    data1 === 'Insufficient licenses' ||
                                    data1 ===
                                        'invalid gateway routing rule transform' ||
                                    data1 === 'Invalid license' ||
                                    data1 === 'Invalid PIN' ||
                                    data1 === 'Invalid protocol for service' ||
                                    data1 === 'Invalid role' ||
                                    data1 === 'Invalid token' ||
                                    data1 ===
                                        'Last remaining participant removed from conference after timeout' ||
                                    data1 === 'Media node disconnected' ||
                                    data1 === 'Media process disconnected' ||
                                    data1 ===
                                        'Neither conference nor gateway found' ||
                                    data1 ===
                                        'No direct route between Edge and Transcoding' ||
                                    data1 ===
                                        'No participants can keep conference alive' ||
                                    data1 === 'Out of proxying resource' ||
                                    data1 === 'Out of resource' ||
                                    data1 === 'Out of transcoding resource' ||
                                    data1 ===
                                        'Participant exceeded PIN entry retries' ||
                                    data1 === 'Participant limit reached' ||
                                    data1 ===
                                        'Proxied participant disconnected' ||
                                    data1 === 'Resource unavailable' ||
                                    data1 === 'Signaling node disconnected' ||
                                    data1 ===
                                        'SSO Authentication Failed. SSO is not available from this domain' ||
                                    data1 ===
                                        'SSO Authentication Failed. The system is in Maintenance mode' ||
                                    data1 === 'SSO Authentication Failed' ||
                                    data1 ===
                                        'SSO enabled but no Identity Providers configured' ||
                                    data1 === 'System in maintenance mode' ||
                                    data1 === 'Telehealth Call failure' ||
                                    data1 === 'Test call finished' ||
                                    data1 ===
                                        'Timeout waiting for conference host to join or permit access to locked conference' ||
                                    data1 ===
                                        'Timer expired awaiting token refresh' ||
                                    data1 === 'transfer failed' ||
                                    data1 === 'Unexpected Response: 503' ||
                                    data1 ===
                                        'Attempted to acquire license while license already acquired. This is usually due to an internal error.' ||
                                    data1 ===
                                        'Failed to transfer into a multi-party conference' ||
                                    data1 ===
                                        'Failed to transfer into a one-to-one conference'
                                )
                            ) {
                                const err4 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/403/definitions/403/properties/result/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema42.properties.result.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                };
                                if (vErrors === null) {
                                    vErrors = [err4];
                                } else {
                                    vErrors.push(err4);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err5 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err5];
            } else {
                vErrors.push(err5);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err6 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err6];
        } else {
            vErrors.push(err6);
        }
        errors++;
        validate33.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate33.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutCallsWebrtcParticipantBody = validate34;
const schema49 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        TopLevel: {
            type: 'object',
            additionalProperties: false,
            properties: {
                call_type: {
                    type: 'string',
                    enum: ['WEBRTC', 'RTMP'],
                    description:
                        '`WEBRTC` for a WebRTC call and `RTMP` for an RTMP call.',
                },
                sdp: {
                    type: 'string',
                    description: 'Contains the SDP of the sender.',
                },
                present: {
                    type: 'string',
                    enum: ['send', 'receive'],
                    description:
                        'Optional field. Contains `send` or `receive` to act as a presentation stream rather than a main audio/video stream.',
                },
                fecc_supported: {
                    type: 'boolean',
                    default: false,
                    description:
                        'Set to true if this participant can be sent FECC messages; false if not.',
                },
                media_type: {
                    type: 'string',
                    enum: ['video', 'video-only', 'audio'],
                    description:
                        'The type of media that this client intends to send.',
                },
            },
            required: ['call_type', 'sdp'],
            title: 'TopLevel',
        },
    },
    oneOf: [
        {
            $ref: '#/definitions/breakout_calls_webrtc_participant/properties/Body/definitions/TopLevel',
        },
    ],
};
const schema50 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        call_type: {
            type: 'string',
            enum: ['WEBRTC', 'RTMP'],
            description:
                '`WEBRTC` for a WebRTC call and `RTMP` for an RTMP call.',
        },
        sdp: {type: 'string', description: 'Contains the SDP of the sender.'},
        present: {
            type: 'string',
            enum: ['send', 'receive'],
            description:
                'Optional field. Contains `send` or `receive` to act as a presentation stream rather than a main audio/video stream.',
        },
        fecc_supported: {
            type: 'boolean',
            default: false,
            description:
                'Set to true if this participant can be sent FECC messages; false if not.',
        },
        media_type: {
            type: 'string',
            enum: ['video', 'video-only', 'audio'],
            description: 'The type of media that this client intends to send.',
        },
    },
    required: ['call_type', 'sdp'],
    title: 'TopLevel',
};
function validate34(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.call_type === undefined && (missing0 = 'call_type')) ||
                (data.sdp === undefined && (missing0 = 'sdp'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_calls_webrtc_participant/properties/Body/definitions/TopLevel/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (
                        !(
                            key0 === 'call_type' ||
                            key0 === 'sdp' ||
                            key0 === 'present' ||
                            key0 === 'fecc_supported' ||
                            key0 === 'media_type'
                        )
                    ) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.call_type !== undefined) {
                        let data0 = data.call_type;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/call_type',
                                schemaPath:
                                    '#/definitions/breakout_calls_webrtc_participant/properties/Body/definitions/TopLevel/properties/call_type/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'WEBRTC' || data0 === 'RTMP')) {
                            const err2 = {
                                instancePath: instancePath + '/call_type',
                                schemaPath:
                                    '#/definitions/breakout_calls_webrtc_participant/properties/Body/definitions/TopLevel/properties/call_type/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema50.properties.call_type.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.sdp !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.sdp !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/sdp',
                                    schemaPath:
                                        '#/definitions/breakout_calls_webrtc_participant/properties/Body/definitions/TopLevel/properties/sdp/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                        if (valid2) {
                            if (data.present !== undefined) {
                                let data2 = data.present;
                                const _errs9 = errors;
                                if (typeof data2 !== 'string') {
                                    const err4 = {
                                        instancePath: instancePath + '/present',
                                        schemaPath:
                                            '#/definitions/breakout_calls_webrtc_participant/properties/Body/definitions/TopLevel/properties/present/type',
                                        keyword: 'type',
                                        params: {type: 'string'},
                                        message: 'must be string',
                                    };
                                    if (vErrors === null) {
                                        vErrors = [err4];
                                    } else {
                                        vErrors.push(err4);
                                    }
                                    errors++;
                                }
                                if (
                                    !(data2 === 'send' || data2 === 'receive')
                                ) {
                                    const err5 = {
                                        instancePath: instancePath + '/present',
                                        schemaPath:
                                            '#/definitions/breakout_calls_webrtc_participant/properties/Body/definitions/TopLevel/properties/present/enum',
                                        keyword: 'enum',
                                        params: {
                                            allowedValues:
                                                schema50.properties.present
                                                    .enum,
                                        },
                                        message:
                                            'must be equal to one of the allowed values',
                                    };
                                    if (vErrors === null) {
                                        vErrors = [err5];
                                    } else {
                                        vErrors.push(err5);
                                    }
                                    errors++;
                                }
                                var valid2 = _errs9 === errors;
                            } else {
                                var valid2 = true;
                            }
                            if (valid2) {
                                if (data.fecc_supported !== undefined) {
                                    const _errs11 = errors;
                                    if (
                                        typeof data.fecc_supported !== 'boolean'
                                    ) {
                                        const err6 = {
                                            instancePath:
                                                instancePath +
                                                '/fecc_supported',
                                            schemaPath:
                                                '#/definitions/breakout_calls_webrtc_participant/properties/Body/definitions/TopLevel/properties/fecc_supported/type',
                                            keyword: 'type',
                                            params: {type: 'boolean'},
                                            message: 'must be boolean',
                                        };
                                        if (vErrors === null) {
                                            vErrors = [err6];
                                        } else {
                                            vErrors.push(err6);
                                        }
                                        errors++;
                                    }
                                    var valid2 = _errs11 === errors;
                                } else {
                                    var valid2 = true;
                                }
                                if (valid2) {
                                    if (data.media_type !== undefined) {
                                        let data4 = data.media_type;
                                        const _errs13 = errors;
                                        if (typeof data4 !== 'string') {
                                            const err7 = {
                                                instancePath:
                                                    instancePath +
                                                    '/media_type',
                                                schemaPath:
                                                    '#/definitions/breakout_calls_webrtc_participant/properties/Body/definitions/TopLevel/properties/media_type/type',
                                                keyword: 'type',
                                                params: {type: 'string'},
                                                message: 'must be string',
                                            };
                                            if (vErrors === null) {
                                                vErrors = [err7];
                                            } else {
                                                vErrors.push(err7);
                                            }
                                            errors++;
                                        }
                                        if (
                                            !(
                                                data4 === 'video' ||
                                                data4 === 'video-only' ||
                                                data4 === 'audio'
                                            )
                                        ) {
                                            const err8 = {
                                                instancePath:
                                                    instancePath +
                                                    '/media_type',
                                                schemaPath:
                                                    '#/definitions/breakout_calls_webrtc_participant/properties/Body/definitions/TopLevel/properties/media_type/enum',
                                                keyword: 'enum',
                                                params: {
                                                    allowedValues:
                                                        schema50.properties
                                                            .media_type.enum,
                                                },
                                                message:
                                                    'must be equal to one of the allowed values',
                                            };
                                            if (vErrors === null) {
                                                vErrors = [err8];
                                            } else {
                                                vErrors.push(err8);
                                            }
                                            errors++;
                                        }
                                        var valid2 = _errs13 === errors;
                                    } else {
                                        var valid2 = true;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        } else {
            const err9 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_calls_webrtc_participant/properties/Body/definitions/TopLevel/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err9];
            } else {
                vErrors.push(err9);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err10 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err10];
        } else {
            vErrors.push(err10);
        }
        errors++;
        validate34.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate34.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutCallsWebrtcParticipant200 = validate35;
const schema51 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        '200': {
            type: 'object',
            additionalProperties: false,
            properties: {
                status: {
                    type: 'string',
                    description: 'Status of the response, eg. success.',
                    enum: ['success'],
                },
                result: {
                    $ref: '#/definitions/breakout_calls_webrtc_participant/properties/200/definitions/Result',
                },
            },
            required: ['status', 'result'],
            title: 'Status200',
        },
        Result: {
            type: 'object',
            additionalProperties: false,
            properties: {
                call_uuid: {
                    type: 'string',
                    description: '`call_uuid` is used to control the call',
                },
                sdp: {type: 'string', description: 'SDP answer from the mcu'},
                offer_ignored: {
                    type: 'boolean',
                    description:
                        'When the call is direct-media and the offer is ignored',
                },
                turn: {
                    type: 'array',
                    items: {
                        $ref: '#/definitions/breakout_calls_webrtc_participant/properties/200/definitions/Turn',
                    },
                },
            },
            required: ['call_uuid', 'sdp'],
            title: 'Result',
        },
        Turn: {
            type: 'object',
            additionalProperties: false,
            properties: {
                urls: {type: 'array', items: {type: 'string'}},
                username: {type: 'string'},
                credential: {type: 'string'},
            },
            required: ['urls', 'username', 'credential'],
            title: 'Turn',
        },
    },
    oneOf: [
        {
            $ref: '#/definitions/breakout_calls_webrtc_participant/properties/200/definitions/200',
        },
    ],
};
const schema52 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        status: {
            type: 'string',
            description: 'Status of the response, eg. success.',
            enum: ['success'],
        },
        result: {
            $ref: '#/definitions/breakout_calls_webrtc_participant/properties/200/definitions/Result',
        },
    },
    required: ['status', 'result'],
    title: 'Status200',
};
const schema53 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        call_uuid: {
            type: 'string',
            description: '`call_uuid` is used to control the call',
        },
        sdp: {type: 'string', description: 'SDP answer from the mcu'},
        offer_ignored: {
            type: 'boolean',
            description:
                'When the call is direct-media and the offer is ignored',
        },
        turn: {
            type: 'array',
            items: {
                $ref: '#/definitions/breakout_calls_webrtc_participant/properties/200/definitions/Turn',
            },
        },
    },
    required: ['call_uuid', 'sdp'],
    title: 'Result',
};
const schema54 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        urls: {type: 'array', items: {type: 'string'}},
        username: {type: 'string'},
        credential: {type: 'string'},
    },
    required: ['urls', 'username', 'credential'],
    title: 'Turn',
};
function validate37(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (errors === 0) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.call_uuid === undefined && (missing0 = 'call_uuid')) ||
                (data.sdp === undefined && (missing0 = 'sdp'))
            ) {
                validate37.errors = [
                    {
                        instancePath,
                        schemaPath: '#/required',
                        keyword: 'required',
                        params: {missingProperty: missing0},
                        message:
                            "must have required property '" + missing0 + "'",
                    },
                ];
                return false;
            } else {
                const _errs1 = errors;
                for (const key0 in data) {
                    if (
                        !(
                            key0 === 'call_uuid' ||
                            key0 === 'sdp' ||
                            key0 === 'offer_ignored' ||
                            key0 === 'turn'
                        )
                    ) {
                        delete data[key0];
                    }
                }
                if (_errs1 === errors) {
                    if (data.call_uuid !== undefined) {
                        const _errs2 = errors;
                        if (typeof data.call_uuid !== 'string') {
                            validate37.errors = [
                                {
                                    instancePath: instancePath + '/call_uuid',
                                    schemaPath: '#/properties/call_uuid/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                },
                            ];
                            return false;
                        }
                        var valid0 = _errs2 === errors;
                    } else {
                        var valid0 = true;
                    }
                    if (valid0) {
                        if (data.sdp !== undefined) {
                            const _errs4 = errors;
                            if (typeof data.sdp !== 'string') {
                                validate37.errors = [
                                    {
                                        instancePath: instancePath + '/sdp',
                                        schemaPath: '#/properties/sdp/type',
                                        keyword: 'type',
                                        params: {type: 'string'},
                                        message: 'must be string',
                                    },
                                ];
                                return false;
                            }
                            var valid0 = _errs4 === errors;
                        } else {
                            var valid0 = true;
                        }
                        if (valid0) {
                            if (data.offer_ignored !== undefined) {
                                const _errs6 = errors;
                                if (typeof data.offer_ignored !== 'boolean') {
                                    validate37.errors = [
                                        {
                                            instancePath:
                                                instancePath + '/offer_ignored',
                                            schemaPath:
                                                '#/properties/offer_ignored/type',
                                            keyword: 'type',
                                            params: {type: 'boolean'},
                                            message: 'must be boolean',
                                        },
                                    ];
                                    return false;
                                }
                                var valid0 = _errs6 === errors;
                            } else {
                                var valid0 = true;
                            }
                            if (valid0) {
                                if (data.turn !== undefined) {
                                    let data3 = data.turn;
                                    const _errs8 = errors;
                                    if (errors === _errs8) {
                                        if (Array.isArray(data3)) {
                                            var valid1 = true;
                                            const len0 = data3.length;
                                            for (let i0 = 0; i0 < len0; i0++) {
                                                let data4 = data3[i0];
                                                const _errs10 = errors;
                                                const _errs11 = errors;
                                                if (errors === _errs11) {
                                                    if (
                                                        data4 &&
                                                        typeof data4 ==
                                                            'object' &&
                                                        !Array.isArray(data4)
                                                    ) {
                                                        let missing1;
                                                        if (
                                                            (data4.urls ===
                                                                undefined &&
                                                                (missing1 =
                                                                    'urls')) ||
                                                            (data4.username ===
                                                                undefined &&
                                                                (missing1 =
                                                                    'username')) ||
                                                            (data4.credential ===
                                                                undefined &&
                                                                (missing1 =
                                                                    'credential'))
                                                        ) {
                                                            validate37.errors =
                                                                [
                                                                    {
                                                                        instancePath:
                                                                            instancePath +
                                                                            '/turn/' +
                                                                            i0,
                                                                        schemaPath:
                                                                            '#/definitions/breakout_calls_webrtc_participant/properties/200/definitions/Turn/required',
                                                                        keyword:
                                                                            'required',
                                                                        params: {
                                                                            missingProperty:
                                                                                missing1,
                                                                        },
                                                                        message:
                                                                            "must have required property '" +
                                                                            missing1 +
                                                                            "'",
                                                                    },
                                                                ];
                                                            return false;
                                                        } else {
                                                            const _errs13 =
                                                                errors;
                                                            for (const key1 in data4) {
                                                                if (
                                                                    !(
                                                                        key1 ===
                                                                            'urls' ||
                                                                        key1 ===
                                                                            'username' ||
                                                                        key1 ===
                                                                            'credential'
                                                                    )
                                                                ) {
                                                                    delete data4[
                                                                        key1
                                                                    ];
                                                                }
                                                            }
                                                            if (
                                                                _errs13 ===
                                                                errors
                                                            ) {
                                                                if (
                                                                    data4.urls !==
                                                                    undefined
                                                                ) {
                                                                    let data5 =
                                                                        data4.urls;
                                                                    const _errs14 =
                                                                        errors;
                                                                    if (
                                                                        errors ===
                                                                        _errs14
                                                                    ) {
                                                                        if (
                                                                            Array.isArray(
                                                                                data5,
                                                                            )
                                                                        ) {
                                                                            var valid4 =
                                                                                true;
                                                                            const len1 =
                                                                                data5.length;
                                                                            for (
                                                                                let i1 = 0;
                                                                                i1 <
                                                                                len1;
                                                                                i1++
                                                                            ) {
                                                                                const _errs16 =
                                                                                    errors;
                                                                                if (
                                                                                    typeof data5[
                                                                                        i1
                                                                                    ] !==
                                                                                    'string'
                                                                                ) {
                                                                                    validate37.errors =
                                                                                        [
                                                                                            {
                                                                                                instancePath:
                                                                                                    instancePath +
                                                                                                    '/turn/' +
                                                                                                    i0 +
                                                                                                    '/urls/' +
                                                                                                    i1,
                                                                                                schemaPath:
                                                                                                    '#/definitions/breakout_calls_webrtc_participant/properties/200/definitions/Turn/properties/urls/items/type',
                                                                                                keyword:
                                                                                                    'type',
                                                                                                params: {
                                                                                                    type: 'string',
                                                                                                },
                                                                                                message:
                                                                                                    'must be string',
                                                                                            },
                                                                                        ];
                                                                                    return false;
                                                                                }
                                                                                var valid4 =
                                                                                    _errs16 ===
                                                                                    errors;
                                                                                if (
                                                                                    !valid4
                                                                                ) {
                                                                                    break;
                                                                                }
                                                                            }
                                                                        } else {
                                                                            validate37.errors =
                                                                                [
                                                                                    {
                                                                                        instancePath:
                                                                                            instancePath +
                                                                                            '/turn/' +
                                                                                            i0 +
                                                                                            '/urls',
                                                                                        schemaPath:
                                                                                            '#/definitions/breakout_calls_webrtc_participant/properties/200/definitions/Turn/properties/urls/type',
                                                                                        keyword:
                                                                                            'type',
                                                                                        params: {
                                                                                            type: 'array',
                                                                                        },
                                                                                        message:
                                                                                            'must be array',
                                                                                    },
                                                                                ];
                                                                            return false;
                                                                        }
                                                                    }
                                                                    var valid3 =
                                                                        _errs14 ===
                                                                        errors;
                                                                } else {
                                                                    var valid3 =
                                                                        true;
                                                                }
                                                                if (valid3) {
                                                                    if (
                                                                        data4.username !==
                                                                        undefined
                                                                    ) {
                                                                        const _errs18 =
                                                                            errors;
                                                                        if (
                                                                            typeof data4.username !==
                                                                            'string'
                                                                        ) {
                                                                            validate37.errors =
                                                                                [
                                                                                    {
                                                                                        instancePath:
                                                                                            instancePath +
                                                                                            '/turn/' +
                                                                                            i0 +
                                                                                            '/username',
                                                                                        schemaPath:
                                                                                            '#/definitions/breakout_calls_webrtc_participant/properties/200/definitions/Turn/properties/username/type',
                                                                                        keyword:
                                                                                            'type',
                                                                                        params: {
                                                                                            type: 'string',
                                                                                        },
                                                                                        message:
                                                                                            'must be string',
                                                                                    },
                                                                                ];
                                                                            return false;
                                                                        }
                                                                        var valid3 =
                                                                            _errs18 ===
                                                                            errors;
                                                                    } else {
                                                                        var valid3 =
                                                                            true;
                                                                    }
                                                                    if (
                                                                        valid3
                                                                    ) {
                                                                        if (
                                                                            data4.credential !==
                                                                            undefined
                                                                        ) {
                                                                            const _errs20 =
                                                                                errors;
                                                                            if (
                                                                                typeof data4.credential !==
                                                                                'string'
                                                                            ) {
                                                                                validate37.errors =
                                                                                    [
                                                                                        {
                                                                                            instancePath:
                                                                                                instancePath +
                                                                                                '/turn/' +
                                                                                                i0 +
                                                                                                '/credential',
                                                                                            schemaPath:
                                                                                                '#/definitions/breakout_calls_webrtc_participant/properties/200/definitions/Turn/properties/credential/type',
                                                                                            keyword:
                                                                                                'type',
                                                                                            params: {
                                                                                                type: 'string',
                                                                                            },
                                                                                            message:
                                                                                                'must be string',
                                                                                        },
                                                                                    ];
                                                                                return false;
                                                                            }
                                                                            var valid3 =
                                                                                _errs20 ===
                                                                                errors;
                                                                        } else {
                                                                            var valid3 =
                                                                                true;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    } else {
                                                        validate37.errors = [
                                                            {
                                                                instancePath:
                                                                    instancePath +
                                                                    '/turn/' +
                                                                    i0,
                                                                schemaPath:
                                                                    '#/definitions/breakout_calls_webrtc_participant/properties/200/definitions/Turn/type',
                                                                keyword: 'type',
                                                                params: {
                                                                    type: 'object',
                                                                },
                                                                message:
                                                                    'must be object',
                                                            },
                                                        ];
                                                        return false;
                                                    }
                                                }
                                                var valid1 = _errs10 === errors;
                                                if (!valid1) {
                                                    break;
                                                }
                                            }
                                        } else {
                                            validate37.errors = [
                                                {
                                                    instancePath:
                                                        instancePath + '/turn',
                                                    schemaPath:
                                                        '#/properties/turn/type',
                                                    keyword: 'type',
                                                    params: {type: 'array'},
                                                    message: 'must be array',
                                                },
                                            ];
                                            return false;
                                        }
                                    }
                                    var valid0 = _errs8 === errors;
                                } else {
                                    var valid0 = true;
                                }
                            }
                        }
                    }
                }
            }
        } else {
            validate37.errors = [
                {
                    instancePath,
                    schemaPath: '#/type',
                    keyword: 'type',
                    params: {type: 'object'},
                    message: 'must be object',
                },
            ];
            return false;
        }
    }
    validate37.errors = vErrors;
    return errors === 0;
}
function validate36(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (errors === 0) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                validate36.errors = [
                    {
                        instancePath,
                        schemaPath: '#/required',
                        keyword: 'required',
                        params: {missingProperty: missing0},
                        message:
                            "must have required property '" + missing0 + "'",
                    },
                ];
                return false;
            } else {
                const _errs1 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs1 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs2 = errors;
                        if (typeof data0 !== 'string') {
                            validate36.errors = [
                                {
                                    instancePath: instancePath + '/status',
                                    schemaPath: '#/properties/status/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                },
                            ];
                            return false;
                        }
                        if (!(data0 === 'success')) {
                            validate36.errors = [
                                {
                                    instancePath: instancePath + '/status',
                                    schemaPath: '#/properties/status/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema52.properties.status.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                },
                            ];
                            return false;
                        }
                        var valid0 = _errs2 === errors;
                    } else {
                        var valid0 = true;
                    }
                    if (valid0) {
                        if (data.result !== undefined) {
                            const _errs4 = errors;
                            if (
                                !validate37(data.result, {
                                    instancePath: instancePath + '/result',
                                    parentData: data,
                                    parentDataProperty: 'result',
                                    rootData,
                                })
                            ) {
                                vErrors =
                                    vErrors === null
                                        ? validate37.errors
                                        : vErrors.concat(validate37.errors);
                                errors = vErrors.length;
                            }
                            var valid0 = _errs4 === errors;
                        } else {
                            var valid0 = true;
                        }
                    }
                }
            }
        } else {
            validate36.errors = [
                {
                    instancePath,
                    schemaPath: '#/type',
                    keyword: 'type',
                    params: {type: 'object'},
                    message: 'must be object',
                },
            ];
            return false;
        }
    }
    validate36.errors = vErrors;
    return errors === 0;
}
function validate35(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    if (
        !validate36(data, {
            instancePath,
            parentData,
            parentDataProperty,
            rootData,
        })
    ) {
        vErrors =
            vErrors === null
                ? validate36.errors
                : vErrors.concat(validate36.errors);
        errors = vErrors.length;
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err0 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err0];
        } else {
            vErrors.push(err0);
        }
        errors++;
        validate35.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate35.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutCallsWebrtcParticipant400 = validate40;
const schema55 = {type: 'string'};
function validate40(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (typeof data !== 'string') {
        validate40.errors = [
            {
                instancePath,
                schemaPath: '#/type',
                keyword: 'type',
                params: {type: 'string'},
                message: 'must be string',
            },
        ];
        return false;
    }
    validate40.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutCallsWebrtcParticipant403 = validate41;
function validate41(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema42.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs7 = errors;
                            if (typeof data1 !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            if (
                                !(
                                    data1 === '404 Not Found' ||
                                    data1 === '502 Bad Gateway' ||
                                    data1 === '503 Service Unavailable' ||
                                    data1 ===
                                        'All conference hosts departed hosted conference' ||
                                    data1 ===
                                        'API initiated conference termination' ||
                                    data1 ===
                                        'API initiated participant disconnect' ||
                                    data1 === 'Call disconnected' ||
                                    data1 === 'Call rejected' ||
                                    data1 ===
                                        'Conference extension not found' ||
                                    data1 ===
                                        'Conference host ended the conference with a DTMF command' ||
                                    data1 ===
                                        'Conference terminated by an administrator' ||
                                    data1 ===
                                        'Conference terminated by another participant' ||
                                    data1 === 'Disabled' ||
                                    data1 ===
                                        'Disconnected by an administrator' ||
                                    data1 ===
                                        'Disconnected by another participant' ||
                                    data1 === 'Failed to forward request' ||
                                    data1 === 'Gateway dial out failed' ||
                                    data1 === 'Ice failure' ||
                                    data1 === 'Insufficient licenses' ||
                                    data1 ===
                                        'invalid gateway routing rule transform' ||
                                    data1 === 'Invalid license' ||
                                    data1 === 'Invalid PIN' ||
                                    data1 === 'Invalid protocol for service' ||
                                    data1 === 'Invalid role' ||
                                    data1 === 'Invalid token' ||
                                    data1 ===
                                        'Last remaining participant removed from conference after timeout' ||
                                    data1 === 'Media node disconnected' ||
                                    data1 === 'Media process disconnected' ||
                                    data1 ===
                                        'Neither conference nor gateway found' ||
                                    data1 ===
                                        'No direct route between Edge and Transcoding' ||
                                    data1 ===
                                        'No participants can keep conference alive' ||
                                    data1 === 'Out of proxying resource' ||
                                    data1 === 'Out of resource' ||
                                    data1 === 'Out of transcoding resource' ||
                                    data1 ===
                                        'Participant exceeded PIN entry retries' ||
                                    data1 === 'Participant limit reached' ||
                                    data1 ===
                                        'Proxied participant disconnected' ||
                                    data1 === 'Resource unavailable' ||
                                    data1 === 'Signaling node disconnected' ||
                                    data1 ===
                                        'SSO Authentication Failed. SSO is not available from this domain' ||
                                    data1 ===
                                        'SSO Authentication Failed. The system is in Maintenance mode' ||
                                    data1 === 'SSO Authentication Failed' ||
                                    data1 ===
                                        'SSO enabled but no Identity Providers configured' ||
                                    data1 === 'System in maintenance mode' ||
                                    data1 === 'Telehealth Call failure' ||
                                    data1 === 'Test call finished' ||
                                    data1 ===
                                        'Timeout waiting for conference host to join or permit access to locked conference' ||
                                    data1 ===
                                        'Timer expired awaiting token refresh' ||
                                    data1 === 'transfer failed' ||
                                    data1 === 'Unexpected Response: 503' ||
                                    data1 ===
                                        'Attempted to acquire license while license already acquired. This is usually due to an internal error.' ||
                                    data1 ===
                                        'Failed to transfer into a multi-party conference' ||
                                    data1 ===
                                        'Failed to transfer into a one-to-one conference'
                                )
                            ) {
                                const err4 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/403/definitions/403/properties/result/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema42.properties.result.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                };
                                if (vErrors === null) {
                                    vErrors = [err4];
                                } else {
                                    vErrors.push(err4);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err5 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err5];
            } else {
                vErrors.push(err5);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err6 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err6];
        } else {
            vErrors.push(err6);
        }
        errors++;
        validate41.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate41.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutCallsWebrtcParticipant500 = validate42;
const schema58 = {type: 'string'};
function validate42(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (typeof data !== 'string') {
        validate42.errors = [
            {
                instancePath,
                schemaPath: '#/type',
                keyword: 'type',
                params: {type: 'string'},
                message: 'must be string',
            },
        ];
        return false;
    }
    validate42.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutCallsWebrtcParticipant504 = validate43;
const schema59 = {type: 'string'};
function validate43(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (typeof data !== 'string') {
        validate43.errors = [
            {
                instancePath,
                schemaPath: '#/type',
                keyword: 'type',
                params: {type: 'string'},
                message: 'must be string',
            },
        ];
        return false;
    }
    validate43.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutMuteParticipant200 = validate44;
function validate44(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema20.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate44.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate44.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutMuteParticipant403 = validate45;
function validate45(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema42.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs7 = errors;
                            if (typeof data1 !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            if (
                                !(
                                    data1 === '404 Not Found' ||
                                    data1 === '502 Bad Gateway' ||
                                    data1 === '503 Service Unavailable' ||
                                    data1 ===
                                        'All conference hosts departed hosted conference' ||
                                    data1 ===
                                        'API initiated conference termination' ||
                                    data1 ===
                                        'API initiated participant disconnect' ||
                                    data1 === 'Call disconnected' ||
                                    data1 === 'Call rejected' ||
                                    data1 ===
                                        'Conference extension not found' ||
                                    data1 ===
                                        'Conference host ended the conference with a DTMF command' ||
                                    data1 ===
                                        'Conference terminated by an administrator' ||
                                    data1 ===
                                        'Conference terminated by another participant' ||
                                    data1 === 'Disabled' ||
                                    data1 ===
                                        'Disconnected by an administrator' ||
                                    data1 ===
                                        'Disconnected by another participant' ||
                                    data1 === 'Failed to forward request' ||
                                    data1 === 'Gateway dial out failed' ||
                                    data1 === 'Ice failure' ||
                                    data1 === 'Insufficient licenses' ||
                                    data1 ===
                                        'invalid gateway routing rule transform' ||
                                    data1 === 'Invalid license' ||
                                    data1 === 'Invalid PIN' ||
                                    data1 === 'Invalid protocol for service' ||
                                    data1 === 'Invalid role' ||
                                    data1 === 'Invalid token' ||
                                    data1 ===
                                        'Last remaining participant removed from conference after timeout' ||
                                    data1 === 'Media node disconnected' ||
                                    data1 === 'Media process disconnected' ||
                                    data1 ===
                                        'Neither conference nor gateway found' ||
                                    data1 ===
                                        'No direct route between Edge and Transcoding' ||
                                    data1 ===
                                        'No participants can keep conference alive' ||
                                    data1 === 'Out of proxying resource' ||
                                    data1 === 'Out of resource' ||
                                    data1 === 'Out of transcoding resource' ||
                                    data1 ===
                                        'Participant exceeded PIN entry retries' ||
                                    data1 === 'Participant limit reached' ||
                                    data1 ===
                                        'Proxied participant disconnected' ||
                                    data1 === 'Resource unavailable' ||
                                    data1 === 'Signaling node disconnected' ||
                                    data1 ===
                                        'SSO Authentication Failed. SSO is not available from this domain' ||
                                    data1 ===
                                        'SSO Authentication Failed. The system is in Maintenance mode' ||
                                    data1 === 'SSO Authentication Failed' ||
                                    data1 ===
                                        'SSO enabled but no Identity Providers configured' ||
                                    data1 === 'System in maintenance mode' ||
                                    data1 === 'Telehealth Call failure' ||
                                    data1 === 'Test call finished' ||
                                    data1 ===
                                        'Timeout waiting for conference host to join or permit access to locked conference' ||
                                    data1 ===
                                        'Timer expired awaiting token refresh' ||
                                    data1 === 'transfer failed' ||
                                    data1 === 'Unexpected Response: 503' ||
                                    data1 ===
                                        'Attempted to acquire license while license already acquired. This is usually due to an internal error.' ||
                                    data1 ===
                                        'Failed to transfer into a multi-party conference' ||
                                    data1 ===
                                        'Failed to transfer into a one-to-one conference'
                                )
                            ) {
                                const err4 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/403/definitions/403/properties/result/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema42.properties.result.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                };
                                if (vErrors === null) {
                                    vErrors = [err4];
                                } else {
                                    vErrors.push(err4);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err5 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err5];
            } else {
                vErrors.push(err5);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err6 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err6];
        } else {
            vErrors.push(err6);
        }
        errors++;
        validate45.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate45.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutUnmuteParticipant200 = validate46;
function validate46(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema20.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate46.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate46.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutUnmuteParticipant403 = validate47;
function validate47(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema42.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs7 = errors;
                            if (typeof data1 !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            if (
                                !(
                                    data1 === '404 Not Found' ||
                                    data1 === '502 Bad Gateway' ||
                                    data1 === '503 Service Unavailable' ||
                                    data1 ===
                                        'All conference hosts departed hosted conference' ||
                                    data1 ===
                                        'API initiated conference termination' ||
                                    data1 ===
                                        'API initiated participant disconnect' ||
                                    data1 === 'Call disconnected' ||
                                    data1 === 'Call rejected' ||
                                    data1 ===
                                        'Conference extension not found' ||
                                    data1 ===
                                        'Conference host ended the conference with a DTMF command' ||
                                    data1 ===
                                        'Conference terminated by an administrator' ||
                                    data1 ===
                                        'Conference terminated by another participant' ||
                                    data1 === 'Disabled' ||
                                    data1 ===
                                        'Disconnected by an administrator' ||
                                    data1 ===
                                        'Disconnected by another participant' ||
                                    data1 === 'Failed to forward request' ||
                                    data1 === 'Gateway dial out failed' ||
                                    data1 === 'Ice failure' ||
                                    data1 === 'Insufficient licenses' ||
                                    data1 ===
                                        'invalid gateway routing rule transform' ||
                                    data1 === 'Invalid license' ||
                                    data1 === 'Invalid PIN' ||
                                    data1 === 'Invalid protocol for service' ||
                                    data1 === 'Invalid role' ||
                                    data1 === 'Invalid token' ||
                                    data1 ===
                                        'Last remaining participant removed from conference after timeout' ||
                                    data1 === 'Media node disconnected' ||
                                    data1 === 'Media process disconnected' ||
                                    data1 ===
                                        'Neither conference nor gateway found' ||
                                    data1 ===
                                        'No direct route between Edge and Transcoding' ||
                                    data1 ===
                                        'No participants can keep conference alive' ||
                                    data1 === 'Out of proxying resource' ||
                                    data1 === 'Out of resource' ||
                                    data1 === 'Out of transcoding resource' ||
                                    data1 ===
                                        'Participant exceeded PIN entry retries' ||
                                    data1 === 'Participant limit reached' ||
                                    data1 ===
                                        'Proxied participant disconnected' ||
                                    data1 === 'Resource unavailable' ||
                                    data1 === 'Signaling node disconnected' ||
                                    data1 ===
                                        'SSO Authentication Failed. SSO is not available from this domain' ||
                                    data1 ===
                                        'SSO Authentication Failed. The system is in Maintenance mode' ||
                                    data1 === 'SSO Authentication Failed' ||
                                    data1 ===
                                        'SSO enabled but no Identity Providers configured' ||
                                    data1 === 'System in maintenance mode' ||
                                    data1 === 'Telehealth Call failure' ||
                                    data1 === 'Test call finished' ||
                                    data1 ===
                                        'Timeout waiting for conference host to join or permit access to locked conference' ||
                                    data1 ===
                                        'Timer expired awaiting token refresh' ||
                                    data1 === 'transfer failed' ||
                                    data1 === 'Unexpected Response: 503' ||
                                    data1 ===
                                        'Attempted to acquire license while license already acquired. This is usually due to an internal error.' ||
                                    data1 ===
                                        'Failed to transfer into a multi-party conference' ||
                                    data1 ===
                                        'Failed to transfer into a one-to-one conference'
                                )
                            ) {
                                const err4 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/403/definitions/403/properties/result/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema42.properties.result.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                };
                                if (vErrors === null) {
                                    vErrors = [err4];
                                } else {
                                    vErrors.push(err4);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err5 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err5];
            } else {
                vErrors.push(err5);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err6 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err6];
        } else {
            vErrors.push(err6);
        }
        errors++;
        validate47.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate47.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutOverlaytextParticipantBody = validate48;
const schema68 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        TopLevel: {
            type: 'object',
            additionalProperties: false,
            properties: {
                text: {
                    type: 'string',
                    description:
                        'Text to use as the participant name overlay text.',
                },
            },
            required: ['text'],
            title: 'TopLevel',
        },
    },
    oneOf: [
        {
            $ref: '#/definitions/breakout_overlaytext_participant/properties/Body/definitions/TopLevel',
        },
    ],
};
const schema69 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        text: {
            type: 'string',
            description: 'Text to use as the participant name overlay text.',
        },
    },
    required: ['text'],
    title: 'TopLevel',
};
function validate48(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (data.text === undefined && (missing0 = 'text')) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_overlaytext_participant/properties/Body/definitions/TopLevel/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'text')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.text !== undefined) {
                        if (typeof data.text !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/text',
                                schemaPath:
                                    '#/definitions/breakout_overlaytext_participant/properties/Body/definitions/TopLevel/properties/text/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                    }
                }
            }
        } else {
            const err2 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_overlaytext_participant/properties/Body/definitions/TopLevel/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err2];
            } else {
                vErrors.push(err2);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err3 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err3];
        } else {
            vErrors.push(err3);
        }
        errors++;
        validate48.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate48.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutOverlaytextParticipant200 = validate49;
function validate49(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema20.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate49.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate49.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutOverlaytextParticipant403 = validate50;
function validate50(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema42.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs7 = errors;
                            if (typeof data1 !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            if (
                                !(
                                    data1 === '404 Not Found' ||
                                    data1 === '502 Bad Gateway' ||
                                    data1 === '503 Service Unavailable' ||
                                    data1 ===
                                        'All conference hosts departed hosted conference' ||
                                    data1 ===
                                        'API initiated conference termination' ||
                                    data1 ===
                                        'API initiated participant disconnect' ||
                                    data1 === 'Call disconnected' ||
                                    data1 === 'Call rejected' ||
                                    data1 ===
                                        'Conference extension not found' ||
                                    data1 ===
                                        'Conference host ended the conference with a DTMF command' ||
                                    data1 ===
                                        'Conference terminated by an administrator' ||
                                    data1 ===
                                        'Conference terminated by another participant' ||
                                    data1 === 'Disabled' ||
                                    data1 ===
                                        'Disconnected by an administrator' ||
                                    data1 ===
                                        'Disconnected by another participant' ||
                                    data1 === 'Failed to forward request' ||
                                    data1 === 'Gateway dial out failed' ||
                                    data1 === 'Ice failure' ||
                                    data1 === 'Insufficient licenses' ||
                                    data1 ===
                                        'invalid gateway routing rule transform' ||
                                    data1 === 'Invalid license' ||
                                    data1 === 'Invalid PIN' ||
                                    data1 === 'Invalid protocol for service' ||
                                    data1 === 'Invalid role' ||
                                    data1 === 'Invalid token' ||
                                    data1 ===
                                        'Last remaining participant removed from conference after timeout' ||
                                    data1 === 'Media node disconnected' ||
                                    data1 === 'Media process disconnected' ||
                                    data1 ===
                                        'Neither conference nor gateway found' ||
                                    data1 ===
                                        'No direct route between Edge and Transcoding' ||
                                    data1 ===
                                        'No participants can keep conference alive' ||
                                    data1 === 'Out of proxying resource' ||
                                    data1 === 'Out of resource' ||
                                    data1 === 'Out of transcoding resource' ||
                                    data1 ===
                                        'Participant exceeded PIN entry retries' ||
                                    data1 === 'Participant limit reached' ||
                                    data1 ===
                                        'Proxied participant disconnected' ||
                                    data1 === 'Resource unavailable' ||
                                    data1 === 'Signaling node disconnected' ||
                                    data1 ===
                                        'SSO Authentication Failed. SSO is not available from this domain' ||
                                    data1 ===
                                        'SSO Authentication Failed. The system is in Maintenance mode' ||
                                    data1 === 'SSO Authentication Failed' ||
                                    data1 ===
                                        'SSO enabled but no Identity Providers configured' ||
                                    data1 === 'System in maintenance mode' ||
                                    data1 === 'Telehealth Call failure' ||
                                    data1 === 'Test call finished' ||
                                    data1 ===
                                        'Timeout waiting for conference host to join or permit access to locked conference' ||
                                    data1 ===
                                        'Timer expired awaiting token refresh' ||
                                    data1 === 'transfer failed' ||
                                    data1 === 'Unexpected Response: 503' ||
                                    data1 ===
                                        'Attempted to acquire license while license already acquired. This is usually due to an internal error.' ||
                                    data1 ===
                                        'Failed to transfer into a multi-party conference' ||
                                    data1 ===
                                        'Failed to transfer into a one-to-one conference'
                                )
                            ) {
                                const err4 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/403/definitions/403/properties/result/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema42.properties.result.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                };
                                if (vErrors === null) {
                                    vErrors = [err4];
                                } else {
                                    vErrors.push(err4);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err5 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err5];
            } else {
                vErrors.push(err5);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err6 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err6];
        } else {
            vErrors.push(err6);
        }
        errors++;
        validate50.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate50.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutTakeFloor200 = validate51;
const schema74 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        '200': {
            type: 'object',
            additionalProperties: false,
            properties: {
                status: {
                    type: 'string',
                    description: 'Status of the response, eg. success.',
                    enum: ['success'],
                },
                result: {type: 'null'},
            },
            required: ['status', 'result'],
            title: 'Status200',
        },
    },
    oneOf: [
        {
            $ref: '#/definitions/breakout_take_floor/properties/200/definitions/200',
        },
    ],
};
const schema75 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        status: {
            type: 'string',
            description: 'Status of the response, eg. success.',
            enum: ['success'],
        },
        result: {type: 'null'},
    },
    required: ['status', 'result'],
    title: 'Status200',
};
function validate51(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_take_floor/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_take_floor/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_take_floor/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema75.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (data.result !== null) {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_take_floor/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'null'},
                                    message: 'must be null',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_take_floor/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate51.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate51.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutTakeFloor403 = validate52;
function validate52(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema42.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs7 = errors;
                            if (typeof data1 !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            if (
                                !(
                                    data1 === '404 Not Found' ||
                                    data1 === '502 Bad Gateway' ||
                                    data1 === '503 Service Unavailable' ||
                                    data1 ===
                                        'All conference hosts departed hosted conference' ||
                                    data1 ===
                                        'API initiated conference termination' ||
                                    data1 ===
                                        'API initiated participant disconnect' ||
                                    data1 === 'Call disconnected' ||
                                    data1 === 'Call rejected' ||
                                    data1 ===
                                        'Conference extension not found' ||
                                    data1 ===
                                        'Conference host ended the conference with a DTMF command' ||
                                    data1 ===
                                        'Conference terminated by an administrator' ||
                                    data1 ===
                                        'Conference terminated by another participant' ||
                                    data1 === 'Disabled' ||
                                    data1 ===
                                        'Disconnected by an administrator' ||
                                    data1 ===
                                        'Disconnected by another participant' ||
                                    data1 === 'Failed to forward request' ||
                                    data1 === 'Gateway dial out failed' ||
                                    data1 === 'Ice failure' ||
                                    data1 === 'Insufficient licenses' ||
                                    data1 ===
                                        'invalid gateway routing rule transform' ||
                                    data1 === 'Invalid license' ||
                                    data1 === 'Invalid PIN' ||
                                    data1 === 'Invalid protocol for service' ||
                                    data1 === 'Invalid role' ||
                                    data1 === 'Invalid token' ||
                                    data1 ===
                                        'Last remaining participant removed from conference after timeout' ||
                                    data1 === 'Media node disconnected' ||
                                    data1 === 'Media process disconnected' ||
                                    data1 ===
                                        'Neither conference nor gateway found' ||
                                    data1 ===
                                        'No direct route between Edge and Transcoding' ||
                                    data1 ===
                                        'No participants can keep conference alive' ||
                                    data1 === 'Out of proxying resource' ||
                                    data1 === 'Out of resource' ||
                                    data1 === 'Out of transcoding resource' ||
                                    data1 ===
                                        'Participant exceeded PIN entry retries' ||
                                    data1 === 'Participant limit reached' ||
                                    data1 ===
                                        'Proxied participant disconnected' ||
                                    data1 === 'Resource unavailable' ||
                                    data1 === 'Signaling node disconnected' ||
                                    data1 ===
                                        'SSO Authentication Failed. SSO is not available from this domain' ||
                                    data1 ===
                                        'SSO Authentication Failed. The system is in Maintenance mode' ||
                                    data1 === 'SSO Authentication Failed' ||
                                    data1 ===
                                        'SSO enabled but no Identity Providers configured' ||
                                    data1 === 'System in maintenance mode' ||
                                    data1 === 'Telehealth Call failure' ||
                                    data1 === 'Test call finished' ||
                                    data1 ===
                                        'Timeout waiting for conference host to join or permit access to locked conference' ||
                                    data1 ===
                                        'Timer expired awaiting token refresh' ||
                                    data1 === 'transfer failed' ||
                                    data1 === 'Unexpected Response: 503' ||
                                    data1 ===
                                        'Attempted to acquire license while license already acquired. This is usually due to an internal error.' ||
                                    data1 ===
                                        'Failed to transfer into a multi-party conference' ||
                                    data1 ===
                                        'Failed to transfer into a one-to-one conference'
                                )
                            ) {
                                const err4 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/403/definitions/403/properties/result/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema42.properties.result.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                };
                                if (vErrors === null) {
                                    vErrors = [err4];
                                } else {
                                    vErrors.push(err4);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err5 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err5];
            } else {
                vErrors.push(err5);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err6 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err6];
        } else {
            vErrors.push(err6);
        }
        errors++;
        validate52.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate52.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutReleaseFloor200 = validate53;
const schema78 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        '200': {
            type: 'object',
            additionalProperties: false,
            properties: {
                status: {
                    type: 'string',
                    description: 'Status of the response, eg. success.',
                    enum: ['success'],
                },
                result: {type: 'null'},
            },
            required: ['status', 'result'],
            title: 'Status200',
        },
    },
    oneOf: [
        {
            $ref: '#/definitions/breakout_release_floor/properties/200/definitions/200',
        },
    ],
};
const schema79 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        status: {
            type: 'string',
            description: 'Status of the response, eg. success.',
            enum: ['success'],
        },
        result: {type: 'null'},
    },
    required: ['status', 'result'],
    title: 'Status200',
};
function validate53(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_release_floor/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_release_floor/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_release_floor/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema79.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (data.result !== null) {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_release_floor/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'null'},
                                    message: 'must be null',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_release_floor/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate53.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate53.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutReleaseFloor403 = validate54;
function validate54(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema42.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs7 = errors;
                            if (typeof data1 !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            if (
                                !(
                                    data1 === '404 Not Found' ||
                                    data1 === '502 Bad Gateway' ||
                                    data1 === '503 Service Unavailable' ||
                                    data1 ===
                                        'All conference hosts departed hosted conference' ||
                                    data1 ===
                                        'API initiated conference termination' ||
                                    data1 ===
                                        'API initiated participant disconnect' ||
                                    data1 === 'Call disconnected' ||
                                    data1 === 'Call rejected' ||
                                    data1 ===
                                        'Conference extension not found' ||
                                    data1 ===
                                        'Conference host ended the conference with a DTMF command' ||
                                    data1 ===
                                        'Conference terminated by an administrator' ||
                                    data1 ===
                                        'Conference terminated by another participant' ||
                                    data1 === 'Disabled' ||
                                    data1 ===
                                        'Disconnected by an administrator' ||
                                    data1 ===
                                        'Disconnected by another participant' ||
                                    data1 === 'Failed to forward request' ||
                                    data1 === 'Gateway dial out failed' ||
                                    data1 === 'Ice failure' ||
                                    data1 === 'Insufficient licenses' ||
                                    data1 ===
                                        'invalid gateway routing rule transform' ||
                                    data1 === 'Invalid license' ||
                                    data1 === 'Invalid PIN' ||
                                    data1 === 'Invalid protocol for service' ||
                                    data1 === 'Invalid role' ||
                                    data1 === 'Invalid token' ||
                                    data1 ===
                                        'Last remaining participant removed from conference after timeout' ||
                                    data1 === 'Media node disconnected' ||
                                    data1 === 'Media process disconnected' ||
                                    data1 ===
                                        'Neither conference nor gateway found' ||
                                    data1 ===
                                        'No direct route between Edge and Transcoding' ||
                                    data1 ===
                                        'No participants can keep conference alive' ||
                                    data1 === 'Out of proxying resource' ||
                                    data1 === 'Out of resource' ||
                                    data1 === 'Out of transcoding resource' ||
                                    data1 ===
                                        'Participant exceeded PIN entry retries' ||
                                    data1 === 'Participant limit reached' ||
                                    data1 ===
                                        'Proxied participant disconnected' ||
                                    data1 === 'Resource unavailable' ||
                                    data1 === 'Signaling node disconnected' ||
                                    data1 ===
                                        'SSO Authentication Failed. SSO is not available from this domain' ||
                                    data1 ===
                                        'SSO Authentication Failed. The system is in Maintenance mode' ||
                                    data1 === 'SSO Authentication Failed' ||
                                    data1 ===
                                        'SSO enabled but no Identity Providers configured' ||
                                    data1 === 'System in maintenance mode' ||
                                    data1 === 'Telehealth Call failure' ||
                                    data1 === 'Test call finished' ||
                                    data1 ===
                                        'Timeout waiting for conference host to join or permit access to locked conference' ||
                                    data1 ===
                                        'Timer expired awaiting token refresh' ||
                                    data1 === 'transfer failed' ||
                                    data1 === 'Unexpected Response: 503' ||
                                    data1 ===
                                        'Attempted to acquire license while license already acquired. This is usually due to an internal error.' ||
                                    data1 ===
                                        'Failed to transfer into a multi-party conference' ||
                                    data1 ===
                                        'Failed to transfer into a one-to-one conference'
                                )
                            ) {
                                const err4 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/403/definitions/403/properties/result/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema42.properties.result.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                };
                                if (vErrors === null) {
                                    vErrors = [err4];
                                } else {
                                    vErrors.push(err4);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err5 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err5];
            } else {
                vErrors.push(err5);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err6 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err6];
        } else {
            vErrors.push(err6);
        }
        errors++;
        validate54.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate54.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutAckBody = validate55;
const schema82 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        TopLevel: {
            type: 'object',
            additionalProperties: false,
            properties: {
                sdp: {
                    type: 'string',
                    description: 'Session Description Protocol',
                },
                offer_ignored: {type: 'boolean'},
            },
            required: [],
            title: 'TopLevel',
        },
    },
    oneOf: [
        {
            $ref: '#/definitions/breakout_ack/properties/Body/definitions/TopLevel',
        },
    ],
};
const schema83 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        sdp: {type: 'string', description: 'Session Description Protocol'},
        offer_ignored: {type: 'boolean'},
    },
    required: [],
    title: 'TopLevel',
};
function validate55(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            const _errs4 = errors;
            for (const key0 in data) {
                if (!(key0 === 'sdp' || key0 === 'offer_ignored')) {
                    delete data[key0];
                }
            }
            if (_errs4 === errors) {
                if (data.sdp !== undefined) {
                    const _errs5 = errors;
                    if (typeof data.sdp !== 'string') {
                        const err0 = {
                            instancePath: instancePath + '/sdp',
                            schemaPath:
                                '#/definitions/breakout_ack/properties/Body/definitions/TopLevel/properties/sdp/type',
                            keyword: 'type',
                            params: {type: 'string'},
                            message: 'must be string',
                        };
                        if (vErrors === null) {
                            vErrors = [err0];
                        } else {
                            vErrors.push(err0);
                        }
                        errors++;
                    }
                    var valid2 = _errs5 === errors;
                } else {
                    var valid2 = true;
                }
                if (valid2) {
                    if (data.offer_ignored !== undefined) {
                        const _errs7 = errors;
                        if (typeof data.offer_ignored !== 'boolean') {
                            const err1 = {
                                instancePath: instancePath + '/offer_ignored',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/Body/definitions/TopLevel/properties/offer_ignored/type',
                                keyword: 'type',
                                params: {type: 'boolean'},
                                message: 'must be boolean',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        var valid2 = _errs7 === errors;
                    } else {
                        var valid2 = true;
                    }
                }
            }
        } else {
            const err2 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/Body/definitions/TopLevel/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err2];
            } else {
                vErrors.push(err2);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err3 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err3];
        } else {
            vErrors.push(err3);
        }
        errors++;
        validate55.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate55.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutAck200 = validate56;
function validate56(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema20.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate56.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate56.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutAck403 = validate57;
function validate57(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema42.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs7 = errors;
                            if (typeof data1 !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            if (
                                !(
                                    data1 === '404 Not Found' ||
                                    data1 === '502 Bad Gateway' ||
                                    data1 === '503 Service Unavailable' ||
                                    data1 ===
                                        'All conference hosts departed hosted conference' ||
                                    data1 ===
                                        'API initiated conference termination' ||
                                    data1 ===
                                        'API initiated participant disconnect' ||
                                    data1 === 'Call disconnected' ||
                                    data1 === 'Call rejected' ||
                                    data1 ===
                                        'Conference extension not found' ||
                                    data1 ===
                                        'Conference host ended the conference with a DTMF command' ||
                                    data1 ===
                                        'Conference terminated by an administrator' ||
                                    data1 ===
                                        'Conference terminated by another participant' ||
                                    data1 === 'Disabled' ||
                                    data1 ===
                                        'Disconnected by an administrator' ||
                                    data1 ===
                                        'Disconnected by another participant' ||
                                    data1 === 'Failed to forward request' ||
                                    data1 === 'Gateway dial out failed' ||
                                    data1 === 'Ice failure' ||
                                    data1 === 'Insufficient licenses' ||
                                    data1 ===
                                        'invalid gateway routing rule transform' ||
                                    data1 === 'Invalid license' ||
                                    data1 === 'Invalid PIN' ||
                                    data1 === 'Invalid protocol for service' ||
                                    data1 === 'Invalid role' ||
                                    data1 === 'Invalid token' ||
                                    data1 ===
                                        'Last remaining participant removed from conference after timeout' ||
                                    data1 === 'Media node disconnected' ||
                                    data1 === 'Media process disconnected' ||
                                    data1 ===
                                        'Neither conference nor gateway found' ||
                                    data1 ===
                                        'No direct route between Edge and Transcoding' ||
                                    data1 ===
                                        'No participants can keep conference alive' ||
                                    data1 === 'Out of proxying resource' ||
                                    data1 === 'Out of resource' ||
                                    data1 === 'Out of transcoding resource' ||
                                    data1 ===
                                        'Participant exceeded PIN entry retries' ||
                                    data1 === 'Participant limit reached' ||
                                    data1 ===
                                        'Proxied participant disconnected' ||
                                    data1 === 'Resource unavailable' ||
                                    data1 === 'Signaling node disconnected' ||
                                    data1 ===
                                        'SSO Authentication Failed. SSO is not available from this domain' ||
                                    data1 ===
                                        'SSO Authentication Failed. The system is in Maintenance mode' ||
                                    data1 === 'SSO Authentication Failed' ||
                                    data1 ===
                                        'SSO enabled but no Identity Providers configured' ||
                                    data1 === 'System in maintenance mode' ||
                                    data1 === 'Telehealth Call failure' ||
                                    data1 === 'Test call finished' ||
                                    data1 ===
                                        'Timeout waiting for conference host to join or permit access to locked conference' ||
                                    data1 ===
                                        'Timer expired awaiting token refresh' ||
                                    data1 === 'transfer failed' ||
                                    data1 === 'Unexpected Response: 503' ||
                                    data1 ===
                                        'Attempted to acquire license while license already acquired. This is usually due to an internal error.' ||
                                    data1 ===
                                        'Failed to transfer into a multi-party conference' ||
                                    data1 ===
                                        'Failed to transfer into a one-to-one conference'
                                )
                            ) {
                                const err4 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/403/definitions/403/properties/result/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema42.properties.result.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                };
                                if (vErrors === null) {
                                    vErrors = [err4];
                                } else {
                                    vErrors.push(err4);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err5 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err5];
            } else {
                vErrors.push(err5);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err6 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err6];
        } else {
            vErrors.push(err6);
        }
        errors++;
        validate57.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate57.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutDisconnectCall200 = validate58;
function validate58(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakouts_disconnect/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakouts_disconnect/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakouts_disconnect/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema22.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (data.result !== null) {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakouts_disconnect/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'null'},
                                    message: 'must be null',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakouts_disconnect/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate58.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate58.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutDisconnectCall403 = validate59;
function validate59(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema42.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs7 = errors;
                            if (typeof data1 !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            if (
                                !(
                                    data1 === '404 Not Found' ||
                                    data1 === '502 Bad Gateway' ||
                                    data1 === '503 Service Unavailable' ||
                                    data1 ===
                                        'All conference hosts departed hosted conference' ||
                                    data1 ===
                                        'API initiated conference termination' ||
                                    data1 ===
                                        'API initiated participant disconnect' ||
                                    data1 === 'Call disconnected' ||
                                    data1 === 'Call rejected' ||
                                    data1 ===
                                        'Conference extension not found' ||
                                    data1 ===
                                        'Conference host ended the conference with a DTMF command' ||
                                    data1 ===
                                        'Conference terminated by an administrator' ||
                                    data1 ===
                                        'Conference terminated by another participant' ||
                                    data1 === 'Disabled' ||
                                    data1 ===
                                        'Disconnected by an administrator' ||
                                    data1 ===
                                        'Disconnected by another participant' ||
                                    data1 === 'Failed to forward request' ||
                                    data1 === 'Gateway dial out failed' ||
                                    data1 === 'Ice failure' ||
                                    data1 === 'Insufficient licenses' ||
                                    data1 ===
                                        'invalid gateway routing rule transform' ||
                                    data1 === 'Invalid license' ||
                                    data1 === 'Invalid PIN' ||
                                    data1 === 'Invalid protocol for service' ||
                                    data1 === 'Invalid role' ||
                                    data1 === 'Invalid token' ||
                                    data1 ===
                                        'Last remaining participant removed from conference after timeout' ||
                                    data1 === 'Media node disconnected' ||
                                    data1 === 'Media process disconnected' ||
                                    data1 ===
                                        'Neither conference nor gateway found' ||
                                    data1 ===
                                        'No direct route between Edge and Transcoding' ||
                                    data1 ===
                                        'No participants can keep conference alive' ||
                                    data1 === 'Out of proxying resource' ||
                                    data1 === 'Out of resource' ||
                                    data1 === 'Out of transcoding resource' ||
                                    data1 ===
                                        'Participant exceeded PIN entry retries' ||
                                    data1 === 'Participant limit reached' ||
                                    data1 ===
                                        'Proxied participant disconnected' ||
                                    data1 === 'Resource unavailable' ||
                                    data1 === 'Signaling node disconnected' ||
                                    data1 ===
                                        'SSO Authentication Failed. SSO is not available from this domain' ||
                                    data1 ===
                                        'SSO Authentication Failed. The system is in Maintenance mode' ||
                                    data1 === 'SSO Authentication Failed' ||
                                    data1 ===
                                        'SSO enabled but no Identity Providers configured' ||
                                    data1 === 'System in maintenance mode' ||
                                    data1 === 'Telehealth Call failure' ||
                                    data1 === 'Test call finished' ||
                                    data1 ===
                                        'Timeout waiting for conference host to join or permit access to locked conference' ||
                                    data1 ===
                                        'Timer expired awaiting token refresh' ||
                                    data1 === 'transfer failed' ||
                                    data1 === 'Unexpected Response: 503' ||
                                    data1 ===
                                        'Attempted to acquire license while license already acquired. This is usually due to an internal error.' ||
                                    data1 ===
                                        'Failed to transfer into a multi-party conference' ||
                                    data1 ===
                                        'Failed to transfer into a one-to-one conference'
                                )
                            ) {
                                const err4 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/403/definitions/403/properties/result/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema42.properties.result.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                };
                                if (vErrors === null) {
                                    vErrors = [err4];
                                } else {
                                    vErrors.push(err4);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err5 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err5];
            } else {
                vErrors.push(err5);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err6 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err6];
        } else {
            vErrors.push(err6);
        }
        errors++;
        validate59.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate59.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutDisconnectCall502 = validate60;
const schema92 = {type: 'string'};
function validate60(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (typeof data !== 'string') {
        validate60.errors = [
            {
                instancePath,
                schemaPath: '#/type',
                keyword: 'type',
                params: {type: 'string'},
                message: 'must be string',
            },
        ];
        return false;
    }
    validate60.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutDisconnectCall503 = validate61;
const schema93 = {type: 'string'};
function validate61(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (typeof data !== 'string') {
        validate61.errors = [
            {
                instancePath,
                schemaPath: '#/type',
                keyword: 'type',
                params: {type: 'string'},
                message: 'must be string',
            },
        ];
        return false;
    }
    validate61.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutNewCandidateBody = validate62;
const schema94 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        TopLevel: {
            type: 'object',
            additionalProperties: false,
            properties: {
                candidate: {
                    type: 'string',
                    description:
                        'Representation of address in candidate-attribute format as per RFC5245.',
                },
                mid: {
                    type: 'string',
                    description: 'The media stream identifier tag.',
                },
                ufrag: {
                    type: 'string',
                    description:
                        'The randomly generated username fragment of the ICE credentials.',
                },
                pwd: {
                    type: 'string',
                    description:
                        'The randomly generated password of the ICE credentials.',
                },
            },
            required: ['candidate', 'mid'],
            title: 'TopLevel',
        },
    },
    oneOf: [
        {
            $ref: '#/definitions/breakout_new_candidate/properties/Body/definitions/TopLevel',
        },
    ],
};
const schema95 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        candidate: {
            type: 'string',
            description:
                'Representation of address in candidate-attribute format as per RFC5245.',
        },
        mid: {type: 'string', description: 'The media stream identifier tag.'},
        ufrag: {
            type: 'string',
            description:
                'The randomly generated username fragment of the ICE credentials.',
        },
        pwd: {
            type: 'string',
            description:
                'The randomly generated password of the ICE credentials.',
        },
    },
    required: ['candidate', 'mid'],
    title: 'TopLevel',
};
function validate62(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.candidate === undefined && (missing0 = 'candidate')) ||
                (data.mid === undefined && (missing0 = 'mid'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_new_candidate/properties/Body/definitions/TopLevel/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (
                        !(
                            key0 === 'candidate' ||
                            key0 === 'mid' ||
                            key0 === 'ufrag' ||
                            key0 === 'pwd'
                        )
                    ) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.candidate !== undefined) {
                        const _errs5 = errors;
                        if (typeof data.candidate !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/candidate',
                                schemaPath:
                                    '#/definitions/breakout_new_candidate/properties/Body/definitions/TopLevel/properties/candidate/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.mid !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.mid !== 'string') {
                                const err2 = {
                                    instancePath: instancePath + '/mid',
                                    schemaPath:
                                        '#/definitions/breakout_new_candidate/properties/Body/definitions/TopLevel/properties/mid/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err2];
                                } else {
                                    vErrors.push(err2);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                        if (valid2) {
                            if (data.ufrag !== undefined) {
                                const _errs9 = errors;
                                if (typeof data.ufrag !== 'string') {
                                    const err3 = {
                                        instancePath: instancePath + '/ufrag',
                                        schemaPath:
                                            '#/definitions/breakout_new_candidate/properties/Body/definitions/TopLevel/properties/ufrag/type',
                                        keyword: 'type',
                                        params: {type: 'string'},
                                        message: 'must be string',
                                    };
                                    if (vErrors === null) {
                                        vErrors = [err3];
                                    } else {
                                        vErrors.push(err3);
                                    }
                                    errors++;
                                }
                                var valid2 = _errs9 === errors;
                            } else {
                                var valid2 = true;
                            }
                            if (valid2) {
                                if (data.pwd !== undefined) {
                                    const _errs11 = errors;
                                    if (typeof data.pwd !== 'string') {
                                        const err4 = {
                                            instancePath: instancePath + '/pwd',
                                            schemaPath:
                                                '#/definitions/breakout_new_candidate/properties/Body/definitions/TopLevel/properties/pwd/type',
                                            keyword: 'type',
                                            params: {type: 'string'},
                                            message: 'must be string',
                                        };
                                        if (vErrors === null) {
                                            vErrors = [err4];
                                        } else {
                                            vErrors.push(err4);
                                        }
                                        errors++;
                                    }
                                    var valid2 = _errs11 === errors;
                                } else {
                                    var valid2 = true;
                                }
                            }
                        }
                    }
                }
            }
        } else {
            const err5 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_new_candidate/properties/Body/definitions/TopLevel/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err5];
            } else {
                vErrors.push(err5);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err6 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err6];
        } else {
            vErrors.push(err6);
        }
        errors++;
        validate62.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate62.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutNewCandidate200 = validate63;
const schema96 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        '200': {
            type: 'object',
            additionalProperties: false,
            properties: {
                status: {
                    type: 'string',
                    description: 'Status of the response, eg. success.',
                    enum: ['success'],
                },
                result: {type: 'null'},
            },
            required: ['status', 'result'],
            title: 'Status200',
        },
    },
    oneOf: [
        {
            $ref: '#/definitions/breakout_new_candidate/properties/200/definitions/200',
        },
    ],
};
const schema97 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        status: {
            type: 'string',
            description: 'Status of the response, eg. success.',
            enum: ['success'],
        },
        result: {type: 'null'},
    },
    required: ['status', 'result'],
    title: 'Status200',
};
function validate63(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_new_candidate/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_new_candidate/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_new_candidate/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema97.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (data.result !== null) {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_new_candidate/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'null'},
                                    message: 'must be null',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_new_candidate/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate63.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate63.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutNewCandidate403 = validate64;
function validate64(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema42.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs7 = errors;
                            if (typeof data1 !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            if (
                                !(
                                    data1 === '404 Not Found' ||
                                    data1 === '502 Bad Gateway' ||
                                    data1 === '503 Service Unavailable' ||
                                    data1 ===
                                        'All conference hosts departed hosted conference' ||
                                    data1 ===
                                        'API initiated conference termination' ||
                                    data1 ===
                                        'API initiated participant disconnect' ||
                                    data1 === 'Call disconnected' ||
                                    data1 === 'Call rejected' ||
                                    data1 ===
                                        'Conference extension not found' ||
                                    data1 ===
                                        'Conference host ended the conference with a DTMF command' ||
                                    data1 ===
                                        'Conference terminated by an administrator' ||
                                    data1 ===
                                        'Conference terminated by another participant' ||
                                    data1 === 'Disabled' ||
                                    data1 ===
                                        'Disconnected by an administrator' ||
                                    data1 ===
                                        'Disconnected by another participant' ||
                                    data1 === 'Failed to forward request' ||
                                    data1 === 'Gateway dial out failed' ||
                                    data1 === 'Ice failure' ||
                                    data1 === 'Insufficient licenses' ||
                                    data1 ===
                                        'invalid gateway routing rule transform' ||
                                    data1 === 'Invalid license' ||
                                    data1 === 'Invalid PIN' ||
                                    data1 === 'Invalid protocol for service' ||
                                    data1 === 'Invalid role' ||
                                    data1 === 'Invalid token' ||
                                    data1 ===
                                        'Last remaining participant removed from conference after timeout' ||
                                    data1 === 'Media node disconnected' ||
                                    data1 === 'Media process disconnected' ||
                                    data1 ===
                                        'Neither conference nor gateway found' ||
                                    data1 ===
                                        'No direct route between Edge and Transcoding' ||
                                    data1 ===
                                        'No participants can keep conference alive' ||
                                    data1 === 'Out of proxying resource' ||
                                    data1 === 'Out of resource' ||
                                    data1 === 'Out of transcoding resource' ||
                                    data1 ===
                                        'Participant exceeded PIN entry retries' ||
                                    data1 === 'Participant limit reached' ||
                                    data1 ===
                                        'Proxied participant disconnected' ||
                                    data1 === 'Resource unavailable' ||
                                    data1 === 'Signaling node disconnected' ||
                                    data1 ===
                                        'SSO Authentication Failed. SSO is not available from this domain' ||
                                    data1 ===
                                        'SSO Authentication Failed. The system is in Maintenance mode' ||
                                    data1 === 'SSO Authentication Failed' ||
                                    data1 ===
                                        'SSO enabled but no Identity Providers configured' ||
                                    data1 === 'System in maintenance mode' ||
                                    data1 === 'Telehealth Call failure' ||
                                    data1 === 'Test call finished' ||
                                    data1 ===
                                        'Timeout waiting for conference host to join or permit access to locked conference' ||
                                    data1 ===
                                        'Timer expired awaiting token refresh' ||
                                    data1 === 'transfer failed' ||
                                    data1 === 'Unexpected Response: 503' ||
                                    data1 ===
                                        'Attempted to acquire license while license already acquired. This is usually due to an internal error.' ||
                                    data1 ===
                                        'Failed to transfer into a multi-party conference' ||
                                    data1 ===
                                        'Failed to transfer into a one-to-one conference'
                                )
                            ) {
                                const err4 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/403/definitions/403/properties/result/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema42.properties.result.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                };
                                if (vErrors === null) {
                                    vErrors = [err4];
                                } else {
                                    vErrors.push(err4);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err5 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err5];
            } else {
                vErrors.push(err5);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err6 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err6];
        } else {
            vErrors.push(err6);
        }
        errors++;
        validate64.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate64.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutNewCandidate502 = validate65;
const schema100 = {type: 'string'};
function validate65(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (typeof data !== 'string') {
        validate65.errors = [
            {
                instancePath,
                schemaPath: '#/type',
                keyword: 'type',
                params: {type: 'string'},
                message: 'must be string',
            },
        ];
        return false;
    }
    validate65.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutUpdateBody = validate66;
const schema101 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        TopLevel: {
            type: 'object',
            additionalProperties: false,
            properties: {
                sdp: {type: 'string', description: 'The new SDP.'},
                fecc_supported: {
                    type: 'string',
                    default: false,
                    description:
                        'Set to true if this participant can be sent FECC messages; false if not.',
                },
            },
            required: [],
            title: 'TopLevel',
        },
    },
    oneOf: [
        {
            $ref: '#/definitions/breakout_update/properties/Body/definitions/TopLevel',
        },
    ],
};
const schema102 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        sdp: {type: 'string', description: 'The new SDP.'},
        fecc_supported: {
            type: 'string',
            default: false,
            description:
                'Set to true if this participant can be sent FECC messages; false if not.',
        },
    },
    required: [],
    title: 'TopLevel',
};
function validate66(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            const _errs4 = errors;
            for (const key0 in data) {
                if (!(key0 === 'sdp' || key0 === 'fecc_supported')) {
                    delete data[key0];
                }
            }
            if (_errs4 === errors) {
                if (data.sdp !== undefined) {
                    const _errs5 = errors;
                    if (typeof data.sdp !== 'string') {
                        const err0 = {
                            instancePath: instancePath + '/sdp',
                            schemaPath:
                                '#/definitions/breakout_update/properties/Body/definitions/TopLevel/properties/sdp/type',
                            keyword: 'type',
                            params: {type: 'string'},
                            message: 'must be string',
                        };
                        if (vErrors === null) {
                            vErrors = [err0];
                        } else {
                            vErrors.push(err0);
                        }
                        errors++;
                    }
                    var valid2 = _errs5 === errors;
                } else {
                    var valid2 = true;
                }
                if (valid2) {
                    if (data.fecc_supported !== undefined) {
                        const _errs7 = errors;
                        if (typeof data.fecc_supported !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/fecc_supported',
                                schemaPath:
                                    '#/definitions/breakout_update/properties/Body/definitions/TopLevel/properties/fecc_supported/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        var valid2 = _errs7 === errors;
                    } else {
                        var valid2 = true;
                    }
                }
            }
        } else {
            const err2 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_update/properties/Body/definitions/TopLevel/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err2];
            } else {
                vErrors.push(err2);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err3 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err3];
        } else {
            vErrors.push(err3);
        }
        errors++;
        validate66.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate66.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutUpdate200 = validate67;
const schema103 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        '200': {
            type: 'object',
            additionalProperties: false,
            properties: {
                status: {
                    type: 'string',
                    description: 'Status of the response, eg. success.',
                    enum: ['success', 'failed'],
                },
                result: {
                    oneOf: [
                        {
                            type: 'string',
                            description: 'SDP answer from the mcu',
                        },
                        {
                            $ref: '#/definitions/breakout_update/properties/200/definitions/Result',
                        },
                    ],
                },
            },
            required: ['status', 'result'],
            title: 'Status200',
        },
        Result: {
            type: 'object',
            additionalProperties: false,
            properties: {
                call_uuid: {type: 'string'},
                sdp: {type: 'string', description: 'SDP answer from the mcu'},
            },
            required: ['call_uuid', 'sdp'],
            title: 'Result',
        },
    },
    oneOf: [
        {$ref: '#/definitions/breakout_update/properties/200/definitions/200'},
    ],
};
const schema104 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        status: {
            type: 'string',
            description: 'Status of the response, eg. success.',
            enum: ['success', 'failed'],
        },
        result: {
            oneOf: [
                {type: 'string', description: 'SDP answer from the mcu'},
                {
                    $ref: '#/definitions/breakout_update/properties/200/definitions/Result',
                },
            ],
        },
    },
    required: ['status', 'result'],
    title: 'Status200',
};
const schema105 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        call_uuid: {type: 'string'},
        sdp: {type: 'string', description: 'SDP answer from the mcu'},
    },
    required: ['call_uuid', 'sdp'],
    title: 'Result',
};
function validate68(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (errors === 0) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                validate68.errors = [
                    {
                        instancePath,
                        schemaPath: '#/required',
                        keyword: 'required',
                        params: {missingProperty: missing0},
                        message:
                            "must have required property '" + missing0 + "'",
                    },
                ];
                return false;
            } else {
                const _errs1 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs1 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs2 = errors;
                        if (typeof data0 !== 'string') {
                            validate68.errors = [
                                {
                                    instancePath: instancePath + '/status',
                                    schemaPath: '#/properties/status/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                },
                            ];
                            return false;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            validate68.errors = [
                                {
                                    instancePath: instancePath + '/status',
                                    schemaPath: '#/properties/status/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema104.properties.status.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                },
                            ];
                            return false;
                        }
                        var valid0 = _errs2 === errors;
                    } else {
                        var valid0 = true;
                    }
                    if (valid0) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs4 = errors;
                            const _errs5 = errors;
                            let valid1 = false;
                            let passing0 = null;
                            const _errs6 = errors;
                            if (typeof data1 !== 'string') {
                                const err0 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/properties/result/oneOf/0/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err0];
                                } else {
                                    vErrors.push(err0);
                                }
                                errors++;
                            }
                            var _valid0 = _errs6 === errors;
                            if (_valid0) {
                                valid1 = true;
                                passing0 = 0;
                            }
                            const _errs8 = errors;
                            const _errs9 = errors;
                            if (errors === _errs9) {
                                if (
                                    data1 &&
                                    typeof data1 == 'object' &&
                                    !Array.isArray(data1)
                                ) {
                                    let missing1;
                                    if (
                                        (data1.call_uuid === undefined &&
                                            (missing1 = 'call_uuid')) ||
                                        (data1.sdp === undefined &&
                                            (missing1 = 'sdp'))
                                    ) {
                                        const err1 = {
                                            instancePath:
                                                instancePath + '/result',
                                            schemaPath:
                                                '#/definitions/breakout_update/properties/200/definitions/Result/required',
                                            keyword: 'required',
                                            params: {missingProperty: missing1},
                                            message:
                                                "must have required property '" +
                                                missing1 +
                                                "'",
                                        };
                                        if (vErrors === null) {
                                            vErrors = [err1];
                                        } else {
                                            vErrors.push(err1);
                                        }
                                        errors++;
                                    } else {
                                        const _errs11 = errors;
                                        for (const key1 in data1) {
                                            if (
                                                !(
                                                    key1 === 'call_uuid' ||
                                                    key1 === 'sdp'
                                                )
                                            ) {
                                                delete data1[key1];
                                            }
                                        }
                                        if (_errs11 === errors) {
                                            if (data1.call_uuid !== undefined) {
                                                const _errs12 = errors;
                                                if (
                                                    typeof data1.call_uuid !==
                                                    'string'
                                                ) {
                                                    const err2 = {
                                                        instancePath:
                                                            instancePath +
                                                            '/result/call_uuid',
                                                        schemaPath:
                                                            '#/definitions/breakout_update/properties/200/definitions/Result/properties/call_uuid/type',
                                                        keyword: 'type',
                                                        params: {
                                                            type: 'string',
                                                        },
                                                        message:
                                                            'must be string',
                                                    };
                                                    if (vErrors === null) {
                                                        vErrors = [err2];
                                                    } else {
                                                        vErrors.push(err2);
                                                    }
                                                    errors++;
                                                }
                                                var valid3 = _errs12 === errors;
                                            } else {
                                                var valid3 = true;
                                            }
                                            if (valid3) {
                                                if (data1.sdp !== undefined) {
                                                    const _errs14 = errors;
                                                    if (
                                                        typeof data1.sdp !==
                                                        'string'
                                                    ) {
                                                        const err3 = {
                                                            instancePath:
                                                                instancePath +
                                                                '/result/sdp',
                                                            schemaPath:
                                                                '#/definitions/breakout_update/properties/200/definitions/Result/properties/sdp/type',
                                                            keyword: 'type',
                                                            params: {
                                                                type: 'string',
                                                            },
                                                            message:
                                                                'must be string',
                                                        };
                                                        if (vErrors === null) {
                                                            vErrors = [err3];
                                                        } else {
                                                            vErrors.push(err3);
                                                        }
                                                        errors++;
                                                    }
                                                    var valid3 =
                                                        _errs14 === errors;
                                                } else {
                                                    var valid3 = true;
                                                }
                                            }
                                        }
                                    }
                                } else {
                                    const err4 = {
                                        instancePath: instancePath + '/result',
                                        schemaPath:
                                            '#/definitions/breakout_update/properties/200/definitions/Result/type',
                                        keyword: 'type',
                                        params: {type: 'object'},
                                        message: 'must be object',
                                    };
                                    if (vErrors === null) {
                                        vErrors = [err4];
                                    } else {
                                        vErrors.push(err4);
                                    }
                                    errors++;
                                }
                            }
                            var _valid0 = _errs8 === errors;
                            if (_valid0 && valid1) {
                                valid1 = false;
                                passing0 = [passing0, 1];
                            } else {
                                if (_valid0) {
                                    valid1 = true;
                                    passing0 = 1;
                                }
                            }
                            if (!valid1) {
                                const err5 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath: '#/properties/result/oneOf',
                                    keyword: 'oneOf',
                                    params: {passingSchemas: passing0},
                                    message:
                                        'must match exactly one schema in oneOf',
                                };
                                if (vErrors === null) {
                                    vErrors = [err5];
                                } else {
                                    vErrors.push(err5);
                                }
                                errors++;
                                validate68.errors = vErrors;
                                return false;
                            } else {
                                errors = _errs5;
                                if (vErrors !== null) {
                                    if (_errs5) {
                                        vErrors.length = _errs5;
                                    } else {
                                        vErrors = null;
                                    }
                                }
                            }
                            var valid0 = _errs4 === errors;
                        } else {
                            var valid0 = true;
                        }
                    }
                }
            }
        } else {
            validate68.errors = [
                {
                    instancePath,
                    schemaPath: '#/type',
                    keyword: 'type',
                    params: {type: 'object'},
                    message: 'must be object',
                },
            ];
            return false;
        }
    }
    validate68.errors = vErrors;
    return errors === 0;
}
function validate67(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    if (
        !validate68(data, {
            instancePath,
            parentData,
            parentDataProperty,
            rootData,
        })
    ) {
        vErrors =
            vErrors === null
                ? validate68.errors
                : vErrors.concat(validate68.errors);
        errors = vErrors.length;
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err0 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err0];
        } else {
            vErrors.push(err0);
        }
        errors++;
        validate67.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate67.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutUpdate403 = validate70;
const schema106 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        '403': {
            type: 'object',
            additionalProperties: false,
            properties: {
                status: {
                    type: 'string',
                    description: 'Status of the response, eg. success.',
                    enum: ['success', 'failed'],
                },
                result: {
                    oneOf: [
                        {type: 'null'},
                        {
                            $ref: '#/definitions/breakout_ack/properties/403/definitions/403/properties/result',
                        },
                    ],
                },
            },
            required: ['status', 'result'],
            title: 'Status403',
        },
    },
    oneOf: [
        {$ref: '#/definitions/breakout_update/properties/403/definitions/403'},
    ],
};
const schema107 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        status: {
            type: 'string',
            description: 'Status of the response, eg. success.',
            enum: ['success', 'failed'],
        },
        result: {
            oneOf: [
                {type: 'null'},
                {
                    $ref: '#/definitions/breakout_ack/properties/403/definitions/403/properties/result',
                },
            ],
        },
    },
    required: ['status', 'result'],
    title: 'Status403',
};
const schema108 = {
    type: 'string',
    enum: [
        '404 Not Found',
        '502 Bad Gateway',
        '503 Service Unavailable',
        'All conference hosts departed hosted conference',
        'API initiated conference termination',
        'API initiated participant disconnect',
        'Call disconnected',
        'Call rejected',
        'Conference extension not found',
        'Conference host ended the conference with a DTMF command',
        'Conference terminated by an administrator',
        'Conference terminated by another participant',
        'Disabled',
        'Disconnected by an administrator',
        'Disconnected by another participant',
        'Failed to forward request',
        'Gateway dial out failed',
        'Ice failure',
        'Insufficient licenses',
        'invalid gateway routing rule transform',
        'Invalid license',
        'Invalid PIN',
        'Invalid protocol for service',
        'Invalid role',
        'Invalid token',
        'Last remaining participant removed from conference after timeout',
        'Media node disconnected',
        'Media process disconnected',
        'Neither conference nor gateway found',
        'No direct route between Edge and Transcoding',
        'No participants can keep conference alive',
        'Out of proxying resource',
        'Out of resource',
        'Out of transcoding resource',
        'Participant exceeded PIN entry retries',
        'Participant limit reached',
        'Proxied participant disconnected',
        'Resource unavailable',
        'Signaling node disconnected',
        'SSO Authentication Failed. SSO is not available from this domain',
        'SSO Authentication Failed. The system is in Maintenance mode',
        'SSO Authentication Failed',
        'SSO enabled but no Identity Providers configured',
        'System in maintenance mode',
        'Telehealth Call failure',
        'Test call finished',
        'Timeout waiting for conference host to join or permit access to locked conference',
        'Timer expired awaiting token refresh',
        'transfer failed',
        'Unexpected Response: 503',
        'Attempted to acquire license while license already acquired. This is usually due to an internal error.',
        'Failed to transfer into a multi-party conference',
        'Failed to transfer into a one-to-one conference',
    ],
    description: 'Error strings',
};
function validate71(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (errors === 0) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                validate71.errors = [
                    {
                        instancePath,
                        schemaPath: '#/required',
                        keyword: 'required',
                        params: {missingProperty: missing0},
                        message:
                            "must have required property '" + missing0 + "'",
                    },
                ];
                return false;
            } else {
                const _errs1 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs1 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs2 = errors;
                        if (typeof data0 !== 'string') {
                            validate71.errors = [
                                {
                                    instancePath: instancePath + '/status',
                                    schemaPath: '#/properties/status/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                },
                            ];
                            return false;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            validate71.errors = [
                                {
                                    instancePath: instancePath + '/status',
                                    schemaPath: '#/properties/status/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema107.properties.status.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                },
                            ];
                            return false;
                        }
                        var valid0 = _errs2 === errors;
                    } else {
                        var valid0 = true;
                    }
                    if (valid0) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs4 = errors;
                            const _errs5 = errors;
                            let valid1 = false;
                            let passing0 = null;
                            const _errs6 = errors;
                            if (data1 !== null) {
                                const err0 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/properties/result/oneOf/0/type',
                                    keyword: 'type',
                                    params: {type: 'null'},
                                    message: 'must be null',
                                };
                                if (vErrors === null) {
                                    vErrors = [err0];
                                } else {
                                    vErrors.push(err0);
                                }
                                errors++;
                            }
                            var _valid0 = _errs6 === errors;
                            if (_valid0) {
                                valid1 = true;
                                passing0 = 0;
                            }
                            const _errs8 = errors;
                            if (typeof data1 !== 'string') {
                                const err1 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err1];
                                } else {
                                    vErrors.push(err1);
                                }
                                errors++;
                            }
                            if (
                                !(
                                    data1 === '404 Not Found' ||
                                    data1 === '502 Bad Gateway' ||
                                    data1 === '503 Service Unavailable' ||
                                    data1 ===
                                        'All conference hosts departed hosted conference' ||
                                    data1 ===
                                        'API initiated conference termination' ||
                                    data1 ===
                                        'API initiated participant disconnect' ||
                                    data1 === 'Call disconnected' ||
                                    data1 === 'Call rejected' ||
                                    data1 ===
                                        'Conference extension not found' ||
                                    data1 ===
                                        'Conference host ended the conference with a DTMF command' ||
                                    data1 ===
                                        'Conference terminated by an administrator' ||
                                    data1 ===
                                        'Conference terminated by another participant' ||
                                    data1 === 'Disabled' ||
                                    data1 ===
                                        'Disconnected by an administrator' ||
                                    data1 ===
                                        'Disconnected by another participant' ||
                                    data1 === 'Failed to forward request' ||
                                    data1 === 'Gateway dial out failed' ||
                                    data1 === 'Ice failure' ||
                                    data1 === 'Insufficient licenses' ||
                                    data1 ===
                                        'invalid gateway routing rule transform' ||
                                    data1 === 'Invalid license' ||
                                    data1 === 'Invalid PIN' ||
                                    data1 === 'Invalid protocol for service' ||
                                    data1 === 'Invalid role' ||
                                    data1 === 'Invalid token' ||
                                    data1 ===
                                        'Last remaining participant removed from conference after timeout' ||
                                    data1 === 'Media node disconnected' ||
                                    data1 === 'Media process disconnected' ||
                                    data1 ===
                                        'Neither conference nor gateway found' ||
                                    data1 ===
                                        'No direct route between Edge and Transcoding' ||
                                    data1 ===
                                        'No participants can keep conference alive' ||
                                    data1 === 'Out of proxying resource' ||
                                    data1 === 'Out of resource' ||
                                    data1 === 'Out of transcoding resource' ||
                                    data1 ===
                                        'Participant exceeded PIN entry retries' ||
                                    data1 === 'Participant limit reached' ||
                                    data1 ===
                                        'Proxied participant disconnected' ||
                                    data1 === 'Resource unavailable' ||
                                    data1 === 'Signaling node disconnected' ||
                                    data1 ===
                                        'SSO Authentication Failed. SSO is not available from this domain' ||
                                    data1 ===
                                        'SSO Authentication Failed. The system is in Maintenance mode' ||
                                    data1 === 'SSO Authentication Failed' ||
                                    data1 ===
                                        'SSO enabled but no Identity Providers configured' ||
                                    data1 === 'System in maintenance mode' ||
                                    data1 === 'Telehealth Call failure' ||
                                    data1 === 'Test call finished' ||
                                    data1 ===
                                        'Timeout waiting for conference host to join or permit access to locked conference' ||
                                    data1 ===
                                        'Timer expired awaiting token refresh' ||
                                    data1 === 'transfer failed' ||
                                    data1 === 'Unexpected Response: 503' ||
                                    data1 ===
                                        'Attempted to acquire license while license already acquired. This is usually due to an internal error.' ||
                                    data1 ===
                                        'Failed to transfer into a multi-party conference' ||
                                    data1 ===
                                        'Failed to transfer into a one-to-one conference'
                                )
                            ) {
                                const err2 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/403/definitions/403/properties/result/enum',
                                    keyword: 'enum',
                                    params: {allowedValues: schema108.enum},
                                    message:
                                        'must be equal to one of the allowed values',
                                };
                                if (vErrors === null) {
                                    vErrors = [err2];
                                } else {
                                    vErrors.push(err2);
                                }
                                errors++;
                            }
                            var _valid0 = _errs8 === errors;
                            if (_valid0 && valid1) {
                                valid1 = false;
                                passing0 = [passing0, 1];
                            } else {
                                if (_valid0) {
                                    valid1 = true;
                                    passing0 = 1;
                                }
                            }
                            if (!valid1) {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath: '#/properties/result/oneOf',
                                    keyword: 'oneOf',
                                    params: {passingSchemas: passing0},
                                    message:
                                        'must match exactly one schema in oneOf',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                                validate71.errors = vErrors;
                                return false;
                            } else {
                                errors = _errs5;
                                if (vErrors !== null) {
                                    if (_errs5) {
                                        vErrors.length = _errs5;
                                    } else {
                                        vErrors = null;
                                    }
                                }
                            }
                            var valid0 = _errs4 === errors;
                        } else {
                            var valid0 = true;
                        }
                    }
                }
            }
        } else {
            validate71.errors = [
                {
                    instancePath,
                    schemaPath: '#/type',
                    keyword: 'type',
                    params: {type: 'object'},
                    message: 'must be object',
                },
            ];
            return false;
        }
    }
    validate71.errors = vErrors;
    return errors === 0;
}
function validate70(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    if (
        !validate71(data, {
            instancePath,
            parentData,
            parentDataProperty,
            rootData,
        })
    ) {
        vErrors =
            vErrors === null
                ? validate71.errors
                : vErrors.concat(validate71.errors);
        errors = vErrors.length;
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err0 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err0];
        } else {
            vErrors.push(err0);
        }
        errors++;
        validate70.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate70.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutUpdate502 = validate73;
const schema109 = {type: 'string'};
function validate73(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (typeof data !== 'string') {
        validate73.errors = [
            {
                instancePath,
                schemaPath: '#/type',
                keyword: 'type',
                params: {type: 'string'},
                message: 'must be string',
            },
        ];
        return false;
    }
    validate73.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutUpdate503 = validate74;
const schema110 = {type: 'string'};
function validate74(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (typeof data !== 'string') {
        validate74.errors = [
            {
                instancePath,
                schemaPath: '#/type',
                keyword: 'type',
                params: {type: 'string'},
                message: 'must be string',
            },
        ];
        return false;
    }
    validate74.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutUpdate520 = validate75;
const schema111 = {type: 'string'};
function validate75(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (typeof data !== 'string') {
        validate75.errors = [
            {
                instancePath,
                schemaPath: '#/type',
                keyword: 'type',
                params: {type: 'string'},
                message: 'must be string',
            },
        ];
        return false;
    }
    validate75.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutShowLiveCaptions200 = validate76;
const schema112 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        '200': {
            type: 'object',
            additionalProperties: false,
            properties: {
                status: {
                    type: 'string',
                    description: 'Status of the response, eg. success.',
                    enum: ['success'],
                },
                result: {
                    type: 'null',
                    description: 'The results flag is not set.',
                },
            },
            required: ['status', 'result'],
            title: 'Status200',
        },
    },
    oneOf: [
        {
            $ref: '#/definitions/breakout_show_live_captions/properties/200/definitions/200',
        },
    ],
};
const schema113 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        status: {
            type: 'string',
            description: 'Status of the response, eg. success.',
            enum: ['success'],
        },
        result: {type: 'null', description: 'The results flag is not set.'},
    },
    required: ['status', 'result'],
    title: 'Status200',
};
function validate76(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_show_live_captions/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_show_live_captions/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_show_live_captions/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema113.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (data.result !== null) {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_show_live_captions/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'null'},
                                    message: 'must be null',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_show_live_captions/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate76.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate76.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutShowLiveCaptions403 = validate77;
function validate77(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema42.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs7 = errors;
                            if (typeof data1 !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            if (
                                !(
                                    data1 === '404 Not Found' ||
                                    data1 === '502 Bad Gateway' ||
                                    data1 === '503 Service Unavailable' ||
                                    data1 ===
                                        'All conference hosts departed hosted conference' ||
                                    data1 ===
                                        'API initiated conference termination' ||
                                    data1 ===
                                        'API initiated participant disconnect' ||
                                    data1 === 'Call disconnected' ||
                                    data1 === 'Call rejected' ||
                                    data1 ===
                                        'Conference extension not found' ||
                                    data1 ===
                                        'Conference host ended the conference with a DTMF command' ||
                                    data1 ===
                                        'Conference terminated by an administrator' ||
                                    data1 ===
                                        'Conference terminated by another participant' ||
                                    data1 === 'Disabled' ||
                                    data1 ===
                                        'Disconnected by an administrator' ||
                                    data1 ===
                                        'Disconnected by another participant' ||
                                    data1 === 'Failed to forward request' ||
                                    data1 === 'Gateway dial out failed' ||
                                    data1 === 'Ice failure' ||
                                    data1 === 'Insufficient licenses' ||
                                    data1 ===
                                        'invalid gateway routing rule transform' ||
                                    data1 === 'Invalid license' ||
                                    data1 === 'Invalid PIN' ||
                                    data1 === 'Invalid protocol for service' ||
                                    data1 === 'Invalid role' ||
                                    data1 === 'Invalid token' ||
                                    data1 ===
                                        'Last remaining participant removed from conference after timeout' ||
                                    data1 === 'Media node disconnected' ||
                                    data1 === 'Media process disconnected' ||
                                    data1 ===
                                        'Neither conference nor gateway found' ||
                                    data1 ===
                                        'No direct route between Edge and Transcoding' ||
                                    data1 ===
                                        'No participants can keep conference alive' ||
                                    data1 === 'Out of proxying resource' ||
                                    data1 === 'Out of resource' ||
                                    data1 === 'Out of transcoding resource' ||
                                    data1 ===
                                        'Participant exceeded PIN entry retries' ||
                                    data1 === 'Participant limit reached' ||
                                    data1 ===
                                        'Proxied participant disconnected' ||
                                    data1 === 'Resource unavailable' ||
                                    data1 === 'Signaling node disconnected' ||
                                    data1 ===
                                        'SSO Authentication Failed. SSO is not available from this domain' ||
                                    data1 ===
                                        'SSO Authentication Failed. The system is in Maintenance mode' ||
                                    data1 === 'SSO Authentication Failed' ||
                                    data1 ===
                                        'SSO enabled but no Identity Providers configured' ||
                                    data1 === 'System in maintenance mode' ||
                                    data1 === 'Telehealth Call failure' ||
                                    data1 === 'Test call finished' ||
                                    data1 ===
                                        'Timeout waiting for conference host to join or permit access to locked conference' ||
                                    data1 ===
                                        'Timer expired awaiting token refresh' ||
                                    data1 === 'transfer failed' ||
                                    data1 === 'Unexpected Response: 503' ||
                                    data1 ===
                                        'Attempted to acquire license while license already acquired. This is usually due to an internal error.' ||
                                    data1 ===
                                        'Failed to transfer into a multi-party conference' ||
                                    data1 ===
                                        'Failed to transfer into a one-to-one conference'
                                )
                            ) {
                                const err4 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/403/definitions/403/properties/result/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema42.properties.result.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                };
                                if (vErrors === null) {
                                    vErrors = [err4];
                                } else {
                                    vErrors.push(err4);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err5 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err5];
            } else {
                vErrors.push(err5);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err6 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err6];
        } else {
            vErrors.push(err6);
        }
        errors++;
        validate77.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate77.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutHideLiveCaptions200 = validate78;
function validate78(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_show_live_captions/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_show_live_captions/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_show_live_captions/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema113.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (data.result !== null) {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_show_live_captions/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'null'},
                                    message: 'must be null',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_show_live_captions/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate78.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate78.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutHideLiveCaptions403 = validate79;
function validate79(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema42.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs7 = errors;
                            if (typeof data1 !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            if (
                                !(
                                    data1 === '404 Not Found' ||
                                    data1 === '502 Bad Gateway' ||
                                    data1 === '503 Service Unavailable' ||
                                    data1 ===
                                        'All conference hosts departed hosted conference' ||
                                    data1 ===
                                        'API initiated conference termination' ||
                                    data1 ===
                                        'API initiated participant disconnect' ||
                                    data1 === 'Call disconnected' ||
                                    data1 === 'Call rejected' ||
                                    data1 ===
                                        'Conference extension not found' ||
                                    data1 ===
                                        'Conference host ended the conference with a DTMF command' ||
                                    data1 ===
                                        'Conference terminated by an administrator' ||
                                    data1 ===
                                        'Conference terminated by another participant' ||
                                    data1 === 'Disabled' ||
                                    data1 ===
                                        'Disconnected by an administrator' ||
                                    data1 ===
                                        'Disconnected by another participant' ||
                                    data1 === 'Failed to forward request' ||
                                    data1 === 'Gateway dial out failed' ||
                                    data1 === 'Ice failure' ||
                                    data1 === 'Insufficient licenses' ||
                                    data1 ===
                                        'invalid gateway routing rule transform' ||
                                    data1 === 'Invalid license' ||
                                    data1 === 'Invalid PIN' ||
                                    data1 === 'Invalid protocol for service' ||
                                    data1 === 'Invalid role' ||
                                    data1 === 'Invalid token' ||
                                    data1 ===
                                        'Last remaining participant removed from conference after timeout' ||
                                    data1 === 'Media node disconnected' ||
                                    data1 === 'Media process disconnected' ||
                                    data1 ===
                                        'Neither conference nor gateway found' ||
                                    data1 ===
                                        'No direct route between Edge and Transcoding' ||
                                    data1 ===
                                        'No participants can keep conference alive' ||
                                    data1 === 'Out of proxying resource' ||
                                    data1 === 'Out of resource' ||
                                    data1 === 'Out of transcoding resource' ||
                                    data1 ===
                                        'Participant exceeded PIN entry retries' ||
                                    data1 === 'Participant limit reached' ||
                                    data1 ===
                                        'Proxied participant disconnected' ||
                                    data1 === 'Resource unavailable' ||
                                    data1 === 'Signaling node disconnected' ||
                                    data1 ===
                                        'SSO Authentication Failed. SSO is not available from this domain' ||
                                    data1 ===
                                        'SSO Authentication Failed. The system is in Maintenance mode' ||
                                    data1 === 'SSO Authentication Failed' ||
                                    data1 ===
                                        'SSO enabled but no Identity Providers configured' ||
                                    data1 === 'System in maintenance mode' ||
                                    data1 === 'Telehealth Call failure' ||
                                    data1 === 'Test call finished' ||
                                    data1 ===
                                        'Timeout waiting for conference host to join or permit access to locked conference' ||
                                    data1 ===
                                        'Timer expired awaiting token refresh' ||
                                    data1 === 'transfer failed' ||
                                    data1 === 'Unexpected Response: 503' ||
                                    data1 ===
                                        'Attempted to acquire license while license already acquired. This is usually due to an internal error.' ||
                                    data1 ===
                                        'Failed to transfer into a multi-party conference' ||
                                    data1 ===
                                        'Failed to transfer into a one-to-one conference'
                                )
                            ) {
                                const err4 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/403/definitions/403/properties/result/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema42.properties.result.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                };
                                if (vErrors === null) {
                                    vErrors = [err4];
                                } else {
                                    vErrors.push(err4);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err5 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err5];
            } else {
                vErrors.push(err5);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err6 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err6];
        } else {
            vErrors.push(err6);
        }
        errors++;
        validate79.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate79.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutMessageConferenceBody = validate80;
function validate80(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.type === undefined && (missing0 = 'type')) ||
                (data.payload === undefined && (missing0 = 'payload'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakouts_message/properties/Body/definitions/TopLevel/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'type' || key0 === 'payload')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.type !== undefined) {
                        let data0 = data.type;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/type',
                                schemaPath:
                                    '#/definitions/breakouts_message/properties/Body/definitions/TopLevel/properties/type/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (
                            !(
                                data0 === 'text/plain' ||
                                data0 === 'application/json'
                            )
                        ) {
                            const err2 = {
                                instancePath: instancePath + '/type',
                                schemaPath:
                                    '#/definitions/breakouts_message/properties/Body/definitions/TopLevel/properties/type/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema18.properties.type.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.payload !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.payload !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/payload',
                                    schemaPath:
                                        '#/definitions/breakouts_message/properties/Body/definitions/TopLevel/properties/payload/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakouts_message/properties/Body/definitions/TopLevel/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate80.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate80.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutMessageConference200 = validate81;
function validate81(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema20.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate81.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate81.errors = vErrors;
    return errors === 0;
}
