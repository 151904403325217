import type {UserAgents} from './detectedBrowser';

export const LearnAboutRequestPermissionsUrls: Partial<
    Record<UserAgents, string>
> = {
    ['Chrome']:
        'https://support.google.com/chrome/answer/2693767?co=GENIE.Platform%3DDesktop&hl=en',
    ['Firefox']:
        'https://support.mozilla.org/en-US/kb/how-manage-your-camera-and-microphone-permissions',
    ['Edge Chromium']:
        'https://www.windowscentral.com/how-manage-site-permissions-new-microsoft-edge',
    ['Safari Mac']:
        'https://support.apple.com/guide/safari/websites-ibrwe2159f50/mac',
};

export const getLearnAboutRequestPermissionsUrl = (browserName?: UserAgents) =>
    browserName ? LearnAboutRequestPermissionsUrls[browserName] : undefined;
