import type {ReactElement} from 'react';
import React from 'react';
import cx from 'classnames';

import {FontVariant} from '../../../../design-tokens/generated/constants';
import {TestId} from '../../../utils/testIds';
import {SvgImage} from '../SvgImage/SvgImage';
import {Text} from '../Text/Text';
import {ThemeProvider} from '../../../themes/ThemeContext';
import {Box} from '../Box/Box';
import type {ColorScheme} from '../../../types/variants';
import {ModalCloseButton} from '../../modules/CloseButton/ModalCloseButton.view';

import ArrowSvg from './arrow.svg';
import type {ControlledTooltip} from './useTooltip';
import {useTooltip} from './useTooltip';

import styles from './Tooltip.module.scss';

export type NotificationTooltipPosition =
    | 'topRight'
    | 'topCenter'
    | 'topLeft'
    | 'bottomRight'
    | 'bottomCenter'
    | 'bottomLeft';

export const NotificationTooltip: React.FC<
    React.ComponentProps<'div'> &
        ControlledTooltip & {
            position?: NotificationTooltipPosition;
            header: string;
            modifier?: 'fullWidth';
            text: string | ReactElement;
            ctaText?: string;
            ctaFn?: () => void;
            isArrowShown?: boolean;
            testIdInner?: string;
            testIdCloseButton?: string;
            colorScheme?: ColorScheme;
            mainWrapperClassName?: string;
            closeButtonAriaLabel?: string;
            stopPointerDownPropagation?: boolean;
        }
> = ({
    closeButtonAriaLabel = 'Close tooltip',
    colorScheme = 'light',
    position = 'topRight',
    header,
    modifier,
    text,
    ctaText,
    ctaFn = () => {
        /*noop*/
    },
    controlledIsVisible,
    controlledOnChange,
    children,
    className,
    mainWrapperClassName,
    isArrowShown = true,
    testIdInner = TestId.NotificationTooltipInner,
    testIdCloseButton = TestId.NotificationTooltipClose,
    stopPointerDownPropagation = false,
}) => {
    const {isVisible, setVisible} = useTooltip({
        controlledIsVisible,
        controlledOnChange,
    });

    return (
        <div
            className={cx(
                styles.tooltipContainer,
                modifier && styles[modifier],
                mainWrapperClassName,
            )}
        >
            {children}
            {isVisible && (
                <ThemeProvider colorScheme={colorScheme}>
                    <Box
                        className={cx(
                            styles.tooltip,
                            styles.tooltipNotification,
                            styles[position],
                            className,
                        )}
                        data-testid={testIdInner}
                        onPointerDownCapture={
                            stopPointerDownPropagation
                                ? e => e.stopPropagation()
                                : undefined
                        }
                    >
                        <div className={styles.headerWrapper}>
                            <Text
                                fontVariant={FontVariant.BodyBold}
                                data-testid={TestId.NotificationTooltipHeader}
                            >
                                {header}
                            </Text>
                            <ModalCloseButton
                                className={styles.closeButton}
                                data-testid={testIdCloseButton}
                                onClose={() =>
                                    setVisible({
                                        isVisible: false,
                                        isControlled: true,
                                    })
                                }
                                aria-label={closeButtonAriaLabel}
                            />
                        </div>
                        <Text
                            fontVariant={FontVariant.Body}
                            data-testid={TestId.NotificationTooltipText}
                        >
                            {text}
                        </Text>
                        {ctaText && (
                            <Text
                                fontVariant={FontVariant.BodyLink}
                                onClick={ctaFn}
                                className={styles.cta}
                                data-testid={TestId.NotificationTooltipCta}
                            >
                                {ctaText}
                            </Text>
                        )}

                        {isArrowShown && (
                            <div className={styles.tooltipArrow}>
                                <SvgImage
                                    source={ArrowSvg}
                                    className={styles.tooltipArrowIcon}
                                />
                            </div>
                        )}
                    </Box>
                </ThemeProvider>
            )}
        </div>
    );
};

export type NotificationTooltipProps = React.ComponentProps<
    typeof NotificationTooltip
>;
