import type React from 'react';
import {useState, useEffect, useCallback, useRef} from 'react';

import type {ImageSrc} from '@pexip/components';
import {
    useLgUp,
    useMdDown,
    useMd,
    useTouchDevice,
    useBreakpointChange,
} from '@pexip/components';
import {noop} from '@pexip/utils';

import type {InfoCardOrientation} from './InfoCard.types';
import {InfoCardWidth} from './InfoCard.constants';

export const useInfoCardOrientation = (): InfoCardOrientation | undefined => {
    const isLgUp = useLgUp();
    const isMd = useMd();
    const isMdDown = useMdDown();
    if (isLgUp || isMdDown) {
        return 'vertical';
    }
    if (isMd) {
        return 'horizontal';
    }
};

export const useInfoCardWidth = () => {
    const isLgUp = useLgUp();
    const isMd = useMd();
    const isMdDown = useMdDown();
    const isTouch = useTouchDevice();
    if (isLgUp) {
        if (isTouch) {
            return InfoCardWidth.NarrowTouch;
        }
        return InfoCardWidth.Narrow;
    }
    if (isMd) {
        return InfoCardWidth.Wide;
    }
    if (isMdDown) {
        return InfoCardWidth.HundredPercent;
    }
};

enum CardAnimateState {
    NoAnimation = '',
    VerticalOpen = 'verticalOpen',
    VerticalClose = 'verticalClose',
    HorizontalOpen = 'horizontalOpen',
    HorizontalClose = 'horizontalClose',
}

const cardClosedWaitTime = 1000;

export const useAnimateInfoCard = ({
    isVertical,
    shouldAnimate,
    updateStates,
    onAnimateComplete,
}: {
    isVertical: boolean;
    shouldAnimate: boolean;
    updateStates: () => void;
    onAnimateComplete: () => void;
}): [CardAnimateState, () => void] => {
    const isAnimating = useRef(false);
    const onAnimationEnd = useRef<() => void>(noop);
    const [cardAnimateState, setCardAnimateState] = useState(
        CardAnimateState.NoAnimation,
    );
    const cleanupAnimation = useCallback(() => {
        setCardAnimateState(CardAnimateState.NoAnimation);
        onAnimationEnd.current = noop;
        isAnimating.current = false;
        onAnimateComplete();
    }, [onAnimateComplete]);
    const breakpointChangeSignal = useBreakpointChange();

    useEffect(() => {
        // If the user changes the viewport size entering one of our defined breakpoints we stop animating.
        return breakpointChangeSignal.add(() => {
            if (isAnimating.current) {
                cleanupAnimation();
            }
        });
    }, [breakpointChangeSignal, cleanupAnimation]);

    const animate = useCallback(() => {
        isAnimating.current = true;
        const closeCardAnimateState = isVertical
            ? CardAnimateState.VerticalClose
            : CardAnimateState.HorizontalClose;
        setCardAnimateState(closeCardAnimateState);
        onAnimationEnd.current = () => {
            onAnimationEnd.current = () => {
                /**
                 * At this point the card width/height has animated to an opened state
                 * but we need to wait to remove the animation state until the deplayed opacity
                 * animation fade in has completed (happens after card has opened).
                 */
                setTimeout(() => {
                    cleanupAnimation();
                }, 200);
            };
            setTimeout(() => {
                updateStates();
                const openCardAnimateState = isVertical
                    ? CardAnimateState.HorizontalOpen
                    : CardAnimateState.VerticalOpen;
                setCardAnimateState(openCardAnimateState);
            }, cardClosedWaitTime);
        };
    }, [isVertical, updateStates, cleanupAnimation]);

    useEffect(() => {
        if (shouldAnimate && !isAnimating.current) {
            animate();
        }
    }, [shouldAnimate, animate]);

    return [cardAnimateState, onAnimationEnd.current];
};

export const useInfoCardStates = ({
    orientation,
    width,
    imageUrl,
    logoUrl,
    imageContent,
    headerText,
    bodyContent,
}: {
    orientation: InfoCardOrientation | undefined;
    width: InfoCardWidth | undefined;
    imageUrl: string;
    logoUrl?: ImageSrc;
    imageContent?: React.ReactElement;
    headerText: string;
    bodyContent: React.ReactNode;
}) => {
    const [isVertical, setIsVertical] = useState(orientation === 'vertical');
    const [cardWidth, setCardWidth] = useState(width);
    const [cardImageUrl, setCardImageUrl] = useState(imageUrl);
    const [cardLogoUrl, setCardLogoUrl] = useState(logoUrl);
    const [cardImageContent, setCardImageContent] = useState(imageContent);
    const [cardHeaderText, setCardHeaderText] = useState(headerText);
    const [cardBodyContent, setCardBodyContent] = useState(bodyContent);

    const updateStates = useCallback(() => {
        const updatedIsVertical = orientation === 'vertical';
        if (updatedIsVertical !== isVertical) {
            setIsVertical(updatedIsVertical);
        }
        if (cardWidth !== width) {
            setCardWidth(width);
        }
        if (cardImageUrl !== imageUrl) {
            setCardImageUrl(imageUrl);
        }
        if (cardLogoUrl !== logoUrl) {
            setCardLogoUrl(logoUrl);
        }
        if (cardImageContent !== imageContent) {
            setCardImageContent(imageContent);
        }
        if (cardHeaderText !== headerText) {
            setCardHeaderText(headerText);
        }
        if (cardBodyContent !== bodyContent) {
            setCardBodyContent(bodyContent);
        }
    }, [
        cardWidth,
        width,
        orientation,
        isVertical,
        cardImageUrl,
        imageUrl,
        cardLogoUrl,
        logoUrl,
        cardImageContent,
        imageContent,
        bodyContent,
        cardBodyContent,
        headerText,
        cardHeaderText,
    ]);
    return [
        {
            isVertical,
            cardWidth,
            cardImageUrl,
            cardLogoUrl,
            cardImageContent,
            cardHeaderText,
            cardBodyContent,
        },
        updateStates,
    ] as const;
};
