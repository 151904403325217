export const PROCESSING_WIDTH = 768;
export const PROCESSING_HEIGHT = 432;

// Rendering parameters
export const FOREGROUND_THRESHOLD = 0.5;
export const BACKGROUND_BLUR_AMOUNT = 3; // Between 1 and 20
export const EDGE_BLUR_AMOUNT = 3; // Between 0 and 20
export const FLIP_HORIZONTAL = false;
export const FRAME_RATE = 20;

export enum AbortReason {
    Close = 'close',
}
