import React from 'react';
import cx from 'classnames';

import {Text} from '../Text/Text';
import {FontVariant} from '../../../../design-tokens/constants';
import type {ColorScheme, TextVariant} from '../../../types/variants';
import {ThemeConsumer} from '../../../themes/ThemeContext';

import styles from './InputLabel.module.scss';

type LabelFontVariant =
    | FontVariant.Body
    | FontVariant.BodyBold
    | FontVariant.Small
    | FontVariant.SmallBold;

export const InputLabel: React.FC<{
    className?: string;
    variant?: TextVariant;
    colorScheme?: ColorScheme;
    fontVariant?: LabelFontVariant;
    form?: string;
    id?: string;
    isLabelHidden?: boolean;
    isLabelInline?: boolean;
    isUppercase?: boolean;
    text: string;
}> = ({
    className,
    colorScheme,
    form,
    fontVariant = FontVariant.BodyBold,
    id,
    isLabelHidden,
    isLabelInline,
    isUppercase,
    text,
    variant = 'inherit',
    ...props
}) => (
    <label
        className={cx(
            styles.label,

            {
                [styles.hidden]: isLabelHidden,
                [styles.inline]: isLabelInline,
                [styles.standard]: !isLabelInline,
            },
            className,
        )}
        htmlFor={id}
        form={form}
        {...props}
    >
        <ThemeConsumer>
            {({colorScheme: defaultColorScheme}) => (
                <Text
                    isUppercase={isUppercase}
                    colorScheme={colorScheme ?? defaultColorScheme}
                    fontVariant={fontVariant}
                    variant={variant}
                >
                    {text}
                </Text>
            )}
        </ThemeConsumer>
    </label>
);
