'use strict';
export const validateAckBody = validate11;
const schema12 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        TopLevel: {
            type: 'object',
            additionalProperties: false,
            properties: {
                sdp: {
                    type: 'string',
                    description: 'Session Description Protocol',
                },
                offer_ignored: {type: 'boolean'},
            },
            required: [],
            title: 'TopLevel',
        },
    },
    oneOf: [{$ref: '#/definitions/ack/properties/Body/definitions/TopLevel'}],
};
const schema13 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        sdp: {type: 'string', description: 'Session Description Protocol'},
        offer_ignored: {type: 'boolean'},
    },
    required: [],
    title: 'TopLevel',
};
function validate11(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            const _errs4 = errors;
            for (const key0 in data) {
                if (!(key0 === 'sdp' || key0 === 'offer_ignored')) {
                    delete data[key0];
                }
            }
            if (_errs4 === errors) {
                if (data.sdp !== undefined) {
                    const _errs5 = errors;
                    if (typeof data.sdp !== 'string') {
                        const err0 = {
                            instancePath: instancePath + '/sdp',
                            schemaPath:
                                '#/definitions/ack/properties/Body/definitions/TopLevel/properties/sdp/type',
                            keyword: 'type',
                            params: {type: 'string'},
                            message: 'must be string',
                        };
                        if (vErrors === null) {
                            vErrors = [err0];
                        } else {
                            vErrors.push(err0);
                        }
                        errors++;
                    }
                    var valid2 = _errs5 === errors;
                } else {
                    var valid2 = true;
                }
                if (valid2) {
                    if (data.offer_ignored !== undefined) {
                        const _errs7 = errors;
                        if (typeof data.offer_ignored !== 'boolean') {
                            const err1 = {
                                instancePath: instancePath + '/offer_ignored',
                                schemaPath:
                                    '#/definitions/ack/properties/Body/definitions/TopLevel/properties/offer_ignored/type',
                                keyword: 'type',
                                params: {type: 'boolean'},
                                message: 'must be boolean',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        var valid2 = _errs7 === errors;
                    } else {
                        var valid2 = true;
                    }
                }
            }
        } else {
            const err2 = {
                instancePath,
                schemaPath:
                    '#/definitions/ack/properties/Body/definitions/TopLevel/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err2];
            } else {
                vErrors.push(err2);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err3 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err3];
        } else {
            vErrors.push(err3);
        }
        errors++;
        validate11.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate11.errors = vErrors;
    return errors === 0;
}
export const validateAck200 = validate12;
const schema14 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        '200': {
            type: 'object',
            additionalProperties: false,
            properties: {
                status: {
                    type: 'string',
                    description: 'Status of the response, eg. success.',
                    enum: ['success'],
                },
                result: {
                    type: 'boolean',
                    description:
                        'The result is true if successful, false otherwise.',
                },
            },
            required: ['status', 'result'],
            title: 'Status200',
        },
    },
    oneOf: [{$ref: '#/definitions/ack/properties/200/definitions/200'}],
};
const schema15 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        status: {
            type: 'string',
            description: 'Status of the response, eg. success.',
            enum: ['success'],
        },
        result: {
            type: 'boolean',
            description: 'The result is true if successful, false otherwise.',
        },
    },
    required: ['status', 'result'],
    title: 'Status200',
};
function validate12(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/ack/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/ack/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/ack/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema15.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/ack/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/ack/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate12.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate12.errors = vErrors;
    return errors === 0;
}
export const validateAck403 = validate13;
const schema16 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        '403': {
            type: 'object',
            additionalProperties: false,
            properties: {
                status: {
                    type: 'string',
                    description: 'Status of the response, eg. success.',
                    enum: ['success', 'failed'],
                },
                result: {
                    type: 'string',
                    enum: [
                        '404 Not Found',
                        '502 Bad Gateway',
                        '503 Service Unavailable',
                        'All conference hosts departed hosted conference',
                        'API initiated conference termination',
                        'API initiated participant disconnect',
                        'Call disconnected',
                        'Call rejected',
                        'Conference extension not found',
                        'Conference host ended the conference with a DTMF command',
                        'Conference terminated by an administrator',
                        'Conference terminated by another participant',
                        'Disabled',
                        'Disconnected by an administrator',
                        'Disconnected by another participant',
                        'Failed to forward request',
                        'Gateway dial out failed',
                        'Ice failure',
                        'Insufficient licenses',
                        'invalid gateway routing rule transform',
                        'Invalid license',
                        'Invalid PIN',
                        'Invalid protocol for service',
                        'Invalid role',
                        'Invalid token',
                        'Last remaining participant removed from conference after timeout',
                        'Media node disconnected',
                        'Media process disconnected',
                        'Neither conference nor gateway found',
                        'No direct route between Edge and Transcoding',
                        'No participants can keep conference alive',
                        'Out of proxying resource',
                        'Out of resource',
                        'Out of transcoding resource',
                        'Participant exceeded PIN entry retries',
                        'Participant limit reached',
                        'Proxied participant disconnected',
                        'Resource unavailable',
                        'Signaling node disconnected',
                        'SSO Authentication Failed. SSO is not available from this domain',
                        'SSO Authentication Failed. The system is in Maintenance mode',
                        'SSO Authentication Failed',
                        'SSO enabled but no Identity Providers configured',
                        'System in maintenance mode',
                        'Telehealth Call failure',
                        'Test call finished',
                        'Timeout waiting for conference host to join or permit access to locked conference',
                        'Timer expired awaiting token refresh',
                        'transfer failed',
                        'Unexpected Response: 503',
                        'Attempted to acquire license while license already acquired. This is usually due to an internal error.',
                        'Failed to transfer into a multi-party conference',
                        'Failed to transfer into a one-to-one conference',
                    ],
                    description: 'Error strings',
                },
            },
            required: ['status', 'result'],
            title: 'Status403',
        },
    },
    oneOf: [{$ref: '#/definitions/ack/properties/403/definitions/403'}],
};
const schema17 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        status: {
            type: 'string',
            description: 'Status of the response, eg. success.',
            enum: ['success', 'failed'],
        },
        result: {
            type: 'string',
            enum: [
                '404 Not Found',
                '502 Bad Gateway',
                '503 Service Unavailable',
                'All conference hosts departed hosted conference',
                'API initiated conference termination',
                'API initiated participant disconnect',
                'Call disconnected',
                'Call rejected',
                'Conference extension not found',
                'Conference host ended the conference with a DTMF command',
                'Conference terminated by an administrator',
                'Conference terminated by another participant',
                'Disabled',
                'Disconnected by an administrator',
                'Disconnected by another participant',
                'Failed to forward request',
                'Gateway dial out failed',
                'Ice failure',
                'Insufficient licenses',
                'invalid gateway routing rule transform',
                'Invalid license',
                'Invalid PIN',
                'Invalid protocol for service',
                'Invalid role',
                'Invalid token',
                'Last remaining participant removed from conference after timeout',
                'Media node disconnected',
                'Media process disconnected',
                'Neither conference nor gateway found',
                'No direct route between Edge and Transcoding',
                'No participants can keep conference alive',
                'Out of proxying resource',
                'Out of resource',
                'Out of transcoding resource',
                'Participant exceeded PIN entry retries',
                'Participant limit reached',
                'Proxied participant disconnected',
                'Resource unavailable',
                'Signaling node disconnected',
                'SSO Authentication Failed. SSO is not available from this domain',
                'SSO Authentication Failed. The system is in Maintenance mode',
                'SSO Authentication Failed',
                'SSO enabled but no Identity Providers configured',
                'System in maintenance mode',
                'Telehealth Call failure',
                'Test call finished',
                'Timeout waiting for conference host to join or permit access to locked conference',
                'Timer expired awaiting token refresh',
                'transfer failed',
                'Unexpected Response: 503',
                'Attempted to acquire license while license already acquired. This is usually due to an internal error.',
                'Failed to transfer into a multi-party conference',
                'Failed to transfer into a one-to-one conference',
            ],
            description: 'Error strings',
        },
    },
    required: ['status', 'result'],
    title: 'Status403',
};
function validate13(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/ack/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/ack/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/ack/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema17.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs7 = errors;
                            if (typeof data1 !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/ack/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            if (
                                !(
                                    data1 === '404 Not Found' ||
                                    data1 === '502 Bad Gateway' ||
                                    data1 === '503 Service Unavailable' ||
                                    data1 ===
                                        'All conference hosts departed hosted conference' ||
                                    data1 ===
                                        'API initiated conference termination' ||
                                    data1 ===
                                        'API initiated participant disconnect' ||
                                    data1 === 'Call disconnected' ||
                                    data1 === 'Call rejected' ||
                                    data1 ===
                                        'Conference extension not found' ||
                                    data1 ===
                                        'Conference host ended the conference with a DTMF command' ||
                                    data1 ===
                                        'Conference terminated by an administrator' ||
                                    data1 ===
                                        'Conference terminated by another participant' ||
                                    data1 === 'Disabled' ||
                                    data1 ===
                                        'Disconnected by an administrator' ||
                                    data1 ===
                                        'Disconnected by another participant' ||
                                    data1 === 'Failed to forward request' ||
                                    data1 === 'Gateway dial out failed' ||
                                    data1 === 'Ice failure' ||
                                    data1 === 'Insufficient licenses' ||
                                    data1 ===
                                        'invalid gateway routing rule transform' ||
                                    data1 === 'Invalid license' ||
                                    data1 === 'Invalid PIN' ||
                                    data1 === 'Invalid protocol for service' ||
                                    data1 === 'Invalid role' ||
                                    data1 === 'Invalid token' ||
                                    data1 ===
                                        'Last remaining participant removed from conference after timeout' ||
                                    data1 === 'Media node disconnected' ||
                                    data1 === 'Media process disconnected' ||
                                    data1 ===
                                        'Neither conference nor gateway found' ||
                                    data1 ===
                                        'No direct route between Edge and Transcoding' ||
                                    data1 ===
                                        'No participants can keep conference alive' ||
                                    data1 === 'Out of proxying resource' ||
                                    data1 === 'Out of resource' ||
                                    data1 === 'Out of transcoding resource' ||
                                    data1 ===
                                        'Participant exceeded PIN entry retries' ||
                                    data1 === 'Participant limit reached' ||
                                    data1 ===
                                        'Proxied participant disconnected' ||
                                    data1 === 'Resource unavailable' ||
                                    data1 === 'Signaling node disconnected' ||
                                    data1 ===
                                        'SSO Authentication Failed. SSO is not available from this domain' ||
                                    data1 ===
                                        'SSO Authentication Failed. The system is in Maintenance mode' ||
                                    data1 === 'SSO Authentication Failed' ||
                                    data1 ===
                                        'SSO enabled but no Identity Providers configured' ||
                                    data1 === 'System in maintenance mode' ||
                                    data1 === 'Telehealth Call failure' ||
                                    data1 === 'Test call finished' ||
                                    data1 ===
                                        'Timeout waiting for conference host to join or permit access to locked conference' ||
                                    data1 ===
                                        'Timer expired awaiting token refresh' ||
                                    data1 === 'transfer failed' ||
                                    data1 === 'Unexpected Response: 503' ||
                                    data1 ===
                                        'Attempted to acquire license while license already acquired. This is usually due to an internal error.' ||
                                    data1 ===
                                        'Failed to transfer into a multi-party conference' ||
                                    data1 ===
                                        'Failed to transfer into a one-to-one conference'
                                )
                            ) {
                                const err4 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/ack/properties/403/definitions/403/properties/result/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema17.properties.result.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                };
                                if (vErrors === null) {
                                    vErrors = [err4];
                                } else {
                                    vErrors.push(err4);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err5 = {
                instancePath,
                schemaPath:
                    '#/definitions/ack/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err5];
            } else {
                vErrors.push(err5);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err6 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err6];
        } else {
            vErrors.push(err6);
        }
        errors++;
        validate13.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate13.errors = vErrors;
    return errors === 0;
}
export const validateDisconnectCall200 = validate14;
const schema18 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        '200': {
            type: 'object',
            additionalProperties: false,
            properties: {
                status: {
                    type: 'string',
                    description: 'Status of the response, eg. success.',
                    enum: ['success'],
                },
                result: {type: 'null'},
            },
            required: ['status', 'result'],
            title: 'Status200',
        },
    },
    oneOf: [
        {$ref: '#/definitions/disconnect_call/properties/200/definitions/200'},
    ],
};
const schema19 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        status: {
            type: 'string',
            description: 'Status of the response, eg. success.',
            enum: ['success'],
        },
        result: {type: 'null'},
    },
    required: ['status', 'result'],
    title: 'Status200',
};
function validate14(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/disconnect_call/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/disconnect_call/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/disconnect_call/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema19.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (data.result !== null) {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/disconnect_call/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'null'},
                                    message: 'must be null',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/disconnect_call/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate14.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate14.errors = vErrors;
    return errors === 0;
}
export const validateDisconnectCall403 = validate15;
function validate15(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/ack/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/ack/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/ack/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema17.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs7 = errors;
                            if (typeof data1 !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/ack/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            if (
                                !(
                                    data1 === '404 Not Found' ||
                                    data1 === '502 Bad Gateway' ||
                                    data1 === '503 Service Unavailable' ||
                                    data1 ===
                                        'All conference hosts departed hosted conference' ||
                                    data1 ===
                                        'API initiated conference termination' ||
                                    data1 ===
                                        'API initiated participant disconnect' ||
                                    data1 === 'Call disconnected' ||
                                    data1 === 'Call rejected' ||
                                    data1 ===
                                        'Conference extension not found' ||
                                    data1 ===
                                        'Conference host ended the conference with a DTMF command' ||
                                    data1 ===
                                        'Conference terminated by an administrator' ||
                                    data1 ===
                                        'Conference terminated by another participant' ||
                                    data1 === 'Disabled' ||
                                    data1 ===
                                        'Disconnected by an administrator' ||
                                    data1 ===
                                        'Disconnected by another participant' ||
                                    data1 === 'Failed to forward request' ||
                                    data1 === 'Gateway dial out failed' ||
                                    data1 === 'Ice failure' ||
                                    data1 === 'Insufficient licenses' ||
                                    data1 ===
                                        'invalid gateway routing rule transform' ||
                                    data1 === 'Invalid license' ||
                                    data1 === 'Invalid PIN' ||
                                    data1 === 'Invalid protocol for service' ||
                                    data1 === 'Invalid role' ||
                                    data1 === 'Invalid token' ||
                                    data1 ===
                                        'Last remaining participant removed from conference after timeout' ||
                                    data1 === 'Media node disconnected' ||
                                    data1 === 'Media process disconnected' ||
                                    data1 ===
                                        'Neither conference nor gateway found' ||
                                    data1 ===
                                        'No direct route between Edge and Transcoding' ||
                                    data1 ===
                                        'No participants can keep conference alive' ||
                                    data1 === 'Out of proxying resource' ||
                                    data1 === 'Out of resource' ||
                                    data1 === 'Out of transcoding resource' ||
                                    data1 ===
                                        'Participant exceeded PIN entry retries' ||
                                    data1 === 'Participant limit reached' ||
                                    data1 ===
                                        'Proxied participant disconnected' ||
                                    data1 === 'Resource unavailable' ||
                                    data1 === 'Signaling node disconnected' ||
                                    data1 ===
                                        'SSO Authentication Failed. SSO is not available from this domain' ||
                                    data1 ===
                                        'SSO Authentication Failed. The system is in Maintenance mode' ||
                                    data1 === 'SSO Authentication Failed' ||
                                    data1 ===
                                        'SSO enabled but no Identity Providers configured' ||
                                    data1 === 'System in maintenance mode' ||
                                    data1 === 'Telehealth Call failure' ||
                                    data1 === 'Test call finished' ||
                                    data1 ===
                                        'Timeout waiting for conference host to join or permit access to locked conference' ||
                                    data1 ===
                                        'Timer expired awaiting token refresh' ||
                                    data1 === 'transfer failed' ||
                                    data1 === 'Unexpected Response: 503' ||
                                    data1 ===
                                        'Attempted to acquire license while license already acquired. This is usually due to an internal error.' ||
                                    data1 ===
                                        'Failed to transfer into a multi-party conference' ||
                                    data1 ===
                                        'Failed to transfer into a one-to-one conference'
                                )
                            ) {
                                const err4 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/ack/properties/403/definitions/403/properties/result/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema17.properties.result.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                };
                                if (vErrors === null) {
                                    vErrors = [err4];
                                } else {
                                    vErrors.push(err4);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err5 = {
                instancePath,
                schemaPath:
                    '#/definitions/ack/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err5];
            } else {
                vErrors.push(err5);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err6 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err6];
        } else {
            vErrors.push(err6);
        }
        errors++;
        validate15.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate15.errors = vErrors;
    return errors === 0;
}
export const validateDisconnectCall502 = validate16;
const schema22 = {type: 'string'};
function validate16(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (typeof data !== 'string') {
        validate16.errors = [
            {
                instancePath,
                schemaPath: '#/type',
                keyword: 'type',
                params: {type: 'string'},
                message: 'must be string',
            },
        ];
        return false;
    }
    validate16.errors = vErrors;
    return errors === 0;
}
export const validateDisconnectCall503 = validate17;
const schema23 = {type: 'string'};
function validate17(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (typeof data !== 'string') {
        validate17.errors = [
            {
                instancePath,
                schemaPath: '#/type',
                keyword: 'type',
                params: {type: 'string'},
                message: 'must be string',
            },
        ];
        return false;
    }
    validate17.errors = vErrors;
    return errors === 0;
}
export const validateDtmfBody = validate18;
const schema24 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        TopLevel: {
            type: 'object',
            additionalProperties: false,
            properties: {
                digits: {
                    type: 'string',
                    description: 'The DTMF digits to send.',
                },
            },
            required: ['digits'],
            title: 'TopLevel',
        },
    },
    oneOf: [{$ref: '#/definitions/dtmf/properties/Body/definitions/TopLevel'}],
};
const schema25 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        digits: {type: 'string', description: 'The DTMF digits to send.'},
    },
    required: ['digits'],
    title: 'TopLevel',
};
function validate18(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (data.digits === undefined && (missing0 = 'digits')) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/dtmf/properties/Body/definitions/TopLevel/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'digits')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.digits !== undefined) {
                        if (typeof data.digits !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/digits',
                                schemaPath:
                                    '#/definitions/dtmf/properties/Body/definitions/TopLevel/properties/digits/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                    }
                }
            }
        } else {
            const err2 = {
                instancePath,
                schemaPath:
                    '#/definitions/dtmf/properties/Body/definitions/TopLevel/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err2];
            } else {
                vErrors.push(err2);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err3 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err3];
        } else {
            vErrors.push(err3);
        }
        errors++;
        validate18.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate18.errors = vErrors;
    return errors === 0;
}
export const validateDtmf200 = validate19;
function validate19(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/ack/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/ack/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/ack/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema15.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/ack/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/ack/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate19.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate19.errors = vErrors;
    return errors === 0;
}
export const validateDtmf403 = validate20;
function validate20(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/ack/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/ack/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/ack/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema17.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs7 = errors;
                            if (typeof data1 !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/ack/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            if (
                                !(
                                    data1 === '404 Not Found' ||
                                    data1 === '502 Bad Gateway' ||
                                    data1 === '503 Service Unavailable' ||
                                    data1 ===
                                        'All conference hosts departed hosted conference' ||
                                    data1 ===
                                        'API initiated conference termination' ||
                                    data1 ===
                                        'API initiated participant disconnect' ||
                                    data1 === 'Call disconnected' ||
                                    data1 === 'Call rejected' ||
                                    data1 ===
                                        'Conference extension not found' ||
                                    data1 ===
                                        'Conference host ended the conference with a DTMF command' ||
                                    data1 ===
                                        'Conference terminated by an administrator' ||
                                    data1 ===
                                        'Conference terminated by another participant' ||
                                    data1 === 'Disabled' ||
                                    data1 ===
                                        'Disconnected by an administrator' ||
                                    data1 ===
                                        'Disconnected by another participant' ||
                                    data1 === 'Failed to forward request' ||
                                    data1 === 'Gateway dial out failed' ||
                                    data1 === 'Ice failure' ||
                                    data1 === 'Insufficient licenses' ||
                                    data1 ===
                                        'invalid gateway routing rule transform' ||
                                    data1 === 'Invalid license' ||
                                    data1 === 'Invalid PIN' ||
                                    data1 === 'Invalid protocol for service' ||
                                    data1 === 'Invalid role' ||
                                    data1 === 'Invalid token' ||
                                    data1 ===
                                        'Last remaining participant removed from conference after timeout' ||
                                    data1 === 'Media node disconnected' ||
                                    data1 === 'Media process disconnected' ||
                                    data1 ===
                                        'Neither conference nor gateway found' ||
                                    data1 ===
                                        'No direct route between Edge and Transcoding' ||
                                    data1 ===
                                        'No participants can keep conference alive' ||
                                    data1 === 'Out of proxying resource' ||
                                    data1 === 'Out of resource' ||
                                    data1 === 'Out of transcoding resource' ||
                                    data1 ===
                                        'Participant exceeded PIN entry retries' ||
                                    data1 === 'Participant limit reached' ||
                                    data1 ===
                                        'Proxied participant disconnected' ||
                                    data1 === 'Resource unavailable' ||
                                    data1 === 'Signaling node disconnected' ||
                                    data1 ===
                                        'SSO Authentication Failed. SSO is not available from this domain' ||
                                    data1 ===
                                        'SSO Authentication Failed. The system is in Maintenance mode' ||
                                    data1 === 'SSO Authentication Failed' ||
                                    data1 ===
                                        'SSO enabled but no Identity Providers configured' ||
                                    data1 === 'System in maintenance mode' ||
                                    data1 === 'Telehealth Call failure' ||
                                    data1 === 'Test call finished' ||
                                    data1 ===
                                        'Timeout waiting for conference host to join or permit access to locked conference' ||
                                    data1 ===
                                        'Timer expired awaiting token refresh' ||
                                    data1 === 'transfer failed' ||
                                    data1 === 'Unexpected Response: 503' ||
                                    data1 ===
                                        'Attempted to acquire license while license already acquired. This is usually due to an internal error.' ||
                                    data1 ===
                                        'Failed to transfer into a multi-party conference' ||
                                    data1 ===
                                        'Failed to transfer into a one-to-one conference'
                                )
                            ) {
                                const err4 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/ack/properties/403/definitions/403/properties/result/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema17.properties.result.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                };
                                if (vErrors === null) {
                                    vErrors = [err4];
                                } else {
                                    vErrors.push(err4);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err5 = {
                instancePath,
                schemaPath:
                    '#/definitions/ack/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err5];
            } else {
                vErrors.push(err5);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err6 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err6];
        } else {
            vErrors.push(err6);
        }
        errors++;
        validate20.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate20.errors = vErrors;
    return errors === 0;
}
export const validateNewCandidateBody = validate21;
const schema30 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        TopLevel: {
            type: 'object',
            additionalProperties: false,
            properties: {
                candidate: {
                    type: 'string',
                    description:
                        'Representation of address in candidate-attribute format as per RFC5245.',
                },
                mid: {
                    type: 'string',
                    description: 'The media stream identifier tag.',
                },
                ufrag: {
                    type: 'string',
                    description:
                        'The randomly generated username fragment of the ICE credentials.',
                },
                pwd: {
                    type: 'string',
                    description:
                        'The randomly generated password of the ICE credentials.',
                },
            },
            required: ['candidate', 'mid'],
            title: 'TopLevel',
        },
    },
    oneOf: [
        {
            $ref: '#/definitions/new_candidate/properties/Body/definitions/TopLevel',
        },
    ],
};
const schema31 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        candidate: {
            type: 'string',
            description:
                'Representation of address in candidate-attribute format as per RFC5245.',
        },
        mid: {type: 'string', description: 'The media stream identifier tag.'},
        ufrag: {
            type: 'string',
            description:
                'The randomly generated username fragment of the ICE credentials.',
        },
        pwd: {
            type: 'string',
            description:
                'The randomly generated password of the ICE credentials.',
        },
    },
    required: ['candidate', 'mid'],
    title: 'TopLevel',
};
function validate21(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.candidate === undefined && (missing0 = 'candidate')) ||
                (data.mid === undefined && (missing0 = 'mid'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/new_candidate/properties/Body/definitions/TopLevel/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (
                        !(
                            key0 === 'candidate' ||
                            key0 === 'mid' ||
                            key0 === 'ufrag' ||
                            key0 === 'pwd'
                        )
                    ) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.candidate !== undefined) {
                        const _errs5 = errors;
                        if (typeof data.candidate !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/candidate',
                                schemaPath:
                                    '#/definitions/new_candidate/properties/Body/definitions/TopLevel/properties/candidate/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.mid !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.mid !== 'string') {
                                const err2 = {
                                    instancePath: instancePath + '/mid',
                                    schemaPath:
                                        '#/definitions/new_candidate/properties/Body/definitions/TopLevel/properties/mid/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err2];
                                } else {
                                    vErrors.push(err2);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                        if (valid2) {
                            if (data.ufrag !== undefined) {
                                const _errs9 = errors;
                                if (typeof data.ufrag !== 'string') {
                                    const err3 = {
                                        instancePath: instancePath + '/ufrag',
                                        schemaPath:
                                            '#/definitions/new_candidate/properties/Body/definitions/TopLevel/properties/ufrag/type',
                                        keyword: 'type',
                                        params: {type: 'string'},
                                        message: 'must be string',
                                    };
                                    if (vErrors === null) {
                                        vErrors = [err3];
                                    } else {
                                        vErrors.push(err3);
                                    }
                                    errors++;
                                }
                                var valid2 = _errs9 === errors;
                            } else {
                                var valid2 = true;
                            }
                            if (valid2) {
                                if (data.pwd !== undefined) {
                                    const _errs11 = errors;
                                    if (typeof data.pwd !== 'string') {
                                        const err4 = {
                                            instancePath: instancePath + '/pwd',
                                            schemaPath:
                                                '#/definitions/new_candidate/properties/Body/definitions/TopLevel/properties/pwd/type',
                                            keyword: 'type',
                                            params: {type: 'string'},
                                            message: 'must be string',
                                        };
                                        if (vErrors === null) {
                                            vErrors = [err4];
                                        } else {
                                            vErrors.push(err4);
                                        }
                                        errors++;
                                    }
                                    var valid2 = _errs11 === errors;
                                } else {
                                    var valid2 = true;
                                }
                            }
                        }
                    }
                }
            }
        } else {
            const err5 = {
                instancePath,
                schemaPath:
                    '#/definitions/new_candidate/properties/Body/definitions/TopLevel/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err5];
            } else {
                vErrors.push(err5);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err6 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err6];
        } else {
            vErrors.push(err6);
        }
        errors++;
        validate21.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate21.errors = vErrors;
    return errors === 0;
}
export const validateNewCandidate200 = validate22;
const schema32 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        '200': {
            type: 'object',
            additionalProperties: false,
            properties: {
                status: {
                    type: 'string',
                    description: 'Status of the response, eg. success.',
                    enum: ['success'],
                },
                result: {type: 'null'},
            },
            required: ['status', 'result'],
            title: 'Status200',
        },
    },
    oneOf: [
        {$ref: '#/definitions/new_candidate/properties/200/definitions/200'},
    ],
};
const schema33 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        status: {
            type: 'string',
            description: 'Status of the response, eg. success.',
            enum: ['success'],
        },
        result: {type: 'null'},
    },
    required: ['status', 'result'],
    title: 'Status200',
};
function validate22(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/new_candidate/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/new_candidate/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/new_candidate/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema33.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (data.result !== null) {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/new_candidate/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'null'},
                                    message: 'must be null',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/new_candidate/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate22.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate22.errors = vErrors;
    return errors === 0;
}
export const validateNewCandidate403 = validate23;
function validate23(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/ack/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/ack/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/ack/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema17.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs7 = errors;
                            if (typeof data1 !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/ack/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            if (
                                !(
                                    data1 === '404 Not Found' ||
                                    data1 === '502 Bad Gateway' ||
                                    data1 === '503 Service Unavailable' ||
                                    data1 ===
                                        'All conference hosts departed hosted conference' ||
                                    data1 ===
                                        'API initiated conference termination' ||
                                    data1 ===
                                        'API initiated participant disconnect' ||
                                    data1 === 'Call disconnected' ||
                                    data1 === 'Call rejected' ||
                                    data1 ===
                                        'Conference extension not found' ||
                                    data1 ===
                                        'Conference host ended the conference with a DTMF command' ||
                                    data1 ===
                                        'Conference terminated by an administrator' ||
                                    data1 ===
                                        'Conference terminated by another participant' ||
                                    data1 === 'Disabled' ||
                                    data1 ===
                                        'Disconnected by an administrator' ||
                                    data1 ===
                                        'Disconnected by another participant' ||
                                    data1 === 'Failed to forward request' ||
                                    data1 === 'Gateway dial out failed' ||
                                    data1 === 'Ice failure' ||
                                    data1 === 'Insufficient licenses' ||
                                    data1 ===
                                        'invalid gateway routing rule transform' ||
                                    data1 === 'Invalid license' ||
                                    data1 === 'Invalid PIN' ||
                                    data1 === 'Invalid protocol for service' ||
                                    data1 === 'Invalid role' ||
                                    data1 === 'Invalid token' ||
                                    data1 ===
                                        'Last remaining participant removed from conference after timeout' ||
                                    data1 === 'Media node disconnected' ||
                                    data1 === 'Media process disconnected' ||
                                    data1 ===
                                        'Neither conference nor gateway found' ||
                                    data1 ===
                                        'No direct route between Edge and Transcoding' ||
                                    data1 ===
                                        'No participants can keep conference alive' ||
                                    data1 === 'Out of proxying resource' ||
                                    data1 === 'Out of resource' ||
                                    data1 === 'Out of transcoding resource' ||
                                    data1 ===
                                        'Participant exceeded PIN entry retries' ||
                                    data1 === 'Participant limit reached' ||
                                    data1 ===
                                        'Proxied participant disconnected' ||
                                    data1 === 'Resource unavailable' ||
                                    data1 === 'Signaling node disconnected' ||
                                    data1 ===
                                        'SSO Authentication Failed. SSO is not available from this domain' ||
                                    data1 ===
                                        'SSO Authentication Failed. The system is in Maintenance mode' ||
                                    data1 === 'SSO Authentication Failed' ||
                                    data1 ===
                                        'SSO enabled but no Identity Providers configured' ||
                                    data1 === 'System in maintenance mode' ||
                                    data1 === 'Telehealth Call failure' ||
                                    data1 === 'Test call finished' ||
                                    data1 ===
                                        'Timeout waiting for conference host to join or permit access to locked conference' ||
                                    data1 ===
                                        'Timer expired awaiting token refresh' ||
                                    data1 === 'transfer failed' ||
                                    data1 === 'Unexpected Response: 503' ||
                                    data1 ===
                                        'Attempted to acquire license while license already acquired. This is usually due to an internal error.' ||
                                    data1 ===
                                        'Failed to transfer into a multi-party conference' ||
                                    data1 ===
                                        'Failed to transfer into a one-to-one conference'
                                )
                            ) {
                                const err4 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/ack/properties/403/definitions/403/properties/result/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema17.properties.result.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                };
                                if (vErrors === null) {
                                    vErrors = [err4];
                                } else {
                                    vErrors.push(err4);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err5 = {
                instancePath,
                schemaPath:
                    '#/definitions/ack/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err5];
            } else {
                vErrors.push(err5);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err6 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err6];
        } else {
            vErrors.push(err6);
        }
        errors++;
        validate23.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate23.errors = vErrors;
    return errors === 0;
}
export const validateNewCandidate502 = validate24;
const schema36 = {type: 'string'};
function validate24(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (typeof data !== 'string') {
        validate24.errors = [
            {
                instancePath,
                schemaPath: '#/type',
                keyword: 'type',
                params: {type: 'string'},
                message: 'must be string',
            },
        ];
        return false;
    }
    validate24.errors = vErrors;
    return errors === 0;
}
export const validateUpdateBody = validate25;
const schema37 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        TopLevel: {
            type: 'object',
            additionalProperties: false,
            properties: {
                sdp: {type: 'string', description: 'The new SDP.'},
                fecc_supported: {
                    type: 'string',
                    default: false,
                    description:
                        'Set to true if this participant can be sent FECC messages; false if not.',
                },
            },
            required: [],
            title: 'TopLevel',
        },
    },
    oneOf: [
        {$ref: '#/definitions/update/properties/Body/definitions/TopLevel'},
    ],
};
const schema38 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        sdp: {type: 'string', description: 'The new SDP.'},
        fecc_supported: {
            type: 'string',
            default: false,
            description:
                'Set to true if this participant can be sent FECC messages; false if not.',
        },
    },
    required: [],
    title: 'TopLevel',
};
function validate25(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            const _errs4 = errors;
            for (const key0 in data) {
                if (!(key0 === 'sdp' || key0 === 'fecc_supported')) {
                    delete data[key0];
                }
            }
            if (_errs4 === errors) {
                if (data.sdp !== undefined) {
                    const _errs5 = errors;
                    if (typeof data.sdp !== 'string') {
                        const err0 = {
                            instancePath: instancePath + '/sdp',
                            schemaPath:
                                '#/definitions/update/properties/Body/definitions/TopLevel/properties/sdp/type',
                            keyword: 'type',
                            params: {type: 'string'},
                            message: 'must be string',
                        };
                        if (vErrors === null) {
                            vErrors = [err0];
                        } else {
                            vErrors.push(err0);
                        }
                        errors++;
                    }
                    var valid2 = _errs5 === errors;
                } else {
                    var valid2 = true;
                }
                if (valid2) {
                    if (data.fecc_supported !== undefined) {
                        const _errs7 = errors;
                        if (typeof data.fecc_supported !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/fecc_supported',
                                schemaPath:
                                    '#/definitions/update/properties/Body/definitions/TopLevel/properties/fecc_supported/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        var valid2 = _errs7 === errors;
                    } else {
                        var valid2 = true;
                    }
                }
            }
        } else {
            const err2 = {
                instancePath,
                schemaPath:
                    '#/definitions/update/properties/Body/definitions/TopLevel/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err2];
            } else {
                vErrors.push(err2);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err3 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err3];
        } else {
            vErrors.push(err3);
        }
        errors++;
        validate25.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate25.errors = vErrors;
    return errors === 0;
}
export const validateUpdate200 = validate26;
const schema39 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        '200': {
            type: 'object',
            additionalProperties: false,
            properties: {
                status: {
                    type: 'string',
                    description: 'Status of the response, eg. success.',
                    enum: ['success', 'failed'],
                },
                result: {
                    oneOf: [
                        {
                            type: 'string',
                            description: 'SDP answer from the mcu',
                        },
                        {
                            $ref: '#/definitions/update/properties/200/definitions/Result',
                        },
                    ],
                },
            },
            required: ['status', 'result'],
            title: 'Status200',
        },
        Result: {
            type: 'object',
            additionalProperties: false,
            properties: {
                call_uuid: {type: 'string'},
                sdp: {type: 'string', description: 'SDP answer from the mcu'},
            },
            required: ['call_uuid', 'sdp'],
            title: 'Result',
        },
    },
    oneOf: [{$ref: '#/definitions/update/properties/200/definitions/200'}],
};
const schema40 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        status: {
            type: 'string',
            description: 'Status of the response, eg. success.',
            enum: ['success', 'failed'],
        },
        result: {
            oneOf: [
                {type: 'string', description: 'SDP answer from the mcu'},
                {
                    $ref: '#/definitions/update/properties/200/definitions/Result',
                },
            ],
        },
    },
    required: ['status', 'result'],
    title: 'Status200',
};
const schema41 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        call_uuid: {type: 'string'},
        sdp: {type: 'string', description: 'SDP answer from the mcu'},
    },
    required: ['call_uuid', 'sdp'],
    title: 'Result',
};
function validate27(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (errors === 0) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                validate27.errors = [
                    {
                        instancePath,
                        schemaPath: '#/required',
                        keyword: 'required',
                        params: {missingProperty: missing0},
                        message:
                            "must have required property '" + missing0 + "'",
                    },
                ];
                return false;
            } else {
                const _errs1 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs1 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs2 = errors;
                        if (typeof data0 !== 'string') {
                            validate27.errors = [
                                {
                                    instancePath: instancePath + '/status',
                                    schemaPath: '#/properties/status/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                },
                            ];
                            return false;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            validate27.errors = [
                                {
                                    instancePath: instancePath + '/status',
                                    schemaPath: '#/properties/status/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema40.properties.status.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                },
                            ];
                            return false;
                        }
                        var valid0 = _errs2 === errors;
                    } else {
                        var valid0 = true;
                    }
                    if (valid0) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs4 = errors;
                            const _errs5 = errors;
                            let valid1 = false;
                            let passing0 = null;
                            const _errs6 = errors;
                            if (typeof data1 !== 'string') {
                                const err0 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/properties/result/oneOf/0/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err0];
                                } else {
                                    vErrors.push(err0);
                                }
                                errors++;
                            }
                            var _valid0 = _errs6 === errors;
                            if (_valid0) {
                                valid1 = true;
                                passing0 = 0;
                            }
                            const _errs8 = errors;
                            const _errs9 = errors;
                            if (errors === _errs9) {
                                if (
                                    data1 &&
                                    typeof data1 == 'object' &&
                                    !Array.isArray(data1)
                                ) {
                                    let missing1;
                                    if (
                                        (data1.call_uuid === undefined &&
                                            (missing1 = 'call_uuid')) ||
                                        (data1.sdp === undefined &&
                                            (missing1 = 'sdp'))
                                    ) {
                                        const err1 = {
                                            instancePath:
                                                instancePath + '/result',
                                            schemaPath:
                                                '#/definitions/update/properties/200/definitions/Result/required',
                                            keyword: 'required',
                                            params: {missingProperty: missing1},
                                            message:
                                                "must have required property '" +
                                                missing1 +
                                                "'",
                                        };
                                        if (vErrors === null) {
                                            vErrors = [err1];
                                        } else {
                                            vErrors.push(err1);
                                        }
                                        errors++;
                                    } else {
                                        const _errs11 = errors;
                                        for (const key1 in data1) {
                                            if (
                                                !(
                                                    key1 === 'call_uuid' ||
                                                    key1 === 'sdp'
                                                )
                                            ) {
                                                delete data1[key1];
                                            }
                                        }
                                        if (_errs11 === errors) {
                                            if (data1.call_uuid !== undefined) {
                                                const _errs12 = errors;
                                                if (
                                                    typeof data1.call_uuid !==
                                                    'string'
                                                ) {
                                                    const err2 = {
                                                        instancePath:
                                                            instancePath +
                                                            '/result/call_uuid',
                                                        schemaPath:
                                                            '#/definitions/update/properties/200/definitions/Result/properties/call_uuid/type',
                                                        keyword: 'type',
                                                        params: {
                                                            type: 'string',
                                                        },
                                                        message:
                                                            'must be string',
                                                    };
                                                    if (vErrors === null) {
                                                        vErrors = [err2];
                                                    } else {
                                                        vErrors.push(err2);
                                                    }
                                                    errors++;
                                                }
                                                var valid3 = _errs12 === errors;
                                            } else {
                                                var valid3 = true;
                                            }
                                            if (valid3) {
                                                if (data1.sdp !== undefined) {
                                                    const _errs14 = errors;
                                                    if (
                                                        typeof data1.sdp !==
                                                        'string'
                                                    ) {
                                                        const err3 = {
                                                            instancePath:
                                                                instancePath +
                                                                '/result/sdp',
                                                            schemaPath:
                                                                '#/definitions/update/properties/200/definitions/Result/properties/sdp/type',
                                                            keyword: 'type',
                                                            params: {
                                                                type: 'string',
                                                            },
                                                            message:
                                                                'must be string',
                                                        };
                                                        if (vErrors === null) {
                                                            vErrors = [err3];
                                                        } else {
                                                            vErrors.push(err3);
                                                        }
                                                        errors++;
                                                    }
                                                    var valid3 =
                                                        _errs14 === errors;
                                                } else {
                                                    var valid3 = true;
                                                }
                                            }
                                        }
                                    }
                                } else {
                                    const err4 = {
                                        instancePath: instancePath + '/result',
                                        schemaPath:
                                            '#/definitions/update/properties/200/definitions/Result/type',
                                        keyword: 'type',
                                        params: {type: 'object'},
                                        message: 'must be object',
                                    };
                                    if (vErrors === null) {
                                        vErrors = [err4];
                                    } else {
                                        vErrors.push(err4);
                                    }
                                    errors++;
                                }
                            }
                            var _valid0 = _errs8 === errors;
                            if (_valid0 && valid1) {
                                valid1 = false;
                                passing0 = [passing0, 1];
                            } else {
                                if (_valid0) {
                                    valid1 = true;
                                    passing0 = 1;
                                }
                            }
                            if (!valid1) {
                                const err5 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath: '#/properties/result/oneOf',
                                    keyword: 'oneOf',
                                    params: {passingSchemas: passing0},
                                    message:
                                        'must match exactly one schema in oneOf',
                                };
                                if (vErrors === null) {
                                    vErrors = [err5];
                                } else {
                                    vErrors.push(err5);
                                }
                                errors++;
                                validate27.errors = vErrors;
                                return false;
                            } else {
                                errors = _errs5;
                                if (vErrors !== null) {
                                    if (_errs5) {
                                        vErrors.length = _errs5;
                                    } else {
                                        vErrors = null;
                                    }
                                }
                            }
                            var valid0 = _errs4 === errors;
                        } else {
                            var valid0 = true;
                        }
                    }
                }
            }
        } else {
            validate27.errors = [
                {
                    instancePath,
                    schemaPath: '#/type',
                    keyword: 'type',
                    params: {type: 'object'},
                    message: 'must be object',
                },
            ];
            return false;
        }
    }
    validate27.errors = vErrors;
    return errors === 0;
}
function validate26(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    if (
        !validate27(data, {
            instancePath,
            parentData,
            parentDataProperty,
            rootData,
        })
    ) {
        vErrors =
            vErrors === null
                ? validate27.errors
                : vErrors.concat(validate27.errors);
        errors = vErrors.length;
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err0 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err0];
        } else {
            vErrors.push(err0);
        }
        errors++;
        validate26.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate26.errors = vErrors;
    return errors === 0;
}
export const validateUpdate403 = validate29;
const schema42 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        '403': {
            type: 'object',
            additionalProperties: false,
            properties: {
                status: {
                    type: 'string',
                    description: 'Status of the response, eg. success.',
                    enum: ['success', 'failed'],
                },
                result: {
                    oneOf: [
                        {type: 'null'},
                        {
                            $ref: '#/definitions/ack/properties/403/definitions/403/properties/result',
                        },
                    ],
                },
            },
            required: ['status', 'result'],
            title: 'Status403',
        },
    },
    oneOf: [{$ref: '#/definitions/update/properties/403/definitions/403'}],
};
const schema43 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        status: {
            type: 'string',
            description: 'Status of the response, eg. success.',
            enum: ['success', 'failed'],
        },
        result: {
            oneOf: [
                {type: 'null'},
                {
                    $ref: '#/definitions/ack/properties/403/definitions/403/properties/result',
                },
            ],
        },
    },
    required: ['status', 'result'],
    title: 'Status403',
};
const schema44 = {
    type: 'string',
    enum: [
        '404 Not Found',
        '502 Bad Gateway',
        '503 Service Unavailable',
        'All conference hosts departed hosted conference',
        'API initiated conference termination',
        'API initiated participant disconnect',
        'Call disconnected',
        'Call rejected',
        'Conference extension not found',
        'Conference host ended the conference with a DTMF command',
        'Conference terminated by an administrator',
        'Conference terminated by another participant',
        'Disabled',
        'Disconnected by an administrator',
        'Disconnected by another participant',
        'Failed to forward request',
        'Gateway dial out failed',
        'Ice failure',
        'Insufficient licenses',
        'invalid gateway routing rule transform',
        'Invalid license',
        'Invalid PIN',
        'Invalid protocol for service',
        'Invalid role',
        'Invalid token',
        'Last remaining participant removed from conference after timeout',
        'Media node disconnected',
        'Media process disconnected',
        'Neither conference nor gateway found',
        'No direct route between Edge and Transcoding',
        'No participants can keep conference alive',
        'Out of proxying resource',
        'Out of resource',
        'Out of transcoding resource',
        'Participant exceeded PIN entry retries',
        'Participant limit reached',
        'Proxied participant disconnected',
        'Resource unavailable',
        'Signaling node disconnected',
        'SSO Authentication Failed. SSO is not available from this domain',
        'SSO Authentication Failed. The system is in Maintenance mode',
        'SSO Authentication Failed',
        'SSO enabled but no Identity Providers configured',
        'System in maintenance mode',
        'Telehealth Call failure',
        'Test call finished',
        'Timeout waiting for conference host to join or permit access to locked conference',
        'Timer expired awaiting token refresh',
        'transfer failed',
        'Unexpected Response: 503',
        'Attempted to acquire license while license already acquired. This is usually due to an internal error.',
        'Failed to transfer into a multi-party conference',
        'Failed to transfer into a one-to-one conference',
    ],
    description: 'Error strings',
};
function validate30(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (errors === 0) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                validate30.errors = [
                    {
                        instancePath,
                        schemaPath: '#/required',
                        keyword: 'required',
                        params: {missingProperty: missing0},
                        message:
                            "must have required property '" + missing0 + "'",
                    },
                ];
                return false;
            } else {
                const _errs1 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs1 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs2 = errors;
                        if (typeof data0 !== 'string') {
                            validate30.errors = [
                                {
                                    instancePath: instancePath + '/status',
                                    schemaPath: '#/properties/status/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                },
                            ];
                            return false;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            validate30.errors = [
                                {
                                    instancePath: instancePath + '/status',
                                    schemaPath: '#/properties/status/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema43.properties.status.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                },
                            ];
                            return false;
                        }
                        var valid0 = _errs2 === errors;
                    } else {
                        var valid0 = true;
                    }
                    if (valid0) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs4 = errors;
                            const _errs5 = errors;
                            let valid1 = false;
                            let passing0 = null;
                            const _errs6 = errors;
                            if (data1 !== null) {
                                const err0 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/properties/result/oneOf/0/type',
                                    keyword: 'type',
                                    params: {type: 'null'},
                                    message: 'must be null',
                                };
                                if (vErrors === null) {
                                    vErrors = [err0];
                                } else {
                                    vErrors.push(err0);
                                }
                                errors++;
                            }
                            var _valid0 = _errs6 === errors;
                            if (_valid0) {
                                valid1 = true;
                                passing0 = 0;
                            }
                            const _errs8 = errors;
                            if (typeof data1 !== 'string') {
                                const err1 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/ack/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err1];
                                } else {
                                    vErrors.push(err1);
                                }
                                errors++;
                            }
                            if (
                                !(
                                    data1 === '404 Not Found' ||
                                    data1 === '502 Bad Gateway' ||
                                    data1 === '503 Service Unavailable' ||
                                    data1 ===
                                        'All conference hosts departed hosted conference' ||
                                    data1 ===
                                        'API initiated conference termination' ||
                                    data1 ===
                                        'API initiated participant disconnect' ||
                                    data1 === 'Call disconnected' ||
                                    data1 === 'Call rejected' ||
                                    data1 ===
                                        'Conference extension not found' ||
                                    data1 ===
                                        'Conference host ended the conference with a DTMF command' ||
                                    data1 ===
                                        'Conference terminated by an administrator' ||
                                    data1 ===
                                        'Conference terminated by another participant' ||
                                    data1 === 'Disabled' ||
                                    data1 ===
                                        'Disconnected by an administrator' ||
                                    data1 ===
                                        'Disconnected by another participant' ||
                                    data1 === 'Failed to forward request' ||
                                    data1 === 'Gateway dial out failed' ||
                                    data1 === 'Ice failure' ||
                                    data1 === 'Insufficient licenses' ||
                                    data1 ===
                                        'invalid gateway routing rule transform' ||
                                    data1 === 'Invalid license' ||
                                    data1 === 'Invalid PIN' ||
                                    data1 === 'Invalid protocol for service' ||
                                    data1 === 'Invalid role' ||
                                    data1 === 'Invalid token' ||
                                    data1 ===
                                        'Last remaining participant removed from conference after timeout' ||
                                    data1 === 'Media node disconnected' ||
                                    data1 === 'Media process disconnected' ||
                                    data1 ===
                                        'Neither conference nor gateway found' ||
                                    data1 ===
                                        'No direct route between Edge and Transcoding' ||
                                    data1 ===
                                        'No participants can keep conference alive' ||
                                    data1 === 'Out of proxying resource' ||
                                    data1 === 'Out of resource' ||
                                    data1 === 'Out of transcoding resource' ||
                                    data1 ===
                                        'Participant exceeded PIN entry retries' ||
                                    data1 === 'Participant limit reached' ||
                                    data1 ===
                                        'Proxied participant disconnected' ||
                                    data1 === 'Resource unavailable' ||
                                    data1 === 'Signaling node disconnected' ||
                                    data1 ===
                                        'SSO Authentication Failed. SSO is not available from this domain' ||
                                    data1 ===
                                        'SSO Authentication Failed. The system is in Maintenance mode' ||
                                    data1 === 'SSO Authentication Failed' ||
                                    data1 ===
                                        'SSO enabled but no Identity Providers configured' ||
                                    data1 === 'System in maintenance mode' ||
                                    data1 === 'Telehealth Call failure' ||
                                    data1 === 'Test call finished' ||
                                    data1 ===
                                        'Timeout waiting for conference host to join or permit access to locked conference' ||
                                    data1 ===
                                        'Timer expired awaiting token refresh' ||
                                    data1 === 'transfer failed' ||
                                    data1 === 'Unexpected Response: 503' ||
                                    data1 ===
                                        'Attempted to acquire license while license already acquired. This is usually due to an internal error.' ||
                                    data1 ===
                                        'Failed to transfer into a multi-party conference' ||
                                    data1 ===
                                        'Failed to transfer into a one-to-one conference'
                                )
                            ) {
                                const err2 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/ack/properties/403/definitions/403/properties/result/enum',
                                    keyword: 'enum',
                                    params: {allowedValues: schema44.enum},
                                    message:
                                        'must be equal to one of the allowed values',
                                };
                                if (vErrors === null) {
                                    vErrors = [err2];
                                } else {
                                    vErrors.push(err2);
                                }
                                errors++;
                            }
                            var _valid0 = _errs8 === errors;
                            if (_valid0 && valid1) {
                                valid1 = false;
                                passing0 = [passing0, 1];
                            } else {
                                if (_valid0) {
                                    valid1 = true;
                                    passing0 = 1;
                                }
                            }
                            if (!valid1) {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath: '#/properties/result/oneOf',
                                    keyword: 'oneOf',
                                    params: {passingSchemas: passing0},
                                    message:
                                        'must match exactly one schema in oneOf',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                                validate30.errors = vErrors;
                                return false;
                            } else {
                                errors = _errs5;
                                if (vErrors !== null) {
                                    if (_errs5) {
                                        vErrors.length = _errs5;
                                    } else {
                                        vErrors = null;
                                    }
                                }
                            }
                            var valid0 = _errs4 === errors;
                        } else {
                            var valid0 = true;
                        }
                    }
                }
            }
        } else {
            validate30.errors = [
                {
                    instancePath,
                    schemaPath: '#/type',
                    keyword: 'type',
                    params: {type: 'object'},
                    message: 'must be object',
                },
            ];
            return false;
        }
    }
    validate30.errors = vErrors;
    return errors === 0;
}
function validate29(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    if (
        !validate30(data, {
            instancePath,
            parentData,
            parentDataProperty,
            rootData,
        })
    ) {
        vErrors =
            vErrors === null
                ? validate30.errors
                : vErrors.concat(validate30.errors);
        errors = vErrors.length;
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err0 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err0];
        } else {
            vErrors.push(err0);
        }
        errors++;
        validate29.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate29.errors = vErrors;
    return errors === 0;
}
export const validateUpdate502 = validate32;
const schema45 = {type: 'string'};
function validate32(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (typeof data !== 'string') {
        validate32.errors = [
            {
                instancePath,
                schemaPath: '#/type',
                keyword: 'type',
                params: {type: 'string'},
                message: 'must be string',
            },
        ];
        return false;
    }
    validate32.errors = vErrors;
    return errors === 0;
}
export const validateUpdate503 = validate33;
const schema46 = {type: 'string'};
function validate33(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (typeof data !== 'string') {
        validate33.errors = [
            {
                instancePath,
                schemaPath: '#/type',
                keyword: 'type',
                params: {type: 'string'},
                message: 'must be string',
            },
        ];
        return false;
    }
    validate33.errors = vErrors;
    return errors === 0;
}
export const validateUpdate520 = validate34;
const schema47 = {type: 'string'};
function validate34(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (typeof data !== 'string') {
        validate34.errors = [
            {
                instancePath,
                schemaPath: '#/type',
                keyword: 'type',
                params: {type: 'string'},
                message: 'must be string',
            },
        ];
        return false;
    }
    validate34.errors = vErrors;
    return errors === 0;
}
export const validateStatisticsBody = validate35;
const schema48 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        TopLevel: {
            type: 'object',
            additionalProperties: false,
            properties: {
                audio: {
                    $ref: '#/definitions/statistics/properties/Body/definitions/Stream',
                },
                video: {
                    $ref: '#/definitions/statistics/properties/Body/definitions/Stream',
                },
                presentation: {
                    $ref: '#/definitions/statistics/properties/Body/definitions/Stream',
                },
            },
            title: 'TopLevel',
        },
        Stream: {
            type: 'object',
            additionalProperties: false,
            properties: {
                rx_bitrate: {
                    type: 'number',
                    description: 'Inbound bitrate (kbps)',
                },
                rx_codec: {type: 'string', description: 'Inbound codec'},
                rx_fps: {
                    type: 'number',
                    description: 'Inbound frame rate (fps)',
                },
                rx_historic_packet_loss: {
                    type: 'number',
                    description:
                        'Total inbound packet loss (percentage from 0 to 100)',
                },
                rx_jitter: {type: 'number', description: 'Inbound jitter (ms)'},
                rx_packets_lost: {
                    type: 'integer',
                    description: 'Total number of inbound packets lost',
                },
                rx_packets_received: {
                    type: 'integer',
                    description: 'Total number of packets received',
                },
                rx_resolution: {
                    type: 'string',
                    description: 'Inbound resolution',
                },
                rx_windowed_packet_loss: {
                    type: 'number',
                    description:
                        'Recent inbound packet loss (percentage from 0 to 100)',
                },
                tx_bitrate: {
                    type: 'number',
                    description: 'Outbound bitrate (kbps)',
                },
                tx_codec: {type: 'string', description: 'Outbound codec'},
                tx_fps: {
                    type: 'number',
                    description: 'Outbound frame rate (fps)',
                },
                tx_historic_packet_loss: {
                    type: 'number',
                    description:
                        'Total outbound packet loss (percentage from 0 to 100)',
                },
                tx_packets_sent: {
                    type: 'integer',
                    description: 'Total number of packets sent',
                },
                tx_rb_jitter: {
                    type: 'number',
                    description: 'Outbound jitter (ms)',
                },
                tx_rb_packetslost: {
                    type: 'integer',
                    description: 'Total number of outbound packets lost',
                },
                tx_resolution: {
                    type: 'string',
                    description: 'Outbound resolution',
                },
                tx_windowed_packet_loss: {
                    type: 'number',
                    description:
                        'Recent outbound packet loss (percentage from 0 to 100)',
                },
            },
            title: 'Stream',
        },
    },
    oneOf: [
        {$ref: '#/definitions/statistics/properties/Body/definitions/TopLevel'},
    ],
};
const schema49 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        audio: {
            $ref: '#/definitions/statistics/properties/Body/definitions/Stream',
        },
        video: {
            $ref: '#/definitions/statistics/properties/Body/definitions/Stream',
        },
        presentation: {
            $ref: '#/definitions/statistics/properties/Body/definitions/Stream',
        },
    },
    title: 'TopLevel',
};
const schema50 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        rx_bitrate: {type: 'number', description: 'Inbound bitrate (kbps)'},
        rx_codec: {type: 'string', description: 'Inbound codec'},
        rx_fps: {type: 'number', description: 'Inbound frame rate (fps)'},
        rx_historic_packet_loss: {
            type: 'number',
            description: 'Total inbound packet loss (percentage from 0 to 100)',
        },
        rx_jitter: {type: 'number', description: 'Inbound jitter (ms)'},
        rx_packets_lost: {
            type: 'integer',
            description: 'Total number of inbound packets lost',
        },
        rx_packets_received: {
            type: 'integer',
            description: 'Total number of packets received',
        },
        rx_resolution: {type: 'string', description: 'Inbound resolution'},
        rx_windowed_packet_loss: {
            type: 'number',
            description:
                'Recent inbound packet loss (percentage from 0 to 100)',
        },
        tx_bitrate: {type: 'number', description: 'Outbound bitrate (kbps)'},
        tx_codec: {type: 'string', description: 'Outbound codec'},
        tx_fps: {type: 'number', description: 'Outbound frame rate (fps)'},
        tx_historic_packet_loss: {
            type: 'number',
            description:
                'Total outbound packet loss (percentage from 0 to 100)',
        },
        tx_packets_sent: {
            type: 'integer',
            description: 'Total number of packets sent',
        },
        tx_rb_jitter: {type: 'number', description: 'Outbound jitter (ms)'},
        tx_rb_packetslost: {
            type: 'integer',
            description: 'Total number of outbound packets lost',
        },
        tx_resolution: {type: 'string', description: 'Outbound resolution'},
        tx_windowed_packet_loss: {
            type: 'number',
            description:
                'Recent outbound packet loss (percentage from 0 to 100)',
        },
    },
    title: 'Stream',
};
const func2 = Object.prototype.hasOwnProperty;
function validate36(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (errors === 0) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            const _errs1 = errors;
            for (const key0 in data) {
                if (
                    !(
                        key0 === 'audio' ||
                        key0 === 'video' ||
                        key0 === 'presentation'
                    )
                ) {
                    delete data[key0];
                }
            }
            if (_errs1 === errors) {
                if (data.audio !== undefined) {
                    let data0 = data.audio;
                    const _errs2 = errors;
                    const _errs3 = errors;
                    if (errors === _errs3) {
                        if (
                            data0 &&
                            typeof data0 == 'object' &&
                            !Array.isArray(data0)
                        ) {
                            const _errs5 = errors;
                            for (const key1 in data0) {
                                if (!func2.call(schema50.properties, key1)) {
                                    delete data0[key1];
                                }
                            }
                            if (_errs5 === errors) {
                                if (data0.rx_bitrate !== undefined) {
                                    const _errs6 = errors;
                                    if (
                                        !(typeof data0.rx_bitrate == 'number')
                                    ) {
                                        validate36.errors = [
                                            {
                                                instancePath:
                                                    instancePath +
                                                    '/audio/rx_bitrate',
                                                schemaPath:
                                                    '#/definitions/statistics/properties/Body/definitions/Stream/properties/rx_bitrate/type',
                                                keyword: 'type',
                                                params: {type: 'number'},
                                                message: 'must be number',
                                            },
                                        ];
                                        return false;
                                    }
                                    var valid2 = _errs6 === errors;
                                } else {
                                    var valid2 = true;
                                }
                                if (valid2) {
                                    if (data0.rx_codec !== undefined) {
                                        const _errs8 = errors;
                                        if (
                                            typeof data0.rx_codec !== 'string'
                                        ) {
                                            validate36.errors = [
                                                {
                                                    instancePath:
                                                        instancePath +
                                                        '/audio/rx_codec',
                                                    schemaPath:
                                                        '#/definitions/statistics/properties/Body/definitions/Stream/properties/rx_codec/type',
                                                    keyword: 'type',
                                                    params: {type: 'string'},
                                                    message: 'must be string',
                                                },
                                            ];
                                            return false;
                                        }
                                        var valid2 = _errs8 === errors;
                                    } else {
                                        var valid2 = true;
                                    }
                                    if (valid2) {
                                        if (data0.rx_fps !== undefined) {
                                            const _errs10 = errors;
                                            if (
                                                !(
                                                    typeof data0.rx_fps ==
                                                    'number'
                                                )
                                            ) {
                                                validate36.errors = [
                                                    {
                                                        instancePath:
                                                            instancePath +
                                                            '/audio/rx_fps',
                                                        schemaPath:
                                                            '#/definitions/statistics/properties/Body/definitions/Stream/properties/rx_fps/type',
                                                        keyword: 'type',
                                                        params: {
                                                            type: 'number',
                                                        },
                                                        message:
                                                            'must be number',
                                                    },
                                                ];
                                                return false;
                                            }
                                            var valid2 = _errs10 === errors;
                                        } else {
                                            var valid2 = true;
                                        }
                                        if (valid2) {
                                            if (
                                                data0.rx_historic_packet_loss !==
                                                undefined
                                            ) {
                                                const _errs12 = errors;
                                                if (
                                                    !(
                                                        typeof data0.rx_historic_packet_loss ==
                                                        'number'
                                                    )
                                                ) {
                                                    validate36.errors = [
                                                        {
                                                            instancePath:
                                                                instancePath +
                                                                '/audio/rx_historic_packet_loss',
                                                            schemaPath:
                                                                '#/definitions/statistics/properties/Body/definitions/Stream/properties/rx_historic_packet_loss/type',
                                                            keyword: 'type',
                                                            params: {
                                                                type: 'number',
                                                            },
                                                            message:
                                                                'must be number',
                                                        },
                                                    ];
                                                    return false;
                                                }
                                                var valid2 = _errs12 === errors;
                                            } else {
                                                var valid2 = true;
                                            }
                                            if (valid2) {
                                                if (
                                                    data0.rx_jitter !==
                                                    undefined
                                                ) {
                                                    const _errs14 = errors;
                                                    if (
                                                        !(
                                                            typeof data0.rx_jitter ==
                                                            'number'
                                                        )
                                                    ) {
                                                        validate36.errors = [
                                                            {
                                                                instancePath:
                                                                    instancePath +
                                                                    '/audio/rx_jitter',
                                                                schemaPath:
                                                                    '#/definitions/statistics/properties/Body/definitions/Stream/properties/rx_jitter/type',
                                                                keyword: 'type',
                                                                params: {
                                                                    type: 'number',
                                                                },
                                                                message:
                                                                    'must be number',
                                                            },
                                                        ];
                                                        return false;
                                                    }
                                                    var valid2 =
                                                        _errs14 === errors;
                                                } else {
                                                    var valid2 = true;
                                                }
                                                if (valid2) {
                                                    if (
                                                        data0.rx_packets_lost !==
                                                        undefined
                                                    ) {
                                                        let data6 =
                                                            data0.rx_packets_lost;
                                                        const _errs16 = errors;
                                                        if (
                                                            !(
                                                                typeof data6 ==
                                                                    'number' &&
                                                                !(data6 % 1) &&
                                                                !isNaN(data6)
                                                            )
                                                        ) {
                                                            validate36.errors =
                                                                [
                                                                    {
                                                                        instancePath:
                                                                            instancePath +
                                                                            '/audio/rx_packets_lost',
                                                                        schemaPath:
                                                                            '#/definitions/statistics/properties/Body/definitions/Stream/properties/rx_packets_lost/type',
                                                                        keyword:
                                                                            'type',
                                                                        params: {
                                                                            type: 'integer',
                                                                        },
                                                                        message:
                                                                            'must be integer',
                                                                    },
                                                                ];
                                                            return false;
                                                        }
                                                        var valid2 =
                                                            _errs16 === errors;
                                                    } else {
                                                        var valid2 = true;
                                                    }
                                                    if (valid2) {
                                                        if (
                                                            data0.rx_packets_received !==
                                                            undefined
                                                        ) {
                                                            let data7 =
                                                                data0.rx_packets_received;
                                                            const _errs18 =
                                                                errors;
                                                            if (
                                                                !(
                                                                    typeof data7 ==
                                                                        'number' &&
                                                                    !(
                                                                        data7 %
                                                                        1
                                                                    ) &&
                                                                    !isNaN(
                                                                        data7,
                                                                    )
                                                                )
                                                            ) {
                                                                validate36.errors =
                                                                    [
                                                                        {
                                                                            instancePath:
                                                                                instancePath +
                                                                                '/audio/rx_packets_received',
                                                                            schemaPath:
                                                                                '#/definitions/statistics/properties/Body/definitions/Stream/properties/rx_packets_received/type',
                                                                            keyword:
                                                                                'type',
                                                                            params: {
                                                                                type: 'integer',
                                                                            },
                                                                            message:
                                                                                'must be integer',
                                                                        },
                                                                    ];
                                                                return false;
                                                            }
                                                            var valid2 =
                                                                _errs18 ===
                                                                errors;
                                                        } else {
                                                            var valid2 = true;
                                                        }
                                                        if (valid2) {
                                                            if (
                                                                data0.rx_resolution !==
                                                                undefined
                                                            ) {
                                                                const _errs20 =
                                                                    errors;
                                                                if (
                                                                    typeof data0.rx_resolution !==
                                                                    'string'
                                                                ) {
                                                                    validate36.errors =
                                                                        [
                                                                            {
                                                                                instancePath:
                                                                                    instancePath +
                                                                                    '/audio/rx_resolution',
                                                                                schemaPath:
                                                                                    '#/definitions/statistics/properties/Body/definitions/Stream/properties/rx_resolution/type',
                                                                                keyword:
                                                                                    'type',
                                                                                params: {
                                                                                    type: 'string',
                                                                                },
                                                                                message:
                                                                                    'must be string',
                                                                            },
                                                                        ];
                                                                    return false;
                                                                }
                                                                var valid2 =
                                                                    _errs20 ===
                                                                    errors;
                                                            } else {
                                                                var valid2 =
                                                                    true;
                                                            }
                                                            if (valid2) {
                                                                if (
                                                                    data0.rx_windowed_packet_loss !==
                                                                    undefined
                                                                ) {
                                                                    const _errs22 =
                                                                        errors;
                                                                    if (
                                                                        !(
                                                                            typeof data0.rx_windowed_packet_loss ==
                                                                            'number'
                                                                        )
                                                                    ) {
                                                                        validate36.errors =
                                                                            [
                                                                                {
                                                                                    instancePath:
                                                                                        instancePath +
                                                                                        '/audio/rx_windowed_packet_loss',
                                                                                    schemaPath:
                                                                                        '#/definitions/statistics/properties/Body/definitions/Stream/properties/rx_windowed_packet_loss/type',
                                                                                    keyword:
                                                                                        'type',
                                                                                    params: {
                                                                                        type: 'number',
                                                                                    },
                                                                                    message:
                                                                                        'must be number',
                                                                                },
                                                                            ];
                                                                        return false;
                                                                    }
                                                                    var valid2 =
                                                                        _errs22 ===
                                                                        errors;
                                                                } else {
                                                                    var valid2 =
                                                                        true;
                                                                }
                                                                if (valid2) {
                                                                    if (
                                                                        data0.tx_bitrate !==
                                                                        undefined
                                                                    ) {
                                                                        const _errs24 =
                                                                            errors;
                                                                        if (
                                                                            !(
                                                                                typeof data0.tx_bitrate ==
                                                                                'number'
                                                                            )
                                                                        ) {
                                                                            validate36.errors =
                                                                                [
                                                                                    {
                                                                                        instancePath:
                                                                                            instancePath +
                                                                                            '/audio/tx_bitrate',
                                                                                        schemaPath:
                                                                                            '#/definitions/statistics/properties/Body/definitions/Stream/properties/tx_bitrate/type',
                                                                                        keyword:
                                                                                            'type',
                                                                                        params: {
                                                                                            type: 'number',
                                                                                        },
                                                                                        message:
                                                                                            'must be number',
                                                                                    },
                                                                                ];
                                                                            return false;
                                                                        }
                                                                        var valid2 =
                                                                            _errs24 ===
                                                                            errors;
                                                                    } else {
                                                                        var valid2 =
                                                                            true;
                                                                    }
                                                                    if (
                                                                        valid2
                                                                    ) {
                                                                        if (
                                                                            data0.tx_codec !==
                                                                            undefined
                                                                        ) {
                                                                            const _errs26 =
                                                                                errors;
                                                                            if (
                                                                                typeof data0.tx_codec !==
                                                                                'string'
                                                                            ) {
                                                                                validate36.errors =
                                                                                    [
                                                                                        {
                                                                                            instancePath:
                                                                                                instancePath +
                                                                                                '/audio/tx_codec',
                                                                                            schemaPath:
                                                                                                '#/definitions/statistics/properties/Body/definitions/Stream/properties/tx_codec/type',
                                                                                            keyword:
                                                                                                'type',
                                                                                            params: {
                                                                                                type: 'string',
                                                                                            },
                                                                                            message:
                                                                                                'must be string',
                                                                                        },
                                                                                    ];
                                                                                return false;
                                                                            }
                                                                            var valid2 =
                                                                                _errs26 ===
                                                                                errors;
                                                                        } else {
                                                                            var valid2 =
                                                                                true;
                                                                        }
                                                                        if (
                                                                            valid2
                                                                        ) {
                                                                            if (
                                                                                data0.tx_fps !==
                                                                                undefined
                                                                            ) {
                                                                                const _errs28 =
                                                                                    errors;
                                                                                if (
                                                                                    !(
                                                                                        typeof data0.tx_fps ==
                                                                                        'number'
                                                                                    )
                                                                                ) {
                                                                                    validate36.errors =
                                                                                        [
                                                                                            {
                                                                                                instancePath:
                                                                                                    instancePath +
                                                                                                    '/audio/tx_fps',
                                                                                                schemaPath:
                                                                                                    '#/definitions/statistics/properties/Body/definitions/Stream/properties/tx_fps/type',
                                                                                                keyword:
                                                                                                    'type',
                                                                                                params: {
                                                                                                    type: 'number',
                                                                                                },
                                                                                                message:
                                                                                                    'must be number',
                                                                                            },
                                                                                        ];
                                                                                    return false;
                                                                                }
                                                                                var valid2 =
                                                                                    _errs28 ===
                                                                                    errors;
                                                                            } else {
                                                                                var valid2 =
                                                                                    true;
                                                                            }
                                                                            if (
                                                                                valid2
                                                                            ) {
                                                                                if (
                                                                                    data0.tx_historic_packet_loss !==
                                                                                    undefined
                                                                                ) {
                                                                                    const _errs30 =
                                                                                        errors;
                                                                                    if (
                                                                                        !(
                                                                                            typeof data0.tx_historic_packet_loss ==
                                                                                            'number'
                                                                                        )
                                                                                    ) {
                                                                                        validate36.errors =
                                                                                            [
                                                                                                {
                                                                                                    instancePath:
                                                                                                        instancePath +
                                                                                                        '/audio/tx_historic_packet_loss',
                                                                                                    schemaPath:
                                                                                                        '#/definitions/statistics/properties/Body/definitions/Stream/properties/tx_historic_packet_loss/type',
                                                                                                    keyword:
                                                                                                        'type',
                                                                                                    params: {
                                                                                                        type: 'number',
                                                                                                    },
                                                                                                    message:
                                                                                                        'must be number',
                                                                                                },
                                                                                            ];
                                                                                        return false;
                                                                                    }
                                                                                    var valid2 =
                                                                                        _errs30 ===
                                                                                        errors;
                                                                                } else {
                                                                                    var valid2 =
                                                                                        true;
                                                                                }
                                                                                if (
                                                                                    valid2
                                                                                ) {
                                                                                    if (
                                                                                        data0.tx_packets_sent !==
                                                                                        undefined
                                                                                    ) {
                                                                                        let data14 =
                                                                                            data0.tx_packets_sent;
                                                                                        const _errs32 =
                                                                                            errors;
                                                                                        if (
                                                                                            !(
                                                                                                typeof data14 ==
                                                                                                    'number' &&
                                                                                                !(
                                                                                                    data14 %
                                                                                                    1
                                                                                                ) &&
                                                                                                !isNaN(
                                                                                                    data14,
                                                                                                )
                                                                                            )
                                                                                        ) {
                                                                                            validate36.errors =
                                                                                                [
                                                                                                    {
                                                                                                        instancePath:
                                                                                                            instancePath +
                                                                                                            '/audio/tx_packets_sent',
                                                                                                        schemaPath:
                                                                                                            '#/definitions/statistics/properties/Body/definitions/Stream/properties/tx_packets_sent/type',
                                                                                                        keyword:
                                                                                                            'type',
                                                                                                        params: {
                                                                                                            type: 'integer',
                                                                                                        },
                                                                                                        message:
                                                                                                            'must be integer',
                                                                                                    },
                                                                                                ];
                                                                                            return false;
                                                                                        }
                                                                                        var valid2 =
                                                                                            _errs32 ===
                                                                                            errors;
                                                                                    } else {
                                                                                        var valid2 =
                                                                                            true;
                                                                                    }
                                                                                    if (
                                                                                        valid2
                                                                                    ) {
                                                                                        if (
                                                                                            data0.tx_rb_jitter !==
                                                                                            undefined
                                                                                        ) {
                                                                                            const _errs34 =
                                                                                                errors;
                                                                                            if (
                                                                                                !(
                                                                                                    typeof data0.tx_rb_jitter ==
                                                                                                    'number'
                                                                                                )
                                                                                            ) {
                                                                                                validate36.errors =
                                                                                                    [
                                                                                                        {
                                                                                                            instancePath:
                                                                                                                instancePath +
                                                                                                                '/audio/tx_rb_jitter',
                                                                                                            schemaPath:
                                                                                                                '#/definitions/statistics/properties/Body/definitions/Stream/properties/tx_rb_jitter/type',
                                                                                                            keyword:
                                                                                                                'type',
                                                                                                            params: {
                                                                                                                type: 'number',
                                                                                                            },
                                                                                                            message:
                                                                                                                'must be number',
                                                                                                        },
                                                                                                    ];
                                                                                                return false;
                                                                                            }
                                                                                            var valid2 =
                                                                                                _errs34 ===
                                                                                                errors;
                                                                                        } else {
                                                                                            var valid2 =
                                                                                                true;
                                                                                        }
                                                                                        if (
                                                                                            valid2
                                                                                        ) {
                                                                                            if (
                                                                                                data0.tx_rb_packetslost !==
                                                                                                undefined
                                                                                            ) {
                                                                                                let data16 =
                                                                                                    data0.tx_rb_packetslost;
                                                                                                const _errs36 =
                                                                                                    errors;
                                                                                                if (
                                                                                                    !(
                                                                                                        typeof data16 ==
                                                                                                            'number' &&
                                                                                                        !(
                                                                                                            data16 %
                                                                                                            1
                                                                                                        ) &&
                                                                                                        !isNaN(
                                                                                                            data16,
                                                                                                        )
                                                                                                    )
                                                                                                ) {
                                                                                                    validate36.errors =
                                                                                                        [
                                                                                                            {
                                                                                                                instancePath:
                                                                                                                    instancePath +
                                                                                                                    '/audio/tx_rb_packetslost',
                                                                                                                schemaPath:
                                                                                                                    '#/definitions/statistics/properties/Body/definitions/Stream/properties/tx_rb_packetslost/type',
                                                                                                                keyword:
                                                                                                                    'type',
                                                                                                                params: {
                                                                                                                    type: 'integer',
                                                                                                                },
                                                                                                                message:
                                                                                                                    'must be integer',
                                                                                                            },
                                                                                                        ];
                                                                                                    return false;
                                                                                                }
                                                                                                var valid2 =
                                                                                                    _errs36 ===
                                                                                                    errors;
                                                                                            } else {
                                                                                                var valid2 =
                                                                                                    true;
                                                                                            }
                                                                                            if (
                                                                                                valid2
                                                                                            ) {
                                                                                                if (
                                                                                                    data0.tx_resolution !==
                                                                                                    undefined
                                                                                                ) {
                                                                                                    const _errs38 =
                                                                                                        errors;
                                                                                                    if (
                                                                                                        typeof data0.tx_resolution !==
                                                                                                        'string'
                                                                                                    ) {
                                                                                                        validate36.errors =
                                                                                                            [
                                                                                                                {
                                                                                                                    instancePath:
                                                                                                                        instancePath +
                                                                                                                        '/audio/tx_resolution',
                                                                                                                    schemaPath:
                                                                                                                        '#/definitions/statistics/properties/Body/definitions/Stream/properties/tx_resolution/type',
                                                                                                                    keyword:
                                                                                                                        'type',
                                                                                                                    params: {
                                                                                                                        type: 'string',
                                                                                                                    },
                                                                                                                    message:
                                                                                                                        'must be string',
                                                                                                                },
                                                                                                            ];
                                                                                                        return false;
                                                                                                    }
                                                                                                    var valid2 =
                                                                                                        _errs38 ===
                                                                                                        errors;
                                                                                                } else {
                                                                                                    var valid2 =
                                                                                                        true;
                                                                                                }
                                                                                                if (
                                                                                                    valid2
                                                                                                ) {
                                                                                                    if (
                                                                                                        data0.tx_windowed_packet_loss !==
                                                                                                        undefined
                                                                                                    ) {
                                                                                                        const _errs40 =
                                                                                                            errors;
                                                                                                        if (
                                                                                                            !(
                                                                                                                typeof data0.tx_windowed_packet_loss ==
                                                                                                                'number'
                                                                                                            )
                                                                                                        ) {
                                                                                                            validate36.errors =
                                                                                                                [
                                                                                                                    {
                                                                                                                        instancePath:
                                                                                                                            instancePath +
                                                                                                                            '/audio/tx_windowed_packet_loss',
                                                                                                                        schemaPath:
                                                                                                                            '#/definitions/statistics/properties/Body/definitions/Stream/properties/tx_windowed_packet_loss/type',
                                                                                                                        keyword:
                                                                                                                            'type',
                                                                                                                        params: {
                                                                                                                            type: 'number',
                                                                                                                        },
                                                                                                                        message:
                                                                                                                            'must be number',
                                                                                                                    },
                                                                                                                ];
                                                                                                            return false;
                                                                                                        }
                                                                                                        var valid2 =
                                                                                                            _errs40 ===
                                                                                                            errors;
                                                                                                    } else {
                                                                                                        var valid2 =
                                                                                                            true;
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        } else {
                            validate36.errors = [
                                {
                                    instancePath: instancePath + '/audio',
                                    schemaPath:
                                        '#/definitions/statistics/properties/Body/definitions/Stream/type',
                                    keyword: 'type',
                                    params: {type: 'object'},
                                    message: 'must be object',
                                },
                            ];
                            return false;
                        }
                    }
                    var valid0 = _errs2 === errors;
                } else {
                    var valid0 = true;
                }
                if (valid0) {
                    if (data.video !== undefined) {
                        let data19 = data.video;
                        const _errs42 = errors;
                        const _errs43 = errors;
                        if (errors === _errs43) {
                            if (
                                data19 &&
                                typeof data19 == 'object' &&
                                !Array.isArray(data19)
                            ) {
                                const _errs45 = errors;
                                for (const key2 in data19) {
                                    if (
                                        !func2.call(schema50.properties, key2)
                                    ) {
                                        delete data19[key2];
                                    }
                                }
                                if (_errs45 === errors) {
                                    if (data19.rx_bitrate !== undefined) {
                                        const _errs46 = errors;
                                        if (
                                            !(
                                                typeof data19.rx_bitrate ==
                                                'number'
                                            )
                                        ) {
                                            validate36.errors = [
                                                {
                                                    instancePath:
                                                        instancePath +
                                                        '/video/rx_bitrate',
                                                    schemaPath:
                                                        '#/definitions/statistics/properties/Body/definitions/Stream/properties/rx_bitrate/type',
                                                    keyword: 'type',
                                                    params: {type: 'number'},
                                                    message: 'must be number',
                                                },
                                            ];
                                            return false;
                                        }
                                        var valid4 = _errs46 === errors;
                                    } else {
                                        var valid4 = true;
                                    }
                                    if (valid4) {
                                        if (data19.rx_codec !== undefined) {
                                            const _errs48 = errors;
                                            if (
                                                typeof data19.rx_codec !==
                                                'string'
                                            ) {
                                                validate36.errors = [
                                                    {
                                                        instancePath:
                                                            instancePath +
                                                            '/video/rx_codec',
                                                        schemaPath:
                                                            '#/definitions/statistics/properties/Body/definitions/Stream/properties/rx_codec/type',
                                                        keyword: 'type',
                                                        params: {
                                                            type: 'string',
                                                        },
                                                        message:
                                                            'must be string',
                                                    },
                                                ];
                                                return false;
                                            }
                                            var valid4 = _errs48 === errors;
                                        } else {
                                            var valid4 = true;
                                        }
                                        if (valid4) {
                                            if (data19.rx_fps !== undefined) {
                                                const _errs50 = errors;
                                                if (
                                                    !(
                                                        typeof data19.rx_fps ==
                                                        'number'
                                                    )
                                                ) {
                                                    validate36.errors = [
                                                        {
                                                            instancePath:
                                                                instancePath +
                                                                '/video/rx_fps',
                                                            schemaPath:
                                                                '#/definitions/statistics/properties/Body/definitions/Stream/properties/rx_fps/type',
                                                            keyword: 'type',
                                                            params: {
                                                                type: 'number',
                                                            },
                                                            message:
                                                                'must be number',
                                                        },
                                                    ];
                                                    return false;
                                                }
                                                var valid4 = _errs50 === errors;
                                            } else {
                                                var valid4 = true;
                                            }
                                            if (valid4) {
                                                if (
                                                    data19.rx_historic_packet_loss !==
                                                    undefined
                                                ) {
                                                    const _errs52 = errors;
                                                    if (
                                                        !(
                                                            typeof data19.rx_historic_packet_loss ==
                                                            'number'
                                                        )
                                                    ) {
                                                        validate36.errors = [
                                                            {
                                                                instancePath:
                                                                    instancePath +
                                                                    '/video/rx_historic_packet_loss',
                                                                schemaPath:
                                                                    '#/definitions/statistics/properties/Body/definitions/Stream/properties/rx_historic_packet_loss/type',
                                                                keyword: 'type',
                                                                params: {
                                                                    type: 'number',
                                                                },
                                                                message:
                                                                    'must be number',
                                                            },
                                                        ];
                                                        return false;
                                                    }
                                                    var valid4 =
                                                        _errs52 === errors;
                                                } else {
                                                    var valid4 = true;
                                                }
                                                if (valid4) {
                                                    if (
                                                        data19.rx_jitter !==
                                                        undefined
                                                    ) {
                                                        const _errs54 = errors;
                                                        if (
                                                            !(
                                                                typeof data19.rx_jitter ==
                                                                'number'
                                                            )
                                                        ) {
                                                            validate36.errors =
                                                                [
                                                                    {
                                                                        instancePath:
                                                                            instancePath +
                                                                            '/video/rx_jitter',
                                                                        schemaPath:
                                                                            '#/definitions/statistics/properties/Body/definitions/Stream/properties/rx_jitter/type',
                                                                        keyword:
                                                                            'type',
                                                                        params: {
                                                                            type: 'number',
                                                                        },
                                                                        message:
                                                                            'must be number',
                                                                    },
                                                                ];
                                                            return false;
                                                        }
                                                        var valid4 =
                                                            _errs54 === errors;
                                                    } else {
                                                        var valid4 = true;
                                                    }
                                                    if (valid4) {
                                                        if (
                                                            data19.rx_packets_lost !==
                                                            undefined
                                                        ) {
                                                            let data25 =
                                                                data19.rx_packets_lost;
                                                            const _errs56 =
                                                                errors;
                                                            if (
                                                                !(
                                                                    typeof data25 ==
                                                                        'number' &&
                                                                    !(
                                                                        data25 %
                                                                        1
                                                                    ) &&
                                                                    !isNaN(
                                                                        data25,
                                                                    )
                                                                )
                                                            ) {
                                                                validate36.errors =
                                                                    [
                                                                        {
                                                                            instancePath:
                                                                                instancePath +
                                                                                '/video/rx_packets_lost',
                                                                            schemaPath:
                                                                                '#/definitions/statistics/properties/Body/definitions/Stream/properties/rx_packets_lost/type',
                                                                            keyword:
                                                                                'type',
                                                                            params: {
                                                                                type: 'integer',
                                                                            },
                                                                            message:
                                                                                'must be integer',
                                                                        },
                                                                    ];
                                                                return false;
                                                            }
                                                            var valid4 =
                                                                _errs56 ===
                                                                errors;
                                                        } else {
                                                            var valid4 = true;
                                                        }
                                                        if (valid4) {
                                                            if (
                                                                data19.rx_packets_received !==
                                                                undefined
                                                            ) {
                                                                let data26 =
                                                                    data19.rx_packets_received;
                                                                const _errs58 =
                                                                    errors;
                                                                if (
                                                                    !(
                                                                        typeof data26 ==
                                                                            'number' &&
                                                                        !(
                                                                            data26 %
                                                                            1
                                                                        ) &&
                                                                        !isNaN(
                                                                            data26,
                                                                        )
                                                                    )
                                                                ) {
                                                                    validate36.errors =
                                                                        [
                                                                            {
                                                                                instancePath:
                                                                                    instancePath +
                                                                                    '/video/rx_packets_received',
                                                                                schemaPath:
                                                                                    '#/definitions/statistics/properties/Body/definitions/Stream/properties/rx_packets_received/type',
                                                                                keyword:
                                                                                    'type',
                                                                                params: {
                                                                                    type: 'integer',
                                                                                },
                                                                                message:
                                                                                    'must be integer',
                                                                            },
                                                                        ];
                                                                    return false;
                                                                }
                                                                var valid4 =
                                                                    _errs58 ===
                                                                    errors;
                                                            } else {
                                                                var valid4 =
                                                                    true;
                                                            }
                                                            if (valid4) {
                                                                if (
                                                                    data19.rx_resolution !==
                                                                    undefined
                                                                ) {
                                                                    const _errs60 =
                                                                        errors;
                                                                    if (
                                                                        typeof data19.rx_resolution !==
                                                                        'string'
                                                                    ) {
                                                                        validate36.errors =
                                                                            [
                                                                                {
                                                                                    instancePath:
                                                                                        instancePath +
                                                                                        '/video/rx_resolution',
                                                                                    schemaPath:
                                                                                        '#/definitions/statistics/properties/Body/definitions/Stream/properties/rx_resolution/type',
                                                                                    keyword:
                                                                                        'type',
                                                                                    params: {
                                                                                        type: 'string',
                                                                                    },
                                                                                    message:
                                                                                        'must be string',
                                                                                },
                                                                            ];
                                                                        return false;
                                                                    }
                                                                    var valid4 =
                                                                        _errs60 ===
                                                                        errors;
                                                                } else {
                                                                    var valid4 =
                                                                        true;
                                                                }
                                                                if (valid4) {
                                                                    if (
                                                                        data19.rx_windowed_packet_loss !==
                                                                        undefined
                                                                    ) {
                                                                        const _errs62 =
                                                                            errors;
                                                                        if (
                                                                            !(
                                                                                typeof data19.rx_windowed_packet_loss ==
                                                                                'number'
                                                                            )
                                                                        ) {
                                                                            validate36.errors =
                                                                                [
                                                                                    {
                                                                                        instancePath:
                                                                                            instancePath +
                                                                                            '/video/rx_windowed_packet_loss',
                                                                                        schemaPath:
                                                                                            '#/definitions/statistics/properties/Body/definitions/Stream/properties/rx_windowed_packet_loss/type',
                                                                                        keyword:
                                                                                            'type',
                                                                                        params: {
                                                                                            type: 'number',
                                                                                        },
                                                                                        message:
                                                                                            'must be number',
                                                                                    },
                                                                                ];
                                                                            return false;
                                                                        }
                                                                        var valid4 =
                                                                            _errs62 ===
                                                                            errors;
                                                                    } else {
                                                                        var valid4 =
                                                                            true;
                                                                    }
                                                                    if (
                                                                        valid4
                                                                    ) {
                                                                        if (
                                                                            data19.tx_bitrate !==
                                                                            undefined
                                                                        ) {
                                                                            const _errs64 =
                                                                                errors;
                                                                            if (
                                                                                !(
                                                                                    typeof data19.tx_bitrate ==
                                                                                    'number'
                                                                                )
                                                                            ) {
                                                                                validate36.errors =
                                                                                    [
                                                                                        {
                                                                                            instancePath:
                                                                                                instancePath +
                                                                                                '/video/tx_bitrate',
                                                                                            schemaPath:
                                                                                                '#/definitions/statistics/properties/Body/definitions/Stream/properties/tx_bitrate/type',
                                                                                            keyword:
                                                                                                'type',
                                                                                            params: {
                                                                                                type: 'number',
                                                                                            },
                                                                                            message:
                                                                                                'must be number',
                                                                                        },
                                                                                    ];
                                                                                return false;
                                                                            }
                                                                            var valid4 =
                                                                                _errs64 ===
                                                                                errors;
                                                                        } else {
                                                                            var valid4 =
                                                                                true;
                                                                        }
                                                                        if (
                                                                            valid4
                                                                        ) {
                                                                            if (
                                                                                data19.tx_codec !==
                                                                                undefined
                                                                            ) {
                                                                                const _errs66 =
                                                                                    errors;
                                                                                if (
                                                                                    typeof data19.tx_codec !==
                                                                                    'string'
                                                                                ) {
                                                                                    validate36.errors =
                                                                                        [
                                                                                            {
                                                                                                instancePath:
                                                                                                    instancePath +
                                                                                                    '/video/tx_codec',
                                                                                                schemaPath:
                                                                                                    '#/definitions/statistics/properties/Body/definitions/Stream/properties/tx_codec/type',
                                                                                                keyword:
                                                                                                    'type',
                                                                                                params: {
                                                                                                    type: 'string',
                                                                                                },
                                                                                                message:
                                                                                                    'must be string',
                                                                                            },
                                                                                        ];
                                                                                    return false;
                                                                                }
                                                                                var valid4 =
                                                                                    _errs66 ===
                                                                                    errors;
                                                                            } else {
                                                                                var valid4 =
                                                                                    true;
                                                                            }
                                                                            if (
                                                                                valid4
                                                                            ) {
                                                                                if (
                                                                                    data19.tx_fps !==
                                                                                    undefined
                                                                                ) {
                                                                                    const _errs68 =
                                                                                        errors;
                                                                                    if (
                                                                                        !(
                                                                                            typeof data19.tx_fps ==
                                                                                            'number'
                                                                                        )
                                                                                    ) {
                                                                                        validate36.errors =
                                                                                            [
                                                                                                {
                                                                                                    instancePath:
                                                                                                        instancePath +
                                                                                                        '/video/tx_fps',
                                                                                                    schemaPath:
                                                                                                        '#/definitions/statistics/properties/Body/definitions/Stream/properties/tx_fps/type',
                                                                                                    keyword:
                                                                                                        'type',
                                                                                                    params: {
                                                                                                        type: 'number',
                                                                                                    },
                                                                                                    message:
                                                                                                        'must be number',
                                                                                                },
                                                                                            ];
                                                                                        return false;
                                                                                    }
                                                                                    var valid4 =
                                                                                        _errs68 ===
                                                                                        errors;
                                                                                } else {
                                                                                    var valid4 =
                                                                                        true;
                                                                                }
                                                                                if (
                                                                                    valid4
                                                                                ) {
                                                                                    if (
                                                                                        data19.tx_historic_packet_loss !==
                                                                                        undefined
                                                                                    ) {
                                                                                        const _errs70 =
                                                                                            errors;
                                                                                        if (
                                                                                            !(
                                                                                                typeof data19.tx_historic_packet_loss ==
                                                                                                'number'
                                                                                            )
                                                                                        ) {
                                                                                            validate36.errors =
                                                                                                [
                                                                                                    {
                                                                                                        instancePath:
                                                                                                            instancePath +
                                                                                                            '/video/tx_historic_packet_loss',
                                                                                                        schemaPath:
                                                                                                            '#/definitions/statistics/properties/Body/definitions/Stream/properties/tx_historic_packet_loss/type',
                                                                                                        keyword:
                                                                                                            'type',
                                                                                                        params: {
                                                                                                            type: 'number',
                                                                                                        },
                                                                                                        message:
                                                                                                            'must be number',
                                                                                                    },
                                                                                                ];
                                                                                            return false;
                                                                                        }
                                                                                        var valid4 =
                                                                                            _errs70 ===
                                                                                            errors;
                                                                                    } else {
                                                                                        var valid4 =
                                                                                            true;
                                                                                    }
                                                                                    if (
                                                                                        valid4
                                                                                    ) {
                                                                                        if (
                                                                                            data19.tx_packets_sent !==
                                                                                            undefined
                                                                                        ) {
                                                                                            let data33 =
                                                                                                data19.tx_packets_sent;
                                                                                            const _errs72 =
                                                                                                errors;
                                                                                            if (
                                                                                                !(
                                                                                                    typeof data33 ==
                                                                                                        'number' &&
                                                                                                    !(
                                                                                                        data33 %
                                                                                                        1
                                                                                                    ) &&
                                                                                                    !isNaN(
                                                                                                        data33,
                                                                                                    )
                                                                                                )
                                                                                            ) {
                                                                                                validate36.errors =
                                                                                                    [
                                                                                                        {
                                                                                                            instancePath:
                                                                                                                instancePath +
                                                                                                                '/video/tx_packets_sent',
                                                                                                            schemaPath:
                                                                                                                '#/definitions/statistics/properties/Body/definitions/Stream/properties/tx_packets_sent/type',
                                                                                                            keyword:
                                                                                                                'type',
                                                                                                            params: {
                                                                                                                type: 'integer',
                                                                                                            },
                                                                                                            message:
                                                                                                                'must be integer',
                                                                                                        },
                                                                                                    ];
                                                                                                return false;
                                                                                            }
                                                                                            var valid4 =
                                                                                                _errs72 ===
                                                                                                errors;
                                                                                        } else {
                                                                                            var valid4 =
                                                                                                true;
                                                                                        }
                                                                                        if (
                                                                                            valid4
                                                                                        ) {
                                                                                            if (
                                                                                                data19.tx_rb_jitter !==
                                                                                                undefined
                                                                                            ) {
                                                                                                const _errs74 =
                                                                                                    errors;
                                                                                                if (
                                                                                                    !(
                                                                                                        typeof data19.tx_rb_jitter ==
                                                                                                        'number'
                                                                                                    )
                                                                                                ) {
                                                                                                    validate36.errors =
                                                                                                        [
                                                                                                            {
                                                                                                                instancePath:
                                                                                                                    instancePath +
                                                                                                                    '/video/tx_rb_jitter',
                                                                                                                schemaPath:
                                                                                                                    '#/definitions/statistics/properties/Body/definitions/Stream/properties/tx_rb_jitter/type',
                                                                                                                keyword:
                                                                                                                    'type',
                                                                                                                params: {
                                                                                                                    type: 'number',
                                                                                                                },
                                                                                                                message:
                                                                                                                    'must be number',
                                                                                                            },
                                                                                                        ];
                                                                                                    return false;
                                                                                                }
                                                                                                var valid4 =
                                                                                                    _errs74 ===
                                                                                                    errors;
                                                                                            } else {
                                                                                                var valid4 =
                                                                                                    true;
                                                                                            }
                                                                                            if (
                                                                                                valid4
                                                                                            ) {
                                                                                                if (
                                                                                                    data19.tx_rb_packetslost !==
                                                                                                    undefined
                                                                                                ) {
                                                                                                    let data35 =
                                                                                                        data19.tx_rb_packetslost;
                                                                                                    const _errs76 =
                                                                                                        errors;
                                                                                                    if (
                                                                                                        !(
                                                                                                            typeof data35 ==
                                                                                                                'number' &&
                                                                                                            !(
                                                                                                                data35 %
                                                                                                                1
                                                                                                            ) &&
                                                                                                            !isNaN(
                                                                                                                data35,
                                                                                                            )
                                                                                                        )
                                                                                                    ) {
                                                                                                        validate36.errors =
                                                                                                            [
                                                                                                                {
                                                                                                                    instancePath:
                                                                                                                        instancePath +
                                                                                                                        '/video/tx_rb_packetslost',
                                                                                                                    schemaPath:
                                                                                                                        '#/definitions/statistics/properties/Body/definitions/Stream/properties/tx_rb_packetslost/type',
                                                                                                                    keyword:
                                                                                                                        'type',
                                                                                                                    params: {
                                                                                                                        type: 'integer',
                                                                                                                    },
                                                                                                                    message:
                                                                                                                        'must be integer',
                                                                                                                },
                                                                                                            ];
                                                                                                        return false;
                                                                                                    }
                                                                                                    var valid4 =
                                                                                                        _errs76 ===
                                                                                                        errors;
                                                                                                } else {
                                                                                                    var valid4 =
                                                                                                        true;
                                                                                                }
                                                                                                if (
                                                                                                    valid4
                                                                                                ) {
                                                                                                    if (
                                                                                                        data19.tx_resolution !==
                                                                                                        undefined
                                                                                                    ) {
                                                                                                        const _errs78 =
                                                                                                            errors;
                                                                                                        if (
                                                                                                            typeof data19.tx_resolution !==
                                                                                                            'string'
                                                                                                        ) {
                                                                                                            validate36.errors =
                                                                                                                [
                                                                                                                    {
                                                                                                                        instancePath:
                                                                                                                            instancePath +
                                                                                                                            '/video/tx_resolution',
                                                                                                                        schemaPath:
                                                                                                                            '#/definitions/statistics/properties/Body/definitions/Stream/properties/tx_resolution/type',
                                                                                                                        keyword:
                                                                                                                            'type',
                                                                                                                        params: {
                                                                                                                            type: 'string',
                                                                                                                        },
                                                                                                                        message:
                                                                                                                            'must be string',
                                                                                                                    },
                                                                                                                ];
                                                                                                            return false;
                                                                                                        }
                                                                                                        var valid4 =
                                                                                                            _errs78 ===
                                                                                                            errors;
                                                                                                    } else {
                                                                                                        var valid4 =
                                                                                                            true;
                                                                                                    }
                                                                                                    if (
                                                                                                        valid4
                                                                                                    ) {
                                                                                                        if (
                                                                                                            data19.tx_windowed_packet_loss !==
                                                                                                            undefined
                                                                                                        ) {
                                                                                                            const _errs80 =
                                                                                                                errors;
                                                                                                            if (
                                                                                                                !(
                                                                                                                    typeof data19.tx_windowed_packet_loss ==
                                                                                                                    'number'
                                                                                                                )
                                                                                                            ) {
                                                                                                                validate36.errors =
                                                                                                                    [
                                                                                                                        {
                                                                                                                            instancePath:
                                                                                                                                instancePath +
                                                                                                                                '/video/tx_windowed_packet_loss',
                                                                                                                            schemaPath:
                                                                                                                                '#/definitions/statistics/properties/Body/definitions/Stream/properties/tx_windowed_packet_loss/type',
                                                                                                                            keyword:
                                                                                                                                'type',
                                                                                                                            params: {
                                                                                                                                type: 'number',
                                                                                                                            },
                                                                                                                            message:
                                                                                                                                'must be number',
                                                                                                                        },
                                                                                                                    ];
                                                                                                                return false;
                                                                                                            }
                                                                                                            var valid4 =
                                                                                                                _errs80 ===
                                                                                                                errors;
                                                                                                        } else {
                                                                                                            var valid4 =
                                                                                                                true;
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            } else {
                                validate36.errors = [
                                    {
                                        instancePath: instancePath + '/video',
                                        schemaPath:
                                            '#/definitions/statistics/properties/Body/definitions/Stream/type',
                                        keyword: 'type',
                                        params: {type: 'object'},
                                        message: 'must be object',
                                    },
                                ];
                                return false;
                            }
                        }
                        var valid0 = _errs42 === errors;
                    } else {
                        var valid0 = true;
                    }
                    if (valid0) {
                        if (data.presentation !== undefined) {
                            let data38 = data.presentation;
                            const _errs82 = errors;
                            const _errs83 = errors;
                            if (errors === _errs83) {
                                if (
                                    data38 &&
                                    typeof data38 == 'object' &&
                                    !Array.isArray(data38)
                                ) {
                                    const _errs85 = errors;
                                    for (const key3 in data38) {
                                        if (
                                            !func2.call(
                                                schema50.properties,
                                                key3,
                                            )
                                        ) {
                                            delete data38[key3];
                                        }
                                    }
                                    if (_errs85 === errors) {
                                        if (data38.rx_bitrate !== undefined) {
                                            const _errs86 = errors;
                                            if (
                                                !(
                                                    typeof data38.rx_bitrate ==
                                                    'number'
                                                )
                                            ) {
                                                validate36.errors = [
                                                    {
                                                        instancePath:
                                                            instancePath +
                                                            '/presentation/rx_bitrate',
                                                        schemaPath:
                                                            '#/definitions/statistics/properties/Body/definitions/Stream/properties/rx_bitrate/type',
                                                        keyword: 'type',
                                                        params: {
                                                            type: 'number',
                                                        },
                                                        message:
                                                            'must be number',
                                                    },
                                                ];
                                                return false;
                                            }
                                            var valid6 = _errs86 === errors;
                                        } else {
                                            var valid6 = true;
                                        }
                                        if (valid6) {
                                            if (data38.rx_codec !== undefined) {
                                                const _errs88 = errors;
                                                if (
                                                    typeof data38.rx_codec !==
                                                    'string'
                                                ) {
                                                    validate36.errors = [
                                                        {
                                                            instancePath:
                                                                instancePath +
                                                                '/presentation/rx_codec',
                                                            schemaPath:
                                                                '#/definitions/statistics/properties/Body/definitions/Stream/properties/rx_codec/type',
                                                            keyword: 'type',
                                                            params: {
                                                                type: 'string',
                                                            },
                                                            message:
                                                                'must be string',
                                                        },
                                                    ];
                                                    return false;
                                                }
                                                var valid6 = _errs88 === errors;
                                            } else {
                                                var valid6 = true;
                                            }
                                            if (valid6) {
                                                if (
                                                    data38.rx_fps !== undefined
                                                ) {
                                                    const _errs90 = errors;
                                                    if (
                                                        !(
                                                            typeof data38.rx_fps ==
                                                            'number'
                                                        )
                                                    ) {
                                                        validate36.errors = [
                                                            {
                                                                instancePath:
                                                                    instancePath +
                                                                    '/presentation/rx_fps',
                                                                schemaPath:
                                                                    '#/definitions/statistics/properties/Body/definitions/Stream/properties/rx_fps/type',
                                                                keyword: 'type',
                                                                params: {
                                                                    type: 'number',
                                                                },
                                                                message:
                                                                    'must be number',
                                                            },
                                                        ];
                                                        return false;
                                                    }
                                                    var valid6 =
                                                        _errs90 === errors;
                                                } else {
                                                    var valid6 = true;
                                                }
                                                if (valid6) {
                                                    if (
                                                        data38.rx_historic_packet_loss !==
                                                        undefined
                                                    ) {
                                                        const _errs92 = errors;
                                                        if (
                                                            !(
                                                                typeof data38.rx_historic_packet_loss ==
                                                                'number'
                                                            )
                                                        ) {
                                                            validate36.errors =
                                                                [
                                                                    {
                                                                        instancePath:
                                                                            instancePath +
                                                                            '/presentation/rx_historic_packet_loss',
                                                                        schemaPath:
                                                                            '#/definitions/statistics/properties/Body/definitions/Stream/properties/rx_historic_packet_loss/type',
                                                                        keyword:
                                                                            'type',
                                                                        params: {
                                                                            type: 'number',
                                                                        },
                                                                        message:
                                                                            'must be number',
                                                                    },
                                                                ];
                                                            return false;
                                                        }
                                                        var valid6 =
                                                            _errs92 === errors;
                                                    } else {
                                                        var valid6 = true;
                                                    }
                                                    if (valid6) {
                                                        if (
                                                            data38.rx_jitter !==
                                                            undefined
                                                        ) {
                                                            const _errs94 =
                                                                errors;
                                                            if (
                                                                !(
                                                                    typeof data38.rx_jitter ==
                                                                    'number'
                                                                )
                                                            ) {
                                                                validate36.errors =
                                                                    [
                                                                        {
                                                                            instancePath:
                                                                                instancePath +
                                                                                '/presentation/rx_jitter',
                                                                            schemaPath:
                                                                                '#/definitions/statistics/properties/Body/definitions/Stream/properties/rx_jitter/type',
                                                                            keyword:
                                                                                'type',
                                                                            params: {
                                                                                type: 'number',
                                                                            },
                                                                            message:
                                                                                'must be number',
                                                                        },
                                                                    ];
                                                                return false;
                                                            }
                                                            var valid6 =
                                                                _errs94 ===
                                                                errors;
                                                        } else {
                                                            var valid6 = true;
                                                        }
                                                        if (valid6) {
                                                            if (
                                                                data38.rx_packets_lost !==
                                                                undefined
                                                            ) {
                                                                let data44 =
                                                                    data38.rx_packets_lost;
                                                                const _errs96 =
                                                                    errors;
                                                                if (
                                                                    !(
                                                                        typeof data44 ==
                                                                            'number' &&
                                                                        !(
                                                                            data44 %
                                                                            1
                                                                        ) &&
                                                                        !isNaN(
                                                                            data44,
                                                                        )
                                                                    )
                                                                ) {
                                                                    validate36.errors =
                                                                        [
                                                                            {
                                                                                instancePath:
                                                                                    instancePath +
                                                                                    '/presentation/rx_packets_lost',
                                                                                schemaPath:
                                                                                    '#/definitions/statistics/properties/Body/definitions/Stream/properties/rx_packets_lost/type',
                                                                                keyword:
                                                                                    'type',
                                                                                params: {
                                                                                    type: 'integer',
                                                                                },
                                                                                message:
                                                                                    'must be integer',
                                                                            },
                                                                        ];
                                                                    return false;
                                                                }
                                                                var valid6 =
                                                                    _errs96 ===
                                                                    errors;
                                                            } else {
                                                                var valid6 =
                                                                    true;
                                                            }
                                                            if (valid6) {
                                                                if (
                                                                    data38.rx_packets_received !==
                                                                    undefined
                                                                ) {
                                                                    let data45 =
                                                                        data38.rx_packets_received;
                                                                    const _errs98 =
                                                                        errors;
                                                                    if (
                                                                        !(
                                                                            typeof data45 ==
                                                                                'number' &&
                                                                            !(
                                                                                data45 %
                                                                                1
                                                                            ) &&
                                                                            !isNaN(
                                                                                data45,
                                                                            )
                                                                        )
                                                                    ) {
                                                                        validate36.errors =
                                                                            [
                                                                                {
                                                                                    instancePath:
                                                                                        instancePath +
                                                                                        '/presentation/rx_packets_received',
                                                                                    schemaPath:
                                                                                        '#/definitions/statistics/properties/Body/definitions/Stream/properties/rx_packets_received/type',
                                                                                    keyword:
                                                                                        'type',
                                                                                    params: {
                                                                                        type: 'integer',
                                                                                    },
                                                                                    message:
                                                                                        'must be integer',
                                                                                },
                                                                            ];
                                                                        return false;
                                                                    }
                                                                    var valid6 =
                                                                        _errs98 ===
                                                                        errors;
                                                                } else {
                                                                    var valid6 =
                                                                        true;
                                                                }
                                                                if (valid6) {
                                                                    if (
                                                                        data38.rx_resolution !==
                                                                        undefined
                                                                    ) {
                                                                        const _errs100 =
                                                                            errors;
                                                                        if (
                                                                            typeof data38.rx_resolution !==
                                                                            'string'
                                                                        ) {
                                                                            validate36.errors =
                                                                                [
                                                                                    {
                                                                                        instancePath:
                                                                                            instancePath +
                                                                                            '/presentation/rx_resolution',
                                                                                        schemaPath:
                                                                                            '#/definitions/statistics/properties/Body/definitions/Stream/properties/rx_resolution/type',
                                                                                        keyword:
                                                                                            'type',
                                                                                        params: {
                                                                                            type: 'string',
                                                                                        },
                                                                                        message:
                                                                                            'must be string',
                                                                                    },
                                                                                ];
                                                                            return false;
                                                                        }
                                                                        var valid6 =
                                                                            _errs100 ===
                                                                            errors;
                                                                    } else {
                                                                        var valid6 =
                                                                            true;
                                                                    }
                                                                    if (
                                                                        valid6
                                                                    ) {
                                                                        if (
                                                                            data38.rx_windowed_packet_loss !==
                                                                            undefined
                                                                        ) {
                                                                            const _errs102 =
                                                                                errors;
                                                                            if (
                                                                                !(
                                                                                    typeof data38.rx_windowed_packet_loss ==
                                                                                    'number'
                                                                                )
                                                                            ) {
                                                                                validate36.errors =
                                                                                    [
                                                                                        {
                                                                                            instancePath:
                                                                                                instancePath +
                                                                                                '/presentation/rx_windowed_packet_loss',
                                                                                            schemaPath:
                                                                                                '#/definitions/statistics/properties/Body/definitions/Stream/properties/rx_windowed_packet_loss/type',
                                                                                            keyword:
                                                                                                'type',
                                                                                            params: {
                                                                                                type: 'number',
                                                                                            },
                                                                                            message:
                                                                                                'must be number',
                                                                                        },
                                                                                    ];
                                                                                return false;
                                                                            }
                                                                            var valid6 =
                                                                                _errs102 ===
                                                                                errors;
                                                                        } else {
                                                                            var valid6 =
                                                                                true;
                                                                        }
                                                                        if (
                                                                            valid6
                                                                        ) {
                                                                            if (
                                                                                data38.tx_bitrate !==
                                                                                undefined
                                                                            ) {
                                                                                const _errs104 =
                                                                                    errors;
                                                                                if (
                                                                                    !(
                                                                                        typeof data38.tx_bitrate ==
                                                                                        'number'
                                                                                    )
                                                                                ) {
                                                                                    validate36.errors =
                                                                                        [
                                                                                            {
                                                                                                instancePath:
                                                                                                    instancePath +
                                                                                                    '/presentation/tx_bitrate',
                                                                                                schemaPath:
                                                                                                    '#/definitions/statistics/properties/Body/definitions/Stream/properties/tx_bitrate/type',
                                                                                                keyword:
                                                                                                    'type',
                                                                                                params: {
                                                                                                    type: 'number',
                                                                                                },
                                                                                                message:
                                                                                                    'must be number',
                                                                                            },
                                                                                        ];
                                                                                    return false;
                                                                                }
                                                                                var valid6 =
                                                                                    _errs104 ===
                                                                                    errors;
                                                                            } else {
                                                                                var valid6 =
                                                                                    true;
                                                                            }
                                                                            if (
                                                                                valid6
                                                                            ) {
                                                                                if (
                                                                                    data38.tx_codec !==
                                                                                    undefined
                                                                                ) {
                                                                                    const _errs106 =
                                                                                        errors;
                                                                                    if (
                                                                                        typeof data38.tx_codec !==
                                                                                        'string'
                                                                                    ) {
                                                                                        validate36.errors =
                                                                                            [
                                                                                                {
                                                                                                    instancePath:
                                                                                                        instancePath +
                                                                                                        '/presentation/tx_codec',
                                                                                                    schemaPath:
                                                                                                        '#/definitions/statistics/properties/Body/definitions/Stream/properties/tx_codec/type',
                                                                                                    keyword:
                                                                                                        'type',
                                                                                                    params: {
                                                                                                        type: 'string',
                                                                                                    },
                                                                                                    message:
                                                                                                        'must be string',
                                                                                                },
                                                                                            ];
                                                                                        return false;
                                                                                    }
                                                                                    var valid6 =
                                                                                        _errs106 ===
                                                                                        errors;
                                                                                } else {
                                                                                    var valid6 =
                                                                                        true;
                                                                                }
                                                                                if (
                                                                                    valid6
                                                                                ) {
                                                                                    if (
                                                                                        data38.tx_fps !==
                                                                                        undefined
                                                                                    ) {
                                                                                        const _errs108 =
                                                                                            errors;
                                                                                        if (
                                                                                            !(
                                                                                                typeof data38.tx_fps ==
                                                                                                'number'
                                                                                            )
                                                                                        ) {
                                                                                            validate36.errors =
                                                                                                [
                                                                                                    {
                                                                                                        instancePath:
                                                                                                            instancePath +
                                                                                                            '/presentation/tx_fps',
                                                                                                        schemaPath:
                                                                                                            '#/definitions/statistics/properties/Body/definitions/Stream/properties/tx_fps/type',
                                                                                                        keyword:
                                                                                                            'type',
                                                                                                        params: {
                                                                                                            type: 'number',
                                                                                                        },
                                                                                                        message:
                                                                                                            'must be number',
                                                                                                    },
                                                                                                ];
                                                                                            return false;
                                                                                        }
                                                                                        var valid6 =
                                                                                            _errs108 ===
                                                                                            errors;
                                                                                    } else {
                                                                                        var valid6 =
                                                                                            true;
                                                                                    }
                                                                                    if (
                                                                                        valid6
                                                                                    ) {
                                                                                        if (
                                                                                            data38.tx_historic_packet_loss !==
                                                                                            undefined
                                                                                        ) {
                                                                                            const _errs110 =
                                                                                                errors;
                                                                                            if (
                                                                                                !(
                                                                                                    typeof data38.tx_historic_packet_loss ==
                                                                                                    'number'
                                                                                                )
                                                                                            ) {
                                                                                                validate36.errors =
                                                                                                    [
                                                                                                        {
                                                                                                            instancePath:
                                                                                                                instancePath +
                                                                                                                '/presentation/tx_historic_packet_loss',
                                                                                                            schemaPath:
                                                                                                                '#/definitions/statistics/properties/Body/definitions/Stream/properties/tx_historic_packet_loss/type',
                                                                                                            keyword:
                                                                                                                'type',
                                                                                                            params: {
                                                                                                                type: 'number',
                                                                                                            },
                                                                                                            message:
                                                                                                                'must be number',
                                                                                                        },
                                                                                                    ];
                                                                                                return false;
                                                                                            }
                                                                                            var valid6 =
                                                                                                _errs110 ===
                                                                                                errors;
                                                                                        } else {
                                                                                            var valid6 =
                                                                                                true;
                                                                                        }
                                                                                        if (
                                                                                            valid6
                                                                                        ) {
                                                                                            if (
                                                                                                data38.tx_packets_sent !==
                                                                                                undefined
                                                                                            ) {
                                                                                                let data52 =
                                                                                                    data38.tx_packets_sent;
                                                                                                const _errs112 =
                                                                                                    errors;
                                                                                                if (
                                                                                                    !(
                                                                                                        typeof data52 ==
                                                                                                            'number' &&
                                                                                                        !(
                                                                                                            data52 %
                                                                                                            1
                                                                                                        ) &&
                                                                                                        !isNaN(
                                                                                                            data52,
                                                                                                        )
                                                                                                    )
                                                                                                ) {
                                                                                                    validate36.errors =
                                                                                                        [
                                                                                                            {
                                                                                                                instancePath:
                                                                                                                    instancePath +
                                                                                                                    '/presentation/tx_packets_sent',
                                                                                                                schemaPath:
                                                                                                                    '#/definitions/statistics/properties/Body/definitions/Stream/properties/tx_packets_sent/type',
                                                                                                                keyword:
                                                                                                                    'type',
                                                                                                                params: {
                                                                                                                    type: 'integer',
                                                                                                                },
                                                                                                                message:
                                                                                                                    'must be integer',
                                                                                                            },
                                                                                                        ];
                                                                                                    return false;
                                                                                                }
                                                                                                var valid6 =
                                                                                                    _errs112 ===
                                                                                                    errors;
                                                                                            } else {
                                                                                                var valid6 =
                                                                                                    true;
                                                                                            }
                                                                                            if (
                                                                                                valid6
                                                                                            ) {
                                                                                                if (
                                                                                                    data38.tx_rb_jitter !==
                                                                                                    undefined
                                                                                                ) {
                                                                                                    const _errs114 =
                                                                                                        errors;
                                                                                                    if (
                                                                                                        !(
                                                                                                            typeof data38.tx_rb_jitter ==
                                                                                                            'number'
                                                                                                        )
                                                                                                    ) {
                                                                                                        validate36.errors =
                                                                                                            [
                                                                                                                {
                                                                                                                    instancePath:
                                                                                                                        instancePath +
                                                                                                                        '/presentation/tx_rb_jitter',
                                                                                                                    schemaPath:
                                                                                                                        '#/definitions/statistics/properties/Body/definitions/Stream/properties/tx_rb_jitter/type',
                                                                                                                    keyword:
                                                                                                                        'type',
                                                                                                                    params: {
                                                                                                                        type: 'number',
                                                                                                                    },
                                                                                                                    message:
                                                                                                                        'must be number',
                                                                                                                },
                                                                                                            ];
                                                                                                        return false;
                                                                                                    }
                                                                                                    var valid6 =
                                                                                                        _errs114 ===
                                                                                                        errors;
                                                                                                } else {
                                                                                                    var valid6 =
                                                                                                        true;
                                                                                                }
                                                                                                if (
                                                                                                    valid6
                                                                                                ) {
                                                                                                    if (
                                                                                                        data38.tx_rb_packetslost !==
                                                                                                        undefined
                                                                                                    ) {
                                                                                                        let data54 =
                                                                                                            data38.tx_rb_packetslost;
                                                                                                        const _errs116 =
                                                                                                            errors;
                                                                                                        if (
                                                                                                            !(
                                                                                                                typeof data54 ==
                                                                                                                    'number' &&
                                                                                                                !(
                                                                                                                    data54 %
                                                                                                                    1
                                                                                                                ) &&
                                                                                                                !isNaN(
                                                                                                                    data54,
                                                                                                                )
                                                                                                            )
                                                                                                        ) {
                                                                                                            validate36.errors =
                                                                                                                [
                                                                                                                    {
                                                                                                                        instancePath:
                                                                                                                            instancePath +
                                                                                                                            '/presentation/tx_rb_packetslost',
                                                                                                                        schemaPath:
                                                                                                                            '#/definitions/statistics/properties/Body/definitions/Stream/properties/tx_rb_packetslost/type',
                                                                                                                        keyword:
                                                                                                                            'type',
                                                                                                                        params: {
                                                                                                                            type: 'integer',
                                                                                                                        },
                                                                                                                        message:
                                                                                                                            'must be integer',
                                                                                                                    },
                                                                                                                ];
                                                                                                            return false;
                                                                                                        }
                                                                                                        var valid6 =
                                                                                                            _errs116 ===
                                                                                                            errors;
                                                                                                    } else {
                                                                                                        var valid6 =
                                                                                                            true;
                                                                                                    }
                                                                                                    if (
                                                                                                        valid6
                                                                                                    ) {
                                                                                                        if (
                                                                                                            data38.tx_resolution !==
                                                                                                            undefined
                                                                                                        ) {
                                                                                                            const _errs118 =
                                                                                                                errors;
                                                                                                            if (
                                                                                                                typeof data38.tx_resolution !==
                                                                                                                'string'
                                                                                                            ) {
                                                                                                                validate36.errors =
                                                                                                                    [
                                                                                                                        {
                                                                                                                            instancePath:
                                                                                                                                instancePath +
                                                                                                                                '/presentation/tx_resolution',
                                                                                                                            schemaPath:
                                                                                                                                '#/definitions/statistics/properties/Body/definitions/Stream/properties/tx_resolution/type',
                                                                                                                            keyword:
                                                                                                                                'type',
                                                                                                                            params: {
                                                                                                                                type: 'string',
                                                                                                                            },
                                                                                                                            message:
                                                                                                                                'must be string',
                                                                                                                        },
                                                                                                                    ];
                                                                                                                return false;
                                                                                                            }
                                                                                                            var valid6 =
                                                                                                                _errs118 ===
                                                                                                                errors;
                                                                                                        } else {
                                                                                                            var valid6 =
                                                                                                                true;
                                                                                                        }
                                                                                                        if (
                                                                                                            valid6
                                                                                                        ) {
                                                                                                            if (
                                                                                                                data38.tx_windowed_packet_loss !==
                                                                                                                undefined
                                                                                                            ) {
                                                                                                                const _errs120 =
                                                                                                                    errors;
                                                                                                                if (
                                                                                                                    !(
                                                                                                                        typeof data38.tx_windowed_packet_loss ==
                                                                                                                        'number'
                                                                                                                    )
                                                                                                                ) {
                                                                                                                    validate36.errors =
                                                                                                                        [
                                                                                                                            {
                                                                                                                                instancePath:
                                                                                                                                    instancePath +
                                                                                                                                    '/presentation/tx_windowed_packet_loss',
                                                                                                                                schemaPath:
                                                                                                                                    '#/definitions/statistics/properties/Body/definitions/Stream/properties/tx_windowed_packet_loss/type',
                                                                                                                                keyword:
                                                                                                                                    'type',
                                                                                                                                params: {
                                                                                                                                    type: 'number',
                                                                                                                                },
                                                                                                                                message:
                                                                                                                                    'must be number',
                                                                                                                            },
                                                                                                                        ];
                                                                                                                    return false;
                                                                                                                }
                                                                                                                var valid6 =
                                                                                                                    _errs120 ===
                                                                                                                    errors;
                                                                                                            } else {
                                                                                                                var valid6 =
                                                                                                                    true;
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                } else {
                                    validate36.errors = [
                                        {
                                            instancePath:
                                                instancePath + '/presentation',
                                            schemaPath:
                                                '#/definitions/statistics/properties/Body/definitions/Stream/type',
                                            keyword: 'type',
                                            params: {type: 'object'},
                                            message: 'must be object',
                                        },
                                    ];
                                    return false;
                                }
                            }
                            var valid0 = _errs82 === errors;
                        } else {
                            var valid0 = true;
                        }
                    }
                }
            }
        } else {
            validate36.errors = [
                {
                    instancePath,
                    schemaPath: '#/type',
                    keyword: 'type',
                    params: {type: 'object'},
                    message: 'must be object',
                },
            ];
            return false;
        }
    }
    validate36.errors = vErrors;
    return errors === 0;
}
function validate35(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    if (
        !validate36(data, {
            instancePath,
            parentData,
            parentDataProperty,
            rootData,
        })
    ) {
        vErrors =
            vErrors === null
                ? validate36.errors
                : vErrors.concat(validate36.errors);
        errors = vErrors.length;
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err0 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err0];
        } else {
            vErrors.push(err0);
        }
        errors++;
        validate35.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate35.errors = vErrors;
    return errors === 0;
}
export const validateStatistics200 = validate38;
const schema53 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        '200': {
            type: 'object',
            additionalProperties: false,
            properties: {
                status: {
                    type: 'string',
                    description: 'Status of the response, eg. success.',
                    enum: ['success'],
                },
                result: {
                    type: 'null',
                    description: 'The results flag is not set.',
                },
            },
            required: ['status', 'result'],
            title: 'Status200',
        },
    },
    oneOf: [{$ref: '#/definitions/statistics/properties/200/definitions/200'}],
};
const schema54 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        status: {
            type: 'string',
            description: 'Status of the response, eg. success.',
            enum: ['success'],
        },
        result: {type: 'null', description: 'The results flag is not set.'},
    },
    required: ['status', 'result'],
    title: 'Status200',
};
function validate38(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/statistics/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/statistics/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/statistics/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema54.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (data.result !== null) {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/statistics/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'null'},
                                    message: 'must be null',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/statistics/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate38.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate38.errors = vErrors;
    return errors === 0;
}
export const validateStatistics403 = validate39;
function validate39(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/ack/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/ack/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/ack/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema17.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs7 = errors;
                            if (typeof data1 !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/ack/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            if (
                                !(
                                    data1 === '404 Not Found' ||
                                    data1 === '502 Bad Gateway' ||
                                    data1 === '503 Service Unavailable' ||
                                    data1 ===
                                        'All conference hosts departed hosted conference' ||
                                    data1 ===
                                        'API initiated conference termination' ||
                                    data1 ===
                                        'API initiated participant disconnect' ||
                                    data1 === 'Call disconnected' ||
                                    data1 === 'Call rejected' ||
                                    data1 ===
                                        'Conference extension not found' ||
                                    data1 ===
                                        'Conference host ended the conference with a DTMF command' ||
                                    data1 ===
                                        'Conference terminated by an administrator' ||
                                    data1 ===
                                        'Conference terminated by another participant' ||
                                    data1 === 'Disabled' ||
                                    data1 ===
                                        'Disconnected by an administrator' ||
                                    data1 ===
                                        'Disconnected by another participant' ||
                                    data1 === 'Failed to forward request' ||
                                    data1 === 'Gateway dial out failed' ||
                                    data1 === 'Ice failure' ||
                                    data1 === 'Insufficient licenses' ||
                                    data1 ===
                                        'invalid gateway routing rule transform' ||
                                    data1 === 'Invalid license' ||
                                    data1 === 'Invalid PIN' ||
                                    data1 === 'Invalid protocol for service' ||
                                    data1 === 'Invalid role' ||
                                    data1 === 'Invalid token' ||
                                    data1 ===
                                        'Last remaining participant removed from conference after timeout' ||
                                    data1 === 'Media node disconnected' ||
                                    data1 === 'Media process disconnected' ||
                                    data1 ===
                                        'Neither conference nor gateway found' ||
                                    data1 ===
                                        'No direct route between Edge and Transcoding' ||
                                    data1 ===
                                        'No participants can keep conference alive' ||
                                    data1 === 'Out of proxying resource' ||
                                    data1 === 'Out of resource' ||
                                    data1 === 'Out of transcoding resource' ||
                                    data1 ===
                                        'Participant exceeded PIN entry retries' ||
                                    data1 === 'Participant limit reached' ||
                                    data1 ===
                                        'Proxied participant disconnected' ||
                                    data1 === 'Resource unavailable' ||
                                    data1 === 'Signaling node disconnected' ||
                                    data1 ===
                                        'SSO Authentication Failed. SSO is not available from this domain' ||
                                    data1 ===
                                        'SSO Authentication Failed. The system is in Maintenance mode' ||
                                    data1 === 'SSO Authentication Failed' ||
                                    data1 ===
                                        'SSO enabled but no Identity Providers configured' ||
                                    data1 === 'System in maintenance mode' ||
                                    data1 === 'Telehealth Call failure' ||
                                    data1 === 'Test call finished' ||
                                    data1 ===
                                        'Timeout waiting for conference host to join or permit access to locked conference' ||
                                    data1 ===
                                        'Timer expired awaiting token refresh' ||
                                    data1 === 'transfer failed' ||
                                    data1 === 'Unexpected Response: 503' ||
                                    data1 ===
                                        'Attempted to acquire license while license already acquired. This is usually due to an internal error.' ||
                                    data1 ===
                                        'Failed to transfer into a multi-party conference' ||
                                    data1 ===
                                        'Failed to transfer into a one-to-one conference'
                                )
                            ) {
                                const err4 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/ack/properties/403/definitions/403/properties/result/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema17.properties.result.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                };
                                if (vErrors === null) {
                                    vErrors = [err4];
                                } else {
                                    vErrors.push(err4);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err5 = {
                instancePath,
                schemaPath:
                    '#/definitions/ack/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err5];
            } else {
                vErrors.push(err5);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err6 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err6];
        } else {
            vErrors.push(err6);
        }
        errors++;
        validate39.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate39.errors = vErrors;
    return errors === 0;
}
