import type {MeetingPanelType, MeetingPanelsState} from '../types';

export enum PanelAnimationTypes {
    NONE = 'none',
    BREAKOUT_ROOMS_IN = 'breakout-rooms-in',
    BREAKOUT_ROOMS_OUT = 'breakout-rooms-out',
    CHAT_IN = 'chat-in',
    CHAT_OUT = 'chat-out',
    PARTICIPANTS_IN = 'participants-in',
    PARTICIPANTS_OUT = 'participants-out',
}

export const calculatePanelAnimation = (state: {
    currentBreakoutRooms: boolean;
    currentChat: boolean;
    currentParticipants: boolean;
    nextBreakoutRooms: boolean;
    nextChat: boolean;
    nextParticipants: boolean;
}) => {
    const {
        currentBreakoutRooms,
        currentChat,
        currentParticipants,
        nextBreakoutRooms,
        nextChat,
        nextParticipants,
    } = state;

    const areAllPanelsClosed =
        !currentChat && !currentParticipants && !currentBreakoutRooms;
    const shouldPanelBeOpen = nextChat || nextParticipants || nextBreakoutRooms;

    const shouldOpenBreakoutRooms = () =>
        areAllPanelsClosed && nextBreakoutRooms;
    const shouldOpenChat = () => areAllPanelsClosed && nextChat;
    const shouldOpenParticipants = () => areAllPanelsClosed && nextParticipants;

    const shouldCloseBreakoutRooms = () =>
        currentBreakoutRooms && !shouldPanelBeOpen;
    const shouldCloseChat = () => currentChat && !shouldPanelBeOpen;
    const shouldCloseParticipants = () =>
        currentParticipants && !shouldPanelBeOpen;

    if (shouldOpenChat()) {
        return PanelAnimationTypes.CHAT_IN;
    }

    if (shouldOpenParticipants()) {
        return PanelAnimationTypes.PARTICIPANTS_IN;
    }

    if (shouldOpenBreakoutRooms()) {
        return PanelAnimationTypes.BREAKOUT_ROOMS_IN;
    }

    if (shouldCloseChat()) {
        return PanelAnimationTypes.CHAT_OUT;
    }

    if (shouldCloseParticipants()) {
        return PanelAnimationTypes.PARTICIPANTS_OUT;
    }

    if (shouldCloseBreakoutRooms()) {
        return PanelAnimationTypes.BREAKOUT_ROOMS_OUT;
    }

    return PanelAnimationTypes.NONE;
};

const calculateNextState = (
    panel: MeetingPanelType,
    {
        openChatPanel,
        openParticipantPanel,
        openBreakoutRoomsPanel,
    }: {
        openChatPanel: boolean;
        openParticipantPanel: boolean;
        openBreakoutRoomsPanel: boolean;
    },
) => {
    if (panel === 'chat') {
        return !openChatPanel;
    }
    if (panel === 'participants') {
        return !openParticipantPanel;
    }
    return !openBreakoutRoomsPanel;
};

/**
 * @returns `open` to indicate if one of the panels is open
 */
export const togglePanel =
    (panel: MeetingPanelType) =>
    (panelsState: MeetingPanelsState): [boolean, PanelAnimationTypes] => {
        const isOpen = calculateNextState(panel, {
            openChatPanel: panelsState.openChatPanel,
            openParticipantPanel: panelsState.openParticipantPanel,
            openBreakoutRoomsPanel: panelsState.openBreakoutRoomsPanel,
        });

        const isChatToShow = panel === 'chat' && isOpen;
        const isParticipantsToShow = panel === 'participants' && isOpen;
        const isBreakoutRoomsToShow = panel === 'breakoutRooms' && isOpen;

        const animationType = calculatePanelAnimation({
            currentBreakoutRooms: panelsState.openBreakoutRoomsPanel,
            currentChat: panelsState.openChatPanel,
            currentParticipants: panelsState.openParticipantPanel,
            nextBreakoutRooms: isBreakoutRoomsToShow,
            nextChat: isChatToShow,
            nextParticipants: isParticipantsToShow,
        });

        return [isOpen, animationType];
    };
