import type {BrowserOptions} from '@sentry/react';

import {FRAME_RATE} from '@pexip/media-processor';

import {logger} from './logger';
import {shouldEnableVideoProcessing} from './config';
import {
    BANDWIDTHS,
    SEGMENTATION_MODEL,
    TERMS_URL,
    BG_IMAGE_URL,
} from './constants';

const el = document.querySelector('#ic-config');

export interface DeploymentConfig {
    sentry?: BrowserOptions;
}

export const deploymentConfig = (() => {
    try {
        return el ? (JSON.parse(el.innerHTML) as DeploymentConfig) : {};
    } catch (error: unknown) {
        logger.error(error, 'failed to parse deploymentConfig');
        return {};
    }
})();

export const applicationConfig = {
    ...deploymentConfig,
    audioProcessing: true,
    bandwidths: BANDWIDTHS,
    frameRate: FRAME_RATE,
    node: window.location.host,
    segmentationModel: SEGMENTATION_MODEL,
    showLiveCaptionsFeature: true,
    videoProcessing: shouldEnableVideoProcessing(),
    shouldMaskConference: false,
    directMedia: true,
    termsAndConditions: {
        en: TERMS_URL,
    } as Record<string, string>,
    disconnectDestination: '',
    bgImageAssets: [BG_IMAGE_URL],
};
export type ApplicationConfig = typeof applicationConfig;

export const setApplicationConfig = <K extends keyof ApplicationConfig>(
    key: K,
    value: ApplicationConfig[K],
) => {
    applicationConfig[key] = value;
};
