import {logger} from './logger';
import {isEvent, isRPCReply} from './typeGuards';
import type {
    Event,
    EventMessage,
    PluginMessage,
    RPCCallReply,
    RPCCalls,
    RPCReply,
} from './types';
import {generateId} from './utils';

type ChannelEventListener = (_: Event) => void | Promise<void>;

type ChannelEvent<T extends keyof EventMessage = keyof EventMessage> =
    T extends keyof EventMessage ? Omit<Event<T>, 'chanId'> : never;

type ChannelRPCReply<T extends keyof RPCCalls = keyof RPCCalls> =
    T extends keyof RPCCalls ? Omit<RPCReply<T>, 'chanId'> : never;

export class Channel {
    private pendingCalls = new Map<
        string,
        [(_: RPCCalls[keyof RPCCalls]['reply']) => void, (_: Error) => void]
    >();
    private eventListeners = new Set<ChannelEventListener>();

    /**
     * RPC communication based on window postMessage
     * @param target - essentially a window obj we want to send messages to
     * @param chanId - Channel id. Allows one frame to contain multiple plugins.
     */
    constructor(private target: Window, private readonly chanId: string) {
        globalThis.addEventListener('message', this.onMessage);
    }

    addEventListener(listener: ChannelEventListener) {
        this.eventListeners.add(listener);
    }
    removeEventListener(listener: ChannelEventListener) {
        this.eventListeners.delete(listener);
    }
    // TODO: Should app->plugin events have to be explicitly registered for? We
    // likely already need some routing for e.g. plugin button interactions,
    // which should probably only go to the plugin it's from
    private emitEvent(event: ChannelEvent | ChannelRPCReply) {
        this.target.postMessage({chanId: this.chanId, ...event}, '*');
    }

    callRPC<T extends keyof RPCCalls>(
        method: T,
        payload: RPCCalls[T]['payload'],
        transfer?: Transferable[],
    ): Promise<RPCCallReply<T>> {
        const id = generateId();
        logger.debug(
            {payload, id},
            `'${method}' called for channel ${this.chanId}`,
        );
        return new Promise((resolve, reject) => {
            this.pendingCalls.set(id, [resolve, reject]);
            this.target.postMessage(
                {
                    rpc: method,
                    payload,
                    id,
                    chanId: this.chanId,
                },
                '*',
                transfer,
            );
        });
    }
    replyRPC<T extends keyof RPCCalls>(event: ChannelRPCReply<T>) {
        this.emitEvent(event);
    }

    sendEvent<T extends keyof EventMessage>(event: ChannelEvent<T>) {
        this.emitEvent(event);
    }

    unregister() {
        globalThis.removeEventListener('message', this.onMessage);
    }

    private onMessage = (evt: MessageEvent<PluginMessage>) => {
        if (evt.data.chanId !== this.chanId) {
            return;
        }
        const data = evt.data;
        logger.debug({evt}, `Message received for channel ${this.chanId}`);
        if (isRPCReply(data)) {
            const [resolve, _reject] =
                this.pendingCalls.get(data.replyTo) ?? [];
            if (!resolve) {
                logger.debug({evt}, 'Resolve fn doesnt exist');
                return;
            }
            this.pendingCalls.delete(data.replyTo);
            resolve(data.payload);
        }
        if (isEvent(data)) {
            this.eventListeners.forEach(listener => {
                void listener(data);
            });
        }
    };

    get targetWindow() {
        return this.target;
    }
}
