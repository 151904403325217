import {useEffect, useState} from 'react';

import type {PreviewStreamParams, PreviewStreamController} from '@pexip/media';
import {createPreviewStreamController} from '@pexip/media';

export const createPreviewControllerHook =
    (getPreviewStreamParams: () => PreviewStreamParams) =>
    (enabled: boolean) => {
        const [controller, setController] = useState<PreviewStreamController>();

        useEffect(() => {
            if (!enabled) {
                setController(controller => {
                    void controller?.revertChanges();
                    return undefined;
                });
                return;
            }

            const controller = createPreviewStreamController(
                getPreviewStreamParams(),
            );
            setController(controller);

            return () => {
                setController(undefined);
                void controller.revertChanges();
            };
        }, [enabled]);

        return controller;
    };
