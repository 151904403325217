import type {SyntheticEvent} from 'react';
import React, {useMemo, useState} from 'react';
import cx from 'classnames';
import {useTranslation} from 'react-i18next';

import {noop} from '@pexip/utils';
import type {MenuItem} from '@pexip/components';
import {
    Button,
    Icon,
    IconTypes,
    KebabMenuButton,
    Menu,
    Tooltip,
} from '@pexip/components';

import type {InMeetingParticipant} from '../../types';
import {SpeakingIndicator} from '../SpeakingIndicator/SpeakingIndicator.view';
import {TestId} from '../../../test/testIds';

import type {RowActionState} from './ParticipantRowActions.module';
import {
    getShouldShowSelfActions,
    getShouldShowHostActions,
    getShouldShowIndicators,
    displayMuteHandCursor,
} from './ParticipantRowActions.module';
import {withClose} from './utils';

import styles from './ParticipantRowActions.module.scss';

const AdditionalActions: React.FC<{
    close: (e: SyntheticEvent<HTMLElement>) => void;
    handleTransfer?: (identity: string) => void;
    handleDTMF?: (identity: string) => void;
    isCurrentPeer: boolean;
    isSpotlight?: boolean;
    participant: InMeetingParticipant;
    additionalMenuContent: MenuItem[];
}> = ({
    close,
    handleTransfer,
    handleDTMF,
    isCurrentPeer,
    isSpotlight,
    participant,
    additionalMenuContent,
}) => {
    const {t} = useTranslation();
    const handleAction = withClose(close);

    const menuContent = useMemo(() => {
        return [
            Boolean(participant.spotlight) &&
                participant.canSpotlight && {
                    label: isSpotlight
                        ? t('media.remove-spotlight', 'Remove spotlight')
                        : t('media.spotlight', 'Spotlight'),
                    clickAction: handleAction(participant.spotlight),
                    testId: isSpotlight
                        ? TestId.ActionRemoveSpotlight
                        : TestId.ActionSpotlight,
                },
            Boolean(handleTransfer) &&
                participant.canTransfer && {
                    label: t('media.transfer', 'Transfer'),
                    clickAction: handleAction(() =>
                        handleTransfer?.(participant.identity),
                    ),
                    testId: TestId.ActionTransfer,
                },
            Boolean(participant.setRole && !isCurrentPeer) &&
                !participant.isExternal && {
                    label: participant.isHost
                        ? t('meeting.make-guest', 'Make guest')
                        : t('meeting.make-host', 'Make host'),
                    clickAction: handleAction(participant.setRole),
                    testId: participant.isHost
                        ? TestId.ActionMakeGuest
                        : TestId.ActionMakeHost,
                },
            Boolean(handleDTMF) &&
                !participant.isConnecting && {
                    label: t('dtmf.enter-dtmf', 'Enter DTMF'),
                    clickAction: handleAction(() =>
                        handleDTMF?.(participant.identity),
                    ),
                    testId: TestId.ActionEnterDTMF,
                },
        ].filter(Boolean) as MenuItem[];
    }, [
        handleAction,
        handleDTMF,
        handleTransfer,
        isCurrentPeer,
        isSpotlight,
        participant.canSpotlight,
        participant.canTransfer,
        participant.identity,
        participant.isConnecting,
        participant.isExternal,
        participant.isHost,
        participant.setRole,
        participant.spotlight,
        t,
    ]);

    return (
        <Menu
            className={styles.additionalOptions}
            menuContent={[[...menuContent, ...additionalMenuContent]]}
        />
    );
};

export const ParticipantRowActions: React.FC<
    Omit<RowActionState, 'isMuted' | 'isCameraMuted' | 'isPresenting'> & {
        displayName: string;
        handleRemoveUserRequest: (
            participant: InMeetingParticipant,
            displayName: string,
        ) => void;
        handleTransfer?: (identity: string) => void;
        handleDTMF?: (identity: string) => void;
        isCurrentPeer: boolean;
        isHost: boolean;
        participant: InMeetingParticipant;
        onMouseLeave: () => void;
        additionalMenuContent: MenuItem[];
    }
> = ({
    displayName,
    handleRemoveUserRequest,
    handleTransfer,
    handleDTMF,
    hasChildCalls,
    isCurrentPeer,
    canPerformHostActions,
    isHost,
    isHover,
    isSpeaking,
    participant,
    onMouseLeave,
    additionalMenuContent,
}) => {
    const {t} = useTranslation();
    const [isOpen, setOpen] = useState(false);
    const {isMuted, isCameraMuted, isPresenting, isSpotlight} = participant;

    const isInteracted = isHover || isOpen;

    const shouldShowIndicators = getShouldShowIndicators({
        canPerformHostActions,
        isInteracted,
    });
    const shouldShowHostActions = getShouldShowHostActions({
        canPerformHostActions,
        isCurrentPeer,
        isHost,
        isInteracted,
    });
    const shouldShowHostActionsForCurrentPeer = getShouldShowHostActions({
        canPerformHostActions,
        isCurrentPeer: false,
        isHost,
        isInteracted,
    });
    const shouldShowSelfActions = getShouldShowSelfActions(
        isCurrentPeer,
        isInteracted,
    );

    const shouldShowActionOrIndicator =
        shouldShowIndicators || shouldShowHostActions;

    const hasRosterCapabilities = useMemo(
        () => displayMuteHandCursor(isHost, isCurrentPeer),
        [isCurrentPeer, isHost],
    );

    const hasAdditionalActions =
        participant.spotlight ??
        participant.setRole ??
        handleTransfer ??
        handleDTMF;
    const shouldShowAdditionalActions =
        shouldShowHostActionsForCurrentPeer &&
        hasAdditionalActions &&
        (participant.canSpotlight || participant.canTransfer);

    return (
        <>
            {shouldShowAdditionalActions && (
                <KebabMenuButton
                    position="bottomMiddle"
                    buttonSize="compact"
                    buttonModifier="square"
                    onContentVisibilityChange={setOpen}
                    menuComponent={close => (
                        <AdditionalActions
                            close={(e: SyntheticEvent<HTMLElement>) => {
                                close(e);
                                onMouseLeave();
                            }}
                            handleTransfer={handleTransfer}
                            handleDTMF={handleDTMF}
                            isCurrentPeer={isCurrentPeer}
                            participant={participant}
                            isSpotlight={isSpotlight}
                            additionalMenuContent={additionalMenuContent}
                        />
                    )}
                />
            )}
            <div className={styles.indicatorWrapper}>
                {shouldShowHostActions && participant.canDisconnect && (
                    <Tooltip text={t('common.remove')} position="top">
                        <Button
                            className={styles.indicator}
                            aria-label={t(
                                'meeting.remove-participant',
                                'Remove participant',
                            )}
                            colorScheme="dark"
                            modifier="square"
                            onClick={() => {
                                handleRemoveUserRequest(
                                    participant,
                                    displayName,
                                );
                            }}
                            variant="tertiary"
                            size="compact"
                            data-testid={TestId.ButtonKickOffParticipant}
                        >
                            <Icon
                                className={styles.actionButtonIcon}
                                source={IconTypes.IconClose}
                                size="small"
                            />
                        </Button>
                    </Tooltip>
                )}
            </div>
            {isPresenting && shouldShowActionOrIndicator && (
                <Tooltip
                    text={t('media.presentation.presenting', 'Presenting')}
                    position="top"
                >
                    <Button
                        className={cx(styles.indicator, {
                            [styles.noneRosterCanMuteButton]:
                                !hasRosterCapabilities,
                        })}
                        isActive
                        modifier="square"
                        onClick={noop}
                        size="compact"
                        variant="transparent"
                        data-testid={TestId.ButtonPeerPresenting}
                        noHover
                        htmlTag="div"
                    >
                        <Icon
                            className={styles.actionButtonIcon}
                            source={IconTypes.IconPresentationOn}
                            size="small"
                        />
                    </Button>
                </Tooltip>
            )}
            {isCameraMuted && !shouldShowSelfActions && (
                <Tooltip text={t('media.muted', 'Muted')} position="top">
                    <Button
                        className={cx(styles.indicator, {
                            [styles.noneRosterCanMuteButton]:
                                !hasRosterCapabilities,
                        })}
                        isActive
                        modifier="square"
                        onClick={noop}
                        size="compact"
                        variant="transparent"
                        data-testid={TestId.ButtonPeerMutedCamera}
                        noHover
                        htmlTag="div"
                    >
                        <Icon
                            className={styles.actionButtonIcon}
                            source={IconTypes.IconVideoOff}
                            size="small"
                        />
                    </Button>
                </Tooltip>
            )}
            {isCameraMuted && shouldShowSelfActions && (
                <Tooltip text={t('media.unmute', 'Unmute')} position="top">
                    <Button
                        aria-label={t('media.unmute-camera', 'Unmute Camera')}
                        className={styles.indicator}
                        colorScheme="dark"
                        modifier="square"
                        onClick={participant.muteVideo}
                        variant="tertiary"
                        size="compact"
                        data-testid={TestId.ButtonPeerUnmuteCamera}
                    >
                        <Icon
                            className={styles.actionButtonIcon}
                            source={IconTypes.IconVideoOff}
                            size="small"
                        />
                    </Button>
                </Tooltip>
            )}
            <div className={styles.indicatorWrapper}>
                {isMuted && !hasChildCalls && shouldShowIndicators && (
                    <Tooltip text={t('media.muted', 'Muted')} position="top">
                        <Button
                            className={cx(styles.indicator, {
                                [styles.noneRosterCanMuteButton]:
                                    !hasRosterCapabilities,
                            })}
                            isActive
                            modifier="square"
                            onClick={noop}
                            size="compact"
                            variant="transparent"
                            data-testid={TestId.ButtonPeerMuted}
                            noHover
                            htmlTag="div"
                        >
                            <Icon
                                className={styles.actionButtonIcon}
                                source={IconTypes.IconMicrophoneOff}
                                size="small"
                            />
                        </Button>
                    </Tooltip>
                )}
                {isSpeaking && !isMuted && shouldShowIndicators && (
                    <SpeakingIndicator
                        data-testid={TestId.SpeakingIndicator}
                        className={styles.indicator}
                    />
                )}
                {(shouldShowHostActions ||
                    (isMuted && shouldShowHostActionsForCurrentPeer)) &&
                    participant.canMute && (
                        <Tooltip
                            text={
                                isMuted
                                    ? t('media.unmute', 'Unmute')
                                    : t('media.mute', 'Mute')
                            }
                            position="top"
                        >
                            <Button
                                aria-label={
                                    isMuted
                                        ? t(
                                              'media.unmute-participant',
                                              'Unmute participant',
                                          )
                                        : t(
                                              'media.mute-participant',
                                              'Mute participant',
                                          )
                                }
                                className={styles.indicator}
                                colorScheme="dark"
                                modifier="square"
                                onClick={participant.mute}
                                variant="tertiary"
                                size="compact"
                                data-testid={TestId.ButtonMuteParticipant}
                            >
                                <Icon
                                    className={styles.actionButtonIcon}
                                    source={
                                        isMuted
                                            ? IconTypes.IconMicrophoneOff
                                            : IconTypes.IconMicrophoneOn
                                    }
                                    size="small"
                                />
                            </Button>
                        </Tooltip>
                    )}
            </div>
        </>
    );
};

export type ParticipantRowActionsProps = React.ComponentProps<
    typeof ParticipantRowActions
>;
