import React from 'react';
import cx from 'classnames';
import {useTranslation} from 'react-i18next';

import {Modal} from '@pexip/components';
import type {MediaDeviceInfoLike} from '@pexip/media-control';

import {BeforeTestingYourMic} from './BeforeTestingYourMic.view';
import {GetReadyTestingYourMic} from './GetReadyTestingYourMic.view';
import {RecordingAudio} from './RecordingAudio.view';
import {PlayingBack} from './PlayingBack.view';
import {MicrophoneMonitorTitle} from './MicrophoneMonitorTitle.view';
import {ReplayButton} from './ReplayButton.view';

import styles from './MicrophoneMonitorModal.module.scss';

export enum TestYourMicStages {
    BeforeTesting = 'before-testing',
    GetReady = 'get-ready',
    Recording = 'recording',
    RecordingSuccess = 'recording-success',
    PlayingBack = 'playing-back',
    PlaybackFinished = 'playback-finished',
}

export const MicrophoneMonitorModal: React.FC<{
    audioMeter?: React.ReactNode;
    className?: string;
    closeOnOutsideClick?: boolean;
    currentStage: TestYourMicStages;
    audioInputs: MediaDeviceInfoLike[];
    audioOutputs: MediaDeviceInfoLike[];
    isOpen: boolean;
    onClose?: () => void;
    onAudioInputChange: (device: MediaDeviceInfoLike) => void;
    onAudioOutputChange: (device: MediaDeviceInfoLike) => void;
    selectedAudioInput?: MediaDeviceInfoLike;
    selectedAudioOutput?: MediaDeviceInfoLike;
    startTest: () => void;
    tryAgain?: () => void;
    replay?: () => void;
}> = ({
    audioMeter,
    className,
    currentStage = TestYourMicStages.BeforeTesting,
    audioInputs,
    audioOutputs,
    selectedAudioInput,
    selectedAudioOutput,
    isOpen,
    onClose,
    onAudioInputChange,
    onAudioOutputChange,
    startTest,
    closeOnOutsideClick = false,
    tryAgain,
    replay,
}) => {
    const {t} = useTranslation();

    if (!isOpen) {
        return null;
    }

    const renderContent = () => {
        switch (currentStage) {
            case TestYourMicStages.GetReady:
                return <GetReadyTestingYourMic />;
            case TestYourMicStages.Recording:
            case TestYourMicStages.RecordingSuccess:
                return (
                    <RecordingAudio
                        isRecordSuccess={
                            currentStage === TestYourMicStages.RecordingSuccess
                        }
                    >
                        {audioMeter}
                    </RecordingAudio>
                );
            case TestYourMicStages.PlayingBack:
            case TestYourMicStages.PlaybackFinished:
                return (
                    <PlayingBack
                        audioInputs={audioInputs}
                        audioOutputs={audioOutputs}
                        onAudioInputChange={onAudioInputChange}
                        onOutputInputChange={onAudioOutputChange}
                        selectedAudioInput={selectedAudioInput}
                        selectedAudioOutput={selectedAudioOutput}
                        tryAgain={tryAgain}
                    >
                        {currentStage === TestYourMicStages.PlaybackFinished ? (
                            <ReplayButton replay={replay} />
                        ) : (
                            audioMeter
                        )}
                    </PlayingBack>
                );
            default:
                return (
                    <BeforeTestingYourMic
                        onAudioInputChange={onAudioInputChange}
                        selectedAudioInput={selectedAudioInput}
                        audioInputs={audioInputs}
                        startTest={startTest}
                    />
                );
        }
    };

    return (
        <Modal
            padding="medium"
            isOpen={isOpen}
            closeOnOutsideClick={closeOnOutsideClick}
            onClose={onClose}
            withCloseButton
            uniqueTitle={t(
                'media.test-your-microphone',
                'Test your microphone',
            )}
            className={cx(styles.modal, className)}
        >
            <MicrophoneMonitorTitle currentStage={currentStage} />
            {renderContent()}
        </Modal>
    );
};

export type MicrophoneMonitorModalProps = React.ComponentProps<
    typeof MicrophoneMonitorModal
>;
