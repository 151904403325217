import React, {useState} from 'react';
import cx from 'classnames';

import {Box, Draggable} from '@pexip/components';

import {BreakoutParticipantRow} from '../ParticipantRow/ParticipantRow.view';
import type {
    BreakoutRoomId,
    BreakoutRoomName,
    InMeetingParticipant,
} from '../../types';
import {BreakoutRoomVariant} from '../../types';

import styles from './BreakoutRoomParticipantList.module.scss';

export const BreakoutRoomParticipantList = React.forwardRef<
    HTMLDivElement,
    React.ComponentProps<typeof Box> & {
        participants: InMeetingParticipant[];
        myIdentity?: string;
        rooms?: Record<BreakoutRoomId, BreakoutRoomName>;
        variant?: BreakoutRoomVariant;
        onParticipantDragging?: (participantDiv: HTMLDivElement) => void;
        onParticipantDrag?: (participantDiv: HTMLDivElement) => void;
        onParticipantRoomChange?: (
            targetParticipantUuid: string,
            targetRoomId: string,
        ) => void;
    }
>(
    (
        {
            participants,
            myIdentity,
            rooms,
            variant = BreakoutRoomVariant.Setup,
            onParticipantDragging,
            onParticipantDrag,
            onParticipantRoomChange,
            className,
            ...props
        },
        ref,
    ) => {
        const [draggedParticipant, setDraggedParticipant] = useState<
            string | undefined
        >();
        return (
            <Box {...props} ref={ref} className={cx(styles.wrapper, className)}>
                {participants.map(participant => (
                    <Draggable
                        className={styles.item}
                        id={participant.identity}
                        key={participant.identity}
                        onPointerMoveExtra={onParticipantDragging}
                        onPointerUpExtra={onParticipantDrag}
                        onPointerDown={() =>
                            setDraggedParticipant(participant.identity)
                        }
                        onPointerUp={() => setDraggedParticipant(undefined)}
                        isDisabled={
                            variant === BreakoutRoomVariant.Preview ||
                            !rooms ||
                            (rooms && Object.entries(rooms).length === 0)
                        }
                    >
                        <BreakoutParticipantRow
                            participant={participant}
                            isCurrentPeer={participant.identity === myIdentity}
                            rooms={rooms}
                            isDragging={draggedParticipant !== undefined}
                            onParticipantRoomChange={onParticipantRoomChange}
                            className={cx(styles.draggableContent, {
                                [styles.dragging]:
                                    variant !== BreakoutRoomVariant.Preview &&
                                    draggedParticipant === participant.identity,
                            })}
                        />
                    </Draggable>
                ))}
            </Box>
        );
    },
);

BreakoutRoomParticipantList.displayName = 'BreakoutRoomParticipantList';
