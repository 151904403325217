import React, {useEffect} from 'react';
import cx from 'classnames';
import {useTranslation} from 'react-i18next';

import {noop} from '@pexip/utils';
import type {ImageSrc} from '@pexip/components';
import {
    isImageSrcEmpty,
    FontVariant,
    Text,
    TextHeading,
    PexipLogo,
    Logo,
} from '@pexip/components';

import defaultImage from '../../assets/images/background.png';

import type {InfoCardOrientation} from './InfoCard.types';
import type {InfoCardWidth} from './InfoCard.constants';
import {InfoCardType} from './InfoCard.constants';
import {
    useAnimateInfoCard,
    useInfoCardOrientation,
    useInfoCardWidth,
    useInfoCardStates,
} from './InfoCard.hooks';

import styles from './InfoCard.module.scss';

export const InfoCard: React.FC<{
    imageUrl?: string;
    logoUrl?: ImageSrc;
    imageContent?: React.ReactElement;
    headerText?: string;
    bodyContent?: React.ReactNode;
    orientation?: InfoCardOrientation;
    width?: InfoCardWidth;
    animate?: {
        shouldAnimate: boolean;
        onAnimateComplete: () => void;
    };
    type: InfoCardType;
    brandName?: string;
}> = React.memo(
    ({
        imageUrl: imageUrlProp,
        logoUrl,
        headerText: headerTextProp,
        bodyContent: bodyContentProp,
        imageContent,
        orientation: orientationProp,
        width: widthProp,
        animate: {shouldAnimate = false, onAnimateComplete = noop} = {},
        type,
        brandName,
    }) => {
        const {t} = useTranslation();
        const logoLabel = t('common.label-logo', 'logo');
        const defaultBrand = t('common.brand-name', 'your video service');
        const defaultHeaderText =
            type === InfoCardType.Meeting
                ? t('infocard.meeting.header', {
                      defaultValue: 'Welcome to\n {{brandName}}',
                      brandName: brandName || defaultBrand,
                  })
                : t(
                      'infocard.lobby.header',
                      'Please wait, waiting for others to join',
                  );
        const headerText = headerTextProp || defaultHeaderText;
        const bodyContent =
            bodyContentProp ||
            t(
                'infocard.meeting.body',
                'A secure video communications platform',
            );
        const imageUrl = imageUrlProp || defaultImage;
        const defaultOrientation = useInfoCardOrientation();
        const defaultWidth = useInfoCardWidth();
        const width = widthProp ?? defaultWidth;
        const orientation = orientationProp ?? defaultOrientation;
        const [
            {
                isVertical,
                cardWidth,
                cardImageUrl,
                cardLogoUrl,
                cardImageContent,
                cardHeaderText,
                cardBodyContent,
            },
            updateStates,
        ] = useInfoCardStates({
            orientation,
            width,
            imageUrl,
            logoUrl,
            imageContent,
            headerText,
            bodyContent,
        });

        const [cardAnimationState, onAnimationEnd] = useAnimateInfoCard({
            isVertical,
            shouldAnimate,
            updateStates,
            onAnimateComplete,
        });

        useEffect(() => {
            if (!shouldAnimate) {
                updateStates();
            }
        }, [updateStates, shouldAnimate]);

        const mainContent = (
            <>
                <TextHeading
                    htmlTag="h2"
                    fontVariant={FontVariant.H4}
                    colorScheme="light"
                >
                    {cardHeaderText}
                </TextHeading>
                {isVertical &&
                    cardBodyContent &&
                    (typeof cardBodyContent !== 'string' ? (
                        cardBodyContent
                    ) : (
                        <Text
                            className="mt-2 mb-0"
                            htmlTag="p"
                            fontVariant={FontVariant.Body}
                            colorScheme="light"
                        >
                            {cardBodyContent}
                        </Text>
                    ))}
            </>
        );

        if (!width || !orientation) {
            return null;
        }

        return (
            <div
                style={{
                    width:
                        cardAnimationState === 'horizontalClose'
                            ? width
                            : cardWidth,
                }}
                className={cx(
                    styles.infoCard,
                    isVertical
                        ? 'flex-direction-column'
                        : 'flex-direction-row-reverse',
                    {
                        [styles.isVertical]: isVertical,
                        [styles.transitionWidth]:
                            cardAnimationState === 'horizontalClose' ||
                            cardAnimationState === 'horizontalOpen',
                    },
                )}
            >
                <div
                    className={cx(styles.infoCardImage, {
                        [styles.isVertical]: isVertical,
                        [styles.transitionHeight]:
                            cardAnimationState === 'verticalOpen',
                    })}
                    style={{backgroundImage: `url('${cardImageUrl}')`}}
                >
                    {cardImageContent && (
                        <div className={styles.infoCardImageContent}>
                            {cardImageContent}
                        </div>
                    )}
                </div>
                <div
                    onAnimationEnd={onAnimationEnd}
                    className={cx(styles.body, {
                        [cardAnimationState]: shouldAnimate,
                        [styles.isVertical]: isVertical,
                    })}
                >
                    <div
                        className={cx(
                            styles.contentContainer,
                            isVertical
                                ? 'flex-direction-column'
                                : 'flex-direction-column-reverse',
                            {
                                [styles.isVertical]: isVertical,
                                'justify-content-space-between': !isVertical,
                            },
                        )}
                    >
                        <div
                            className={cx(styles.mainContent, {
                                [styles.isVertical]: isVertical,
                            })}
                        >
                            {mainContent}
                        </div>
                        <div
                            className={cx({
                                'mt-12': isVertical,
                            })}
                        >
                            {cardLogoUrl && !isImageSrcEmpty(cardLogoUrl) ? (
                                <Logo
                                    className={styles.logo}
                                    imageSrc={cardLogoUrl}
                                    alt={
                                        brandName
                                            ? `${brandName} ${logoLabel}`
                                            : logoLabel
                                    }
                                    aria-label={
                                        brandName
                                            ? `${brandName} ${logoLabel}`
                                            : logoLabel
                                    }
                                />
                            ) : (
                                <PexipLogo
                                    className={styles.logo}
                                    aria-label={t(
                                        'common.label-pexip-logo',
                                        'Pexip logo',
                                    )}
                                    size="full"
                                    colorScheme="light"
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    },
);

InfoCard.displayName = 'InfoCard';
