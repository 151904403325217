import React, {useEffect, useLayoutEffect} from 'react';
import {setContext} from '@sentry/react';

import {FullscreenSpinner, NotificationToast} from '@pexip/components';

import {UserName} from './pages/UserName/UserName.page';
import {MobileGetStarted} from './pages/MobileGetStarted/MobileGetStarted.page';
import {replace, useRouter} from './router';
import {BrandingContext} from './branding/Context';
import {useBrandingLoader} from './branding/useBrandingLoader';
import {getShouldRenderUserNamePageHook} from './hooks/useShouldRenderUserNamePage';
import {useMainRoutes} from './hooks/useMainRoutes';
import {
    mobileGetStartedNextSignal,
    renderUserNamePageSignal,
} from './signals/MeetingFlow.signals';
import {getShouldRenderMobileGetStartedPageHook} from './hooks/useShouldRenderMobileGetStartedPage';
import {PluginManager} from './plugins';
import {parseLegacyParams} from './utils/parseLegacyParams';
import {EXPRESS_PATH} from './constants';
import {getBrandingPath} from './branding';
import {PluginForms} from './plugins/components/Forms';
import {PluginPrompts} from './plugins/components/Prompts';

const useShouldRenderUserNamePage = getShouldRenderUserNamePageHook(
    renderUserNamePageSignal,
);
const useShouldRenderMobileGetStartedPage =
    getShouldRenderMobileGetStartedPageHook(mobileGetStartedNextSignal);

const IN_MEETING_RE = /^\/m\/[^/]+\/?$/;

const useRedirectInMeeting = () => {
    useLayoutEffect(() => {
        const fromBase = window.location
            .toString()
            .slice(document.baseURI.replace(/\/$/, '').length);
        if (IN_MEETING_RE.exec(fromBase)) {
            replace(`${fromBase.replace(/\/$/, '')}/${EXPRESS_PATH}`);
        }
    }, []);
};

export const App: React.FC = () => {
    useRouter();
    const brand = useBrandingLoader();
    if (brand?.plugins) {
        brand.plugins = brand.plugins.map(plugin => {
            const src = plugin.src;
            try {
                new URL(src);
                return {src};
            } catch {
                return {src: getBrandingPath(src)};
            }
        });
    }
    const matchedRoutes = useMainRoutes();
    const is404 = matchedRoutes.some(route => route.props.match.fallback);
    const shouldRenderUserNamePage = useShouldRenderUserNamePage();
    const shouldRenderMobileGetStartedPage =
        useShouldRenderMobileGetStartedPage();

    useRedirectInMeeting();

    useEffect(() => {
        if (brand?.plugins) {
            setContext('Plugins', {
                ...brand.plugins.map(plugin => ({
                    [plugin.src]: {},
                })),
            });
        }
    }, [brand]);

    useLayoutEffect(() => {
        const paramsString =
            location.search.substring(1) || location.hash.substring(3);
        if (!paramsString) {
            return;
        }
        parseLegacyParams(paramsString);
    }, []);

    const renderPage = () => {
        if (!is404 && shouldRenderMobileGetStartedPage) {
            return <MobileGetStarted />;
        }

        if (!is404 && shouldRenderUserNamePage) {
            return <UserName />;
        }

        return matchedRoutes;
    };

    if (!brand) {
        return null;
    }

    return (
        <React.Suspense fallback={<FullscreenSpinner />}>
            <main>
                <BrandingContext.Provider value={brand}>
                    <PluginManager>
                        <>
                            {renderPage()}
                            <PluginForms />
                            <PluginPrompts />
                            <NotificationToast />
                        </>
                    </PluginManager>
                </BrandingContext.Provider>
            </main>
        </React.Suspense>
    );
};
