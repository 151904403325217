import React from 'react';
import cx from 'classnames';

import type {
    ColorScheme,
    MultistepIndicatorVariant,
} from '../../../types/variants';
import {InteractiveElement} from '../../elements/InteractiveElement/InteractiveElement';
import {ThemeConsumer, ThemeProvider} from '../../../themes/ThemeContext';
import {TestId} from '../../../utils/testIds';
import {LineStep} from '../../elements/LineStep/LineStep.view';

import type {MultistepItem} from './MultistepIndicator.types';
import {getCurrentStepBounds} from './MultistepIndicator.utils';
import {OrdinalText} from './OrdinalText.view';

import styles from './MultistepIndicator.module.scss';

const MultistepIndicatorButton: React.FC<{
    className?: string;
    isCurrent: boolean;
    isStepNameTruncated?: boolean;
    isStepNameHidden: boolean;
    step: MultistepItem;
    stepIndex: number;
    variant: MultistepIndicatorVariant;
    onStepChange?: (stepIndex: number) => void;
}> = ({
    className,
    isCurrent,
    isStepNameTruncated,
    isStepNameHidden,
    step,
    stepIndex,
    variant,
    onStepChange,
}) => {
    const {isActive, isDisabled, url, isCompleted, label, onClick} = step;
    // Disables items that are explicitly disabled, or if they are neither active or current
    const shouldDisable = isDisabled || (!isCurrent && !isActive);

    return (
        <li
            className={cx(
                styles.item,
                {
                    [styles.truncated]: isStepNameTruncated,
                    [styles.lineStepListItem]: variant === 'line',
                },
                className,
            )}
        >
            <InteractiveElement
                aria-current={isCurrent ? 'step' : undefined}
                data-testid={`${TestId.MultistepIndicatorButton}-${stepIndex}`}
                disabled={shouldDisable}
                className={cx(
                    styles.itemButton,
                    {
                        [styles.noHover]: shouldDisable,
                        [styles.lineStepButton]: variant === 'line',
                    },
                    'd-flex align-items-center',
                )}
                onClick={() => {
                    if (!isDisabled) {
                        onStepChange?.(stepIndex);
                        onClick?.();
                    }
                }}
                href={url}
                htmlTag={variant === 'line' ? 'button' : 'a'}
            >
                {variant === 'ordered-list' ? (
                    <OrdinalText
                        isActive={isActive}
                        isCompleted={isCompleted}
                        isCurrent={isCurrent}
                        isStepNameTruncated={isStepNameTruncated}
                        isStepNameHidden={isStepNameHidden}
                        label={label}
                        stepNumber={String(stepIndex + 1)}
                    />
                ) : (
                    <LineStep
                        isActive={isActive || isCurrent}
                        isCompleted={isCompleted}
                        isDisabled={isDisabled}
                    />
                )}
            </InteractiveElement>
        </li>
    );
};

export const MultistepIndicator: React.FC<{
    className?: string;
    colorScheme?: ColorScheme;
    currentStepIndex?: number;
    isStepNameTruncated?: boolean;
    isStepNameHidden?: boolean;
    stepClassName?: string;
    steps: MultistepItem[];
    variant?: MultistepIndicatorVariant;
    onStepChange?: (stepNumber: number) => void;
}> = ({
    className,
    colorScheme,
    currentStepIndex: initialStepIndex = 0,
    isStepNameTruncated,
    isStepNameHidden = false,
    stepClassName,
    steps,
    variant = 'ordered-list',
    onStepChange,
}) => {
    const currentStepIndex = getCurrentStepBounds(
        initialStepIndex,
        steps.length - 1,
    );
    const currentStep = steps[currentStepIndex];

    return (
        <ThemeConsumer>
            {({colorScheme: defaultColorScheme}) => (
                <ThemeProvider colorScheme={colorScheme ?? defaultColorScheme}>
                    <ol
                        aria-label={
                            currentStep
                                ? `${currentStep.label}, step ${
                                      currentStepIndex + 1
                                  } of ${steps.length}`
                                : undefined
                        }
                        className={cx(
                            'd-flex',
                            styles.multistepIndicatorWrapper,
                            {
                                [styles.lineStepperWrapper]: variant === 'line',
                                [styles.isStepNameHidden]: isStepNameHidden,
                            },
                            className,
                        )}
                        data-testid={TestId.MultistepIndicator}
                    >
                        {steps.map((step, index) => (
                            <MultistepIndicatorButton
                                className={stepClassName}
                                isCurrent={index === currentStepIndex}
                                isStepNameTruncated={isStepNameTruncated}
                                isStepNameHidden={isStepNameHidden}
                                key={`${index}-${step.label}`}
                                onStepChange={onStepChange}
                                step={step}
                                stepIndex={index}
                                variant={variant}
                            />
                        ))}
                    </ol>
                </ThemeProvider>
            )}
        </ThemeConsumer>
    );
};

export type MultistepIndicatorProps = React.ComponentProps<
    typeof MultistepIndicator
>;
