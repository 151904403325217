import React from 'react';
import {useTranslation} from 'react-i18next';

import type {InMeetingUI} from '@pexip/media-components';

import type {UserMenuViewProps} from '../views/UserMenu/UserMenu.view';
import {UserMenuView} from '../views/UserMenu/UserMenu.view';
import {DIRTY, VERSION, PLUGIN_API_VERSION, APP_VERSION} from '../constants';
import {useUserMenuContent} from '../hooks/useUserMenuContent';
import {applicationConfig} from '../applicationConfig';

export const UserMenu: React.FC<
    Pick<UserMenuViewProps, 'autoHideProps' | 'variant'> & {
        isInMeeting?: boolean;
        inMeetingUI?: InMeetingUI;
        openSettings?: () => void;
    }
> = ({isInMeeting = false, inMeetingUI, openSettings, ...props}) => {
    const {i18n} = useTranslation();
    const {menuContent, isAboutOpen, setAboutOpen} = useUserMenuContent(
        isInMeeting,
        inMeetingUI,
        openSettings,
    );
    return (
        <UserMenuView
            isAboutOpen={isAboutOpen}
            version={`${APP_VERSION}+${VERSION}${DIRTY}`}
            pluginApiVersion={PLUGIN_API_VERSION}
            menuContent={menuContent}
            setAboutOpen={setAboutOpen}
            termsOfServiceUrl={
                applicationConfig.termsAndConditions?.[i18n.language] ??
                applicationConfig.termsAndConditions?.en
            }
            {...props}
        />
    );
};
