import {useCallback, useState} from 'react';

import {isMobileDevice} from '../utils/isMobileDevice';
import {interfacesMargin} from '../constants';

const coreHeaderHeight = 56;
const headerHeight = coreHeaderHeight + interfacesMargin;

const mobileFooterHeight = 80;
const desktopFooterHeight = 64;
const footerHeight = isMobileDevice()
    ? mobileFooterHeight
    : desktopFooterHeight;

export const useControlsRelativePosition = (
    overlapsCb?: () => void,
    notOverlapsCb?: () => void,
) => {
    const [isOverlappingControls, setIsOverlappingControls] = useState(true);

    const update = useCallback(
        (el: HTMLElement) => {
            if (
                el.offsetTop <= headerHeight ||
                el.getBoundingClientRect().bottom >=
                    window.innerHeight - footerHeight
            ) {
                setIsOverlappingControls(true);
                if (overlapsCb) {
                    overlapsCb();
                }
            } else {
                setIsOverlappingControls(false);
                if (notOverlapsCb) {
                    notOverlapsCb();
                }
            }
        },
        [overlapsCb, notOverlapsCb],
    );

    return {isOverlappingControls, update};
};
