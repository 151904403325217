import React from 'react';

import type {SelectProps} from '@pexip/components';
import {Row} from '@pexip/components';
import type {MediaDeviceInfoLike} from '@pexip/media-control';

import {DevicesList} from '../DevicesList/DevicesList.view';
import type {DeviceError} from '../../types';

export const DevicesSelection: React.FC<
    {
        requestedVideo?: boolean;
        requestedAudio?: boolean;
        videoInput?: MediaDeviceInfoLike;
        audioInput?: MediaDeviceInfoLike;
        audioOutput?: MediaDeviceInfoLike;
        devices: MediaDeviceInfoLike[];
        videoInputError: DeviceError;
        audioInputError: DeviceError;
        onAudioInputChange: (device: MediaDeviceInfoLike) => void;
        onAudioOutputChange: (device: MediaDeviceInfoLike) => void;
        onVideoInputChange: (device: MediaDeviceInfoLike) => void;
        isLoading?: boolean;
        inputAudioTester?: React.ReactNode;
        outputAudioTester?: React.ReactNode;
        learnHowToGrantAccessURL?: string;
        setShowHelpVideo: React.Dispatch<React.SetStateAction<boolean>>;
    } & Pick<SelectProps, 'sizeModifier'>
> = ({
    devices,
    videoInputError,
    audioInputError,
    videoInput,
    audioInput,
    audioOutput,
    onAudioInputChange,
    onAudioOutputChange,
    onVideoInputChange,
    isLoading = false,
    requestedAudio = true,
    requestedVideo = true,
    inputAudioTester,
    outputAudioTester,
    learnHowToGrantAccessURL,
    sizeModifier,
    setShowHelpVideo,
}) => (
    <Row spacing="medium">
        <DevicesList
            requestedAudio={requestedAudio}
            requestedVideo={requestedVideo}
            devices={devices}
            videoInputError={videoInputError}
            audioInputError={audioInputError}
            videoInput={videoInput}
            audioInput={audioInput}
            audioOutput={audioOutput}
            onAudioInputChange={onAudioInputChange}
            onAudioOutputChange={onAudioOutputChange}
            onVideoInputChange={onVideoInputChange}
            isLoading={isLoading}
            inputAudioTester={inputAudioTester}
            outputAudioTester={outputAudioTester}
            learnHowToGrantAccessURL={learnHowToGrantAccessURL}
            sizeModifier={sizeModifier}
            setShowHelpVideo={setShowHelpVideo}
            showTooltip={true}
        />
    </Row>
);
