/**
 * A type utility function to check if the provided object has the prop, and
 * provide a proper type to workaround Typescript's limitation
 *
 * @param obj - The object to check
 * @param prop - The prop to be used for the checking
 */
// eslint-disable-next-line @typescript-eslint/ban-types -- any non-nullish value is the goal here
export const hasOwnProperty = <T extends {}, K extends PropertyKey>(
    obj: T,
    prop: K,
): obj is T & Record<K, unknown> =>
    Object.prototype.hasOwnProperty.call(obj, prop);
