import React, {useCallback, useState} from 'react';

import type {Option} from '@pexip/components';
import {
    Button,
    Form,
    Input,
    Select,
    Checkbox,
    InputLabel,
    TextArea,
} from '@pexip/components';
import type {
    FormInput,
    TextArea as ITextArea,
    FormInputId,
    FormInputValue,
    InputElement,
    SelectElement,
    FormElement,
    ChecklistElement,
    ChecklistOption,
} from '@pexip/plugin-api';
import {isFormSelectElement, isFormChecklistElement} from '@pexip/plugin-api';

export const FormWrapper: React.FC<{
    elements: Record<string, FormElement>;
    submitTitle: string;
    onSubmitInput: (input: FormInput) => void;
}> = ({elements, submitTitle, onSubmitInput}) => {
    const [formElements, setFormElements] = useState<FormInput>(() =>
        Object.entries(elements).reduce((initialState, [id, element]) => {
            if (isFormSelectElement(element)) {
                return {
                    ...initialState,
                    [id]:
                        element.selected ?? (element.options[0]?.id as string),
                };
            }
            if (isFormChecklistElement(element)) {
                return {
                    ...initialState,
                    [id]: (element.options as ChecklistOption[]).reduce(
                        (accm, option) => ({
                            ...accm,
                            [option.id]: option.checked ?? false,
                        }),
                        {},
                    ),
                };
            }
            return {...initialState, [id]: ''};
        }, {}),
    );

    const updateFormElement = useCallback(
        (value: FormInputValue, inputId: FormInputId) => {
            setFormElements(updatedInput => {
                return {
                    ...updatedInput,
                    [inputId]: value,
                };
            });
        },
        [],
    );

    const renderElement = (inputId: string, element: FormElement) => {
        switch (element.type) {
            case 'select': {
                return (
                    <SelectWrapper
                        element={element}
                        updateValue={value => updateFormElement(value, inputId)}
                    />
                );
            }
            case 'checklist': {
                return (
                    <ChecklistWrapper
                        element={element}
                        updateValue={value => updateFormElement(value, inputId)}
                    />
                );
            }
            default: {
                return (
                    <InputWrapper
                        element={element}
                        updateValue={value => updateFormElement(value, inputId)}
                    />
                );
            }
        }
    };

    return (
        <Form
            onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
                event.preventDefault();
                onSubmitInput(formElements);
            }}
        >
            {Object.entries(elements).map(([inputId, element]) => (
                <div key={inputId} className="my-4">
                    {renderElement(inputId, element)}
                </div>
            ))}
            <Button type="submit" modifier="fullWidth" size="large">
                {submitTitle}
            </Button>
        </Form>
    );
};

const SelectWrapper: React.FC<{
    element: SelectElement;
    updateValue: (value: string) => void;
}> = ({element, updateValue}) => {
    const [options] = useState(element.options as Option[]);
    const [selected, setSelected] = useState(
        element.selected ?? (element.options[0]?.id as string),
    );

    const handleChange = useCallback(
        (value: string) => {
            setSelected(value);
            updateValue(value);
        },
        [updateValue],
    );

    return (
        <Select
            autoComplete={element.autoComplete ?? 'off'}
            name={element.name}
            label={element.name}
            value={selected}
            onValueChange={handleChange}
            options={options}
            required={Boolean(!element.isOptional)}
            isFullWidth
        />
    );
};

const InputWrapper: React.FC<{
    element: InputElement | ITextArea;
    updateValue: (value: string) => void;
}> = ({element, updateValue}) => {
    const [input, setInput] = useState('');

    const handleChange = useCallback(
        (value: string) => {
            setInput(value);
            updateValue(value);
        },
        [updateValue],
    );

    const props = {
        autoComplete: element.autoComplete ?? 'off',
        name: element.name,
        label: element.name,
        onValueChange: handleChange,
        required: Boolean(!element.isOptional),
        value: input,
        placeholder: element.placeholder,
    };

    return element.type === 'textarea' ? (
        <TextArea {...props} />
    ) : (
        <Input type={element.type} {...props} />
    );
};

const ChecklistWrapper: React.FC<{
    element: ChecklistElement;
    updateValue: (value: Record<string, boolean>) => void;
}> = ({element, updateValue}) => {
    const [checked, setChecked] = useState<Record<string, boolean>>(() =>
        (element.options as ChecklistOption[]).reduce<Record<string, boolean>>(
            (state, option) => {
                state[option.id] = option.checked ?? false;
                return state;
            },
            {},
        ),
    );

    const handleChange = useCallback(
        (value: Record<string, boolean>) => {
            setChecked(value);
            updateValue(value);
        },
        [updateValue],
    );

    return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
            <InputLabel text={element.name} variant="standard" />
            {element.options.map(({id, label}) => (
                <Checkbox
                    key={id}
                    label={label}
                    name={label}
                    checked={checked[id]}
                    onChange={_e =>
                        handleChange({...checked, [id]: !checked[id]})
                    }
                />
            ))}
        </div>
    );
};
