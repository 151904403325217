import React, {
    createContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

import type {RPCCall} from '@pexip/plugin-api';
import {isRPCCall, Channel} from '@pexip/plugin-api';

import {useBranding} from '../branding/Context';
import {logger} from '../logger';

import type {PluginContext} from './types';
import {handleInfinityCall} from './infinityCalls';
import {registerInfinityClientSignals} from './registerInfinityClientSignals';
import {
    handleAddButton,
    handleOpenForm,
    handleOpenPrompt,
    handleRemoveElement,
    handleShowToast,
    handleUpdateButton,
} from './handleUiRPC';

export const Plugins = createContext<PluginContext>({});

export const Channels = createContext<
    React.MutableRefObject<Map<string, Channel>> | undefined
>(undefined);

export const PluginManager: React.FC<React.PropsWithChildren> = ({
    children,
}) => {
    const plugins = useBranding('plugins');
    const activePlugins = useMemo(
        () =>
            (plugins ?? []).map(plugin => (
                // eslint-disable-next-line jsx-a11y/iframe-has-title -- the plugins are only logical and not visible
                <iframe
                    key={`plugin:${plugin.src}`}
                    sandbox="allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
                    src={plugin.src}
                    aria-hidden
                />
            )),
        [plugins],
    );
    const channels = useRef(new Map<string, Channel>());
    const [pluginsElements, setPluginsElements] = useState<PluginContext>({});

    useEffect(() => {
        const detachSignals = registerInfinityClientSignals(channels.current);
        return () => {
            detachSignals.forEach(detachSignal => detachSignal());
        };
    }, []);

    useEffect(() => {
        const onMessage = ({source, data}: MessageEvent<RPCCall>) => {
            if (!isRPCCall(data)) {
                return;
            }
            const chanId = data.chanId;
            if (data.rpc === 'syn') {
                logger.debug({data}, 'Registering new plugin');
                const newChannel = new Channel(source as Window, chanId);
                channels.current.set(chanId, newChannel);
                setPluginsElements(pluginsElements => ({
                    ...pluginsElements,
                    [chanId]: {
                        buttons: [],
                        forms: [],
                        prompts: [],
                    },
                }));
                newChannel.replyRPC({
                    rpc: data.rpc,
                    replyTo: data.id,
                    payload: {ack: true},
                });
                return;
            }

            const channel = channels.current.get(chanId);
            if (channel) {
                logger.debug({data}, 'Emit reply for plugin message');
                switch (data.rpc) {
                    case 'ui:button:add': {
                        channel.replyRPC(
                            handleAddButton(data, setPluginsElements),
                        );
                        break;
                    }
                    case 'ui:button:update': {
                        channel.replyRPC(
                            handleUpdateButton(data, setPluginsElements),
                        );
                        break;
                    }
                    case 'ui:form:open': {
                        channel.replyRPC(
                            handleOpenForm(data, setPluginsElements),
                        );
                        break;
                    }
                    case 'ui:toast:show': {
                        channel.replyRPC(handleShowToast(data));
                        break;
                    }
                    case 'ui:prompt:open': {
                        channel.replyRPC(
                            handleOpenPrompt(data, setPluginsElements),
                        );
                        break;
                    }
                    case 'ui:removeElement': {
                        channel.replyRPC(
                            handleRemoveElement(data, setPluginsElements),
                        );
                        break;
                    }
                    case 'conference:dialOut':
                    case 'conference:sendMessage':
                    case 'conference:sendApplicationMessage':
                    case 'conference:lock':
                    case 'conference:muteAllGuests':
                    case 'conference:setBandwidth':
                    case 'conference:setLayout':
                    case 'conference:disconnectAll':
                    case 'conference:sendRequest':
                    case 'conference:requestParticipants':
                    case 'conference:breakout':
                    case 'conference:joinBreakoutRoom':
                    case 'conference:closeBreakouts':
                    case 'conference:closeBreakoutRoom':
                    case 'conference:emptyBreakouts':
                    case 'conference:breakoutMoveParticipants':
                    case 'conference:currentRoomId':
                    case 'participant:transfer':
                    case 'participant:mute':
                    case 'participant:muteVideo':
                    case 'participant:disconnect':
                    case 'participant:spotlight':
                    case 'participant:admit':
                    case 'participant:setRole':
                    case 'participant:raiseHand':
                    case 'participant:setTextOverlay':
                    case 'participant:sendDTMF':
                        handleInfinityCall({
                            data,
                            channel,
                        });
                        break;
                }
            }
        };
        window.addEventListener('message', onMessage);
        return () => {
            window.removeEventListener('message', onMessage);
        };
    }, []);

    return (
        <>
            <Channels.Provider value={channels}>
                <Plugins.Provider value={pluginsElements}>
                    {children}
                </Plugins.Provider>
            </Channels.Provider>
            {activePlugins}
        </>
    );
};
