import {createInfinityClient} from '@pexip/infinity';

import {callSignals} from '../signals/Call.signals';
import {infinityClientSignals} from '../signals/InfinityClient.signals';

export const infinityService = createInfinityClient(
    infinityClientSignals,
    callSignals,
);

// Top level subscription to update window.pexDebug values for e2e/QA
callSignals.onRtcStats.add(stats => {
    window.pexDebug = {...window.pexDebug, stats};
});
