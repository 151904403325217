import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';

import type {ChatActivityType, ChatMessage} from '../types';
import {getGroupActivityMessages} from '../utils/getGroupActivityMessages';

export const useBuildTranslatedActivityChatMessages = (
    setMessages: React.Dispatch<React.SetStateAction<ChatMessage[]>>,
    chatActivityType: ChatActivityType,
    chatActivityMessage?: ChatMessage,
) => {
    const {t} = useTranslation();

    useEffect(() => {
        const translateGroupedMessages = (
            previousMessage: ChatMessage,
            newMessage: ChatMessage,
            chatActivityType: ChatActivityType,
        ) => {
            const newDisplayName = newMessage.displayName;
            const previousDisplayName = previousMessage.displayName;

            if (previousMessage?.groupedParticipants) {
                const groupedParticipantCount =
                    previousMessage.groupedParticipants?.length;

                if (groupedParticipantCount === 2) {
                    const firstDisplayName =
                        previousMessage.groupedParticipants[0]?.displayName;
                    const previousSecondDisplayName =
                        previousMessage.groupedParticipants[1]?.displayName;

                    return chatActivityType === 'joined'
                        ? t('meeting.three-participants-joined-group', {
                              defaultValue:
                                  '{{newDisplayName}}, {{previousSecondDisplayName}} and {{firstDisplayName}} joined the meeting',
                              newDisplayName,
                              previousSecondDisplayName,
                              firstDisplayName,
                          })
                        : t('meeting.three-participants-left-group', {
                              defaultValue:
                                  '{{newDisplayName}}, {{previousSecondDisplayName}} and {{firstDisplayName}} left the meeting',
                              newDisplayName,
                              previousSecondDisplayName,
                              firstDisplayName,
                          });
                }

                if (groupedParticipantCount > 2) {
                    const firstDisplayName =
                        previousMessage.groupedParticipants[
                            groupedParticipantCount - 1
                        ]?.displayName;
                    const previousSecondDisplayName =
                        previousMessage.groupedParticipants[
                            groupedParticipantCount - 2
                        ]?.displayName;

                    if (groupedParticipantCount === 3) {
                        return chatActivityType === 'joined'
                            ? t('meeting.four-joined-group', {
                                  defaultValue:
                                      '{{newDisplayName}}, {{previousSecondDisplayName}}, {{firstDisplayName}} and 1 other joined the meeting',
                                  newDisplayName,
                                  previousSecondDisplayName,
                                  firstDisplayName,
                              })
                            : t('meeting.four-left-group', {
                                  defaultValue:
                                      '{{newDisplayName}}, {{previousSecondDisplayName}}, {{firstDisplayName}} and 1 other left the meeting',
                                  newDisplayName,
                                  previousSecondDisplayName,
                                  firstDisplayName,
                              });
                    }

                    return chatActivityType === 'joined'
                        ? t('meeting.more-than-four-joined-group', {
                              defaultValue:
                                  '{{newDisplayName}}, {{previousSecondDisplayName}}, {{firstDisplayName}} and {{participantsCount}} others joined the meeting',
                              newDisplayName,
                              previousSecondDisplayName,
                              firstDisplayName,
                              participantsCount: groupedParticipantCount - 2,
                          })
                        : t('meeting.more-than-four-left-group', {
                              defaultValue:
                                  '{{newDisplayName}}, {{previousSecondDisplayName}}, {{firstDisplayName}} and {{participantsCount}} others left the meeting',
                              newDisplayName,
                              previousSecondDisplayName,
                              firstDisplayName,
                              participantsCount: groupedParticipantCount - 2,
                          });
                }
            }

            return chatActivityType === 'joined'
                ? t('meeting.two-participants-joined-group-display-names', {
                      defaultValue:
                          '{{newDisplayName}} and {{previousDisplayName}} joined the meeting',
                      newDisplayName,
                      previousDisplayName,
                  })
                : t('meeting.two-participants-left-group-display-names', {
                      defaultValue:
                          '{{newDisplayName}} and {{previousDisplayName}} left the meeting',
                      newDisplayName,
                      previousDisplayName,
                  });
        };

        const updateMessage = (
            chatActivityType: ChatActivityType,
            chatActivityMessage?: ChatMessage,
        ) => {
            if (chatActivityMessage) {
                setMessages(messages => {
                    const lastMessage = messages[messages.length - 1];
                    const groupActivityMessages = getGroupActivityMessages(
                        chatActivityType,
                    )(messages, chatActivityMessage);
                    const groupedMessage =
                        groupActivityMessages[messages.length - 1];

                    if (groupedMessage?.groupedParticipants && lastMessage) {
                        const messageText = translateGroupedMessages(
                            lastMessage,
                            chatActivityMessage,
                            chatActivityType,
                        );

                        groupedMessage.message = messageText;
                    }

                    return groupActivityMessages;
                });
            }
        };

        if (chatActivityMessage) {
            updateMessage(chatActivityType, chatActivityMessage);
        }
    }, [chatActivityMessage, chatActivityType, setMessages, t]);
};
