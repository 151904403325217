import {useState, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import type {InMeetingUI} from '@pexip/media-components';
import type {MenuContent} from '@pexip/components';

import {rootLogger} from '../logger';
import {TestId} from '../../test/testIds';

import {useMe} from './useMe';

const downloadLogWithName = () => rootLogger.downloadLog();

export const useUserMenuContent = (
    isInMeeting: boolean,
    inMeetingUI?: InMeetingUI,
    openSettings?: () => void,
) => {
    const [isAboutOpen, setAboutOpen] = useState(false);
    const {t} = useTranslation();
    const me = useMe();
    const canControl = me?.canControl;
    const isAddParticipantOpen = inMeetingUI?.panelsState.openAddParticipant;
    const toggleInMeetingPanels = inMeetingUI?.togglePanels;

    const menuContent: MenuContent = useMemo(() => {
        const menu = [
            ...(isInMeeting && canControl
                ? [
                      {
                          label: t('add-participant.submit', 'Add participant'),
                          testId: TestId.UserMenuAddParticipant,
                          clickAction: () => {
                              !isAddParticipantOpen &&
                                  toggleInMeetingPanels?.('add');
                          },
                          role: 'menuitem',
                      },
                  ]
                : []),
            ...(isInMeeting && openSettings
                ? [
                      {
                          label: t('settings.settings', 'Settings'),
                          testId: TestId.ButtonMeetingDeviceSettings,
                          clickAction: openSettings,
                          role: 'menuitem',
                      },
                  ]
                : []),
            {
                label: t('common.download-log', 'Download log'),
                testId: TestId.LinkDownloadLog,
                clickAction: downloadLogWithName,
                role: 'menuitem',
            },
            {
                label: t('common.about-pexip', 'About Pexip'),
                testId: TestId.LinkAboutPexip,
                clickAction: () => setAboutOpen(true),
                role: 'menuitem',
            },
        ];

        return [menu];
    }, [
        isInMeeting,
        canControl,
        t,
        openSettings,
        isAddParticipantOpen,
        toggleInMeetingPanels,
    ]);

    return {
        menuContent,
        isAboutOpen,
        setAboutOpen,
    };
};
