import React, {useCallback, useEffect, useRef} from 'react';

import {Quality} from '@pexip/peer-connection-stats';
import {notificationToastSignal} from '@pexip/components';

import {CallQualityAlert} from '../views/CallQualityAlert/CallQualityAlert.view';
import {StreamQuality, NetworkState} from '../types';

const CLOSE_ALERT_TIMEOUT = 3000;

const openNotificationToast = (
    streamQuality: StreamQuality,
    onCallQualityAlertClick: () => void,
) => {
    const shouldDisplayChangeLink = streamQuality !== StreamQuality.Low;
    notificationToastSignal.emit([
        {
            message: (
                <CallQualityAlert
                    onClick={
                        shouldDisplayChangeLink
                            ? onCallQualityAlertClick
                            : undefined
                    }
                />
            ),
            isDanger: true,
            isInterrupt: true,
            colorScheme: 'light',
            timeout: 0,
            isClickable: shouldDisplayChangeLink,
        },
    ]);
};

const closeAlert = () => {
    notificationToastSignal.emit({close: true});
};

export const useCallQualityToast = ({
    callQuality,
    getStreamQuality,
    onCallQualityAlertClick,
    networkState = NetworkState.Connected,
}: {
    callQuality: Quality;
    getStreamQuality: () => StreamQuality;
    onCallQualityAlertClick: () => void;
    networkState?: NetworkState;
}) => {
    const timeoutId = useRef<number>(0);

    const handleCallQualityAlertClick = useCallback(() => {
        onCallQualityAlertClick();
        closeAlert();
    }, [onCallQualityAlertClick]);

    const cleanupTimeout = () => {
        if (timeoutId.current) {
            clearTimeout(timeoutId.current);
            timeoutId.current = 0;
        }
    };

    useEffect(() => {
        if (networkState === NetworkState.Reconnecting) {
            return closeAlert();
        } else {
            const streamQuality = getStreamQuality();
            if (
                callQuality === Quality.BAD ||
                callQuality === Quality.TERRIBLE
            ) {
                cleanupTimeout();
                openNotificationToast(
                    streamQuality,
                    handleCallQualityAlertClick,
                );
            } else {
                timeoutId.current = window.setTimeout(
                    closeAlert,
                    CLOSE_ALERT_TIMEOUT,
                );
            }

            return cleanupTimeout;
        }
    }, [
        callQuality,
        networkState,
        getStreamQuality,
        handleCallQualityAlertClick,
    ]);
};
