import {ConfigManager, configHook} from '@pexip/config-manager';
import type {MediaDeviceInfoLike} from '@pexip/media-control';
import {currentBrowserName, isMobileDevice} from '@pexip/media-components';

import {
    INITIAL_BANDWIDTH,
    RENDER_EFFECTS,
    FOREGROUND_THRESHOLD,
    BACKGROUND_BLUR_AMOUNT,
    EDGE_BLUR_AMOUNT,
    FLIP_HORIZONTAL,
    BG_IMAGE_URL,
    CALL_TYPE,
} from './constants';

export const shouldEnableVideoProcessing = () =>
    !isMobileDevice() &&
    (currentBrowserName === 'Chrome' ||
        currentBrowserName === 'Firefox' ||
        currentBrowserName === 'Edge Chromium') &&
    (() => {
        const WEBGL_ATTRIBUTES = {
            alpha: false,
            antialias: false,
            premultipliedAlpha: false,
            preserveDrawingBuffer: false,
            depth: false,
            stencil: false,
            failIfMajorPerformanceCaveat: true,
        };
        try {
            const canvas = document.createElement('canvas');
            return !!(
                window.WebGLRenderingContext &&
                (canvas.getContext('webgl', WEBGL_ATTRIBUTES) ||
                    canvas.getContext('experimental-webgl', WEBGL_ATTRIBUTES))
            );
        } catch (e) {
            return false;
        }
    })();

const internalConfig = {
    asd: false,
    audioInput: {} as MediaDeviceInfoLike | undefined,
    audioOutput: {} as MediaDeviceInfoLike | undefined,
    lastFallBackMsg: '',
    showLayoutChangeConfirmationModal: true,
    showSelfviewHiddenTooltip: true,
    vad: false,
    videoInput: {} as MediaDeviceInfoLike | undefined,
};

const defaultUserConfig = {
    backgroundBlurAmount: BACKGROUND_BLUR_AMOUNT,
    bandwidth: INITIAL_BANDWIDTH,
    bgImageUrl: BG_IMAGE_URL,
    denoise: false,
    displayName: '',
    edgeBlurAmount: EDGE_BLUR_AMOUNT,
    flipHorizontal: FLIP_HORIZONTAL,
    foregroundThreshold: FOREGROUND_THRESHOLD,
    isAudioInputMuted: false,
    isVideoInputMuted: false,
    segmentationEffects: RENDER_EFFECTS,
    showSelfView: true,
    isUserFacing: true,
    userBgImageAssets: [] as string[],
    callType: CALL_TYPE,
};
export type DefaultUserConfig = typeof defaultUserConfig;

export const config = new ConfigManager(
    {
        ...internalConfig,
        ...defaultUserConfig,
    },
    'infinity-connect',
);

export const useConfig = configHook(config);
