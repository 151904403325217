import React, {forwardRef} from 'react';
import cx from 'classnames';

import type {IScrollbars} from '@pexip/components';
import {Scrollbars} from '@pexip/components';

import styles from './ChatScrollbars.module.scss';

/**
 * Wrapper component over `Scrollbars` that adds dynamic max-height.
 */
export const ChatScrollbars = forwardRef<
    IScrollbars,
    {
        onScroll?: () => void;
        children?: React.ReactNode;
        hasInput?: boolean;
    }
>(({children, onScroll, hasInput = true, ...props}, ref) => (
    <Scrollbars
        className={cx(
            hasInput ? styles.scrollContent : styles.scrollContentWithoutInput,
        )}
        onScroll={onScroll}
        autoHide="leave"
        {...props}
        ref={ref}
    >
        {children}
    </Scrollbars>
));

ChatScrollbars.displayName = 'ChatScrollbars';

export type ChatScrollbarsProps = React.ComponentProps<typeof ChatScrollbars>;
