import React from 'react';
import cx from 'classnames';

import {Box} from '@pexip/components';

import {TestId} from '../../../test/testIds';
import type {MeetingPanelsState} from '../../types';
import {PanelAnimationTypes} from '../../utils/meetingPanels';

import styles from './ParticipantSidePanelWrapper.module.scss';

export const ParticipantSidePanelWrapper: React.FC<{
    children: React.ReactNode;
    panelsState: MeetingPanelsState;
    isNetworkAlertDisplayed: boolean;
}> = ({children, panelsState, isNetworkAlertDisplayed}) => (
    <Box
        className={cx(styles.panel, styles.participantList, {
            [styles.show]: panelsState.openParticipantPanel,
            [styles.animateIn]:
                panelsState.animationType ===
                PanelAnimationTypes.PARTICIPANTS_IN,
            [styles.animateOut]:
                panelsState.animationType ===
                PanelAnimationTypes.PARTICIPANTS_OUT,
            [styles.networkAlert]: isNetworkAlertDisplayed,
        })}
        data-testid={TestId.ParticipantPanel}
    >
        {children}
    </Box>
);

export type ParticipantSidePanelWrapperProps = React.ComponentProps<
    typeof ParticipantSidePanelWrapper
>;
