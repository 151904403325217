import React from 'react';

import {FullSizeWindow} from '../FullSizeWindow/FullSizeWindow';

import styles from './FullscreenOverlay.module.scss';

export const FullscreenOverlay: React.FC<React.PropsWithChildren> = ({
    children,
    ...props
}) => (
    <FullSizeWindow
        padding="none"
        background="dark"
        className={styles.overlay}
        {...props}
    >
        {children}
    </FullSizeWindow>
);
