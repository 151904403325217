import {useCallback, useEffect, useState} from 'react';

import {PresentationEmphasis, StreamStatus} from '../types';

export const useMeetingStageLayout = ({
    expandPrimaryVideo,
    isPresenting,
    isPresentationPoppedOut,
    presentationEmphasis,
    setEmphasis,
}: {
    expandPrimaryVideo: boolean;
    isPresenting: boolean;
    isPresentationPoppedOut?: boolean;
    presentationEmphasis?: PresentationEmphasis;
    setEmphasis: (emphasis: PresentationEmphasis) => void;
}) => {
    const [isMeetingVideoBrowserPip, setIsMeetingVideoBrowserPip] =
        useState(false);
    const [isPresentationBrowserPip, setIsPresentationBrowserPip] =
        useState(false);

    const [meetingStreamStatus, setMeetingStreamStatus] =
        useState<StreamStatus>(StreamStatus.None);
    const [presoStreamStatus, setPresoStreamStatus] = useState<StreamStatus>(
        StreamStatus.None,
    );

    useEffect(() => {
        if (!isPresenting) {
            setMeetingStreamStatus(StreamStatus.Expanded);
        } else if (isPresentationPoppedOut) {
            setMeetingStreamStatus(StreamStatus.Expanded);
            setPresoStreamStatus(StreamStatus.External);
        } else if (isMeetingVideoBrowserPip) {
            setMeetingStreamStatus(StreamStatus.BrowserPip);
            setPresoStreamStatus(StreamStatus.Expanded);
        } else if (isPresentationBrowserPip) {
            setMeetingStreamStatus(StreamStatus.Expanded);
            setPresoStreamStatus(StreamStatus.BrowserPip);
        } else if (
            expandPrimaryVideo &&
            presentationEmphasis === PresentationEmphasis.Primary
        ) {
            setMeetingStreamStatus(StreamStatus.Pip);
            setPresoStreamStatus(StreamStatus.Expanded);
        } else if (
            expandPrimaryVideo &&
            presentationEmphasis === PresentationEmphasis.Secondary
        ) {
            setMeetingStreamStatus(StreamStatus.Expanded);
            setPresoStreamStatus(StreamStatus.Pip);
        } else if (
            !expandPrimaryVideo &&
            presentationEmphasis === PresentationEmphasis.Secondary
        ) {
            setMeetingStreamStatus(StreamStatus.Emphasized);
            setPresoStreamStatus(StreamStatus.Deemphasized);
        } else {
            setMeetingStreamStatus(StreamStatus.Deemphasized);
            setPresoStreamStatus(StreamStatus.Emphasized);
        }
    }, [
        isMeetingVideoBrowserPip,
        isPresentationBrowserPip,
        isPresenting,
        isPresentationPoppedOut,
        expandPrimaryVideo,
        presentationEmphasis,
    ]);

    const handleMeetingVideoClick = useCallback(() => {
        // deemphasize a emphasized presentation on meeting click
        handleSecondaryVideoClick(meetingStreamStatus, () =>
            setEmphasis(PresentationEmphasis.Secondary),
        );
    }, [meetingStreamStatus, setEmphasis]);

    const handlePresentationVideoClick = useCallback(() => {
        // emphasize a deemphasized presentation on click
        handleSecondaryVideoClick(presoStreamStatus, () =>
            setEmphasis(PresentationEmphasis.Primary),
        );
    }, [presoStreamStatus, setEmphasis]);

    return {
        meetingStreamStatus,
        presoStreamStatus,
        setIsMeetingVideoBrowserPip,
        setIsPresentationBrowserPip,
        handleMeetingVideoClick,
        handlePresentationVideoClick,
    };
};

const handleSecondaryVideoClick = (
    status: StreamStatus,
    action: () => void,
) => {
    // Secondary is defined as a meeting video with either Deemphasized or Pip status
    if (status === StreamStatus.Deemphasized || status === StreamStatus.Pip) {
        action();
    }
};
