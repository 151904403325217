import React from 'react';
import cx from 'classnames';
import {useTranslation} from 'react-i18next';

import {Quality} from '@pexip/peer-connection-stats';
import {Button, SvgImage, Tooltip} from '@pexip/components';
import type {ButtonProps, TooltipPosition} from '@pexip/components';

import {stopMouseEventPropagation} from '../../utils/domEventsHandles';

import GoodIcon from './svgs/good.svg';
import OKIcon from './svgs/ok.svg';
import BadIcon from './svgs/bad.svg';
import TerribleIcon from './svgs/terrible.svg';

import styles from './CallQualityIndicatorButton.module.scss';

const QualityIcon: Record<Quality, React.ElementType> = {
    [Quality.GOOD]: GoodIcon,
    [Quality.OK]: OKIcon,
    [Quality.BAD]: BadIcon,
    [Quality.TERRIBLE]: TerribleIcon,
};

const getQualityIcon = (quality: Quality) =>
    QualityIcon[quality] ? QualityIcon[quality] : GoodIcon;

export const TransparentCallQualityIndicator: React.FC<{
    className?: string;
    isPhone?: boolean;
    onClick: () => void;
    stopPointerDownEventPropagation?: boolean;
    quality: Quality;
    tooltipClassName?: string;
    tooltipPosition?: TooltipPosition;
    tooltipText: string;
}> = ({
    className,
    isPhone = false,
    onClick,
    stopPointerDownEventPropagation = false,
    quality,
    tooltipClassName,
    tooltipPosition,
    tooltipText,
    ...props
}) => {
    const icon = (
        <SvgImage
            onClick={onClick}
            onPointerDownCapture={stopMouseEventPropagation(
                stopPointerDownEventPropagation,
            )}
            source={getQualityIcon(quality)}
            className={cx(
                styles.callQualityIndicatorIcon,
                isPhone ? styles.mini : styles.compact,
                className,
            )}
            {...props}
        />
    );

    if (isPhone) {
        return icon;
    }

    return (
        <Tooltip
            text={tooltipText}
            position={tooltipPosition}
            className={tooltipClassName}
        >
            {icon}
        </Tooltip>
    );
};

export const CallQualityIndicatorButton: React.FC<
    ButtonProps & {
        onClick: () => void;
        quality: Quality;
        tooltipPosition?: TooltipPosition;
        tooltipText: string;
    }
> = ({className, onClick, quality, tooltipPosition, tooltipText, ...props}) => {
    const {t} = useTranslation();
    return (
        <Tooltip text={tooltipText} position={tooltipPosition}>
            <Button
                aria-label={t('quality.quality', 'Connection quality')}
                onClick={onClick}
                className={cx(styles.callQualityIndicatorButton, className)}
                variant="transparent"
                modifier="square"
                {...props}
            >
                <SvgImage
                    source={getQualityIcon(quality)}
                    className={cx(
                        styles.callQualityIndicatorIcon,
                        styles.compact,
                    )}
                />
            </Button>
        </Tooltip>
    );
};

export type TransparentCallQualityIndicatorProps = React.ComponentProps<
    typeof TransparentCallQualityIndicator
>;

export type CallQualityIndicatorButtonProps = React.ComponentProps<
    typeof CallQualityIndicatorButton
>;
