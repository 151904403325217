import React from 'react';
import {useTranslation} from 'react-i18next';

import {NotificationTooltip} from '@pexip/components';
import type {DeniedDevices} from '@pexip/media';
import {UserMediaStatus} from '@pexip/media';

import type {DeviceStatusInfo} from '../../types';
import {DeviceDeniedTooltip} from '../DeviceDeniedTooltip/DeviceDeniedTooltip.view';
import {TestId} from '../../../test/testIds';

import {InputControl} from './InputControl.view';

export type TooltipPosition =
    | 'topLeft'
    | 'topRight'
    | 'topCenter'
    | 'bottomRight'
    | 'bottomCenter'
    | 'bottomLeft'
    | undefined;

export const PreflightInputControl: React.FC<
    React.ComponentProps<typeof InputControl> & {
        className?: string;
        inputStatusInfo: DeviceStatusInfo;
        isTooltipVisible: boolean;
        toolTipTestId?: string;
        setIsTooltipVisible: (value: React.SetStateAction<boolean>) => void;
        shouldShowRetry: boolean;
        isDisabled?: boolean;
        testIdCloseButton?: string;
        requestMediaPermissions: () => void;
        audioInputControl?: React.ReactNode;
        tooltipPosition?: TooltipPosition;
        streamStatus?: UserMediaStatus;
        deniedDevice?: DeniedDevices;
        setShowHelpVideo: React.Dispatch<React.SetStateAction<boolean>>;
        learnHowToGrantAccessURL?: string;
    }
> = ({
    inputStatusInfo,
    isTooltipVisible,
    toolTipTestId,
    setIsTooltipVisible,
    shouldShowRetry,
    testIdCloseButton,
    requestMediaPermissions,
    tooltipPosition = 'topLeft',
    audioInputControl,
    streamStatus,
    deniedDevice,
    setShowHelpVideo,
    learnHowToGrantAccessURL,
    ...props
}) => {
    const {t} = useTranslation();
    const control = audioInputControl ? (
        audioInputControl
    ) : (
        <InputControl variant="tertiary" {...props} />
    );

    return streamStatus === UserMediaStatus.PermissionsRejected &&
        deniedDevice ? (
        <DeviceDeniedTooltip
            deniedDevice={deniedDevice}
            onClose={() => setIsTooltipVisible(!isTooltipVisible)}
            visible={isTooltipVisible}
            data-testid={TestId.TooltipDeniedDevice}
            setShowHelpVideo={setShowHelpVideo}
            learnHowToGrantAccessURL={learnHowToGrantAccessURL}
        >
            {control}
        </DeviceDeniedTooltip>
    ) : (
        <NotificationTooltip
            controlledIsVisible={isTooltipVisible}
            controlledOnChange={setIsTooltipVisible}
            ctaFn={shouldShowRetry ? requestMediaPermissions : undefined}
            ctaText={shouldShowRetry ? t('common.retry', 'Retry') : undefined}
            header={inputStatusInfo.title}
            position={tooltipPosition}
            testIdCloseButton={testIdCloseButton}
            testIdInner={toolTipTestId}
            text={inputStatusInfo.message}
            closeButtonAriaLabel={t('common.close-tooltip', 'Close tooltip')}
        >
            {control}
        </NotificationTooltip>
    );
};
