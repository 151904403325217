import React from 'react';

import {HeaderView} from '../views/Header/Header.view';
import {NonMeetingHeaderEndEnhancer} from '../views/NonMeetingHeaderEndEnhancer/NonMeetingHeaderEndEnhancer.view';

import {UserMenu} from './UserMenu.viewModel';

export const NonMeetingHeader: React.FC = () => (
    <HeaderView
        enhancerEnd={
            <NonMeetingHeaderEndEnhancer
                userMenu={<UserMenu variant="bordered" />}
            />
        }
    />
);
