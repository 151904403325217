import {Tensor, browser} from '@tensorflow/tfjs-core/dist/base.js';

import type {Canvas} from '../types';
import {fitDestinationSize} from '../process';

import type {ProcessInputType, Segmentation, ImageType} from './types';
import {
    createOffscreenCanvas,
    getImageSize,
    getCanvasRenderingContext2D,
    toBinaryMask,
    flipCanvasHorizontal,
    loadImage,
} from './utils';

interface InternalCanvases {
    drawImageDataCanvas?: Canvas;
    maskCanvas?: Canvas;
    blurredMaskCanvas?: Canvas;
    blurredCanvas?: Canvas;
    backgroundImageCanvas?: Canvas;
    inputCanvas?: Canvas;
}

interface InternalImages {
    backgroundImage?: HTMLImageElement;
}

export const createCanvasRenderUtils = (
    processingWidth: number,
    processingHeight: number,
) => {
    const props: InternalCanvases & InternalImages = {};

    const getImage = (imageName: keyof InternalImages): HTMLImageElement => {
        const image = props[imageName];
        if (!image) {
            const img = new Image();
            props[imageName] = img;
            return img;
        }
        return image;
    };

    const getCanvas = (canvasName: keyof InternalCanvases): Canvas => {
        const canvas = props[canvasName];
        if (!canvas) {
            const canvas = createOffscreenCanvas(
                processingWidth,
                processingHeight,
            );
            props[canvasName] = canvas;
            return canvas;
        }
        return canvas;
    };

    const renderImageDataToOffScreenCanvas = (
        image: ImageData,
        canvasName: keyof InternalCanvases,
    ) => {
        const canvas = getCanvas(canvasName);
        const context = getCanvasRenderingContext2D(canvas);
        context.putImageData(image, 0, 0);
        return canvas;
    };

    /**
     * Draw image on a 2D rendering context.
     */
    const drawImage = async (
        ctx: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D,
        image: ImageType,
        sx: number,
        sy: number,
        sw?: number,
        sh?: number,
        dx?: number,
        dy?: number,
        dw?: number,
        dh?: number,
        // eslint-disable-next-line max-params -- avoid unnecessary object creation
    ) => {
        if (image instanceof Tensor) {
            const pixels = await browser.toPixels(image);
            const {height, width} = getImageSize(image);
            image = new ImageData(pixels, width, height);
        }
        const source =
            image instanceof ImageData
                ? renderImageDataToOffScreenCanvas(image, 'drawImageDataCanvas')
                : image;
        if (sw === undefined || sh === undefined) {
            ctx.drawImage(source, sx, sy);
        } else if (
            dx === undefined ||
            dy === undefined ||
            dw === undefined ||
            dh === undefined
        ) {
            ctx.drawImage(source, sx, sy, sw, sh);
        } else {
            ctx.drawImage(source, sx, sy, sw, sh, dx, dy, dw, dh);
        }
    };

    const renderImageToCanvas = async (
        image: ImageType,
        canvas: Canvas,
        dw = processingWidth,
        dh = processingHeight,
        options: CanvasRenderingContext2DSettings = {},
        // eslint-disable-next-line max-params -- avoid unnecessary object creation
    ) => {
        const {height, width} = getImageSize(image);
        const rect = fitDestinationSize(width, height, dw, dh);
        const ctx = getCanvasRenderingContext2D(canvas, options);

        await drawImage(ctx, image, rect.x, rect.y, rect.width, rect.height);
    };

    const renderImageToOffScreenCanvas = async (
        image: ImageType,
        canvasName: keyof InternalCanvases,
    ) => {
        const canvas = getCanvas(canvasName);
        await renderImageToCanvas(image, canvas);
        return canvas as HTMLCanvasElement;
    };

    const drawWithCompositing = async (
        ctx: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D,
        image: ImageType,
        compositeOperation: GlobalCompositeOperation,
    ) => {
        ctx.globalCompositeOperation = compositeOperation;
        await drawImage(ctx, image, 0, 0);
    };

    // method copied from blur in https://codepen.io/zhaojun/pen/zZmRQe
    const cpuBlur = async (canvas: Canvas, image: ImageType, blur: number) => {
        const ctx = getCanvasRenderingContext2D(canvas);

        let sum = 0;
        const delta = 5;
        const alphaLeft = 1 / (2 * Math.PI * delta * delta);
        const step = blur < 3 ? 1 : 2;
        for (let y = -blur; y <= blur; y += step) {
            for (let x = -blur; x <= blur; x += step) {
                const weight =
                    alphaLeft *
                    Math.exp(-(x * x + y * y) / (2 * delta * delta));
                sum += weight;
            }
        }
        for (let y = -blur; y <= blur; y += step) {
            for (let x = -blur; x <= blur; x += step) {
                ctx.globalAlpha =
                    ((alphaLeft *
                        Math.exp(-(x * x + y * y) / (2 * delta * delta))) /
                        sum) *
                    blur;
                await drawImage(ctx, image, x, y);
            }
        }
        ctx.globalAlpha = 1;
    };

    const drawAndBlurImageOnCanvas = async (
        image: ImageType,
        blurAmount: number,
        canvas: Canvas,
    ) => {
        const {height, width} = getImageSize(image);
        const ctx = getCanvasRenderingContext2D(canvas);
        ctx.clearRect(0, 0, width, height);
        if (blurAmount <= 0) {
            return drawImage(ctx, image, 0, 0, width, height);
        }
        ctx.save();
        if ('filter' in ctx) {
            // Avoid the transparent edge by Gaussian blur
            await drawImage(ctx, image, 0, 0, width, height);
            ctx.filter = `blur(${blurAmount}px)`;
            await drawImage(ctx, image, 0, 0, width, height);
        } else {
            // Safari doesn't support filter
            // https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/filter
            await cpuBlur(canvas, image, blurAmount);
        }
        ctx.restore();
    };

    const drawAndBlurImageOnOffScreenCanvas = async (
        image: ImageType,
        blurAmount: number,
        offscreenCanvasName: keyof InternalCanvases,
    ): Promise<Canvas> => {
        const canvas = getCanvas(offscreenCanvasName);
        if (blurAmount === 0) {
            await renderImageToCanvas(image, canvas);
        } else {
            await drawAndBlurImageOnCanvas(image, blurAmount, canvas);
        }
        return canvas;
    };

    const createPersonMask = async (
        segmentation: Segmentation | Segmentation[],
        foregroundThreshold: number,
        edgeBlurAmount: number,
    ): Promise<Canvas> => {
        const backgroundMaskImage = await toBinaryMask(
            segmentation,
            {r: 0, g: 0, b: 0, a: 255},
            {r: 0, g: 0, b: 0, a: 0},
            false,
            foregroundThreshold,
        );

        if (!backgroundMaskImage) {
            return getCanvas('maskCanvas');
        }

        const backgroundMask = renderImageDataToOffScreenCanvas(
            backgroundMaskImage,
            'maskCanvas',
        );
        if (edgeBlurAmount === 0) {
            return backgroundMask;
        } else {
            return drawAndBlurImageOnOffScreenCanvas(
                backgroundMask,
                edgeBlurAmount,
                'blurredMaskCanvas',
            );
        }
    };

    const loadImageElement = async (
        url: string,
        imageName: keyof InternalImages,
    ) => {
        const image = getImage(imageName);
        await loadImage(image, url);
        return image;
    };

    const loadAndDrawImageOnOffscreenCanvas = async (
        url: string,
        canvasName: keyof InternalCanvases,
        imageName: keyof InternalImages,
    ) => {
        const image = await loadImageElement(url, imageName);
        const canvas = getCanvas(canvasName);
        const context = getCanvasRenderingContext2D(canvas);
        const imageSize = getImageSize(image);
        const rect = fitDestinationSize(
            imageSize.width,
            imageSize.height,
            processingWidth,
            processingHeight,
        );
        await drawImage(
            context,
            image,
            rect.x,
            rect.y,
            rect.width,
            rect.height,
        );
        return canvas;
    };

    const loadBackgroundImage = (url: string) =>
        loadAndDrawImageOnOffscreenCanvas(
            url,
            'backgroundImageCanvas',
            'backgroundImage',
        );

    const drawBokehEffect = async (
        canvas: Canvas,
        inputImage: ImageType,
        backgroundImage: ImageType,
        segmentations: Segmentation | Segmentation[],
        foregroundThreshold = 0.5,
        backgroundBlurAmount = 3,
        edgeBlurAmount = 3,
        flipHorizontal = false,
        // eslint-disable-next-line max-params -- avoid unnecessary object creation
    ) => {
        const blurredImage = await drawAndBlurImageOnOffScreenCanvas(
            backgroundImage,
            backgroundBlurAmount,
            'blurredCanvas',
        );

        const ctx = getCanvasRenderingContext2D(canvas);

        if (Array.isArray(segmentations) && segmentations.length === 0) {
            return drawImage(ctx, blurredImage, 0, 0);
        }

        const personMask = await createPersonMask(
            segmentations,
            foregroundThreshold,
            edgeBlurAmount,
        );

        ctx.save();
        if (flipHorizontal) {
            flipCanvasHorizontal(canvas);
        }
        // draw the original image on the final canvas
        const {height, width} = getImageSize(inputImage);
        await drawImage(ctx, inputImage, 0, 0, width, height);

        // "destination-in" - "The existing canvas content is kept where both the
        // new shape and existing canvas content overlap. Everything else is made
        // transparent."
        // crop what's not the person using the mask from the original image
        await drawWithCompositing(ctx, personMask, 'destination-in');
        // "destination-over" - "The existing canvas content is kept where both the
        // new shape and existing canvas content overlap. Everything else is made
        // transparent."
        // draw the blurred background on top of the original image where it doesn't
        // overlap.
        await drawWithCompositing(ctx, blurredImage, 'destination-over');
        ctx.restore();
    };

    const drawBlurEffect = (
        canvas: Canvas,
        inputImage: ImageType,
        segmentations: Segmentation | Segmentation[],
        foregroundThreshold = 0.5,
        backgroundBlurAmount = 3,
        edgeBlurAmount = 3,
        flipHorizontal = false,
        // eslint-disable-next-line max-params -- avoid unnecessary object creation
    ) =>
        drawBokehEffect(
            canvas,
            inputImage,
            inputImage,
            segmentations,
            foregroundThreshold,
            backgroundBlurAmount,
            edgeBlurAmount,
            flipHorizontal,
        );

    const drawOverlayEffect = (
        canvas: Canvas,
        inputImage: ProcessInputType,
        backgroundImage: CanvasImageSource | OffscreenCanvas,
        segmentations: Segmentation | Segmentation[],
        foregroundThreshold = 0.5,
        backgroundBlurAmount = 0,
        edgeBlurAmount = 3,
        flipHorizontal = false,
        // eslint-disable-next-line max-params -- avoid unnecessary object creation
    ) =>
        drawBokehEffect(
            canvas,
            inputImage,
            backgroundImage,
            segmentations,
            foregroundThreshold,
            backgroundBlurAmount,
            edgeBlurAmount,
            flipHorizontal,
        );

    const evaluateInput = async (inputImage: ProcessInputType | VideoFrame) => {
        const image = await renderImageToOffScreenCanvas(
            inputImage,
            'inputCanvas',
        );
        return image;
    };

    return {
        evaluateInput,
        renderImageToCanvas,
        drawBlurEffect,
        drawOverlayEffect,
        loadBackgroundImage,
        renderImageToOffScreenCanvas,
        renderImageDataToOffScreenCanvas,
        drawAndBlurImageOnOffScreenCanvas,
    };
};
