import React from 'react';
import {useTranslation} from 'react-i18next';

import type {ButtonProps} from '@pexip/components';
import {BadgeCounter, IconTypes, useLgDown} from '@pexip/components';

import {TestId} from '../../../test/testIds';
import {ResponsiveButton} from '../ResponsiveButton/ResponsiveButton.view';

export const ChatPanelToggleView: React.FC<
    ButtonProps & {
        newMessagesCount: number;
        isPanelOpen: boolean;
    }
> = ({isPanelOpen, newMessagesCount, ...props}) => {
    const {t} = useTranslation();
    const isLgDown = useLgDown();
    return (
        <BadgeCounter
            number={newMessagesCount}
            isVisible={!isPanelOpen && newMessagesCount > 0}
            isTruncated={newMessagesCount > 9}
        >
            <ResponsiveButton
                aria-label={t('meeting.toggle-chat', 'Toggle chat panel')}
                data-testid={TestId.ButtonChat}
                iconSource={IconTypes.IconChat}
                isActive={isPanelOpen}
                hideChildren={isLgDown}
                {...props}
            >
                {t('meeting.chat', 'Chat')}
            </ResponsiveButton>
        </BadgeCounter>
    );
};
