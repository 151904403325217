import React from 'react';
import cx from 'classnames';

import {ThemeConsumer} from '../../../themes/ThemeContext';
import type {ColorScheme} from '../../../types/variants';
import {TestId} from '../../../utils/testIds';

import styles from './LineStep.module.scss';

export const LineStep: React.FC<{
    className?: string;
    colorScheme?: ColorScheme;
    isActive?: boolean;
    isCompleted?: boolean;
    isDisabled?: boolean;
}> = ({
    className,
    colorScheme,
    isActive,
    isCompleted,
    isDisabled,
    ...props
}) => (
    <ThemeConsumer>
        {({colorScheme: defaultColorScheme}) => (
            <div
                className={cx(
                    styles.line,
                    styles[colorScheme ?? defaultColorScheme],
                    {
                        [styles.active]: isActive,
                        [styles.completed]: isCompleted,
                        [styles.disabled]: isDisabled,
                    },
                    className,
                )}
                data-testid={TestId.LineStep}
                {...props}
            />
        )}
    </ThemeConsumer>
);

export type LineStepProps = React.ComponentProps<typeof LineStep>;
