import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

import type {ButtonProps} from '@pexip/components';
import type {DeniedDevices} from '@pexip/media';
import {Icon, IconTypes} from '@pexip/components';

import {TestId} from '../../../test/testIds';
import {MuteInputTooltipContent} from '../MuteInputTooltip/MuteInputTooltipContent.view';
import type {DeviceStatusInfo, MuteState} from '../../types';
import {InMeetingInputControl} from '../InputControl/InMeetingInputControl.view';
import {useInputsState} from '../../hooks/useInputsState';
import {BackgroundBlurInputControl} from '../InputControl/InputControl.view';
import {isMediaLocked, isMediaMuted} from '../../utils/isMedia';

export const InMeetingInputControls: React.FC<
    ButtonProps & {
        audioMuteState: MuteState;
        isAudioInputAvailable?: boolean;
        isVideoInputAvailable?: boolean;
        deniedDevices?: DeniedDevices;
        handleAudioInput?: () => void;
        handlePresentation?: () => void;
        handleVideoInput?: () => void;
        inputStatusInfo: DeviceStatusInfo;
        isScreenShareSupported: boolean;
        localIsPresenting?: boolean;
        videoMuteState: MuteState;
        isMobileDevice?: boolean;
        enhancerEnd?: React.ReactNode;
        isBlurred?: boolean;
        segmentationEnabled?: boolean;
        toggleBlur?: () => void;
        learnHowToGrantAccessURL?: string;
        setShowHelpVideo: React.Dispatch<React.SetStateAction<boolean>>;
    }
> = ({
    audioMuteState,
    inputStatusInfo,
    localIsPresenting,
    handleAudioInput,
    handleVideoInput,
    handlePresentation,
    isScreenShareSupported,
    videoMuteState,
    deniedDevices,
    learnHowToGrantAccessURL,
    isMobileDevice = false,
    isBlurred,
    segmentationEnabled,
    toggleBlur,
    enhancerEnd,
    setShowHelpVideo,
    isAudioInputAvailable = true,
    isVideoInputAvailable = true,
    ...props
}) => {
    const [showDeniedDeviceTooltip, setShowDeniedDeviceTooltip] =
        useState(false);
    const isAudioInputMuted = isMediaMuted(audioMuteState);
    const isVideoInputMuted = isMediaMuted(videoMuteState);
    const {audioProps, videoProps, isAudioIconMuted, isVideoIconMuted} =
        useInputsState({
            isAudioInputMuted,
            isVideoInputMuted,
            inputStatusInfo,
            isMobileDevice,
        });

    const {t} = useTranslation();

    const nextAction = localIsPresenting ? 'stop' : 'start';
    const isSharing = nextAction === 'stop';
    const presentationAriaLabel = isSharing
        ? t('media.presentation.stop-share-screen', 'Stop sharing')
        : t('media.presentation.share-screen', 'Share screen');
    const isMicrophonePermissionsRejected =
        deniedDevices === 'microphone' ||
        deniedDevices === 'microphone-and-camera';
    const isCameraPermissionsRejected =
        deniedDevices === 'camera' || deniedDevices === 'microphone-and-camera';

    return (
        <>
            <InMeetingInputControl
                {...audioProps}
                isDisabled={
                    isMediaLocked(audioMuteState) || !isAudioInputAvailable
                }
                onClick={
                    isMicrophonePermissionsRejected
                        ? undefined
                        : handleAudioInput
                }
                tooltipText={
                    isMicrophonePermissionsRejected ||
                    isMediaLocked(audioMuteState) ? undefined : (
                        <MuteInputTooltipContent
                            muteText={audioProps['aria-label']}
                            isAudioMuted={isAudioInputMuted}
                        />
                    )
                }
                data-testid={
                    isAudioIconMuted
                        ? TestId.ButtonMeetingAudioInputMuted
                        : TestId.ButtonMeetingAudioInput
                }
                {...props}
                deniedDevice={
                    deniedDevices === 'microphone' ? deniedDevices : undefined
                }
                learnHowToGrantAccessURL={learnHowToGrantAccessURL}
                showDeniedDeviceTooltip={showDeniedDeviceTooltip}
                setShowDeniedDeviceTooltip={setShowDeniedDeviceTooltip}
                size={isMobileDevice ? 'large' : 'medium'}
                shouldShowTooltip={!isMobileDevice}
                setShowHelpVideo={setShowHelpVideo}
            />
            <InMeetingInputControl
                {...videoProps}
                isDisabled={
                    isMediaLocked(videoMuteState) || !isVideoInputAvailable
                }
                onClick={
                    isCameraPermissionsRejected ? undefined : handleVideoInput
                }
                tooltipText={`${videoProps['aria-label']} (C)`}
                data-testid={
                    isVideoIconMuted
                        ? TestId.ButtonMeetingVideoInputMuted
                        : TestId.ButtonMeetingVideoInput
                }
                {...props}
                deniedDevice={
                    isCameraPermissionsRejected ? deniedDevices : undefined
                }
                learnHowToGrantAccessURL={learnHowToGrantAccessURL}
                showDeniedDeviceTooltip={showDeniedDeviceTooltip}
                setShowDeniedDeviceTooltip={setShowDeniedDeviceTooltip}
                size={isMobileDevice ? 'large' : 'medium'}
                shouldShowTooltip={!isMobileDevice}
                setShowHelpVideo={setShowHelpVideo}
            />
            {isScreenShareSupported && (
                <InMeetingInputControl
                    onClick={handlePresentation}
                    data-testid={
                        isSharing
                            ? TestId.ButtonStopPresentation
                            : TestId.ButtonStartPresentation
                    }
                    aria-label={presentationAriaLabel}
                    tooltipText={presentationAriaLabel}
                    icon={<Icon source={IconTypes.IconPresentationOn} />}
                    isActive={localIsPresenting}
                    shouldShowTooltip={!isMobileDevice}
                    {...props}
                />
            )}
            {!!segmentationEnabled && !videoProps.hasError && (
                <BackgroundBlurInputControl
                    variant="translucent"
                    size={isMobileDevice ? 'large' : 'medium'}
                    isBlurred={isBlurred}
                    isTooltipVisible
                    isDisabled={isVideoInputMuted}
                    onClick={toggleBlur}
                    iconSize={isMobileDevice ? 'medium' : 'small'}
                    data-testid={
                        isBlurred
                            ? TestId.ButtonMeetingBackgroundEffectsOn
                            : TestId.ButtonMeetingBackgroundEffectsOff
                    }
                    {...props}
                />
            )}
            {enhancerEnd && enhancerEnd}
        </>
    );
};
