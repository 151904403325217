import {useState, useLayoutEffect, useEffect, useCallback} from 'react';

import type {PreviewStreamController} from '@pexip/media';

import {logger} from '../logger';

const usePreviewMedia = (controller: PreviewStreamController) => {
    const [previewMedia, setPreviewMedia] = useState(controller.media);

    useLayoutEffect(
        () =>
            controller.onMediaChanged(media => {
                setPreviewMedia(media);
            }),
        [controller],
    );

    return previewMedia;
};

const useUpdatingPreview = (controller: PreviewStreamController) => {
    const [updatingPreview, setUpdatingPreview] = useState(false);

    useEffect(
        () =>
            controller.onUpdatingPreview(updating => {
                setUpdatingPreview(updating);
            }),
        [controller],
    );

    return updatingPreview;
};

export const usePreviewControllerHandler = ({
    applyChanges,
    close,
    controller,
}: {
    applyChanges: Array<() => void>;
    close: () => void;
    controller: PreviewStreamController;
}) => {
    const [isSaving, setSaving] = useState(false);

    const previewMedia = usePreviewMedia(controller);
    const updatingPreview = useUpdatingPreview(controller);

    const handleSave = useCallback(async () => {
        logger.debug('Handle saving settings modal');

        setSaving(true);

        applyChanges.forEach(applyChange => applyChange());

        await controller?.applyChanges();

        setSaving(false);

        close();
    }, [applyChanges, close, controller]);

    return {
        handleSave,
        isSaving,
        previewMedia,
        updatingPreview,
    };
};

export type PreviewControllerHandler = ReturnType<
    typeof usePreviewControllerHandler
>;
