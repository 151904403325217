import cx from 'classnames';
import React, {useCallback, useState, useEffect, useRef} from 'react';

import type {
    FloatRootOverflow,
    AlignWithFloatRootTrigger,
} from '@pexip/components';
import {Draggable} from '@pexip/components';
import {useIsInWindowBottomHalf} from '@pexip/hooks';

import {interfacesMargin} from '../../constants';
import {
    isOverlappingSidePanel,
    useControlsRelativePosition,
    useSidePanelTransform,
} from '../../hooks';

import styles from './InMeetingDraggable.module.scss';

export interface InMeetingDraggablePosition {
    floatRootOverflow: FloatRootOverflow;
}

export const InMeetingDraggable = React.forwardRef<
    HTMLDivElement,
    React.ComponentProps<'div'> & {
        isSidePanelVisible: boolean;
        isSidePanelTransformEnabled?: boolean;
        isDisabled?: boolean;
        floatRoot?: React.RefObject<HTMLDivElement>;
        center?: boolean;
        isPhone?: boolean;
        beforeRepositionSideEffect?: () => void;
        onRepositionSideEffect?: (
            el: HTMLDivElement,
            position: InMeetingDraggablePosition,
        ) => void;
        shouldCaptureClick?: boolean;
        alignWithFloatRootTrigger?: AlignWithFloatRootTrigger;
        style?: React.CSSProperties;
    }
>(
    (
        {
            center = false,
            isSidePanelVisible,
            isSidePanelTransformEnabled = true,
            isDisabled,
            floatRoot,
            isPhone,
            className,
            beforeRepositionSideEffect,
            onRepositionSideEffect,
            shouldCaptureClick = true,
            alignWithFloatRootTrigger,
            children,
            style,
        },
        ref,
    ) => {
        let componentRef = useRef<HTMLDivElement>(null);
        componentRef = (ref as React.RefObject<HTMLDivElement>) ?? componentRef;

        const lastDraggablePos = useRef<[number, number] | null>(null);

        const [initiallyCentered, setCenterInitially] = useState(false);
        const [isDraggableControlled, setIsDraggableControlled] =
            useState(false);
        const {isInBottomHalf, update: updateIsInBottomHalf} =
            useIsInWindowBottomHalf();

        const onControlsOverlap = useCallback(() => {
            const element = componentRef.current;
            if (element) {
                updateIsInBottomHalf(element);
                element.style.removeProperty('top');
                element.style.removeProperty('bottom');
            }
        }, [componentRef, updateIsInBottomHalf]);

        const {isOverlappingControls, update: updateControlsRelativePosition} =
            useControlsRelativePosition(onControlsOverlap);

        useEffect(() => {
            if (isDraggableControlled && componentRef.current) {
                const el = componentRef.current;

                el.style.removeProperty('transition');
                el.ontransitionend = event => {
                    // we should ideally not leave inline transforms after the animation
                    // as it blocks class styles and doesn't behave well with window resizing
                    if (event.propertyName !== 'transform') {
                        el.ontransitionend = null;
                        return;
                    }
                    let animationFrameTS: number;
                    requestAnimationFrame(ts => {
                        animationFrameTS = ts;
                        el.style.transition = 'none';
                        el.style.left = `${el.getBoundingClientRect().left}px`;
                        el.style.removeProperty('transform');
                    });

                    const removeInlineTransition = () => {
                        requestAnimationFrame(ts => {
                            if (ts === animationFrameTS) {
                                // make sure this runs after the requested frame that sets transition to 'none'
                                removeInlineTransition();
                            } else {
                                el.style.removeProperty('transition');
                            }
                        });
                    };
                    removeInlineTransition();

                    el.ontransitionend = null;
                };
            }
        }, [isDraggableControlled, isSidePanelVisible]);

        const setNoSidePanelLeft = useSidePanelTransform(
            isSidePanelVisible,
            componentRef,
            isSidePanelTransformEnabled,
        );

        const onPositionChange = useCallback(
            (el: HTMLDivElement, isRestored = false) => {
                updateControlsRelativePosition(el);
                const elementRect = el.getBoundingClientRect();
                if (isOverlappingSidePanel(elementRect)) {
                    setNoSidePanelLeft(interfacesMargin);
                } else {
                    setNoSidePanelLeft(undefined);
                }
                if (!isRestored) {
                    lastDraggablePos.current = [el.offsetLeft, el.offsetTop];
                }
            },
            [updateControlsRelativePosition, setNoSidePanelLeft],
        );

        const onReposition = useCallback(
            (el: HTMLDivElement, floatRootOverflow: FloatRootOverflow) => {
                onPositionChange(el);
                if (onRepositionSideEffect) {
                    onRepositionSideEffect(el, {floatRootOverflow});
                }
            },
            [onPositionChange, onRepositionSideEffect],
        );

        const beforeReposition = useCallback(() => {
            if (componentRef.current) {
                componentRef.current.style.removeProperty('transform');
            }
            setIsDraggableControlled(true);
            if (beforeRepositionSideEffect) {
                beforeRepositionSideEffect();
            }
        }, [componentRef, beforeRepositionSideEffect]);

        useEffect(() => {
            if (!isDisabled) {
                setCenterInitially(false);
                if (componentRef.current && lastDraggablePos.current) {
                    componentRef.current.style.left = `${lastDraggablePos.current[0]}px`;
                    componentRef.current.style.top = `${lastDraggablePos.current[1]}px`;
                    onPositionChange(componentRef.current, true);
                }
            } else {
                setCenterInitially(true);
            }
        }, [isDisabled, onPositionChange, updateControlsRelativePosition]);

        const enableStyle = (shouldEnable: boolean | undefined) =>
            !isDisabled && shouldEnable;

        return (
            <Draggable
                isDisabled={isDisabled}
                floatRoot={floatRoot}
                onReposition={onReposition}
                beforeReposition={beforeReposition}
                shouldCaptureClick={shouldCaptureClick}
                ref={componentRef}
                alignWithFloatRootTrigger={alignWithFloatRootTrigger}
                className={cx(
                    {
                        [styles.inMeetingDraggable]: enableStyle(true),
                        [styles.bottomHalf]: enableStyle(isInBottomHalf),
                        [styles.overlapsControls]: enableStyle(
                            isOverlappingControls,
                        ),
                        [styles.center]: enableStyle(
                            center &&
                                !isDraggableControlled &&
                                !initiallyCentered,
                        ),
                        [styles.centerInitial]: enableStyle(
                            center &&
                                !isDraggableControlled &&
                                initiallyCentered,
                        ),
                        [styles.sidePanelOpen]: enableStyle(isSidePanelVisible),
                        [styles.phone]: enableStyle(isPhone),
                    },
                    className,
                )}
                style={style}
            >
                {children}
            </Draggable>
        );
    },
);

InMeetingDraggable.displayName = 'InMeetingDraggable';
export type InMeetingDraggableProps = React.ComponentProps<
    typeof InMeetingDraggable
>;
