import React, {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {Button, Panel, Tooltip, useSmUp} from '@pexip/components';

import type {
    BreakoutParticipants,
    BreakoutRoomAssignmentMode,
} from '../../types';
import {BreakoutRoom} from '../BreakoutRoom/BreakoutRoom.view';
import {BreakoutRoomsConfigurationToolbar} from '../BreakoutRoomsConfigurationToolbar/BreakoutRoomsConfigurationToolbar.view';
import {BreakoutRoomsPanelHeader} from '../BreakoutRoomsPanelHeader/BreakoutRoomsPanelHeader.view';
import {
    useBreakoutAddRoom,
    useBreakoutAssignParticipantsByMode,
    useBreakoutChangeParticipantRoom,
    useBreakoutParticipantDrag,
    useBreakoutRemoveRoom,
    useBreakoutRoomNameChange,
    useBreakoutShuffleParticipants,
    useBreakoutUpdateParticipants,
} from '../../hooks';
import {getBreakoutRooms} from '../../utils/getBreakoutRooms';
import {TestId} from '../../../test/testIds';
import type {ShuffleBreakoutParticipants} from '../../utils/shuffleBreakoutParticipants';
import {shuffleBreakoutParticipants} from '../../utils/shuffleBreakoutParticipants';

export const BreakoutSetupPanel: React.FC<
    React.PropsWithChildren<{
        currentParticipants: BreakoutParticipants;
        myIdentity?: string;
        openRoomsText: string;
        onOpenRooms: (participants: BreakoutParticipants) => void;
        numberOfRooms: number;
        assignmentMode: BreakoutRoomAssignmentMode;
        onClosePanel: () => void;
        onBack: () => void;
        onSettingsButtonClick: () => void;
        hideAddRoomButtonText?: boolean;
        hideShuffleButtonText?: boolean;
        shuffle?: ShuffleBreakoutParticipants;
    }>
> = ({
    currentParticipants,
    myIdentity,
    openRoomsText,
    onBack,
    onClosePanel,
    onOpenRooms,
    onSettingsButtonClick,
    hideAddRoomButtonText = false,
    hideShuffleButtonText = false,
    numberOfRooms = 0,
    shuffle = shuffleBreakoutParticipants,
    assignmentMode,
    children,
}) => {
    const {t} = useTranslation();
    const isSmUp = useSmUp();
    const [participants, setParticipants] = useState(currentParticipants);

    const {handleBreakoutRoomNameChange, duplicateRoomNameModal} =
        useBreakoutRoomNameChange({
            participants,
            setParticipants,
        });

    const changeParticipantRoom = useBreakoutChangeParticipantRoom({
        participants,
        setParticipants,
    });

    useBreakoutUpdateParticipants({
        currentParticipants,
        participants,
        setParticipants,
        changeParticipantRoom,
    });

    useBreakoutAssignParticipantsByMode({
        assignmentMode,
        myIdentity,
        numberOfRooms,
        setParticipants,
        shuffle,
    });

    const {
        targetRoomId: currentDragTarget,
        onParticipantDragging,
        onParticipantDrag,
        getBreakoutRoomRefCallback,
    } = useBreakoutParticipantDrag({
        participants,
        changeParticipantRoom,
    });

    const onRemoveRoom = useBreakoutRemoveRoom({setParticipants});

    const {doShuffle, isShuffling} = useBreakoutShuffleParticipants({
        myIdentity,
        setParticipants,
        shuffle,
    });

    const onAddRoom = useBreakoutAddRoom({setParticipants});

    const isNoBreakoutRooms = useMemo(
        () => getBreakoutRooms(participants).length < 1,
        [participants],
    );

    const openRoomsButton = (
        <Button
            onClick={() => onOpenRooms(participants)}
            modifier="fullWidth"
            isDisabled={isNoBreakoutRooms}
            data-testid={TestId.OpenBreakoutRooms}
        >
            {openRoomsText}
        </Button>
    );

    return (
        <>
            <Panel
                isRounded={isSmUp}
                headerPadding="none"
                headerContent={
                    <BreakoutRoomsPanelHeader
                        onBackClick={onBack}
                        onCloseClick={onClosePanel}
                    />
                }
                footerContent={
                    isNoBreakoutRooms ? (
                        <Tooltip
                            tooltipContainerClassName="d-flex"
                            text={t(
                                'breakouts.open-rooms-btn-disabled-tooltip',
                                'Minimum one breakout room is required',
                            )}
                            position="top"
                        >
                            {openRoomsButton}
                        </Tooltip>
                    ) : (
                        openRoomsButton
                    )
                }
            >
                <BreakoutRoomsConfigurationToolbar
                    onAddRoomButtonClick={onAddRoom}
                    onSettingsButtonClick={onSettingsButtonClick}
                    onShuffledButtonClick={doShuffle}
                    hideAddRoomButtonText={hideAddRoomButtonText}
                    hideShuffleButtonText={hideShuffleButtonText}
                    isShuffling={isShuffling}
                    className="pb-4"
                />
                {Array.from(participants.entries()).map(
                    ([roomId, roomParticipants]) => {
                        return (
                            <BreakoutRoom
                                id={roomId}
                                key={roomId}
                                name={roomId}
                                rooms={Array.from(participants.keys()).reduce(
                                    (acc, current) =>
                                        roomId === current
                                            ? acc
                                            : {
                                                  ...acc,
                                                  [current]: current,
                                              },
                                    {},
                                )}
                                myIdentity={myIdentity}
                                participants={roomParticipants}
                                isDragTarget={currentDragTarget === roomId}
                                onNameChanged={handleBreakoutRoomNameChange}
                                onParticipantDragging={onParticipantDragging}
                                onParticipantDrag={onParticipantDrag}
                                onParticipantRoomChange={changeParticipantRoom}
                                onRemoveRoom={onRemoveRoom}
                                ref={getBreakoutRoomRefCallback(roomId)}
                            />
                        );
                    },
                )}
            </Panel>
            {duplicateRoomNameModal}
            {children}
        </>
    );
};
