import React from 'react';
import cx from 'classnames';

import {Text} from '../../elements/Text/Text';
import {ThemeConsumer} from '../../../themes/ThemeContext';
import {FontVariant} from '../../../../design-tokens/constants';
import type {ColorScheme} from '../../../types/variants';
import {Icon, IconTypes} from '../Icon/Icon';
import {TestId} from '../../../utils/testIds';

import styles from './StepNumber.module.scss';

export const StepNumber: React.FC<
    React.ComponentProps<'div'> & {
        children?: never;
        className?: string;
        colorScheme?: ColorScheme;
        isActive?: boolean;
        isCompleted?: boolean;
        isDisabled?: boolean;
        stepNumber: string;
    }
> = ({
    className,
    colorScheme,
    isActive,
    isCompleted,
    isDisabled,
    stepNumber,
    ...props
}) => (
    <ThemeConsumer>
        {({colorScheme: defaultColorScheme}) => (
            <div
                className={cx(
                    styles.stepNumber,
                    styles[colorScheme ?? defaultColorScheme],
                    {
                        [styles.active]: isActive,
                        [styles.completed]: isCompleted,
                        [styles.disabled]: isDisabled,
                    },
                    className,
                )}
                data-testid={TestId.StepNumber}
                {...props}
            >
                <Text
                    variant="inherit"
                    fontVariant={FontVariant.BodyBold}
                    data-testid={TestId.StepNumberString}
                >
                    {isCompleted ? (
                        <Icon
                            data-testid={TestId.StepNumberCheckmark}
                            source={IconTypes.IconCheckmark}
                            size="mini"
                            className="d-flex"
                        />
                    ) : (
                        stepNumber
                    )}
                </Text>
            </div>
        )}
    </ThemeConsumer>
);

export type StepNumberProps = React.ComponentProps<typeof StepNumber>;
