import React from 'react';
import cx from 'classnames';

import type {ColorScheme} from '../../../types/variants';
import type {BaseSizeModifier} from '../../../types/sizes';

import styles from './SvgImage.module.scss';

export const SvgImage: React.FC<
    React.ComponentProps<'svg'> & {
        colorScheme?: ColorScheme;
        size?: BaseSizeModifier;
        source: React.ElementType;
    }
> = ({size, source: Svg, className, colorScheme, ...props}) => (
    <Svg
        className={cx(
            colorScheme && styles[colorScheme],
            size && styles[size],
            className,
        )}
        {...props}
    />
);

export type SvgImageProps = React.ComponentProps<typeof SvgImage>;
