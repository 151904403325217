const policy = {
    createScriptURL: (url: string) => {
        if (new URL(url, document.baseURI).origin !== window.location.origin) {
            throw new Error(
                `Trying to create script url not on same origin (${url} not on ${window.location.origin})`,
            );
        }
        return url;
    },
};

// Ambient definitions does not seem to be working, so we have to do this :(
const sameOriginPolicy =
    typeof window.trustedTypes !== 'undefined' &&
    window.trustedTypes.createPolicy
        ? window.trustedTypes.createPolicy('same-origin', policy)
        : policy;

export const loadScript = (path: string, id: string) =>
    new Promise<void>((resolve, reject) => {
        const scriptExist = document.getElementById(id);
        if (scriptExist) {
            return;
        }
        const removeScript = () => {
            document.head.removeChild(script);
        };
        const script = document.createElement('script');
        // Use a cast as typescript dom types do not support it yet.
        script.src = sameOriginPolicy.createScriptURL(path) as string;
        script.id = id;
        script.onload = () => {
            removeScript();
            resolve();
        };
        script.onerror = ev => {
            removeScript();
            reject(ev);
        };
        document.head.appendChild(script);
    });

export type WasmPaths = [string, string | undefined];

export const loadWasms = async (paths: WasmPaths[]): Promise<void> => {
    // TODO: map the wasm path
    await Promise.all(paths.map(([path]) => loadScript(path, path)));
};

export const loadTfjsCore = async (prodMode: boolean): Promise<unknown> => {
    const tfjs = await import('@tensorflow/tfjs-core');
    if (prodMode) {
        tfjs.enableProdMode();
    }
    return tfjs;
};
export const loadTfjsBackendWebGl = () =>
    import('@tensorflow/tfjs-backend-webgl');
