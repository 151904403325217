import type {RefObject} from 'react';
import {useCallback, useRef, useEffect} from 'react';

import {usePrevious} from '@pexip/hooks';

import {sidePanelWidth} from '../constants';

export const isOverlappingSidePanel = (elementRect: DOMRect) =>
    Math.floor(elementRect.left) <= sidePanelWidth ||
    Math.ceil(elementRect.left) <= sidePanelWidth;

export const useSidePanelTransform = (
    isSidePanelVisible: boolean,
    elementRef: RefObject<HTMLDivElement>,
    enabled = true,
) => {
    const prevIsSidePanelVisible = usePrevious(isSidePanelVisible);
    const lastLeft = useRef<number | undefined>();

    const setNoSidePanelLeft = useCallback((left?: number) => {
        lastLeft.current = left;
    }, []);

    useEffect(() => {
        if (isSidePanelVisible === prevIsSidePanelVisible) {
            return;
        }
        if (enabled && elementRef.current) {
            const element = elementRef.current;
            if (isSidePanelVisible) {
                const elementRect = element.getBoundingClientRect();
                if (isOverlappingSidePanel(elementRect)) {
                    lastLeft.current = elementRect.left;
                    element.style.transform = `translate(${
                        sidePanelWidth - element.offsetLeft
                    }px)`;
                }
            } else {
                if (lastLeft.current) {
                    element.style.transform = `translate(${
                        lastLeft.current - element.offsetLeft
                    }px)`;
                    lastLeft.current = undefined;
                }
            }
        }
    }, [enabled, elementRef, isSidePanelVisible, prevIsSidePanelVisible]);

    return setNoSidePanelLeft;
};
