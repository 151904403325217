import React from 'react';

import {AspectRatio, VideoWrapper, Video} from '@pexip/components';

import {TestId} from '../../../test/testIds';

import styles from './SelfViewSettings.module.scss';

export const SelfViewSettings: React.FC<
    React.PropsWithChildren<{
        mediaStream?: MediaStream;
    }>
> = ({children, mediaStream}) => (
    <>
        <AspectRatio className="mb-2">
            <VideoWrapper
                background="secondary"
                flexDirection="column"
                isFullHeight
            >
                {mediaStream && (
                    <Video
                        className={styles.selfViewSettingsVideo}
                        data-testid={TestId.SettingsVideoSelfview}
                        isMirrored={true}
                        muted
                        srcObject={mediaStream}
                    />
                )}
            </VideoWrapper>
        </AspectRatio>
        {children}
    </>
);
