import type {ReactElement} from 'react';
import React from 'react';
import cx from 'classnames';
import {useTranslation} from 'react-i18next';

import type {IconSource} from '@pexip/components';
import {
    InteractiveElement,
    Icon,
    IconTypes,
    NotificationTooltip,
    Text,
} from '@pexip/components';
import type {DeniedDevices} from '@pexip/media';

import {TestId} from '../../../test/testIds';
import {DeviceDeniedTooltip} from '../DeviceDeniedTooltip/DeviceDeniedTooltip.view';

import styles from './MissingDeviceAlert.module.scss';

export const MissingDeviceAlert: React.FC<{
    className?: string;
    deniedDevice?: DeniedDevices;
    learnHowToGrantAccessURL?: string;
    title: string;
    tooltip?: string | ReactElement;
    setIsTooltipVisible: (visible: boolean) => void;
    isTooltipVisible: boolean;
    icon?: IconSource;
    setShowHelpVideo?: React.Dispatch<React.SetStateAction<boolean>>;
    showTooltip?: boolean;
}> = ({
    className,
    deniedDevice,
    learnHowToGrantAccessURL,
    title,
    tooltip,
    setIsTooltipVisible,
    isTooltipVisible,
    icon = IconTypes.IconAlert,
    setShowHelpVideo,
    showTooltip = true,
    ...props
}) => {
    const {t} = useTranslation();

    const button = (
        <InteractiveElement
            htmlTag="div"
            className={cx(
                'pl-2',
                'pr-3',
                styles.missingDeviceButton,
                className,
            )}
            onClick={() => setIsTooltipVisible(!isTooltipVisible)}
            title={title}
            {...props}
        >
            <Icon
                source={icon}
                className={cx('mr-2', styles.warningIcon)}
                colorScheme="dark"
                size="compact"
            />
            <Text isTruncated colorScheme="light" variant="danger">
                {title}
            </Text>
        </InteractiveElement>
    );

    if (!showTooltip) {
        return button;
    }

    if (deniedDevice && setShowHelpVideo) {
        return (
            <DeviceDeniedTooltip
                learnHowToGrantAccessURL={learnHowToGrantAccessURL}
                deniedDevice={deniedDevice}
                onClose={() => setIsTooltipVisible(!isTooltipVisible)}
                visible={isTooltipVisible}
                data-testid={TestId.TooltipDeniedDevice}
                contentWrapperClassName={styles.deviceDeniedTooltipWrapper}
                mainWrapperClassName={styles.deviceDeniedTooltipMainWrapper}
                setShowHelpVideo={setShowHelpVideo}
            >
                {button}
            </DeviceDeniedTooltip>
        );
    }

    return (
        <NotificationTooltip
            header={title}
            text={tooltip || ''}
            position="topCenter"
            ctaFn={() => setIsTooltipVisible(!isTooltipVisible)}
            controlledIsVisible={isTooltipVisible}
            controlledOnChange={setIsTooltipVisible}
            testIdInner={TestId.NotificationToolTipDeviceError}
            mainWrapperClassName={styles.deviceDeniedTooltipMainWrapper}
            closeButtonAriaLabel={t('common.close-tooltip', 'Close tooltip')}
        >
            {button}
        </NotificationTooltip>
    );
};
