import React from 'react';

import {Button, Icon, IconTypes} from '@pexip/components';

import styles from './PanelHeaderWrapper.module.scss';

export const PanelHeaderWrapper: React.FC<
    React.PropsWithChildren & {onCloseClick?: () => void}
> = ({children, onCloseClick}) => (
    <div className={styles.container}>
        <div className={styles.title}>{children}</div>
        {onCloseClick && (
            <Button onClick={onCloseClick} size="compact" variant="translucent">
                <Icon size="compact" source={IconTypes.IconClose}></Icon>
            </Button>
        )}
    </div>
);
