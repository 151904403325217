import {createMediaStreamTrackProcessor} from '../processor';
import {createMediaStreamTrackGenerator} from '../generator';
import {createStreamTransformer} from '../transformer';
import {stopStreamTracks} from '../utils';

import {
    adaptInputFrameTransformer,
    nullTransformController,
} from './transformer';
import {
    toVideoElement,
    playVideo,
    createFrameCallbackRequest,
    createCanvas,
    getImageSize,
    getCanvasRenderingContext2D,
} from './utils';
import type {InputFrame, ProcessInputType} from './types';
import {
    AbortReason,
    PROCESSING_WIDTH,
    PROCESSING_HEIGHT,
    FRAME_RATE,
} from './constants';

type Track = MediaStreamVideoTrack | MediaStreamVideoTrackGenerator;
interface Options {
    signal?: AbortSignal;
}
export type ProcessVideoTrack = (
    track: MediaStreamVideoTrack,
    transformers: Array<Transformer<InputFrame, InputFrame>>,
    options?: Options,
) => Promise<Track>;

export const createVideoTrackProcessor =
    (): ProcessVideoTrack =>
    (track, transformers, {signal} = {}) => {
        if (!transformers.length) {
            return Promise.resolve(track);
        }
        const processor = createMediaStreamTrackProcessor({track});
        const trackGenerator = createMediaStreamTrackGenerator({kind: 'video'});
        let readable = processor.readable;
        transformers.forEach(transformer => {
            readable = readable.pipeThrough(
                createStreamTransformer(
                    adaptInputFrameTransformer(transformer),
                ),
                {signal},
            );
        });
        // Promise returned from `ReadableStream['pipeTo']` is only resolved
        // when the streaming is finished
        readable
            .pipeTo(trackGenerator.writable, {
                signal,
            })
            .catch(error => {
                // Ignore abort error
                if (
                    signal &&
                    !(
                        signal.aborted &&
                        (signal.reason === AbortReason.Close ||
                            // AbortSignal['reason'] is only supported from Chromium v98 or Firefox v97
                            !('reason' in AbortSignal.prototype))
                    )
                ) {
                    throw error;
                }
            });
        return Promise.resolve(trackGenerator);
    };

interface FallbackOptions {
    width?: number;
    height?: number;
    frameRate?: number;
}
export const createVideoTrackProcessorWithFallback =
    ({
        width = PROCESSING_WIDTH,
        height = PROCESSING_HEIGHT,
        frameRate = FRAME_RATE,
    }: FallbackOptions = {}): ProcessVideoTrack =>
    async (track, transformers, {signal} = {}) => {
        const [transformer] = transformers;
        if (!transformer?.transform) {
            return track;
        }
        if (transformers.length > 1) {
            throw new Error('Multi-transformer is NOT supported');
        }
        const outputCanvas = createCanvas(width, height);
        const ctx = getCanvasRenderingContext2D(outputCanvas, {alpha: false});
        const render = (input: ProcessInputType) => {
            if (!transformer.transform) {
                throw new Error('Transform is undefined');
            }
            return transformer.transform(
                input as CanvasImageSource,
                nullTransformController({
                    enqueue: frame => {
                        if (!frame) {
                            return;
                        }
                        const frameSize = getImageSize(frame);
                        if (frameSize.height !== outputCanvas.height) {
                            outputCanvas.height = frameSize.height;
                            outputCanvas.width = frameSize.width;
                        }
                        ctx.drawImage(
                            frame,
                            0,
                            0,
                            frameSize.width,
                            frameSize.height,
                        );
                    },
                    terminate: () => {
                        stop();
                    },
                }),
            ) as Promise<void>;
        };
        const runner = createFrameCallbackRequest(render, frameRate);
        const videoElement = toVideoElement(
            new MediaStream([track]),
            width,
            height,
        );

        await playVideo(videoElement);
        await runner.start(videoElement);
        const stream = outputCanvas.captureStream(frameRate);
        const [trackGenerated] = stream.getVideoTracks();
        if (!trackGenerated) {
            throw new Error('Canvas captureStream returns no video track');
        }
        const stop = () => {
            stopStreamTracks(stream);
            runner.stop();
        };
        signal?.addEventListener('abort', stop);
        return trackGenerated;
    };
