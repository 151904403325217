import React from 'react';
import cx from 'classnames';

import {IconTypes} from '../../elements/Icon/Icon';
import type {DropContentProps} from '../DropContent/DropContent';
import {DropContent} from '../DropContent/DropContent';
import {Button} from '../../elements/Button/Button';
import type {
    ButtonVariant,
    ColorScheme,
    TextVariant,
} from '../../../types/variants';
import {ThemeConsumer} from '../../../themes/ThemeContext';
import type {RoundIndicatorBaseTypeProps} from '../RoundIndicator/RoundIndicator';
import {RoundIndicator} from '../RoundIndicator/RoundIndicator';
import {getInitials} from '../../../utils/string';

import styles from './UserMenuDropContent.module.scss';

const inheritStylesFromParentButton = (): TextVariant => 'inherit';

export const UserMenuButton: React.FC<{
    isContentVisible: boolean;
    displayName?: string;
    variant?: ButtonVariant;
    title?: string;
    isDisabled?: boolean;
    colorScheme: ColorScheme;
}> = ({
    isContentVisible,
    displayName,
    variant = 'tertiary',
    title = 'User menu',
    isDisabled = false,
    colorScheme,
    ...buttonProps
}) => {
    const getRoundIndicatorProperties = (
        isContentVisible = false,
    ): RoundIndicatorBaseTypeProps =>
        displayName
            ? {
                  type: 'text',
                  text: getInitials(displayName),
              }
            : {
                  type: 'icon',
                  iconType: isContentVisible
                      ? IconTypes.IconClose
                      : IconTypes.IconMoreVertical,
                  title,
              };

    return (
        <Button
            {...buttonProps}
            isActive={isContentVisible}
            modifier="disc"
            size="large"
            variant={variant}
            colorScheme={colorScheme}
            isDisabled={isDisabled}
            className={cx(
                styles.mobileButton,
                isContentVisible && styles.active,
                isDisabled && styles.disabled,
            )}
        >
            <RoundIndicator
                modifier="noBackground"
                textVariant={inheritStylesFromParentButton()}
                iconSize="small"
                {...getRoundIndicatorProperties(isContentVisible)}
            />
        </Button>
    );
};

export const UserMenuDropContent: React.FC<
    DropContentProps & {
        displayName?: string;
        variant?: ButtonVariant;
        title?: string;
        isDisabled?: boolean;
    }
> = ({
    className,
    contentClassName,
    displayName,
    variant = 'tertiary',
    title = 'User menu',
    buttonLabel = 'Toggle user menu',
    isDisabled = false,
    ...dropContentProps
}) => (
    <ThemeConsumer>
        {({colorScheme: defaultColorScheme}) => (
            <DropContent
                className={className}
                contentClassName={contentClassName}
                buttonLabel={buttonLabel}
                interactiveElement={(buttonProps, isContentVisible) => (
                    <UserMenuButton
                        {...buttonProps}
                        isContentVisible={isContentVisible}
                        variant={variant}
                        colorScheme={defaultColorScheme}
                        isDisabled={isDisabled}
                        displayName={displayName}
                        title={title}
                    />
                )}
                {...dropContentProps}
            />
        )}
    </ThemeConsumer>
);

export type UserMenuDropContentProps = React.ComponentProps<
    typeof UserMenuDropContent
>;
