import React from 'react';
import {useTranslation} from 'react-i18next';

import {ConfirmationModal} from '@pexip/components';

import {TestId} from '../../../test/testIds';

export const StealPresentationModal: React.FC<{
    isOpen: boolean;
    presenterName?: string;
    onConfirm: () => void;
    onCancel: () => void;
}> = ({isOpen, presenterName, onConfirm, onCancel}) => {
    const {t} = useTranslation();
    const normalizedPresenterName = presenterName
        ? getPossessive(presenterName)
        : t('media.presentation.the-current', 'the current');

    return (
        <ConfirmationModal
            cancelButtonTestid={TestId.ButtonStealPresentationCancel}
            cancelButtonText={t('common.cancel', 'Cancel')}
            confirmButtonTestid={TestId.ButtonStealPresentationConfirm}
            confirmButtonText={t('media.presentation.take-over', 'Take over')}
            data-testid={TestId.ModalStealPresentation}
            description={t('media.presentation.this-will-end', {
                defaultValue:
                    'This will end {{normalizedPresenterName}} presentation and start your own.',
                normalizedPresenterName,
            })}
            isOpen={isOpen}
            onCancel={onCancel}
            onConfirm={onConfirm}
            title={t(
                'media-presentation.do-you-want-to-take-over',
                'Do you want to take over the presentation?',
            )}
        />
    );
};

const getPossessive = (noun: string) => {
    if (noun === '') {
        return noun;
    }

    const lastChar = noun.slice(-1);
    return lastChar.toLowerCase() === 's' ? `${noun}’` : `${noun}’s`;
};
