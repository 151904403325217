import type {RPCCall, Channel} from '@pexip/plugin-api';

import {infinityService} from '../services/InfinityClient.service';

export const handleInfinityCall = ({
    data,
    channel,
}: {
    data: RPCCall;
    channel: Channel;
}) => {
    if (data.rpc === 'conference:dialOut') {
        void infinityService.dial(data.payload).then(result => {
            if (!result || result.data.result.length === 0) {
                return;
            }
            channel.replyRPC({
                rpc: data.rpc,
                replyTo: data.id,
                payload: result,
            });
        });
    } else if (data.rpc === 'conference:sendMessage') {
        void infinityService.sendMessage(data.payload).then(result => {
            channel.replyRPC({
                rpc: data.rpc,
                replyTo: data.id,
                payload: result,
            });
        });
    } else if (data.rpc === 'conference:sendApplicationMessage') {
        void infinityService
            .sendApplicationMessage(data.payload)
            .then(result => {
                channel.replyRPC({
                    rpc: data.rpc,
                    replyTo: data.id,
                    payload: result,
                });
            });
    } else if (data.rpc === 'conference:lock') {
        void infinityService.lock(data.payload).then(result => {
            channel.replyRPC({
                rpc: data.rpc,
                replyTo: data.id,
                payload: result,
            });
        });
    } else if (data.rpc === 'conference:muteAllGuests') {
        void infinityService.muteAllGuests(data.payload).then(result => {
            channel.replyRPC({
                rpc: data.rpc,
                replyTo: data.id,
                payload: result,
            });
        });
    } else if (data.rpc === 'conference:setBandwidth') {
        infinityService.setBandwidth(data.payload);
        channel.replyRPC({
            rpc: data.rpc,
            replyTo: data.id,
            payload: undefined,
        });
    } else if (data.rpc === 'conference:setLayout') {
        void infinityService.setLayout(data.payload).then(result => {
            channel.replyRPC({
                rpc: data.rpc,
                replyTo: data.id,
                payload: result,
            });
        });
    } else if (data.rpc === 'conference:disconnectAll') {
        void infinityService.disconnectAll(data.payload).then(result => {
            channel.replyRPC({
                rpc: data.rpc,
                replyTo: data.id,
                payload: result,
            });
        });
    } else if (data.rpc === 'conference:sendRequest') {
        void infinityService
            .sendConferenceRequest(data.payload)
            .then(result => {
                channel.replyRPC({
                    rpc: data.rpc,
                    replyTo: data.id,
                    payload: result,
                });
            });
    } else if (data.rpc === 'conference:requestParticipants') {
        void infinityService.requestParticipants(data.payload).then(result => {
            channel.replyRPC({
                rpc: data.rpc,
                replyTo: data.id,
                payload: result,
            });
        });
    } else if (data.rpc === 'conference:breakout') {
        void infinityService.breakout(data.payload).then(result => {
            channel.replyRPC({
                rpc: data.rpc,
                replyTo: data.id,
                payload: result,
            });
        });
    } else if (data.rpc === 'conference:joinBreakoutRoom') {
        void infinityService.joinBreakoutRoom(data.payload).then(result => {
            channel.replyRPC({
                rpc: data.rpc,
                replyTo: data.id,
                payload: result,
            });
        });
    } else if (data.rpc === 'conference:closeBreakouts') {
        void infinityService.closeBreakouts().then(result => {
            channel.replyRPC({
                rpc: data.rpc,
                replyTo: data.id,
                payload: result,
            });
        });
    } else if (data.rpc === 'conference:closeBreakoutRoom') {
        void infinityService.closeBreakoutRoom(data.payload).then(result => {
            channel.replyRPC({
                rpc: data.rpc,
                replyTo: data.id,
                payload: result,
            });
        });
    } else if (data.rpc === 'conference:emptyBreakouts') {
        void infinityService.emptyBreakouts().then(result => {
            channel.replyRPC({
                rpc: data.rpc,
                replyTo: data.id,
                payload: result,
            });
        });
    } else if (data.rpc === 'conference:breakoutMoveParticipants') {
        void infinityService
            .breakoutMoveParticipants(data.payload)
            .then(result => {
                channel.replyRPC({
                    rpc: data.rpc,
                    replyTo: data.id,
                    payload: result,
                });
            });
    } else if (data.rpc === 'conference:currentRoomId') {
        channel.replyRPC({
            rpc: data.rpc,
            replyTo: data.id,
            payload: infinityService.roomId,
        });
    } else if (data.rpc === 'participant:transfer') {
        void infinityService.transfer(data.payload).then(result => {
            channel.replyRPC({
                rpc: data.rpc,
                replyTo: data.id,
                payload: result,
            });
        });
    } else if (data.rpc === 'participant:mute') {
        void infinityService.mute(data.payload).then(result => {
            channel.replyRPC({
                rpc: data.rpc,
                replyTo: data.id,
                payload: result,
            });
        });
    } else if (data.rpc === 'participant:muteVideo') {
        void infinityService.muteVideo(data.payload).then(result => {
            channel.replyRPC({
                rpc: data.rpc,
                replyTo: data.id,
                payload: result,
            });
        });
    } else if (data.rpc === 'participant:spotlight') {
        void infinityService.spotlight(data.payload).then(result => {
            channel.replyRPC({
                rpc: data.rpc,
                replyTo: data.id,
                payload: result,
            });
        });
    } else if (data.rpc === 'participant:admit') {
        void infinityService.admit(data.payload).then(result => {
            channel.replyRPC({
                rpc: data.rpc,
                replyTo: data.id,
                payload: result,
            });
        });
    } else if (data.rpc === 'participant:raiseHand') {
        void infinityService.raiseHand(data.payload).then(result => {
            channel.replyRPC({
                rpc: data.rpc,
                replyTo: data.id,
                payload: result,
            });
        });
    } else if (data.rpc === 'participant:setRole') {
        void infinityService.setRole(data.payload).then(result => {
            channel.replyRPC({
                rpc: data.rpc,
                replyTo: data.id,
                payload: result,
            });
        });
    } else if (data.rpc === 'participant:setTextOverlay') {
        void infinityService.setTextOverlay(data.payload).then(result => {
            channel.replyRPC({
                rpc: data.rpc,
                replyTo: data.id,
                payload: result,
            });
        });
    } else if (data.rpc === 'participant:sendDTMF') {
        void infinityService.sendDTMF(data.payload).then(result => {
            channel.replyRPC({
                rpc: data.rpc,
                replyTo: data.id,
                payload: result,
            });
        });
    } else if (data.rpc === 'participant:disconnect') {
        void infinityService.kick(data.payload).then(result => {
            channel.replyRPC({
                rpc: data.rpc,
                replyTo: data.id,
                payload: result,
            });
        });
    }
};
