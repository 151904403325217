import {ValidationError, NonJSONResponseError} from '../errors';

import type {Endpoints} from './types';
import {
    validateRequestTokenBody,
    validateRequestToken200,
    validateRequestToken403,
    validateRequestToken404,
    validateRequestToken415,
    validateRequestToken502,
    validateRequestToken504,
    validateRequestToken529,
    validateRefreshToken200,
    validateRefreshToken403,
    validateReleaseTokenBody,
    validateReleaseToken200,
    validateReleaseToken403,
} from './validation';

type RequestTokenResponse =
    | {status: 200; data: Endpoints['request_token']['200']}
    | {status: 403; data: Endpoints['request_token']['403']}
    | {status: 404; data: Endpoints['request_token']['404']}
    | {status: 415; data: Endpoints['request_token']['415']}
    | {status: 502; data: Endpoints['request_token']['502']}
    | {status: 504; data: Endpoints['request_token']['504']}
    | {status: 529; data: Endpoints['request_token']['529']};
export async function requestToken({
    fetcher,
    init,
    params,
    body,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['request_token']['Params'];
    body: Endpoints['request_token']['Body'];
    host: string;
}): Promise<RequestTokenResponse> {
    if (!validateRequestTokenBody(body)) {
        throw new ValidationError(validateRequestTokenBody.errors);
    }
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(
            params.conferenceAlias,
        )}/request_token`,
        {
            ...init,
            method: 'post',

            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            },
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateRequestToken200(data)) {
                throw new ValidationError(validateRequestToken200.errors);
            }
            return {
                status: res.status,
                data: data as Endpoints['request_token']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }

    if (res.status === 403) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateRequestToken403(data)) {
                throw new ValidationError(validateRequestToken403.errors);
            }
            return {
                status: res.status,
                data: data as Endpoints['request_token']['403'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }

    if (res.status === 404) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateRequestToken404(data)) {
                throw new ValidationError(validateRequestToken404.errors);
            }
            return {
                status: res.status,
                data: data as Endpoints['request_token']['404'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }

    if (res.status === 415) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateRequestToken415(data)) {
                throw new ValidationError(validateRequestToken415.errors);
            }
            return {
                status: res.status,
                data: data as Endpoints['request_token']['415'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }

    if (res.status === 502) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateRequestToken502(data)) {
                throw new ValidationError(validateRequestToken502.errors);
            }
            return {
                status: res.status,
                data: data as Endpoints['request_token']['502'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }

    if (res.status === 504) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateRequestToken504(data)) {
                throw new ValidationError(validateRequestToken504.errors);
            }
            return {
                status: res.status,
                data: data as Endpoints['request_token']['504'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }

    if (res.status === 529) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateRequestToken529(data)) {
                throw new ValidationError(validateRequestToken529.errors);
            }
            return {
                status: res.status,
                data: data as Endpoints['request_token']['529'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type RequestTokenMap = Endpoints['request_token'];

type RefreshTokenResponse =
    | {status: 200; data: Endpoints['refresh_token']['200']}
    | {status: 403; data: Endpoints['refresh_token']['403']};
export async function refreshToken({
    fetcher,
    init,
    params,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['refresh_token']['Params'];
    host: string;
}): Promise<RefreshTokenResponse> {
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(
            params.conferenceAlias,
        )}/refresh_token`,
        {
            ...init,
            method: 'post',
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateRefreshToken200(data)) {
                throw new ValidationError(validateRefreshToken200.errors);
            }
            return {
                status: res.status,
                data: data as Endpoints['refresh_token']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }

    if (res.status === 403) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateRefreshToken403(data)) {
                throw new ValidationError(validateRefreshToken403.errors);
            }
            return {
                status: res.status,
                data: data as Endpoints['refresh_token']['403'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type RefreshTokenMap = Endpoints['refresh_token'];

type ReleaseTokenResponse =
    | {status: 200; data: Endpoints['release_token']['200']}
    | {status: 403; data: Endpoints['release_token']['403']};
export async function releaseToken({
    fetcher,
    init,
    params,
    body,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['release_token']['Params'];
    body: Endpoints['release_token']['Body'];
    host: string;
}): Promise<ReleaseTokenResponse> {
    if (!validateReleaseTokenBody(body)) {
        throw new ValidationError(validateReleaseTokenBody.errors);
    }
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(
            params.conferenceAlias,
        )}/release_token`,
        {
            ...init,
            method: 'post',

            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            },
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateReleaseToken200(data)) {
                throw new ValidationError(validateReleaseToken200.errors);
            }
            return {
                status: res.status,
                data: data as Endpoints['release_token']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }

    if (res.status === 403) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateReleaseToken403(data)) {
                throw new ValidationError(validateReleaseToken403.errors);
            }
            return {
                status: res.status,
                data: data as Endpoints['release_token']['403'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type ReleaseTokenMap = Endpoints['release_token'];
