import React from 'react';
import cx from 'classnames';

import type {ExtendedSizeModifier} from '../../../types/sizes';
import {sizeToSpacing} from '../../../utils/helpers';

import styles from './List.module.scss';

export const List: React.FC<
    React.ComponentProps<'div'> & {spacing?: ExtendedSizeModifier}
> = ({children, className, spacing = 'small', ...props}) => (
    <div
        className={cx(styles.list, styles[sizeToSpacing(spacing)], className)}
        {...props}
    >
        {children}
    </div>
);
