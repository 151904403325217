import type {Dispatch, SetStateAction} from 'react';
import {useCallback} from 'react';

import type {MediaDeviceInfoLike} from '@pexip/media-control';
import type {PreviewInput, PreviewStreamController} from '@pexip/media';

import {logger} from '../logger';
import {onDeviceSelectChange} from '../utils/onDeviceSelectChange';
import type {DeviceError} from '../types';

import {useSelectedInput} from './useSelectedInput';

export const createPreviewAudioInputHook =
    ({
        get,
        getExpected,
        set,
    }: {
        get: () => MediaDeviceInfoLike | undefined;
        getExpected: () => MediaDeviceInfoLike | undefined;
        set: (value?: PreviewInput) => void;
    }) =>
    ({
        error,
        controller,
        setError,
    }: {
        error: DeviceError;
        controller: PreviewStreamController;
        setError: Dispatch<SetStateAction<DeviceError>>;
    }) => {
        const [preview] = useSelectedInput(
            controller.onAudioInputChanged,
            getExpected(),
        );

        const applyChanges = useCallback(() => {
            if (controller.audioInput?.deviceId !== get()?.deviceId) {
                const {audioInput} = controller;
                logger.debug({audioInput}, 'Save AudioInput');
                set(audioInput);
            }
        }, [controller]);

        const setPreview = onDeviceSelectChange(
            setError,
            controller?.updateAudioInput,
        );

        return {
            error,
            preview,
            applyChanges,
            setPreview,
        };
    };
